<div (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)" [attr.aria-labelledby]="formField?.getLabelId()" [formGroup]="form"
     class="iot-platform-ui-phone-number-input"
     fxLayout="row"
     fxLayoutAlign="start center"
     role="group"
>
    <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <input
                class="iot-platform-ui-phone-number-input__control"
                formControlName="number"
                matInput
                maxlength="20">
        <div *ngIf="control?.valid && (convertedPhoneNumber$ | async) !== null"
             class="iot-platform-ui-phone-number-input__warning"
             fxLayout="row">
            <div
                    class="iot-platform-ui-phone-number-input__warning_chip"
                    fxLayout="row"
                    fxLayoutAlign="start center">
                        <span class="fw-800">{{ 'FORM.WARNINGS.MOBILE_ALPHA_CHARACTERS' | translate }}
                            ({{ convertedPhoneNumber$ | async }})</span>
            </div>
        </div>
    </div>

</div>