<mat-sidenav-container (backdropClick)="onCloseComments()" data-cy="iot4bos-ui-device-events-shell" fxFlex
                       hasBackdrop="true">
  <mat-sidenav-content fxLayout="column">
    <iot-platform-ui-toolbar-v2
      (dispatchToolbarEvent)="onToolbarEvent($event)"
      [autoRefresh]="{
        counter: 100 - ((timerValue$ | async) / (deviceEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay) * 100,
        timeLeft: (deviceEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay - (timerValue$ | async),
        displaySpinner: (deviceEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.enabled
      }"
      [buttonList]="masterViewDeviceEventsButtonList"
      [favoriteViewConfiguration]="deviceEventFavoriteViewsConfiguration$ | async"
      [gridsConfiguration]="deviceEventGridsConfiguration$ | async"
      [isDataLoaded]="deviceEventsLoaded$ | async"
      [name]="'EVENTS.DEVICE_EVENTS' | translate"
      [size]="toolbarSize"
      [total]="totalDeviceEvents$ | async"
    >
    </iot-platform-ui-toolbar-v2>

    <iot-platform-ui-filter-engine
      (applyFilters)="onApplyFilters($event)"
      [currentFavoriteView]="currentFavoriteView$ | async"
      [currentFilters]="currentFilters$ | async"
      [displayActionButtons]="true"
      [expanded]="filterEngineOpened"
      [masterView]="'device-events'"
      [withFavoriteFilters]="true"
    >
    </iot-platform-ui-filter-engine>

    <grid-engine-grid-manager
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      (timerValueChanged)="_timerValue$.next($event)"
      [currentFilters]="currentFilters$ | async"
      [gridSort]="gridSort$ | async"
      [grid]="grid$ | async"
      [userPermissions]="userPermissions"
      [visibleNodeId]="selectedRowId"
      class="layout-master-view-engine-container"
    ></grid-engine-grid-manager>
  </mat-sidenav-content>

  <mat-sidenav #sidenav disableClose position="end">
    <shared-iot4bos-ui-comments-panel
      (commentsEvent)="onCommentsEvent($event)"
      [canDeleteComment]="canUpdateEvent"
      [canEditComment]="canUpdateEvent"
      [comments]="deviceEventComments()"
      [defaultConcept]="CONCEPT.EVENT"
      [filters]="commentsContexts"
      [loading]="deviceEventCommentsLoading()"
      [withFilters]="true"
      [withFooter]="canUpdateEvent"
      data-cy="iot4bos-ui-device-events-comments"
    >
    </shared-iot4bos-ui-comments-panel>
  </mat-sidenav>
</mat-sidenav-container>
