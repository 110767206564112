import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CardLoaderModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { Filter, PlatformRequest } from '@iot-platform/models/common';
import {
  Asset,
  AssetStatusName,
  AssetVariable,
  AssetVariableThreshold,
  Device,
  DeviceVariable,
  FormulaParameters,
  NORMALIZED_ASSET_VARIABLES
} from '@iot-platform/models/i4b';
import { DateFormatModule, InfoDisplayModule, NumberFormatPipeModule, TruncateModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { ValueUnitFormatPipe } from 'libs/iot-platform-pipes/src/lib/value-unit-format/value-unit-format.pipe';
import { AssetVariablesService } from 'libs/shared/src/lib/services/asset-variables.service';
import { BehaviorSubject, map, of, switchMap, tap } from 'rxjs';

import { GridEngineSettingsService } from '../../../services/grid-engine-settings.service';

@Component({
  standalone: true,
  imports: [
    CardLoaderModule,
    NgFor,
    AsyncPipe,
    NgIf,
    FlexModule,
    NumberFormatPipeModule,
    DateFormatModule,
    DetailPopupModule,
    InfoDisplayModule,
    TruncateModule,
    SharedModule,
    ValueUnitFormatPipe
  ],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-asset-variable-configure-read-popup',
  templateUrl: './asset-variable-configure-read-popup.component.html',
  styleUrls: ['./asset-variable-configure-read-popup.component.scss']
})
export class AssetVariableConfigureReadPopupComponent implements OnInit {
  assetVariable!: AssetVariable;
  parameters: { key: string; value: number }[] = [];
  isLoading = false;

  displaySourceAssetVariables = false;
  assetSource?: Asset;
  displaySourceDeviceVariable = false;
  deviceSource?: Device;
  variablesToDisplay: AssetVariable[] | DeviceVariable[] = [];

  editThresholdsOn = false;
  metadata$: BehaviorSubject<AssetVariable> = new BehaviorSubject(this.data.assetVariable);

  originalThresholds: AssetVariableThreshold[] = [];
  workingOnThresholds: AssetVariableThreshold[] = [];
  toSaveThresholds: AssetVariableThreshold[] = [];

  canUpdateAssetContent = false;
  canUpdateAsset = false;
  canUpdateThresholds = false;
  userPermnissions: string[] = [];
  normalizedVariable?: { name: string; unit: string; description: string };

  constructor(
    private readonly authzService: AuthorizationService,
    private readonly assetVariableService: AssetVariablesService,
    private readonly gridEngineSettingsService: GridEngineSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: { assetVariable: AssetVariable; asset: Asset }
  ) {
    this.canUpdateAssetContent = this.authzService.applyAuthorization(AuthorizationConcept.ASSET_CONTENT, AuthorizationType.UPDATE);
    this.canUpdateAsset = this.authzService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.UPDATE);
    this.canUpdateThresholds = (this.canUpdateAsset || this.canUpdateAssetContent) && this.data.asset.status.name !== AssetStatusName.decommissioned;
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.data && this.data.assetVariable.id) {
      this.assetVariableService
        .getAssetVariableById(this.data.assetVariable.id)
        .pipe(
          tap((assetVariable: AssetVariable) => this.metadata$.next(assetVariable)),
          switchMap((assetVariable: AssetVariable) => {
            this.assetVariable = assetVariable;
            this.originalThresholds = assetVariable.thresholds?.values ?? [];
            this.workingOnThresholds = [...this.originalThresholds];

            this.parameters = this.getFormattedParameters(this.assetVariable.formula?.parameters);

            this.displaySourceAssetVariables = Object.values(assetVariable.formula?.srcVariables ?? {})
              .map((srcVar) => srcVar.type)
              .includes('asset-variable');
            this.displaySourceDeviceVariable = Object.values(assetVariable.formula?.srcVariables ?? {})
              .map((srcVar) => srcVar.type)
              .includes('device-variable');

            if (this.displaySourceAssetVariables) {
              const assetId: string = Object.values(assetVariable.formula?.srcVariables ?? {})[0].originId as string;
              const assetVariablesIdsFilters: Filter[] = [
                ...new Set(
                  Object.values(assetVariable.formula?.srcVariables ?? {}).map((srcVar) => ({ criteriaKey: 'assetVariableId', value: srcVar.variableId }))
                )
              ];
              const request: PlatformRequest = { filters: assetVariablesIdsFilters, page: 0, limit: assetVariablesIdsFilters.length, urlParam: assetId };
              return this.assetVariableService.getAll(request).pipe(map((response) => response.data));
            } else if (this.displaySourceDeviceVariable) {
              return this.assetVariableService
                .getDeviceVariableById(Object.values(assetVariable.formula?.srcVariables ?? {})[0].variableId ?? '')
                .pipe(map((deviceVar) => [deviceVar]));
            } else {
              return of(undefined);
            }
          })
        )
        .subscribe((variablesToDisplay?: AssetVariable[] | DeviceVariable[]) => {
          this.isLoading = false;

          if (variablesToDisplay) {
            this.variablesToDisplay = variablesToDisplay;

            if (this.displaySourceAssetVariables) {
              this.assetSource = (this.variablesToDisplay[0] as AssetVariable)?.asset;
            } else if (this.displaySourceDeviceVariable) {
              this.deviceSource = (this.variablesToDisplay[0] as DeviceVariable)?.device;
            }
          }
        });

      this.setNormalizedVariable();
    }
  }

  editThresholds() {
    this.editThresholdsOn = !this.editThresholdsOn;
    this.workingOnThresholds = [...this.originalThresholds];
  }

  setNormalizedVariable(): void {
    let firstNormalizedVariable: { name: string; unit: string; description: string };
    NORMALIZED_ASSET_VARIABLES.find((element) => {
      const tempVar = element.variables.find((v) => v.name.toLowerCase() === this.data.assetVariable.name.toLowerCase());

      if (tempVar) {
        firstNormalizedVariable = tempVar;
      }
      return !!tempVar;
    });

    this.normalizedVariable = firstNormalizedVariable;
  }

  getTextualOperator(operator: string | undefined) {
    switch (operator) {
      case '=':
        return 'Equals';
      case '<':
        return 'Lower than';
      case '>':
        return 'Greater than';
      default:
        return '';
    }
  }

  saveThresholds() {
    this.editThresholdsOn = false;
    const newAssetVariable: AssetVariable = {
      ...this.assetVariable,
      thresholds: { ...this.assetVariable.thresholds, values: this.workingOnThresholds }
    };
    this.gridEngineSettingsService.updateAssetVariableThresholds(newAssetVariable);
    this.originalThresholds = [...this.workingOnThresholds];
  }

  cancelThresholds() {
    this.editThresholdsOn = false;
    this.workingOnThresholds = [...this.originalThresholds];
  }

  thresholdChanged(threshold: AssetVariableThreshold, input: number) {
    const toUpdate = {
      ...this.workingOnThresholds.find((t) => t.position === threshold.position),
      value: input * 1
    };
    this.workingOnThresholds[this.workingOnThresholds.findIndex((t) => t.position === threshold.position)] = toUpdate;
  }

  private getFormattedParameters(parameters: FormulaParameters): { key: string; value: number }[] {
    const parametersEntries = Object.entries(parameters);
    return parametersEntries.map((p) => ({ key: p[0], value: p[1] }));
  }
}
