import { Injectable } from '@angular/core';

import { PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { DeviceEvent } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';
import { UserPreferencesService } from '@iot-platform/users';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DeviceEventsService } from '../../../../../../shared/src/lib/services/device-events.service';
import { DeviceEventsByDeviceDbActions, DeviceEventsByDeviceUiActions } from '../actions';
import * as fromNavigation from '../reducers';

@Injectable()
export class DeviceEventsByDeviceEffects {
  loadDeviceEventsByDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.loadDeviceEventsByDevice),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getDeviceEventsByDevicePreviousDeviceId)))),
      switchMap(([action, previousDeviceId]) => {
        let request: PlatformRequest;
        if (!previousDeviceId || previousDeviceId === action.request.filters.find((filter) => filter.criteriaKey === 'deviceId').value) {
          request = { ...action.request };
        }
        if (previousDeviceId !== action.request.filters.find((filter) => filter.criteriaKey === 'deviceId').value) {
          request = { ...action.request, page: 0 };
        }
        return this.deviceEventsService.getDeviceEvents(request).pipe(
          map((response: PlatformResponse) =>
            DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceSuccess({
              response,
              deviceId: action.request.filters.find((filter) => filter.criteriaKey === 'deviceId').value
            })
          ),
          catchError((error) => of(DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceFailure({ error })))
        );
      })
    )
  );

  loadTotalActiveDeviceEventsByDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.loadTotalActiveDeviceEventsByDevice),
      switchMap((action) =>
        this.deviceEventsService.getDeviceEvents(action.request).pipe(
          map((response) => DeviceEventsByDeviceDbActions.loadTotalActiveDeviceEventsByDeviceSuccess({ totalActiveEvents: response.total })),
          catchError((error) => of(DeviceEventsByDeviceDbActions.loadTotalActiveDeviceEventsByDeviceFailure({ error })))
        )
      )
    )
  );

  loadDeviceEventsByDeviceAndLoadTotalActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.loadDeviceEventsByDevice),
      map((action) => {
        const newRequest: PlatformRequest = {
          ...action.request,
          limit: 0,
          page: 0,
          filters: [...action.request.filters, { criteriaKey: 'eventStatus', value: 'active' }]
        };
        return DeviceEventsByDeviceUiActions.loadTotalActiveDeviceEventsByDevice({ request: newRequest });
      })
    )
  );

  updateStatusByDeviceEventIdByDeviceThenLoadTotalActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getDeviceEventsByDevicePreviousDeviceId)))),
      map(([_, previousDeviceId]) => {
        const newRequest: PlatformRequest = {
          limit: 0,
          page: 0,
          filters: [
            { criteriaKey: 'eventStatus', value: 'active' },
            { criteriaKey: 'deviceId', value: previousDeviceId }
          ]
        };
        return DeviceEventsByDeviceUiActions.loadTotalActiveDeviceEventsByDevice({ request: newRequest });
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.updateStatusByDeviceEventIdByDevice),
      concatMap((action) =>
        this.deviceEventsService.putStatus(action.status).pipe(
          map((deviceEvent: DeviceEvent) => DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess({ deviceEvent })),
          catchError((error) => of(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceFailure({ error })))
        )
      )
    )
  );

  loadMvDeviceEventsByDeviceSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.loadMvDeviceEventsByDeviceSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsSuccess({ settings })),
          catchError((error) => of(DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.bulkUpdateStatusByDeviceEventIdByDevice),
      concatMap((action) => this.deviceEventsService.bulkUpdateStatus(action.deviceEventIds, action.status)),
      mergeMap((results) =>
        results.pipe(
          map((deviceEvent) => DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess({ deviceEvent })),
          catchError((error) => of(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsByDeviceUiActions.saveTableStateByDevice),
      switchMap((action) =>
        this.deviceEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: DeviceEvent; checked: DeviceEvent[] }) =>
            DeviceEventsByDeviceDbActions.saveTableByDeviceStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(DeviceEventsByDeviceDbActions.saveTableByDeviceStateFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsByDeviceDbActions.saveTableByDeviceStateFailure,
          DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceFailure,
          DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceFailure,
          DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsByDeviceUiActions.loadDeviceEventsByDevice,
          DeviceEventsByDeviceUiActions.updateStatusByDeviceEventIdByDevice,
          DeviceEventsByDeviceUiActions.loadMvDeviceEventsByDeviceSettings
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceSuccess,
          DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceFailure,
          DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess,
          DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceFailure,
          DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsFailure,
          DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsSuccess
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private deviceEventsService: DeviceEventsService,
    private notificationService: NotificationService,
    private userPrefService: UserPreferencesService,
    private store: Store<fromNavigation.State>
  ) {}
}
