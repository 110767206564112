import { Component, Input } from '@angular/core';

import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-detail-device-card',
  templateUrl: './detail-device-card.component.html',
  styleUrls: ['./detail-device-card.component.scss']
})
export class DetailDeviceCardComponent {
  @Input() device: Device;
  @Input() context: string;
}
