import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SectionHeaderAction } from './section-header-action.model';
import { SectionHeaderEvent } from './section-header-event.model';

@Component({
  selector: 'iot-platform-ui-section-header',
  standalone: true,
  imports: [FlexLayoutModule, MatIconModule, TranslateModule, NgClass, NgStyle, MatTooltipModule, MatButtonModule],
  templateUrl: './section-header.component.html',
  styleUrl: './section-header.component.scss'
})
export class SectionHeaderComponent {
  @Input() styles: any;
  @Input() title!: string;
  @Input() icon!: string;
  @Input() svgIcon!: string;
  @Input() cssClassName!: string;
  @Input() actions: SectionHeaderAction[] = [];

  @Output() dispatchEvent: EventEmitter<SectionHeaderEvent> = new EventEmitter<SectionHeaderEvent>();

  onDispatchEven(button: SectionHeaderAction): void {
    this.dispatchEvent.emit({
      type: button.action
    });
  }
}
