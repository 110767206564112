<mat-nav-list>
    <mat-list-item
            (click)="onSelectUser(user)"
            *ngFor="let user of sortedUsers"
            [disableRipple]="true"
            [lines]="1"
            class="hoverable fs-12">
        <span matListItemTitle>{{ user.firstname }} {{ user.lastname }}</span>
    </mat-list-item>
</mat-nav-list>
