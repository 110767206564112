<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ title$ | async }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content">
        <form [formGroup]="productForm" class="full-width p-20" fxLayout="column" fxLayoutAlign="space-between"
              fxLayoutGap="">
            <mat-form-field color="accent">
                <mat-label>{{ 'ADMIN.PRODUCT_CATALOGS.FORM.CATALOG' | translate }}</mat-label>
                <mat-select
                        (selectionChange)="initCatalogValidation()"
                        [placeholder]=""
                        formControlName="catalog"
                        required
                >
                    <!--(selectionChange)="checkIdUnicity()"-->
                    <mat-option *ngFor="let catalog of data.catalogs" [value]="catalog">{{ catalog.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="catalog.invalid">{{ errorMessage$ | async }}</mat-error>
            </mat-form-field>

            <mat-form-field color="accent">
                <mat-label>{{ 'ADMIN.PRODUCT_CATALOGS.PRODUCT_ID' | translate }}</mat-label>
                <input
                  #productIdInput
                  (change)="initCatalogValidation()"
                  [maxlength]="ID_MAX_LENGTH"
                  [placeholder]=""
                  formControlName="productId"
                  matInput
                  oninput="this.value = this.value.toUpperCase()"
                  required
                />
                <mat-error *ngIf="productId.invalid && !productId.value">{{ errorMessage$ | async }}</mat-error>
                <mat-error
                        *ngIf="productId.invalid && productId.errors.pattern">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.PATTERN_ERROR' | translate }}</mat-error>
                <mat-error
                        *ngIf="productId.invalid && productId.errors.productExist">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.DUPLICATE_ID' | translate }}</mat-error>
                <mat-hint align="end">{{ productIdInput.value?.length || 0 }}/{{ ID_MAX_LENGTH }}</mat-hint>
            </mat-form-field>

            <mat-form-field color="accent">
                <mat-label>{{ 'ADMIN.PRODUCT_CATALOGS.FORM.PRODUCT_NAME' | translate }}</mat-label>
                <input
                  #nameInput
                  [maxlength]="NAME_MAX_LENGTH"
                  [placeholder]=""
                  formControlName="name"
                  matInput
                  required
                />
                <mat-error *ngIf="name.invalid">{{ errorMessage$ | async }}</mat-error>
                <mat-hint align="end">{{ nameInput.value?.length || 0 }}/{{ NAME_MAX_LENGTH }}</mat-hint>
            </mat-form-field>
            <p>{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
        <button (click)="save()" [disabled]="!productForm.valid && productForm.dirty" class="button-regular"
                color="accent"
                mat-raised-button>
            {{ actionButtonLabel$ | async | translate }}
        </button>
    </mat-card-actions>
</mat-card>
