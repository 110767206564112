<div data-cy="iot-platform-login-callback" class="login-shell default-theme">
    <div class="login-shell-row" fxLayout="row" fxLayout.lt-md="column">
        <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%"></div>
        <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column">
            <h1 class="login-shell-title">{{ authConfig.appName }}</h1>

            <h2>{{ 'LOGIN.AUTH_IN_PROGRESS' | translate }}</h2>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
                <mat-progress-spinner [diameter]="90" color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
    </div>
</div>
