import { Injectable } from '@angular/core';
import { AbstractTagEditorService } from '@iot-platform/iot-platform-ui';
import { TagCategory } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AdminOrganizationsTagsPageActions } from '../features/admin-organizations/state/actions';
import * as fromOrganizations from '../features/admin-organizations/state/reducers';

@Injectable({
  providedIn: 'root'
})
export class TagEditorService extends AbstractTagEditorService {
  constructor(private readonly store: Store) {
    super();
  }

  getCategories$(): Observable<TagCategory[]> {
    return this.store.select(fromOrganizations.getInEditModeTags);
  }

  getListTagsByOrganizationForEdition(organizationId: string, withParents: boolean, concept: string): void {
    this.store.dispatch(AdminOrganizationsTagsPageActions.listTagsByOrganizationForEdition({ organizationId, withParents, concept }));
  }

  addTagToOrganization(tagToAdd: TagCategory): void {
    this.store.dispatch(AdminOrganizationsTagsPageActions.addTagToOrganizationForEdition({ tagToAdd }));
  }

  updateTagInOrganization(tagToUpdate: TagCategory): void {
    this.store.dispatch(AdminOrganizationsTagsPageActions.updateTagInOrganizationForEdition({ tagToUpdate }));
  }

  removeTagFromOrganization(tagToRemove: TagCategory): void {
    this.store.dispatch(AdminOrganizationsTagsPageActions.removeTagFromOrganizationForEdition({ tagToRemove }));
  }
}
