<div  class="chat-message-container" fxLayoutGap="4px" [style]="message?.customStyle?.()">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    @if (message?.icon?.()) {
      <mat-icon class="message-icon" [svgIcon]="message?.icon?.()" color="accent"></mat-icon>
    }
    @if (message?.author?.()) {
      <span class="message-author">{{ message?.author?.() }}</span>
    }
    @if (message?.date?.()) {
      <span class="message-date">{{ message?.date?.() | dateFormat }}</span>
    }
  </div>

  <div class="message-container">
    @if (message?.text?.()) {
      <div class="message-value">
        <p [innerHTML]="message?.text?.()  | linkify "></p>
      </div>
      @if (message?.withActions?.()) {
        <div class="message-actions">
          @for (action of message?.actions?.(); track action?.name) {
            @if (action?.canDo?.()) {
              <button class="action-button" mat-icon-button (click)="dispatchAction.emit({ name: action?.name?.(), value: message?.id?.() })">
                <mat-icon class="action-icon">{{ action?.icon?.() }}</mat-icon>
              </button>
            }
          }
        </div>
      }
    }
  </div>
</div>
