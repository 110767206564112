<form
  [formGroup]="form"
  class="chat-footer-container"
  [style.background-color]="footer.backgroundColor?.()"
  [style.color]="footer.textColor?.()"
  fxLayoutAlign="space-between center"
  fxLayoutGap="10px"
>
  <mat-form-field fxFlex="100%" hideRequiredMarker>
    <mat-label [style.color]="footer.textColor?.()">{{ footer.label?.() | translate }}</mat-label>
    <input class="chat-footer-input" [style.color]="footer.textColor?.()" formControlName="message" matInput maxlength="300" />
  </mat-form-field>

  <button
    (click)="dispatchAction.emit({ name: footer.action?.()?.name?.(), value: message.getRawValue() }); message.reset()"
    class="chat-footer-button"
    [disabled]="form.invalid"
    mat-icon-button
    type="submit"
  >
    <mat-icon>{{ footer.action?.()?.icon?.() }}</mat-icon>
  </button>
</form>
