@if (loading) {
  <mat-progress-bar class="login-select-profile-loader" color="accent" mode="indeterminate"></mat-progress-bar>
} @else if ((bpByEntities$ | async)?.length > 0) {
  <div class="bp-found">
    <h2 class="login-select-profile-title">{{ 'LOGIN.TITLE.SELECT_PROFILE' | translate }}</h2>
    <ul class="login-select-profile-entities">
      @for (bpByEntity of bpByEntities$ | async; track bpByEntity.entityId) {
        <li class="login-select-profile-entity">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="login-select-profile-entity-name">{{ bpByEntity.entityName }}</span>
            <hr class="login-select-profile-line" />
          </div>
          <ul class="login-select-profile-business-profiles">
            @for (businessProfile of bpByEntity.businessProfiles; track businessProfile.id) {
              <li class="login-select-profile-business-profile">
                <button
                  (click)="onSelectProfile(businessProfile)"
                  [disabled]="businessProfile.notCoveredByUserEntityAt"
                  class="login-select-profile-button"
                  data-cy="login-select-profile-business-profile-btn"
                  mat-button
                >
                  <span class="login-select-profile--bp-name">{{ businessProfile.name }}</span>
                  @if (businessProfile.notCoveredByUserEntityAt) {
                    <mat-icon
                      [matTooltip]="'HEADER.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate"
                      class="login-select-profile--info-icon"
                      matTooltipClass="regular-tooltip"
                    >info_outlined
                    </mat-icon>
                  }
                </button>
              </li>
            }
          </ul>
        </li>
      }
    </ul>
  </div>
}
@if (!loading && !(businessProfiles$ | async)?.length) {
  <div class="login-select-profile-no-bp-message">
    <br />
    <mat-icon [svgIcon]="'flag'" class="icon-flag"></mat-icon>
    <p class="title">One more step to use IoT-4BOS</p>
    <br />
    <p class="fw-600">
      account created
      <mat-icon class="icon-check"> done</mat-icon>
    </p>
    <p class="fw-600">Business profile assigned <span style="color: #bf5f68">pending...</span></p>
    <br /><br />
    <p>Business profile is assigned by your IoT-4BOS key user</p>
    <br /><br />
    <p>You will be informed by email once done.</p>
    <p>Feel free to contact your key user if necessary</p>
  </div>
}
