import { Injectable } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Contact, PlatformRequest, Product, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetEvent, AssetVariable, CommandType, Device, DeviceVariable, Event, Log, Site, VariablesTableFilters } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import * as fromNavigation from '../+state/reducers';
import { AssetEventsApi } from '../../features/asset-events/+state/asset-events.api';
import { AssetsFacade } from '../../features/assets/+state/facades/assets.facade';
import { DeviceEventsApi } from '../../features/device-events/+state/device-events.api';
import { DevicesFacade } from '../../features/devices/+state/facades/devices.facade';
import { ContactsFacade } from '../../features/sites/+state/facades/contacts.facade';
import { ExportSpreadsheetsFacade } from '../../features/sites/+state/facades/export-spreadsheets.facade';
import { GraphsFacade } from '../../features/sites/+state/facades/graphs.facade';
import { NotificationsFacade } from '../../features/sites/+state/facades/notifications.facade';
import { SitesFacade } from '../../features/sites/+state/facades/sites.facade';
import {
  AssetEventsByAssetLogsUiActions,
  AssetEventsBySiteLogsUiActions,
  DeviceEventsByDeviceLogsUiActions,
  DeviceEventsBySiteLogsUiActions,
  NavigationActions
} from './actions';

@Injectable()
export class NavigationApi {
  // SITE
  site$ = this.store.select(fromNavigation.getSite);
  assets$ = this.store.select(fromNavigation.getAssets);
  devices$ = this.store.select(fromNavigation.getDevices);
  devicesByStock$ = this.store.select(fromNavigation.getDevicesByStock);
  siteTags$ = this.store.select(fromNavigation.getSiteTags);
  siteLoaded = this.store.select(fromNavigation.getSiteLoaded);
  siteTagsLoaded = this.store.select(fromNavigation.getSiteTagsLoaded);
  siteAssociations$ = this.store.select(fromNavigation.getSiteAssociations);
  siteFullAssociations$ = this.store.select(fromNavigation.getFullAssociations);
  siteFullAssociationsLoaded$ = this.store.select(fromNavigation.getFullAssociationsLoaded);
  isSiteReadyFromDeletion$ = this.store.select(fromNavigation.selectIsSiteReadyFromDeletion);
  siteComments = this.sitesFacade.siteComments;
  siteCommentsLoading = this.sitesFacade.siteCommentsLoading;

  // STOCK DEVICES
  deviceCountByFamily$ = this.store.select(fromNavigation.selectDeviceCountByFamily);
  deviceCountByFamilyLoading$ = this.store.select(fromNavigation.selectDeviceCountByFamilyLoading);

  // SELECTED ASSET
  selectedAsset$ = this.store.select(fromNavigation.getSelectedAsset);
  assetLoaded = this.store.select(fromNavigation.getAssetLoaded);
  assetTags$ = this.store.select(fromNavigation.getAssetTags);
  assetTagsLoaded$ = this.store.select(fromNavigation.getAssetTagsLoaded);
  assetVariables$ = this.store.select(fromNavigation.getAssetVariables);
  assetVariablesLoaded$ = this.store.select(fromNavigation.getAssetVariablesLoaded);
  assetComments = this.assetsFacade.assetComments;
  assetCommentsLoading = this.assetsFacade.assetCommentsLoading;

  // SELECTED DEVICE
  selectedDevice$ = this.store.select(fromNavigation.getSelectedDevice);
  deviceLoaded = this.store.select(fromNavigation.getDeviceLoaded);
  deviceTags$ = this.store.select(fromNavigation.getDeviceTags);
  deviceTagsLoaded$ = this.store.select(fromNavigation.getDeviceTagsLoaded);
  deviceVariables$ = this.store.select(fromNavigation.getDeviceVariables);
  getDeviceVariablesTableFilters$ = this.store.select(fromNavigation.getDeviceVariablesTableFilters);
  deviceVariablesLoaded$ = this.store.select(fromNavigation.getDeviceVariablesLoaded);
  deviceComments = this.devicesFacade.deviceComments;
  deviceCommentsLoading = this.devicesFacade.deviceCommentsLoading;
  //

  siteId$ = this.store.select(fromNavigation.getSiteId);
  activeId$ = this.store.select(fromNavigation.getActiveId);
  origin$ = this.store.select(fromNavigation.getOrigin);
  pageType$ = this.store.select(fromNavigation.getPageType);
  data$ = this.store.select(fromNavigation.getData);

  // MASTER VIEWS ASSET EVENT BY ASSET
  selectedAssetEventByAsset$ = this.store.select(fromNavigation.getSelectedAssetEventByAsset);
  assetEventsByAssetLogsByAssetEventId$ = this.store.select(fromNavigation.getAllAssetEventsByAssetLogs);
  assetEventsByAssetLogsByAssetEventIdLoaded$ = this.store.select(fromNavigation.getAssetEventsByAssetLogsLoaded);

  // MASTER VIEWS ASSET EVENT BY SITE
  selectedAssetEventBySite$ = this.store.select(fromNavigation.getSelectedAssetEventBySite);
  assetEventsBySitePagination$ = this.store.select(fromNavigation.getAssetEventsBySitePagination);
  assetEventsBySiteLogsByAssetEventId$ = this.store.select(fromNavigation.getAllAssetEventsBySiteLogs);
  assetEventsBySiteLogsByAssetEventIdLoaded$ = this.store.select(fromNavigation.getAssetEventsBySiteLogsLoaded);

  // MASTER VIEWS DEVICE EVENT BY DEVICE
  selectedDeviceEventByDevice$ = this.store.select(fromNavigation.getSelectedDeviceEventByDevice);
  deviceEventsByDeviceLogsByDeviceEventId$ = this.store.select(fromNavigation.getAllDeviceEventsByDeviceLogs);
  deviceEventsByDeviceLogsByDeviceEventIdLoaded$ = this.store.select(fromNavigation.getDeviceEventsByDeviceLogsLoaded);

  // MASTER VIEWS DEVICE EVENT BY SITE
  selectedDeviceEventBySite$ = this.store.select(fromNavigation.getSelectedDeviceEventBySite);
  deviceEventsBySitePagination$ = this.store.select(fromNavigation.getDeviceEventsBySitePagination);
  deviceEventsBySiteLogsByDeviceEventId$ = this.store.select(fromNavigation.getAllDeviceEventsBySiteLogs);
  deviceEventsBySiteLogsByDeviceEventIdLoaded$ = this.store.select(fromNavigation.getDeviceEventsBySiteLogsLoaded);

  constructor(
    private readonly store: Store,
    private readonly authorizationService: AuthorizationService,
    private readonly assetEventsApi: AssetEventsApi,
    private readonly deviceEventsApi: DeviceEventsApi,
    private readonly sitesFacade: SitesFacade,
    private readonly assetsFacade: AssetsFacade,
    private readonly devicesFacade: DevicesFacade,
    private readonly graphsFacade: GraphsFacade,
    private readonly contactsFacade: ContactsFacade,
    private readonly notificationsFacade: NotificationsFacade,
    private readonly exportSpreadsheetsFacade: ExportSpreadsheetsFacade,
    private readonly storage: LocalStorageService
  ) {}

  public selectAsset(siteId: string, assetId: string) {
    this.store.dispatch(NavigationActions.selectAsset({ siteId, assetId }));
  }

  public selectDevice(siteId: string, deviceId: string) {
    this.store.dispatch(NavigationActions.selectDevice({ siteId, deviceId }));
  }

  public loadSiteById(siteId: string) {
    this.sitesFacade.loadSiteById(siteId);
  }

  public loadAssociationsBySiteId(siteId: string) {
    this.sitesFacade.loadAssociationsBySiteId(siteId);
  }

  public loadTagsBySiteId(siteId: string) {
    this.sitesFacade.loadTagsBySiteId(siteId);
  }

  public loadAssetsBySiteId(siteId: string) {
    this.sitesFacade.loadAssetsBySiteId({ limit: 50, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });
  }

  public loadDevicesBySiteId(siteId: string) {
    this.sitesFacade.loadDevicesBySiteId({ limit: 50, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });
  }

  public loadGraphsBySiteId(siteId: string) {
    if (this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.READ)) {
      this.graphsFacade.loadGraphsBySiteId(siteId);
    }
  }

  public changeStockPage(request: PlatformRequest) {
    this.store.dispatch(NavigationActions.changeStockPage({ request }));
  }

  public deleteSite(siteToDelete: Site) {
    this.sitesFacade.deleteSite(siteToDelete);
  }

  public updateSite(siteToUpdate: Site) {
    this.sitesFacade.updateSite(siteToUpdate);
  }

  public addAsset(assetToAdd: Asset) {
    this.assetsFacade.addAsset(assetToAdd);
  }

  public loadContactBySiteId(siteId: string) {
    if (this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ)) {
      this.contactsFacade.loadContacts(siteId);
    }
  }

  public loadNotificationBySiteId(siteId: string) {
    if (this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ)) {
      this.notificationsFacade.loadNotifications(siteId);
    }
  }

  public loadExportSpreadsheetsBySiteId(siteId: string) {
    if (this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ)) {
      this.exportSpreadsheetsFacade.loadExportSpreadsheets(siteId);
    }
  }

  public addContact(contact: Contact) {
    this.contactsFacade.addContact(contact);
  }

  public updateContact(contactToUpdate: Contact) {
    this.contactsFacade.updateContact(contactToUpdate);
  }

  public deleteContact(contactToDelete: Contact) {
    this.contactsFacade.deleteContact(contactToDelete);
  }

  public addDevice(deviceToAdd: Device) {
    this.devicesFacade.addDevice(deviceToAdd);
  }

  public updateTagsBySiteId(siteId: string, tagsToUpdate: TagCategory[]) {
    this.sitesFacade.updateTagsBySiteId(siteId, tagsToUpdate);
  }

  public clearSelectedItem(site: Site) {
    this.store.dispatch(NavigationActions.clearSelectedItem({ site }));
  }

  public loadTagsByAssetId(assetId: string) {
    this.assetsFacade.loadTagsByAssetId(assetId);
  }

  loadVariablesByAssetId(assetId: string) {
    this.assetsFacade.loadVariablesByAssetId(assetId);
  }

  loadTagsByDeviceId(deviceId: string) {
    this.devicesFacade.loadTagsByDeviceId(deviceId);
  }

  loadVariablesByDeviceId(deviceId: string) {
    this.devicesFacade.loadVariablesByDeviceId(deviceId);
  }

  loadDeviceCallLogById(deviceId: string) {
    this.devicesFacade.loadDeviceCallLogById(deviceId);
  }

  loadAssetById(assetId: string) {
    this.assetsFacade.loadAssetById(assetId);
  }

  addAssetByTemplateId(assetTemplateId: string, siteId: string) {
    this.assetsFacade.addAssetByTemplateId(assetTemplateId, siteId);
  }

  selectAssetAvecLeSite(selectedAsset: Asset) {
    this.store.dispatch(NavigationActions.selectAssetAvecLeSite({ selectedAsset }));
  }

  selectDeviceAvecLeSite(selectedDevice: Device) {
    this.store.dispatch(NavigationActions.selectDeviceAvecLeSite({ selectedDevice }));
  }

  selectLeSite(selectedSite: Site) {
    this.store.dispatch(NavigationActions.selectLeSite({ selectedSite }));
  }

  selectLeStock(stock: Site, request: PlatformRequest) {
    this.store.dispatch(NavigationActions.selectLeStock({ stock, request }));
  }

  openSiteDetail(site: Site, origin = 'site') {
    if (site.type === 'stock') {
      this.selectLeStock(site, { page: 0, limit: 25, filters: [{ criteriaKey: 'siteId', value: site.id }] });
    } else {
      this.selectLeSite(site);
    }
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, site.id ?? '');
  }

  openSiteDetailFromDevice(device: Device, origin = 'device') {
    if (device?.site) {
      if (device.site?.type === 'stock') {
        this.selectLeStock(device.site, { page: 0, limit: 25, filters: [{ criteriaKey: 'siteId', value: device.site.id }] });
      } else {
        this.selectLeSite(device.site);
      }
      this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
      this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, device.id ?? '');
    }
  }

  openAssetDetail(asset: Asset, origin = 'asset') {
    this.selectAssetAvecLeSite(asset);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, asset?.id ?? '');
  }

  openDeviceDetail(device: Device, origin = 'device') {
    this.selectDeviceAvecLeSite(device);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, device?.id ?? '');
  }

  loadDeviceById(id: string) {
    this.devicesFacade.loadDeviceById(id);
  }

  sendConnectorCommand(device: Device, command: any) {
    this.devicesFacade.sendConnectorCommand(device, command);
  }

  bulkSendConnectorCommand(devices: Device[], command: any) {
    this.devicesFacade.bulkSendConnectorCommand(devices, command);
  }

  updateDevice(deviceToMove: Device) {
    this.store.dispatch(NavigationActions.moveDevice({ deviceToMove }));
  }

  bulkUpdateDevices(devicesToMove: Device[]) {
    this.store.dispatch(NavigationActions.moveDevices({ devicesToMove }));
  }

  moveDevices(devicesToMove: Device[], originSite: Site): void {
    this.devicesFacade.moveDevices(devicesToMove, originSite);
  }

  deleteAsset(asset: Asset) {
    this.assetsFacade.deleteAsset(asset);
  }

  deleteAssetVariables(assetVariables: AssetVariable[]) {
    this.assetsFacade.deleteAssetVariables(assetVariables);
  }

  deleteDeviceVariables(deviceVariables: DeviceVariable[]) {
    this.devicesFacade.deleteVariable(deviceVariables);
  }

  updateAsset(asset: Asset) {
    this.assetsFacade.updateAsset(asset);
  }

  addAssetVariable(assetVariable: AssetVariable) {
    this.assetsFacade.addAssetVariable(assetVariable);
  }

  updateAssetVariable(assetVariable: AssetVariable) {
    this.assetsFacade.updateAssetVariable(assetVariable);
  }

  updateFollowedAssetVariable(assetVariable: AssetVariable) {
    this.assetsFacade.updateFollowedAssetVariable(assetVariable);
  }

  updateTagsByAssetId(id: string, tags: TagCategory[]) {
    this.assetsFacade.updateTagsByAssetId(id, tags);
  }

  assignProductToAsset(product: Product, asset: Asset) {
    this.assetsFacade.assignProductToAsset(product, asset);
  }

  removeProductFromAsset(product: Product, asset: Asset) {
    this.assetsFacade.removeProductFromAsset(product, asset);
  }

  sendAssetCommand(
    asset: Asset,
    command: {
      command: CommandType;
    }
  ) {
    this.assetsFacade.sendCommand(asset, command);
  }

  applyDeviceVariablesFilters(filters: Partial<VariablesTableFilters>) {
    this.store.dispatch(NavigationActions.applyDeviceVariablesTableFilters({ filters }));
  }

  loadEventDetailPopupDataByEvent(event: AssetEvent, view: string, filteredBy: string) {
    if (view === 'asset-events') {
      this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(event);
    }
    if (view === 'device-events') {
      this.deviceEventsApi.loadEventDetailPopupDataByDeviceEvent(event);
    }
    this.loadLogs(event, view, filteredBy);
  }

  loadLogs(event: Event, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteLogsUiActions.loadLogsByAssetEvent({ assetEvent: event }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetLogsUiActions.loadLogsByAssetEvent({ assetEvent: event }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteLogsUiActions.loadLogsByDeviceEvent({ deviceEvent: event }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceLogsUiActions.loadLogsByDeviceEvent({ deviceEvent: event }));
    }
  }

  loadLogsByConcept(concept: 'site' | 'asset' | 'device', element: Site | Asset | Device) {
    switch (concept) {
      case 'site':
        this.sitesFacade.loadComments(element.id as string);
        break;
      case 'asset':
        this.assetsFacade.loadComments(element as Asset);
        break;
      case 'device':
        this.devicesFacade.loadComments(element as Device);
        break;
    }
  }

  addComment(concept: 'site' | 'asset' | 'device', elementId: string, comment: string) {
    switch (concept) {
      case 'site':
        this.sitesFacade.addComment(elementId, comment);
        break;
      case 'asset':
        this.assetsFacade.addComment(elementId, comment);
        break;
      case 'device':
        this.devicesFacade.addComment(elementId, comment);
        break;
    }
  }

  editComment(concept: 'site' | 'asset' | 'device', elementId: string, comment: Log) {
    switch (concept) {
      case 'site':
        this.sitesFacade.editComment(elementId, comment);
        break;
      case 'asset':
        this.assetsFacade.editComment(elementId, comment);
        break;
      case 'device':
        this.devicesFacade.editComment(elementId, comment);
        break;
    }
  }

  deleteComment(concept: 'site' | 'asset' | 'device', elementId: string, commentId: string) {
    switch (concept) {
      case 'site':
        this.sitesFacade.deleteComment(elementId, commentId);
        break;
      case 'asset':
        this.assetsFacade.deleteComment(elementId, commentId);
        break;
      case 'device':
        this.devicesFacade.deleteComment(elementId, commentId);
        break;
    }
  }

  /* Master View Events By Site END */

  clearData() {
    this.store.dispatch(NavigationActions.clearData());
  }
}
