import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { UserAccount } from '@iot-platform/models/common';
import { Asset, Device, SiteAssociationsWithData } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-asset-device-associations',
  templateUrl: './asset-device-associations.component.html',
  styleUrls: ['./asset-device-associations.component.scss']
})
export class AssetDeviceAssociationsComponent implements OnChanges {
  @Input() associations: SiteAssociationsWithData;
  @Input() associationsLoaded: boolean;
  @Input() canCreateAsset = false;
  @Input() canCreateDevice = false;
  @Input() hasAssets = false;
  @Input() hasDevices = false;
  @Input() user: UserAccount;
  @Input() navigationMode: boolean;
  @Input() selectedItem: { type: string; id: string };

  @Output() dispatchAction: EventEmitter<{ action: string; data?: Asset | Device }> = new EventEmitter();
  @Output() updateUserPreferences: EventEmitter<UserAccount> = new EventEmitter();

  displayBy = 'assets';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('user') && changes.user.currentValue && changes.user.currentValue.preferences.siteAssociationDisplay) {
      this.displayBy = changes.user.currentValue.preferences.siteAssociationDisplay;
    }

    if (changes.hasOwnProperty('selectedItem') && changes.selectedItem.currentValue) {
      this.displayBy = changes.selectedItem.currentValue.type;
    }
  }

  onUpdateUserPreferences(siteAssociationDisplay: string): void {
    if (!this.navigationMode && this.displayBy !== siteAssociationDisplay) {
      const updateUser: UserAccount = {
        ...this.user,
        preferences: { ...this.user.preferences, siteAssociationDisplay }
      };

      this.updateUserPreferences.emit(updateUser);
    }

    this.displayBy = siteAssociationDisplay;
  }
}
