<mat-card-content class="p-20 mve-detail-popup-container" fxLayout="column" fxLayoutGap="20px">
    <section fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50%" fxLayout="column" fxLayoutGap="6px">
            <span (click)="ruleNameClicked.emit(data.selected)"
                  class="mve-detail-popup-title fw-600 mve-detail-popup-title-clickable">{{ data.selected?.name }}</span>
            <iot-platform-ui-detail-popup-display-property
                    property="{{ 'IOT_DICTIONARY.EVENT_TYPE' | translate }}:"
                    value="{{ data.selected?.algo }}"
            ></iot-platform-ui-detail-popup-display-property>
        </div>
        <div fxFlex="50%" fxLayout="column" fxLayoutGap="2px">
            <iot-platform-ui-detail-popup-display-property
                    property="{{ 'IOT_DICTIONARY.ENTITY' | translate }}:"
                    value="{{ data.selected?.entity.name }}"
            ></iot-platform-ui-detail-popup-display-property>
            <iot-platform-ui-detail-popup-display-property
                    property="{{ 'IOT_DICTIONARY.STATUS' | translate }}:"
                    value="{{ (data.selected?.isActive ? 'IOT_DICTIONARY.active' : 'IOT_DICTIONARY.disabled') | translate }}"
            ></iot-platform-ui-detail-popup-display-property>
        </div>
    </section>
    <section fxLayout="column" fxLayoutGap="10px">
        <iot-platform-ui-detail-popup-section-header
                headerTitle="{{ 'IOT_DICTIONARY.PARAMETERS' | translate }}"></iot-platform-ui-detail-popup-section-header>
        <div fxFlex fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
            <div fxFlex fxLayout="column" fxLayoutGap="6px">
                <iot-platform-ui-detail-popup-display-property
                        *ngFor="let param of data.selected?.params"
                        property="{{ param.name }} :"
                        value="{{ param.value }}"
                ></iot-platform-ui-detail-popup-display-property>
            </div>
            <div fxFlex fxLayout="column" fxLayoutGap="6px">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.SEVERITY' | translate }}:</span>
                    <iot-platform-ui-event-severity-display
                            [severity]="data.selected?.severity"></iot-platform-ui-event-severity-display>
                </div>
                <iot-platform-ui-detail-popup-display-property property="Class : "
                                                               value="{{ data.selected?.class }}"></iot-platform-ui-detail-popup-display-property>
            </div>
            <div fxFlex fxLayout="column" fxLayoutGap="6px">
                <iot-platform-ui-detail-popup-display-property
                        property="Back to normal : "
                        value="{{ data.selected?.backToNormal }}"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                        property="Concept : "
                        value="{{ data.selected?.concept }}"
                ></iot-platform-ui-detail-popup-display-property>
            </div>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="10px">
        <iot-platform-ui-detail-popup-section-header
                headerTitle="{{ 'IOT_DICTIONARY.FILTERS' | translate }}"></iot-platform-ui-detail-popup-section-header>
        <mat-chip-set *ngIf="data.selected?.filters" fxLayout="row wrap">
            <iot-platform-ui-chip *ngFor="let filter of data.selected.filters" [chipButton]="{ display: false }"
                                  [chip]="filter"></iot-platform-ui-chip>
        </mat-chip-set>
    </section>

    <section fxLayout="row" fxLayoutGap="10px">
        <iot-platform-ui-detail-popup-metadata-footer
                [data]="data.selected"></iot-platform-ui-detail-popup-metadata-footer>
    </section>
</mat-card-content>
