import { NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CardLoaderModule, WidgetsModule } from '@iot-platform/iot-platform-ui';
import { Graph } from '@iot-platform/models/common';
import { Asset, FollowedVariable } from '@iot-platform/models/i4b';
import { WidgetAction, WidgetEvent, WidgetInstance, WidgetOptions, WidgetSize, WidgetTypes } from '@iot-platform/models/widgets';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { GraphsService } from '../../../../../../shared/src/lib/services/graphs.service';
import { VariableChartDialogComponent } from '../../../../../../shared/src/lib/variable-chart/variable-chart-dialog/variable-chart-dialog.component';

@Component({
  standalone: true,
  imports: [NgIf, FlexLayoutModule, MatCardModule, MatToolbarModule, MatIconModule, MatButtonModule, MatDialogModule, CardLoaderModule, WidgetsModule],
  selector: 'iot-platform-ui-graph-list-popup',
  templateUrl: './graph-list-popup.component.html',
  styleUrls: ['./graph-list-popup.component.css']
})
export class GraphListPopupComponent implements OnInit, OnDestroy {
  widgets: WidgetInstance[] = [];
  loading = true;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GraphListPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { asset: Asset; followedVariable: FollowedVariable; siteId: string },
    private readonly graphsService: GraphsService
  ) {}

  ngOnInit(): void {
    this.graphsService
      .getGraphsByAssetVariableId(get(this.data, 'followedVariable.id'), get(this.data, 'siteId'))
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((graphs: Graph[]) => {
        this.initWidgets(graphs);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onDispatchEvent(event: WidgetEvent): void {
    if (event.action === WidgetAction.PREVIEW) {
      this.openGraph(event.widget.data);
    }
  }

  openGraph(graph: Graph) {
    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data: {
        variables: graph.variables,
        variableType: 'assetVariable',
        title: graph.name
      }
    });
  }

  private initWidgets(graphList: Graph[]): void {
    this.widgets = graphList.map((graph: Graph) => GraphListPopupComponent.buildWidget(graph));
  }

  private static buildWidget(graph: Graph): WidgetInstance {
    const options: WidgetOptions = { ...WidgetInstance.getOptionsByType(graph, {}), menu: { enabled: false }, size: WidgetSize.small } as WidgetOptions;
    return new WidgetInstance({
      type: WidgetTypes[graph.type],
      data: graph,
      options
    });
  }
}
