import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

import { BaseUser, BusinessProfile } from '@iot-platform/models/common';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

@Component({
  selector: 'iot-platform-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnChanges {
  @Input() logo: string;
  @Input() currentUser: BaseUser;
  @Input() currentUserLoading;
  @Input() businessProfiles: BusinessProfile[];
  @Input() selectedBusinessProfile: BusinessProfile;
  @Input() allowSwitchBusinessProfile: boolean;
  @Input() allowRedirectToMyProfile = true;
  @Input() allowAIChatBot = false;

  @Output() logout: EventEmitter<boolean> = new EventEmitter();
  @Output() logoClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() menuClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() businessProfileListClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() myProfile: EventEmitter<boolean> = new EventEmitter();
  @Output() changeBusinessProfile: EventEmitter<BusinessProfile> = new EventEmitter();
  @Output() chatBotClicked: EventEmitter<boolean> = new EventEmitter();

  selectedProfile: BusinessProfile;

  timezone$: Subject<string> = new ReplaySubject(0);

  constructor(
    public translateService: TranslateService,
    public storage: LocalStorageService
  ) {}

  get initials(): string {
    return this.currentUser ? `${this.currentUser.firstname.slice(0, 1).toUpperCase()}${this.currentUser.lastname.slice(0, 1).toUpperCase()}` : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedBusinessProfile')) {
      this.selectedProfile = changes.selectedBusinessProfile.currentValue;

      if (changes.selectedBusinessProfile.currentValue) {
        const tzName =
          this.selectedProfile.timezoneDetails && this.selectedProfile.timezoneDetails.name ? this.selectedProfile.timezoneDetails.name : 'NEED UPDATE';
        const tzOffset = this.selectedProfile.timezoneDetails && this.selectedProfile.timezoneDetails.offset ? this.selectedProfile.timezoneDetails.offset : '';
        this.timezone$.next(`${tzName} ${tzOffset}`);
      }
    }

    if (changes.hasOwnProperty('businessProfiles') && this.selectedProfile && !changes.businessProfiles.firstChange) {
      this.selectedProfile = changes.businessProfiles.currentValue?.find((bp) => bp.id === this.selectedBusinessProfile.id);
      const tzName =
        this.selectedProfile?.timezoneDetails && this.selectedProfile.timezoneDetails.name ? this.selectedProfile.timezoneDetails.name : 'NEED UPDATE';
      const tzOffset = this.selectedProfile?.timezoneDetails && this.selectedProfile.timezoneDetails.offset ? this.selectedProfile.timezoneDetails.offset : '';

      const elBp = { ...this.selectedProfile, timezone: tzOffset, timezoneDetails: { name: tzName, offset: tzOffset } };
      this.storage.set(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY, JSON.stringify(elBp));

      this.timezone$.next(`${tzName} ${tzOffset}`);
    }
  }

  onLogout() {
    this.logout.emit(true);
  }

  onLogoClick() {
    this.logoClicked.emit(true);
  }

  onToggleMenuClick() {
    this.menuClicked.emit();
  }

  switchBusinessProfile(profile: any) {
    this.changeBusinessProfile.emit(profile.value);
  }

  onMyProfileClick() {
    this.myProfile.emit();
  }

  onBusinessProfileListClicked(event: MouseEvent): void {
    this.currentUserLoading = true;
    event.stopPropagation();
    this.businessProfileListClicked.emit();
  }
}
