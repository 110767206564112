import { inject } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { DevicesService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, of, map, tap, concatMap } from 'rxjs';
import { DevicesCommentsActions } from '../actions';
import { NotificationService } from '@iot-platform/notification';

const loadDeviceComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesCommentsActions.loadComments),
      concatMap(({ device }) =>
        devicesService.loadComments(device).pipe(
          map((comments: Log[]) => DevicesCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(DevicesCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesCommentsActions.addComment),
      concatMap(({ deviceId, comment }) =>
        devicesService.addComment(deviceId, comment).pipe(
          map((addedComment: Log) => DevicesCommentsActions.addCommentSuccess({ comment: addedComment })),
          catchError((error) => of(DevicesCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesCommentsActions.editComment),
      concatMap(({ deviceId, comment }) =>
        devicesService.editComment(deviceId, comment).pipe(
          map((editedComment: Log) => DevicesCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(DevicesCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesCommentsActions.deleteComment),
      concatMap(({ deviceId, commentId }) =>
        devicesService.deleteComment(deviceId, commentId).pipe(
          map((deletedCommentId: string) => DevicesCommentsActions.deleteCommentSuccess({ commentId: deletedCommentId })),
          catchError((error) => of(DevicesCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DevicesCommentsActions.loadComments, DevicesCommentsActions.addComment, DevicesCommentsActions.editComment, DevicesCommentsActions.deleteComment),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesCommentsActions.loadCommentsSuccess,
        DevicesCommentsActions.loadCommentsFailure,
        DevicesCommentsActions.addCommentSuccess,
        DevicesCommentsActions.addCommentFailure,
        DevicesCommentsActions.editCommentSuccess,
        DevicesCommentsActions.editCommentFailure,
        DevicesCommentsActions.deleteCommentSuccess,
        DevicesCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DevicesCommentsActions.addCommentSuccess, DevicesCommentsActions.editCommentSuccess, DevicesCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesCommentsActions.loadCommentsFailure,
        DevicesCommentsActions.addCommentFailure,
        DevicesCommentsActions.editCommentFailure,
        DevicesCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const DevicesCommentsEffects = {
  loadDeviceComments$,
  addComment$,
  editComment$,
  deleteComment$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
