import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { get } from 'lodash';

@Component({
  selector: 'iot-platform-ui-detail-popup-metadata-footer',
  templateUrl: './detail-popup-metadata-footer.component.html',
  styleUrls: ['./detail-popup-metadata-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailPopupMetadataFooterComponent implements OnInit {
  @Input() data!: { createdAt: string; updatedAt: string; createdBy: string; updatedBy: string };

  hasCreator = false;
  hasUpdator = false;

  ngOnInit(): void {
    this.hasCreator = !!get(this.data, 'createdBy');
    this.hasUpdator = !!get(this.data, 'updatedBy');
  }
}
