import { NgComponentOutlet, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, EventEmitter, Input, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChatBody, ChatEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { CardLoaderModule } from '../../card-loader/card-loader.module';
import { ChatConversationLoaderComponent } from '../chat-conversation-loader/chat-conversation-loader.component';
import { ChatMessageComponent } from '../chat-message/chat-message.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ChatMessageComponent, CardLoaderModule, NgComponentOutlet, NgTemplateOutlet, ChatConversationLoaderComponent],
  selector: 'iot-platform-ui-chat-body',
  templateUrl: './chat-body.component.html',
  styleUrls: ['./chat-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBodyComponent {
  currentBody: WritableSignal<ChatBody | null> = signal(null);

  @Input() set body(value: ChatBody) {
    this.currentBody.set(value);
  }

  @Output() dispatchAction: EventEmitter<ChatEvent> = new EventEmitter<ChatEvent>();

  @ViewChild('chatBody') chatBody!: ElementRef;

  constructor() {
    effect(() => {
      const body = this.currentBody();
      if (body) {
        const timeout = setTimeout(() => {
          this.scrollHandler();
          clearTimeout(timeout);
        }, 100);
      }
    });
  }

  private scrollHandler() {
    if (this.chatBody) {
      const container = this.chatBody?.nativeElement;
      const scrollHeight: number = get(container, 'scrollHeight', 0);
      container.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    }
  }
}
