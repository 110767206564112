import { Component, Input, OnInit } from '@angular/core';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';

import { FollowedVariable } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-detail-followed-variable',
  templateUrl: './detail-followed-variable.component.html',
  styleUrls: ['./detail-followed-variable.component.scss']
})
export class DetailFollowedVariableComponent implements OnInit {
  @Input() followedVariable?: FollowedVariable;
  @Input() followedNumber?: number;

  processedValue!: string | null;
  isMultipleStateVariable = false;

  ngOnInit() {
    this.isMultipleStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(this.followedVariable?.unit);

    if (this.isMultipleStateVariable && !!this.followedVariable) {
      this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit(this.followedVariable);
    }
  }
}
