import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformDashboardsModule } from '@iot-platform/iot-platform-dashboards';
import { IotPlatformMapsModule } from '@iot-platform/iot-platform-maps';
import { DetailPopupCardsModule, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { CommentsPanelComponent } from '@iot-platform/shared/iot4bos-ui';
import { TranslateModule } from '@ngx-translate/core';
import { MasterViewEngineModule } from '../../../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { BulkManageTagDialogModule } from '../../../../components/bulk-manage-tag-dialog/bulk-manage-tag-dialog.module';
import { MvCellModule } from '../../../../components/mv-cell/mv-cell.module';
import { SiteDetailComponent } from '../../components/site-detail/site-detail.component';
import { StockSiteDetailPopupComponent } from '../../components/stock-site-detail-popup/stock-site-detail-popup.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    MvCellModule,
    DetailPopupCardsModule,
    FavoriteViewsModule,
    MasterViewEngineModule,
    GridEngineModule,
    IotPlatformMapsModule,
    BulkManageTagDialogModule,
    IotPlatformDashboardsModule,
    CommentsPanelComponent
  ],
  declarations: [SiteDetailComponent, StockSiteDetailPopupComponent]
})
export class SitesShellModule {}
