import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuthApi from './auth-api.reducer';
import * as fromAuthBusinessProfilesApi from './auth-business-profiles-api.reducer';
import * as fromAuthBusinessProfilesPage from './auth-business-profiles-page.reducer';
import * as fromAuthPage from './auth-page.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromAuthApi.authApiFeatureKey]: fromAuthApi.State;
  [fromAuthPage.authPageFeatureKey]: fromAuthPage.State;
  [fromAuthBusinessProfilesApi.authBusinessProfilesApiFeatureKey]: fromAuthBusinessProfilesApi.State;
  [fromAuthBusinessProfilesPage.authBusinessProfilesPageFeatureKey]: fromAuthBusinessProfilesPage.State;
}

export interface State {
  [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromAuthApi.authApiFeatureKey]: fromAuthApi.reducer,
    [fromAuthPage.authPageFeatureKey]: fromAuthPage.reducer,
    [fromAuthBusinessProfilesApi.authBusinessProfilesApiFeatureKey]: fromAuthBusinessProfilesApi.reducer,
    [fromAuthBusinessProfilesPage.authBusinessProfilesPageFeatureKey]: fromAuthBusinessProfilesPage.reducer
  })(state, action);
}

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectAuthApiState = createSelector(selectAuthState, (state: AuthState) => state[fromAuthApi.authApiFeatureKey]);

export const selectAuthPageState = createSelector(selectAuthState, (state: AuthState) => state[fromAuthPage.authPageFeatureKey]);

export const selectAuthBusinessProfilePageState = createSelector(
  selectAuthState,
  (state: AuthState) => state[fromAuthBusinessProfilesPage.authBusinessProfilesPageFeatureKey]
);

export const selectAuthBusinessProfileApiState = createSelector(
  selectAuthState,
  (state: AuthState) => state[fromAuthBusinessProfilesApi.authBusinessProfilesApiFeatureKey]
);

// BUSINESS PROFILES
export const {
  selectIds: getBusinessProfilesIds,
  selectEntities: getBusinessProfilesEntities,
  selectAll: getAllBusinessProfiles,
  selectTotal: getTotalBusinessProfiles
} = fromAuthBusinessProfilesApi.adapter.getSelectors(selectAuthBusinessProfileApiState);

export const selectSelectedBusinessProfileId = createSelector(selectAuthBusinessProfileApiState, fromAuthBusinessProfilesApi.getSelectedId);

export const selectSelectedBusinessProfile = createSelector(
  getBusinessProfilesEntities,
  selectSelectedBusinessProfileId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectSelectedBusinessProfileForAccount = createSelector(selectAuthApiState, fromAuthApi.getSelectedBusinessProfileForAccount);
export const selectSelectedEntityForSession = createSelector(selectAuthApiState, fromAuthApi.getSelectedEntityForSession);
export const selectSelectedBusinessProfileTimezone = createSelector(selectAuthApiState, fromAuthApi.getSelectedBusinessProfileTimezone);

export const selectLoggedIn = createSelector(selectAuthApiState, fromAuthApi.getLoggedIn);
export const selectBusinessProfileInitialized = createSelector(selectAuthApiState, fromAuthApi.getBusinessProfileInitialized);

export const selectAccount = createSelector(selectAuthApiState, fromAuthApi.getAccount);

export const selectIdToken = createSelector(selectAuthApiState, fromAuthApi.getIdToken);
export const selectLoggedInWithSSO = createSelector(selectAuthApiState, (state) => state.loggedInWithSSO);
export const selectSignOutPending = createSelector(selectAuthPageState, (state) => state.signOutPending);
export const selectAccessToken = createSelector(selectAuthApiState, (state) => state.accessToken);

export const selectRefreshToken = createSelector(selectAuthApiState, fromAuthApi.getRefreshToken);

export const selectBusinessProfilesForAccount = createSelector(selectAuthApiState, fromAuthApi.getBusinessProfilesForAccount);

export const selectCurrentUser = createSelector(selectAuthApiState, fromAuthApi.getCurrentUser);

export const selectSsoTokenExpiresAt = createSelector(selectAuthApiState, fromAuthApi.getSsoTokenExpiresAt);

export const selectUserId = createSelector(selectAuthApiState, fromAuthApi.getUserId);

export const selectPreferences = createSelector(selectAuthApiState, fromAuthApi.getPreferencesForAccount);

export const selectPrivileges = createSelector(selectAuthApiState, fromAuthApi.getPrivileges);

export const selectLoggedOut = createSelector(selectAuthApiState, fromAuthApi.getLoggedOut);

export const selectAuthPagePending = createSelector(selectAuthPageState, fromAuthPage.getPending);

export const selectAuthPageStepEmail = createSelector(selectAuthPageState, fromAuthPage.getStepEmail);

export const selectAuthPageStepPassword = createSelector(selectAuthPageState, fromAuthPage.getStepPassword);

export const selectAuthPageRequireNewPassword = createSelector(selectAuthPageState, fromAuthPage.getRequiredNewPassword);

export const selectAuthPage = createSelector(selectAuthPageState, fromAuthPage.getPending);

export const selectAuthPageError = createSelector(selectAuthPageState, fromAuthPage.getError);

export const selectAuthPageErrorSignIn = createSelector(selectAuthPageState, fromAuthPage.getErrorSignIn);

export const selectAuthPageErrorEmail = createSelector(selectAuthPageState, fromAuthPage.getErrorEmail);

export const selectAuthPageErrorPassword = createSelector(selectAuthPageState, fromAuthPage.getErrorPassword);

export const selectAccountLoading = createSelector(selectAuthPageState, fromAuthPage.getAccountLoading);

export const selectIsUserAdmin = createSelector(selectAuthApiState, fromAuthApi.getIsUserAdmin);

export const selectUserIsTopLevelAdmin = createSelector(
  selectIsUserAdmin,
  selectSelectedEntityForSession,
  (isUserAdmin, sessionEntity) => isUserAdmin && sessionEntity?.parentId === null
);

export const selectDisplayEmailPasswordLogin = createSelector(selectAuthPageState, fromAuthPage.getDisplayEmailPasswordLogin);
