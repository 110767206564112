<mat-nav-list>
    <mat-list-item
            (click)="selectRole.emit(role); selectedRole = role"
            *ngFor="let role of sortedRoles"
            [lines]="1"
            [ngClass]="{ selected: selectedRole && role ? selectedRole.id === role.id : false }"
            class="hoverable fs-12"
    >
        <h3 matListItemTitle>{{ role.name }}</h3>
        <button *ngIf="showActionMenu" [matMenuTriggerFor]="actionMenu" class="role-list-call-to-action" mat-icon-button
                matListItemMeta>
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #actionMenu="matMenu">
            <button (click)="onRenameRoleButtonClick(role)" mat-menu-item>
                <span>{{ 'ADMIN.ROLES_LIST.RENAME' | translate | uppercase }}</span>
            </button>
            <button (click)="onEditRoleButtonClick(role)" mat-menu-item>
                <span>{{ 'IOT_DICTIONARY.EDIT' | translate | uppercase }}</span>
            </button>
            <div [matTooltipDisabled]="!isDeletePossible(role)" [matTooltip]="getTooltip(role)"
                 matTooltipClass="regular-tooltip">
                <button (click)="onDeleteRoleButtonClick(role)" [disabled]="isDeletePossible(role)" mat-menu-item>
                    <span>{{ 'ADMIN.ROLES_LIST.DELETE' | translate | uppercase }}</span>
                </button>
            </div>
        </mat-menu>
    </mat-list-item>
</mat-nav-list>
