import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { ChipModule } from '../chip/chip.module';
import { DetailPopupCardsModule } from '../detail-popup-cards/detail-popup-cards.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { DeviceDetailsBasicInfoComponent } from './device-details-basic-info/device-details-basic-info.component';
import { DeviceDetailsConnectorsComponent } from './device-details-connectors/device-details-connectors.component';
import { DeviceInfoStatusModule } from './device-info-status/device-info-status.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    IotPlatformPipesModule,
    DetailPopupModule,
    DetailPopupCardsModule,
    CardLoaderModule,
    ChipModule,
    MatChipsModule,
    DeviceInfoStatusModule
  ],
  declarations: [DeviceDetailsBasicInfoComponent, DeviceDetailsConnectorsComponent],
  exports: [DeviceDetailsBasicInfoComponent, DeviceDetailsConnectorsComponent]
})
export class DeviceDetailsModule {}
