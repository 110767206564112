<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'aws credentials' }}</h1>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
    <form [formGroup]="form" class="p-20" fxFlex fxLayout="column" fxLayoutAlign="space-between">
      <mat-form-field color="accent">
        <mat-label>aws_access_key_id</mat-label>
        <input formControlName="aws_access_key_id" matInput required />
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>aws_secret_access_key</mat-label>
        <input formControlName="aws_secret_access_key" matInput required />
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>aws_session_token</mat-label>
        <input formControlName="aws_session_token" matInput required />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="save()" [disabled]="!form.valid" class="button-regular" color="accent" mat-raised-button>{{ 'IOT_DICTIONARY.SAVE' | translate }}</button>
  </mat-card-actions>
</mat-card>
