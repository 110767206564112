import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeviceVariable, I4BBulkOperationApiResponse } from '@iot-platform/models/i4b';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceVariablesService {
  constructor(
    @Inject('environment') private environment,
    public httpClient: HttpClient
  ) {}

  getById(deviceVariableId: string): Observable<DeviceVariable> {
    return this.httpClient.get<DeviceVariable>(`${this.environment.api.url + this.environment.api.endpoints.deviceVariables}/${deviceVariableId}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getManyByDeviceId(deviceId: string, limit = 3000, page = 0): Observable<DeviceVariable[]> {
    return this.httpClient
      .get<DeviceVariable[]>(`${this.environment.api.url + this.environment.api.endpoints.devices}/${deviceId}/variables?limit=3000&page=${page}`)
      .pipe(map((data: any) => data.content));
  }

  update(variable: DeviceVariable): Observable<DeviceVariable> {
    return this.httpClient.put<DeviceVariable>(`${this.environment.api.url}${this.environment.api.endpoints.deviceVariables}/${variable.id}`, variable);
  }

  delete(variable: DeviceVariable): Observable<DeviceVariable> {
    return this.httpClient
      .delete<DeviceVariable>(`${this.environment.api.url}${this.environment.api.endpoints.deviceVariables}/${variable.id}`)
      .pipe(map(() => variable));
  }

  deleteMany(deviceVariables: DeviceVariable[]): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.deleteDevicesVariables}`, {
      variablesIds: deviceVariables.map((variable) => variable.id)
    });
  }
}
