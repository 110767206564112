import { CommonModule, registerLocaleData } from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';

import localeFr from '@angular/common/locales/fr';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NumberFormatPipe } from './number-format.pipe';

@NgModule({
  declarations: [NumberFormatPipe],
  imports: [CommonModule],
  exports: [NumberFormatPipe]
})
export class NumberFormatPipeModule {
  static registerTestingLocal(): ModuleWithProviders<NumberFormatPipeModule> {
    registerLocaleData(localeFr, 'fr', localeFrExtra);
    return {
      ngModule: NumberFormatPipeModule
    };
  }
}
