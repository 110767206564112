import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ASSOCIATION_RULES, TemplateSuggestedVariableMatchingType, TemplateSuggestedVariable } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    TitleCasePipe
  ],
  selector: 'shared-iot4bos-asset-ui-source-variables-configuration',
  templateUrl: './source-variables-configuration.component.html'
})
export class SourceVariablesConfigurationComponent implements OnInit {
  @Input() srcVariables: TemplateSuggestedVariable | undefined = null;
  @Output() srcVariablesChange = new EventEmitter();

  associationRules = [...ASSOCIATION_RULES];
  ASSOCIATION_RULE = TemplateSuggestedVariableMatchingType;

  form: UntypedFormGroup = new UntypedFormGroup({
    suggestedNameControl: new UntypedFormControl('', [this.suggestedNameValidator()])
  });

  selectedAssociationRule: WritableSignal<string> = signal(TemplateSuggestedVariableMatchingType.NONE);
  suggestedNames: WritableSignal<string[]> = signal([]);

  infoMessage: Signal<string> = computed(() => {
    if (this.selectedAssociationRule() === (TemplateSuggestedVariableMatchingType.NONE as string)) {
      return 'ASSETS.VARIABLE_FORM.INFO_MESSAGE_NONE';
    } else if (this.selectedAssociationRule() === (TemplateSuggestedVariableMatchingType.EXACT as string)) {
      return 'ASSETS.VARIABLE_FORM.INFO_MESSAGE_EXACT';
    } else {
      return 'ASSETS.VARIABLE_FORM.INFO_MESSAGE_BEGINS_WITH';
    }
  });

  validSrcVariablesConfig: Signal<boolean> = computed(() => {
    if (this.selectedAssociationRule() === (TemplateSuggestedVariableMatchingType.NONE as string)) {
      return true;
    } else return this.selectedAssociationRule() !== (TemplateSuggestedVariableMatchingType.NONE as string) && this.suggestedNames()?.length > 0;
  });

  injector: Injector = inject(Injector);

  get suggestedNameControl(): AbstractControl {
    return this.form.get('suggestedNameControl');
  }

  ngOnInit(): void {
    this.initSrcVariables();
    this.initSrcVariablesChangeEffect();
  }

  onSelectedRuleChange(rule: TemplateSuggestedVariableMatchingType): void {
    this.selectedAssociationRule.set(rule);
    if (rule === this.srcVariables?.match?.type) {
      this.suggestedNames.set(this.srcVariables.match.suggestedNames);
    } else {
      this.suggestedNames.set([]);
    }
  }

  onAddSuggestedVariableName(): void {
    if (this.suggestedNameControl.getRawValue()) {
      this.suggestedNames.set([...this.suggestedNames(), this.suggestedNameControl.getRawValue() as string]);
      this.suggestedNameControl.reset();
    }
  }

  onSuggestedNameRemove(name: string): void {
    this.suggestedNames.set(this.suggestedNames().filter((elem) => elem !== name));
  }

  private initSrcVariables(): void {
    if (this.srcVariables) {
      this.selectedAssociationRule.set(this.srcVariables?.match?.type ?? TemplateSuggestedVariableMatchingType.NONE);
      this.suggestedNames.set(this.srcVariables?.match?.suggestedNames ?? []);
    }
  }

  private initSrcVariablesChangeEffect(): void {
    effect(
      () => {
        if (this.validSrcVariablesConfig()) {
          if (this.selectedAssociationRule() === (TemplateSuggestedVariableMatchingType.NONE as string)) {
            this.srcVariablesChange.emit({ '0': { name: '0', type: 'device-variable', match: null } });
          } else {
            this.srcVariablesChange.emit({
              '0': {
                name: '0',
                type: 'device-variable',
                match: {
                  type: this.selectedAssociationRule(),
                  suggestedNames: this.suggestedNames()
                }
              }
            });
          }
        } else {
          this.srcVariablesChange.emit(null);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private suggestedNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = control?.getRawValue()?.trim() as string;
      if (name) {
        return this.suggestedNames().includes(name) ? { duplicate: true } : null;
      }
      return null;
    };
  }
}
