import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardLoaderModule, WidgetsModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { HighchartsChartModule } from 'highcharts-angular';
import { AssetVariableSelectorListComponent } from './components/asset-variable-selector-list/asset-variable-selector-list.component';
import { AssetVariableSelectorModule } from './components/asset-variable-selector/asset-variable-selector.module';
import { CreateGraphDialogComponent } from './components/dialogs/create-graph-dialog/create-graph-dialog.component';
import { DashboardsShellComponent } from './containers/dashboards-shell/dashboards-shell.component';
import { GraphsShellComponent } from './containers/graphs-shell/graphs-shell.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    IotPlatformPipesModule,
    WidgetsModule,
    AssetVariableSelectorModule,
    AssetVariableSelectorListComponent,
    HighchartsChartModule,
    CardLoaderModule
  ],
  declarations: [GraphsShellComponent, CreateGraphDialogComponent, DashboardsShellComponent],
  exports: [GraphsShellComponent, CreateGraphDialogComponent, AssetVariableSelectorListComponent, DashboardsShellComponent]
})
export class IotPlatformDashboardsModule {}

//
