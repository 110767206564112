import { Inject, Injectable, Optional } from '@angular/core';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { EMPTY, Observable, tap } from 'rxjs';
import { FILTER_ENGINE_SETTINGS } from '../filter-engine-settings.providers';
import { FilterEngineOptions, FilterEngineSettings } from '../models';
import { AbstractFavoriteFilterEngineService } from './abstract-favorite-filter-engine.service';

@Injectable()
export class FilterEngineSettingsService extends ComponentStore<object> {
  account!: UserAccount;
  account$: Observable<UserAccount> = this.favoriteFilterEngineService?.getAccount$() ?? EMPTY;
  userPreferences!: UserPreferences;
  userPreferences$: Observable<UserPreferences> = this.favoriteFilterEngineService?.getUserPreferences$() ?? EMPTY;
  options!: FilterEngineOptions;
  private readonly init$ = this.effect(($trigger) =>
    $trigger.pipe(
      concatLatestFrom(() => [this.account$, this.userPreferences$]),
      tap(([_, account, userPreferences]) => {
        this.account = account;
        this.userPreferences = userPreferences;
        this.options = this.filterEngineSettings?.options;
      })
    )
  );

  constructor(
    @Optional() @Inject(FILTER_ENGINE_SETTINGS) private readonly filterEngineSettings: FilterEngineSettings,
    @Optional() private readonly favoriteFilterEngineService: AbstractFavoriteFilterEngineService
  ) {
    super();
    this.init$();
  }

  saveUserPreferences(user: UserAccount, preferences: UserPreferences): void {
    return this.favoriteFilterEngineService?.saveUserPreferences(user, preferences);
  }
}
