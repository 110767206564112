import {
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  signal,
  SimpleChanges,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';

import { GetUtils, SortUtil } from '@iot-platform/iot-platform-utils';
import { FavoriteView, IotToolbarEvent, ToolbarSize } from '@iot-platform/models/common';
import { Dashboard } from '@iot-platform/models/dashboards';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { IotToolbarMenuButtonOption, ToolbarAutoRefreshOptions, ToolbarFilterTextOptions, ToolbarPageType, ToolbarPageTypeOption } from './models';
import { IotToolbarDispatchActionType } from './toolbar-button/configuration-files/button-dispatch-action-type.enum';

import { ADD_BUTTON_CONFIG, CONFIGURE_GRIDS_BUTTON_CONFIG, DELETE_BUTTON_CONFIG, EDIT_BUTTON_CONFIG } from './toolbar-button/configuration-files/button.config';
import { IotToolbarDefaultButton } from './toolbar-button/configuration-files/default-button';
import { IotToolbarMenuButton } from './toolbar-button/configuration-files/menu-button';

interface FVGroup {
  name: string;
  favoriteViews: FavoriteView[];
}

interface GridGroup {
  name: string;
  grids: I4BGrid<any, any>[];
  shared: boolean;
}

@Component({
  selector: 'iot-platform-ui-toolbar-v2',
  templateUrl: './toolbar-v2.component.html',
  styleUrls: ['./toolbar-v2.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-mdc-select-fv-overlay-pane' }
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarV2Component implements OnInit, OnChanges, OnDestroy {
  @Input() name!: string;
  @Input() total!: number;
  @Input() withFavoriteViews = true;
  @Input() withGrids = true;
  @Input() pageTypeOptions: {
    visible: boolean;
    pageType: ToolbarPageType;
    options?: ToolbarPageTypeOption[];
  } = {
    visible: false,
    pageType: ToolbarPageType.GRID,
    options: []
  };

  @Input() autoRefresh: ToolbarAutoRefreshOptions = { counter: 0, timeLeft: 0, displaySpinner: false };
  @Input() isDataLoaded = true;

  @Input() buttonList?: Array<IotToolbarDefaultButton | IotToolbarMenuButton> = [];

  @Input() breadCrumbConfiguration?: {
    entityName: string;
    icon: string;
  };
  @Input() filterText: Partial<ToolbarFilterTextOptions> = {
    visible: false,
    debounceTime: 300,
    minLength: 0,
    autoFocus: false
  };
  @Input() size: string = ToolbarSize.REGULAR;
  @Output() dispatchToolbarEvent: EventEmitter<IotToolbarEvent> = new EventEmitter<IotToolbarEvent>();
  currentDashboardsConfiguration: WritableSignal<{
    sortedDashboards: Dashboard[];
    currentDashboard: Dashboard | undefined;
    isDashboardsLoading: boolean;
  } | null> = signal(null);
  currentGridsConfiguration: WritableSignal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> = signal(null);
  currentGrid: WritableSignal<I4BGrid<I4BGridOptions, I4BGridData> | undefined> = signal(undefined);
  groupedGrids: Signal<GridGroup[]>;
  groupedFavoriteViews: Signal<FVGroup[]>;
  currentFavoriteView: WritableSignal<FavoriteView | null> = signal(null);
  currentFavoriteViewConfiguration: WritableSignal<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> = signal(null);
  fvButtonList = [
    new IotToolbarDefaultButton(
      {
        ...ADD_BUTTON_CONFIG,
        tooltip: 'FILTER_ENGINE.CREATE_FAVORITE_VIEW',
        disabled: false,
        dispatchAction: { type: IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW, options: undefined }
      },
      1
    ),
    new IotToolbarDefaultButton(
      {
        ...EDIT_BUTTON_CONFIG,
        tooltip: 'FILTER_ENGINE.EDIT_FAVORITE_VIEW',
        dispatchAction: { type: IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW, options: undefined }
      },
      1
    ),
    new IotToolbarDefaultButton(
      {
        ...DELETE_BUTTON_CONFIG,
        tooltip: 'FILTER_ENGINE.DELETE_FAVORITE_VIEW',
        dispatchAction: { type: IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW, options: undefined }
      },
      1
    )
  ];
  gridButtons: Partial<IotToolbarDefaultButton>[] = [
    new IotToolbarDefaultButton(
      {
        ...CONFIGURE_GRIDS_BUTTON_CONFIG,
        displayButton: true,
        tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_GRIDS.DEFAULT',
        dispatchAction: { type: IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS, options: undefined }
      },
      1
    )
  ];
  filterTextControl: UntypedFormControl = new UntypedFormControl('');
  displayButtonSection = false;
  ToolbarPageType = ToolbarPageType;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor() {
    this.groupedFavoriteViews = computed(() => {
      const fvConfig = this.currentFavoriteViewConfiguration();
      const defaultValue = [
        { name: 'IOT_TOOLBAR.MY_FAVORITE_VIEWS', favoriteViews: [] },
        { name: 'IOT_TOOLBAR.SHARED', favoriteViews: [] }
      ];
      return fvConfig?.sortedFavoriteViews
        ? fvConfig?.sortedFavoriteViews?.reduce((acc: FVGroup[], favoriteView: FavoriteView) => {
            if (favoriteView.shared) {
              acc.find((gr) => gr?.name === 'IOT_TOOLBAR.SHARED')?.favoriteViews.push(favoriteView);
            }
            if (!favoriteView.shared) {
              acc.find((gr) => gr?.name === 'IOT_TOOLBAR.MY_FAVORITE_VIEWS')?.favoriteViews.push(favoriteView);
            }
            return acc;
          }, defaultValue)
        : defaultValue;
    });
    effect(
      () => {
        const fvConfig = this.currentFavoriteViewConfiguration();
        const currentFavoriteView = get(fvConfig, ['currentFavoriteView'], null);
        this.currentFavoriteView.set(currentFavoriteView);
        if (!currentFavoriteView) {
          this.setButtonStatus(this.buttonList);
        }
      },
      { allowSignalWrites: true }
    );

    this.groupedGrids = computed(() => {
      const gridConf = this.currentGridsConfiguration();
      const defaultValue = [
        { name: 'IOT_TOOLBAR.MY_GRIDS', grids: [], shared: false },
        { name: 'IOT_TOOLBAR.SHARED', grids: [], shared: true }
      ];

      return gridConf?.sortedGridsWithoutAppDefault
        ? gridConf.sortedGridsWithoutAppDefault.reduce((acc: GridGroup[], grid: I4BGrid<any, any>) => {
            if (grid.businessProfileId !== null) {
              acc.find((gr) => gr?.name === 'IOT_TOOLBAR.SHARED')?.grids.push(grid);
            }
            if (grid.businessProfileId === null) {
              acc.find((gr) => gr?.name === 'IOT_TOOLBAR.MY_GRIDS')?.grids.push(grid);
            }
            return acc;
          }, defaultValue)
        : defaultValue;
    });
    effect(
      () => {
        const gridConf = this.currentGridsConfiguration();
        const currentGrid = get(gridConf, ['currentGrid'], undefined);
        this.currentGrid.set(currentGrid);
      },
      { allowSignalWrites: true }
    );
  }

  @Input() set favoriteViewConfiguration(value: {
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  }) {
    this.currentFavoriteViewConfiguration.set(value);
  }

  @Input() set gridsConfiguration(value: {
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }) {
    this.currentGridsConfiguration.set(value);
  }

  @Input() set dashboardsConfiguration(value: { sortedDashboards: Dashboard[]; currentDashboard: Dashboard | undefined; isDashboardsLoading: boolean }) {
    this.currentDashboardsConfiguration.set(value);
  }

  ngOnInit(): void {
    this.listenForFilterInputChanges();
    this.manageExportButton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterText?.currentValue && changes.filterText.currentValue.defaultValue !== this.filterTextControl.getRawValue()) {
      this.filterTextControl.setValue(changes.filterText.currentValue.defaultValue, { emitEvent: false });
    }

    if (changes.hasOwnProperty('buttonList') && changes.buttonList.currentValue) {
      this.buttonList = changes.buttonList.currentValue.sort(SortUtil.sortByOrder);
      this.buttonList = this.buttonList?.map((button) => {
        if (button instanceof IotToolbarMenuButton) {
          button.menuOptions = button.menuOptions.sort(SortUtil.sortByOrder);
        }
        return button;
      });
      if (this.buttonList) {
        this.setDisplayButtonSection(this.buttonList);
        this.setButtonStatus(this.buttonList);
      }
    }
  }

  setDisplayButtonSection(buttonList: Array<IotToolbarDefaultButton | IotToolbarMenuButton>): void {
    this.displayButtonSection = buttonList.reduce((acc: boolean, value) => {
      acc = acc || value.displayButton;
      return acc;
    }, false);
  }

  setButtonStatus(buttonList: Array<IotToolbarDefaultButton | IotToolbarMenuButton>): void {
    setTimeout(() => {
      buttonList.forEach((button) => {
        if (button instanceof IotToolbarMenuButton) {
          button.menuOptions.forEach((menuOption: IotToolbarMenuButtonOption) => {
            const idx = this.fvButtonList.findIndex(
              (fvButton) => fvButton.tooltip === menuOption.label && fvButton.tooltip !== 'FILTER_ENGINE.CREATE_FAVORITE_VIEW'
            );
            this.fvButtonList[idx] = { ...this.fvButtonList[idx], disabled: menuOption.disableOption };
          });
        }
      });
    }, 1000);
  }

  onApplyFavoriteView(element: MatSelectChange) {
    if (this.buttonList) {
      this.setButtonStatus(this.buttonList);
    }
    this.dispatchToolbarEvent.emit({
      type: IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW,
      options: element.value ? element.value : null
    });
  }

  onApplyGrid(event: MatSelectChange) {
    this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.APPLY_GRID, options: { grid: event.value } });
  }

  onTogglePageType(event: MatButtonToggleChange): void {
    this.pageTypeOptions.pageType = event.value;
    this.manageExportButton();
    this.dispatchToolbarEvent.emit({
      type: IotToolbarDispatchActionType.TOGGLE_PAGE_TYPE,
      options: { pageType: event.value }
    });

    if (this.pageTypeOptions.pageType === ToolbarPageType.DASHBOARD && get(this.currentDashboardsConfiguration(), ['sortedDashboards'], []).length) {
      this.onDashboardSelection({ value: get(this.currentDashboardsConfiguration(), ['sortedDashboards'], [])[0] } as MatSelectChange);
    }
  }

  manageExportButton(): void {
    this.buttonList?.map((button) => {
      if (button instanceof IotToolbarDefaultButton && button.dispatchAction.type === IotToolbarDispatchActionType.EXPORT_DATA) {
        button.disabled = this.pageTypeOptions.pageType === ToolbarPageType.MAP;
      }
    });
  }

  onDashboardSelection(event: MatSelectChange): void {
    this.dispatchToolbarEvent.emit({
      type: IotToolbarDispatchActionType.APPLY_DASHBOARD,
      options: { dashboard: event.value }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private listenForFilterInputChanges(): void {
    this.filterTextControl.valueChanges
      .pipe(
        debounceTime(Number(GetUtils.get(this.filterText, 'debounceTime', 300))),
        tap((term) => {
          if (term === null || term === undefined || !term.length || term.length < GetUtils.get(this.filterText, 'minLength', 0)) {
            this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.FILTER_TEXT_CHANGE, options: null });
          }
        }),
        filter((term: string) => !!term && term.length >= Number(GetUtils.get(this.filterText, 'minLength', 0)))
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((term: string) => {
        this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.FILTER_TEXT_CHANGE, options: term });
      });
  }
}
