import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { AssetVariableSelectorModule } from './../../../../../../../iot-platform-dashboards/src/lib/components/asset-variable-selector/asset-variable-selector.module';
import { ExportSpreadsheetCreationFormComponent } from './export-spreadsheet-creation-form.component';
import { AssetVariableSelectorListComponent } from '@iot-platform/iot-platform-dashboards';

@NgModule({
  declarations: [ExportSpreadsheetCreationFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatCardModule,
    IotPlatformPipesModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    AssetVariableSelectorModule,
    AssetVariableSelectorListComponent,
    MatSlideToggleModule,
    MatTabsModule
  ]
})
export class ExportSpreadsheetCreationFormModule {}
