import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'iot-platform-ui-timezone-autocomplete-field',
  templateUrl: './timezone-autocomplete-field.component.html',
  styleUrls: ['./timezone-autocomplete-field.component.scss']
})
export class TimezoneAutocompleteFieldComponent {
  @Input() data: any;
  @Input() currentFilters$!: Observable<Filter[]>;
  @Input() currentFiltersSize = 0;
  @Input() maxFilters = 20;

  @Output() dispatchFilterValue: EventEmitter<Filter> = new EventEmitter<Filter>();

  onSelectionChange(value: string): void {
    const newFilter: Filter = {
      value,
      label: value,
      criteriaKey: this.data.criteriaKey,
      criteriaLabel: this.data.criteriaLabel
    };
    this.dispatchFilterValue.emit(newFilter);
  }
}
