import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [MatIconModule, NgClass, FlexLayoutModule, NgIf, MatRippleModule],
  selector: 'iot-platform-ui-default-card',
  templateUrl: './default-card.component.html',
  styleUrls: ['./default-card.component.scss']
})
export class DefaultCardComponent {
  @Input() title!: string;
  @Input() backgroundClass!: string;
  @Input() withCustomButton = false;
}
