import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { noop, of } from 'rxjs';
import { AbstractTagEditorService } from './abstract-tag-editor.service';
import { TagEditorService } from './tag-editor.service';

export { AbstractTagEditorService };

export function provideTagEditorSettings(settings: { tagEditorService: Type<AbstractTagEditorService> }): EnvironmentProviders {
  return makeEnvironmentProviders([TagEditorService, { provide: AbstractTagEditorService, useClass: settings.tagEditorService }]);
}

export function provideTagEditorTestingSettings(): EnvironmentProviders {
  return makeEnvironmentProviders([
    TagEditorService,
    {
      provide: AbstractTagEditorService,
      useValue: {
        getCategories$: () => of([]),
        listTagsByOrganizationForEdition: () => noop(),
        addTagToOrganization: () => noop(),
        updateTagInOrganization: () => noop(),
        removeTagFromOrganization: () => noop()
      }
    }
  ]);
}
