import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SortUtil } from '@iot-platform/iot-platform-utils';

import { Connector } from '@iot-platform/models/common';
import { Device, DeviceStatusName } from '@iot-platform/models/i4b';
import { DeviceConnectorsService, DeviceStatusService } from '@iot-platform/shared/services';

import { TranslateService } from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'iot4bos-ui-device-info-form',
  templateUrl: './device-info-form.component.html',
  styleUrls: ['./device-info-form.component.scss']
})
export class DeviceInfoFormComponent implements OnInit, OnDestroy {
  allStatusAllowed: string[] = [];
  defaultName: string;
  deviceForm: UntypedFormGroup;
  imageUrl: string = null;
  allConnectors: Connector[] = [];
  hide = true;
  subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private deviceStatusService: DeviceStatusService,
    private deviceConnectorsService: DeviceConnectorsService,
    private dialogRef: MatDialogRef<DeviceInfoFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { device: Device }
  ) {}

  get title$(): Observable<string> {
    return this.data.device
      ? this.translateService.get('DEVICES.INFO_FORM.EDIT_DEVICE', { value: this.data.device.name })
      : this.translateService.get('DEVICES.INFO_FORM.CREATE_DEVICE');
  }

  get name(): AbstractControl {
    return this.deviceForm.get('name');
  }

  get identifier(): AbstractControl {
    return this.deviceForm.get('identifier');
  }

  get family(): AbstractControl {
    if (this.deviceForm.get('family').valid) {
      this.deviceForm.controls.model.enable();
    } else {
      this.deviceForm.controls.model.disable();
    }
    return this.deviceForm.get('family');
  }

  get model(): AbstractControl {
    return this.deviceForm.get('model');
  }

  get incomingConnector(): AbstractControl {
    return this.deviceForm.get('incomingConnector');
  }

  get outgoingConnector(): AbstractControl {
    return this.deviceForm.get('outgoingConnector');
  }

  get status(): AbstractControl {
    return this.deviceForm.get('status');
  }

  get endpoint(): AbstractControl {
    return this.deviceForm.get('endpoint');
  }

  get login(): AbstractControl {
    return this.deviceForm.get('login');
  }

  get password(): AbstractControl {
    return this.deviceForm.get('password');
  }

  get support(): AbstractControl {
    return this.deviceForm.get('support');
  }

  get description(): AbstractControl {
    return this.deviceForm.get('description');
  }

  get action$(): Observable<string> {
    return this.data.device ? this.translateService.get('IOT_DICTIONARY.SAVE') : this.translateService.get('IOT_DICTIONARY.CREATE');
  }

  ngOnInit() {
    this.deviceForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(30), Validators.pattern('\\S.*')]),
      identifier: new UntypedFormControl(null),
      family: new UntypedFormControl(null, [Validators.required, Validators.maxLength(20)]),
      model: new UntypedFormControl(null, [Validators.maxLength(20)]),
      incomingConnector: new UntypedFormControl(null),
      outgoingConnector: new UntypedFormControl(null),
      status: new UntypedFormControl(null, [Validators.required]),
      endpoint: new UntypedFormControl(null, [Validators.maxLength(50)]),
      support: new UntypedFormControl(null, [Validators.maxLength(30)]),
      login: new UntypedFormControl({ value: null, disabled: true }),
      password: new UntypedFormControl({ value: null, disabled: true }),
      description: new UntypedFormControl(null, [Validators.maxLength(300)])
    });

    this.subscriptions.push(
      this.deviceStatusService
        .getAll()
        .subscribe(
          (allStatus: string[]) =>
            (this.allStatusAllowed = allStatus.filter(
              (status) => status !== DeviceStatusName.decommissioned && status !== DeviceStatusName.test_mode && status !== DeviceStatusName.maintenance
            ))
        )
    );

    if (this.data.device) {
      this.defaultName = this.data.device.name;
      this.imageUrl = this.data.device.imageUrl;

      this.subscriptions.push(
        this.deviceConnectorsService.getConnectorsByDeviceId(this.data.device.id).subscribe((allConnectors) => {
          this.allConnectors = allConnectors.sort(SortUtil.sortByName);

          if (this.deviceForm) {
            this.deviceForm.patchValue({
              incomingConnector: this.data.device.incomingConnector ? this.allConnectors.find((c) => c.id === this.data.device.incomingConnector.id) : null,
              outgoingConnector: this.data.device.outgoingConnector ? this.allConnectors.find((c) => c.id === this.data.device.outgoingConnector.id) : null
            });
            this.onOutgoingSelectorChange(this.outgoingConnector.value);
          }
        })
      );
    }

    this.initForm();
  }

  initForm() {
    if (this.data.device) {
      this.deviceForm.patchValue({
        name: this.data.device.name.trim(),
        identifier: this.data.device.identifier,
        family: this.data.device.type.family,
        model: this.data.device.type.model,
        incomingConnector: this.data.device.incomingConnector,
        outgoingConnector: this.data.device.outgoingConnector,
        status: this.data.device.status?.name,
        endpoint: this.data.device.communication.endpoint,
        support: this.data.device.communication.support,
        description: this.data.device.description,
        login: this.data.device.credential ? this.data.device.credential.login : null,
        password: this.data.device.credential ? this.data.device.credential.password : null
      });
    }
    this.onOutgoingSelectorChange(this.outgoingConnector.value);
    this.deviceForm.controls.support.disable();
    this.deviceForm.controls.identifier.disable();
    this.deviceForm.controls.family.disable();
    this.deviceForm.controls.incomingConnector.disable();
    if (this.family.valid) {
      this.deviceForm.controls.model.enable();
    } else {
      this.deviceForm.controls.model.disable();
    }
  }

  togglePassword() {
    if (!this.password.disabled) {
      this.hide = !this.hide;
    }
  }

  onSelectImage(image: string) {
    this.imageUrl = image;
  }

  onOutgoingSelectorChange(selectedConnector: Connector) {
    if (selectedConnector) {
      if (selectedConnector.requestConfiguration.authentication === 'login') {
        this.login.enable();
        this.password.enable();
      } else {
        this.login.disable();
        this.password.disable();
      }
    } else {
      this.login.disable();
      this.password.disable();
    }
  }

  save() {
    const device: Device = {
      ...this.data.device,
      name: this.name.value.trim(),
      identifier: this.identifier.value ? this.identifier.value.trim() : null,
      type: { ...this.data.device.type, family: this.family.value, model: this.model.value ? this.model.value : null },
      incomingConnector: this.incomingConnector.value ? this.incomingConnector.value : null,
      outgoingConnector: this.outgoingConnector.value ? this.outgoingConnector.value : null,
      status: { name: this.status.value, datetime: '' },
      communication: { endpoint: this.endpoint.value, support: this.support.value ? this.support.value : null },
      description: this.description.value ? this.description.value : null,
      imageUrl: this.imageUrl ?? null
    };

    device.credential = this.data.device.credential
      ? { ...this.data.device.credential, login: this.login.value, password: this.password.value }
      : { login: this.login.value, password: this.password.value };

    this.dialogRef.close(device);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
