import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';

import { IconModule } from '@iot-platform/shared/components';

import { TranslateModule } from '@ngx-translate/core';
import { TagCategoryListByConceptComponent } from './tag-category-list-by-concept.component';
import { TagLabelListComponent } from './tag-label-list/tag-label-list.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    TranslateModule,
    FlexModule,
    ReactiveFormsModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [TagCategoryListByConceptComponent, TagLabelListComponent],
  exports: [TagCategoryListByConceptComponent, TagLabelListComponent]
})
export class TagCategoryListByConceptModule {}
