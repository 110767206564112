import { createAction, props } from '@ngrx/store';

export const signIn = createAction('[Auth/Page] Sign In', props<{ username: string; password: string }>());
export const signInWithSso = createAction('[Auth/Page] Sign In with SSO');
export const retrieveSsoSession = createAction('[Auth/Page] Retrieve SSO session', props<{ idToken: string; accessToken: string; refreshToken: string }>());
export const refreshSsoTokens = createAction('[Auth/Page] Refresh SSO Tokens', props<{ refreshToken: string }>());
export const validateSsoTokens = createAction(
  '[Auth/Page] Validate SSO Tokens',
  props<{ idToken: string; accessToken: string; refreshToken: string; expiresIn: number }>()
);
export const loadAccount = createAction('[Auth/Page] Load Account');
export const changePassword = createAction('[Auth/Page] Change Password', props<{ user: any; newPassword: string }>());
export const forgotPassword = createAction('[Auth/Page] Forgot Password', props<{ username: string }>());
export const updatePasswordWithCode = createAction('[Auth/Page] Update Password with code', props<{ username: string; code: string; password: string }>());
export const requireNewPassword = createAction('[Auth/Page] Require New Password', props<{ username: string }>());
export const cancel = createAction('[Auth/Page] Cancel');
export const resetPassword = createAction('[Auth/Page] Reset Password');
export const loadPrivileges = createAction('[Auth/Page] Load Privileges');
export const signOut = createAction('[Auth/Page] Sign Out');
export const displayEmailPasswordLogin = createAction('[Auth/Page] Display Email Password Login');
export const setPending = createAction('[Auth/Page] Set pending', props<{ pending: boolean }>());
