<div cdkDropList [cdkDropListDisabled]="!enableDragAndDrop" class="widget-zone" (cdkDropListDropped)="drop($event)">
  @for (widget of widgets; track widget; let index = $index){

  <div class="widget-zone_item" [ngClass]="'widget-zone_size-' + widget.options.size">
    <div class="widget-zone_item-content">
      <iot-platform-ui-widget-item cdkDrag (dispatchEvent)="dispatchEvent?.emit($event)" [widget]="widget"></iot-platform-ui-widget-item>
    </div>
  </div>

  }
</div>
