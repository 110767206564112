/* eslint-disable ngrx/prefix-selectors-with-select */
import { PlatformResponse } from '@iot-platform/models/common';
import { CommandType } from '@iot-platform/models/i4b';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import * as fromAssetsDb from './assets-db.reducer';
import * as fromAssetsUi from './assets-ui.reducer';
import { assetsCommentsFeature, State as AssetsCommentsState, adapter as AssetsCommentsAdapter } from './assets-comments.reducer';

export const assetsFeatureKey = 'assets';

export interface AssetsState {
  [fromAssetsDb.assetsDbFeatureKey]: fromAssetsDb.State;
  [fromAssetsUi.assetsUiFeatureKey]: fromAssetsUi.State;
  [assetsCommentsFeature.name]: AssetsCommentsState;
}

export interface State {
  [assetsFeatureKey]: AssetsState;
}

export function reducers(state: AssetsState | undefined, action: Action) {
  return combineReducers({
    [fromAssetsDb.assetsDbFeatureKey]: fromAssetsDb.reducer,
    [fromAssetsUi.assetsUiFeatureKey]: fromAssetsUi.reducer,
    [assetsCommentsFeature.name]: assetsCommentsFeature.reducer
  })(state, action);
}

export const selectAssetsState = createFeatureSelector<AssetsState>(assetsFeatureKey);

// DB
export const selectAssetsDbState = createSelector(selectAssetsState, (state: AssetsState) => state[fromAssetsDb.assetsDbFeatureKey]);

export const {
  selectIds: getAssetsIds,
  selectEntities: getAssetsEntities,
  selectAll: getAllAssets,
  selectTotal: getTotalAssets
} = fromAssetsDb.adapter.getSelectors(selectAssetsDbState);

export const getCurrentFavoriteView = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.currentFavoriteView);
export const getCurrentFilters = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.currentFilters);
export const getSelectedAssetId = createSelector(selectAssetsDbState, fromAssetsDb.getSelectedId);
export const getSelectedAsset = createSelector(getAssetsEntities, getSelectedAssetId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getVariable = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.variable);
export const getVariablesByAssetId = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) =>
  state ? state.allVariables?.sort(SortUtil.sortByName) : []
);
export const getSite = createSelector(selectAssetsDbState, fromAssetsDb.getSite);
export const getAsset = createSelector(selectAssetsDbState, fromAssetsDb.getAsset);
export const getTags = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.tags);
export const getMVSettings = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.settings);
export const getInitialSort = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.initialSort);
export const getPagination = createSelector(selectAssetsDbState, getMVSettings, (state, settings) =>
  settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination
);
export const getFormattedData = createSelector(getAllAssets, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data: data.map((asset) => ({
      ...asset,
      isRefreshCommandEnabled: !!asset.commands?.includes(CommandType.REFRESH)
    })),
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

// ***** UI
export const selectAssetsUiState = createSelector(selectAssetsState, (state: AssetsState) => state[fromAssetsUi.assetsUiFeatureKey]);

export const getSiteLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.siteLoaded);
export const getError = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.error);
export const getAssetLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.assetLoaded);
export const getAssetLoading = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.assetLoading);
export const getAssetVarLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => (state ? state.assetVarLoaded : false));
export const getAssetVarLoading = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.assetVarLoading);
export const getTagsLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => (state ? state.tagsLoaded : false));
export const selectAssetCreationByTemplateStatuses = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) =>
  state ? state.assetCreationByTemplateStatuses : { assetCreationByTemplateCompleted: false, assetCreationByTemplatePending: false }
);

// Comments
const selectAssetsCommentsState = createSelector(selectAssetsState, (state: AssetsState) => state[assetsCommentsFeature.name]);

export const {
  selectIds: selectAssetCommentsIds,
  selectEntities: selectAssetCommentsDictionary,
  selectAll: selectAllAssetComments,
  selectTotal: selectTotalAssetComments
} = AssetsCommentsAdapter.getSelectors(selectAssetsCommentsState);

export const selectAssetCommentsError = createSelector(selectAssetsCommentsState, (state: AssetsCommentsState) => state.error);
export const selectAssetCommentsLoading = createSelector(selectAssetsCommentsState, (state: AssetsCommentsState) => state.loading);
