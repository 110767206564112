<form [formGroup]="dynamicListForm" fxLayout="row" fxLayoutGap="20px">
    <ng-container *ngIf="!data.multiSelect">
        <div *ngFor="let input of data.inputs">
            <mat-form-field *ngIf="input.display && !showLoader" class="select-field" color="accent">
                <mat-label>{{ 'FILTER_ENGINE.TAG_PLACEHOLDER_CATEGORY' | translate }}</mat-label>
                <mat-select
                        (selectionChange)="onSelectionChange(input, dynamicListForm.get(input.id).value)"
                        [formControlName]="input.id"
                                                data-cy="iot-platform-ui-dynamic-list-field-multiple-select"
                >
                    <mat-option *ngFor="let option of input.display" [value]="option"
                                data-cy="iot-platform-ui-dynamic-list-field-multiple-select-option">{{
                      option[input.selectByProperty]
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-progress-spinner *ngIf="showLoader && !input.parentListId" [diameter]="20" color="accent"
                                  mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-container>

    <ng-container *ngIf="data.multiSelect">
        <mat-form-field class="select-field" color="accent">
            <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                <span class="filter-label" fxFlex="90">{{ data.criteriaLabel | translate }}</span>
                <mat-progress-spinner
                        *ngIf="showLoader && !categoryList.parentListId"
                        [diameter]="16"
                        color="accent"
                        fxFlex="10"
                        mode="indeterminate"
                ></mat-progress-spinner>
            </mat-label>
            <mat-select
                    (selectionChange)="onSelectionChange(categoryList, dynamicListForm.get(categoryList.id).value)"
                    [formControlName]="categoryList.id"
                    data-cy="iot-platform-ui-dynamic-list-field-multiple-select"
            >
                <ng-container *ngIf="categoryList.display && !showLoader">
                    <mat-option *ngFor="let option of categoryList.display" [value]="option"
                                data-cy="iot-platform-ui-dynamic-list-field-multiple-select-option">{{
                      option[categoryList.selectByProperty]
                        }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <iot-platform-ui-async-autocomplete-multiple-selects
                (selectionChanged)="onMultiSelectionChange($event)"
                *ngIf="labelList.display"
                [currentFilters$]="currentFilters$"
                [currentFiltersSize]="currentFiltersSize"
                [data]="labelList.display"
                [displayKey]="labelList.selectByProperty"
                [displaySearchIcon]="false"
                [filterKey]="labelList?.skipAutoCompleteFilterCriteria ? null : labelList.selectByProperty"
                [maxFilters]="maxFilters"
                [minLength]="1"
                [multiSelect]="data.multiSelect"
                [placeholder]="labelList.placeholder"
                [showSpinner]="showLoader"
                [standaloneMode]="true"
                [type]="'tag'"
        >
        </iot-platform-ui-async-autocomplete-multiple-selects>
    </ng-container>
</form>
