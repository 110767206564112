import { Injectable } from '@angular/core';
import { AbstractAssetVariableConfigureReadPopupService } from '@iot-platform/grid-engine';
import { AssetVariable } from '@iot-platform/models/i4b';
import { AssetsFacade } from '../features/assets/+state/facades/assets.facade';

@Injectable({
  providedIn: 'root'
})
export class AssetVariableConfigureReadPopupService extends AbstractAssetVariableConfigureReadPopupService {
  constructor(private readonly assetsFacade: AssetsFacade) {
    super();
  }

  updateAssetVariableThresholds(assetVariable: AssetVariable): void {
    this.assetsFacade.updateAssetVariableThresholds(assetVariable);
  }
}
