<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ data.appName }} {{ 'RELEASE_NOTES.POPUP.CURRENT_VERSION' | translate }} {{ currentVersion$ | async }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20 release-content">
        <mat-progress-bar *ngIf="(releaseNote$ | async) === null" color="accent"
                          mode="indeterminate"></mat-progress-bar>
        <div [innerHTML]="releaseNote$ | async"></div>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular" color="accent"
                mat-raised-button>{{ 'RELEASE_NOTES.POPUP.CLOSE' | translate | uppercase }}</button>
    </mat-card-actions>
</mat-card>
