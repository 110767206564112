import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Log } from '@iot-platform/models/i4b';
import { DateFormatModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, UpperCasePipe, MatIconModule, DateFormatModule, TranslateModule, MatTooltipModule],
  selector: 'shared-iot4bos-ui-start-maintenance-comment',
  templateUrl: './start-maintenance-comment.component.html',
  styleUrls: ['./start-maintenance-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartMaintenanceCommentComponent {
  @Input() log!: Log;
}
