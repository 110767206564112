<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">
                {{
              'MANAGE_TAGS_FORM.ADD_OR_REMOVE_DIALOG.' + (data.isAddition ? 'TITLE_ADD' : 'TITLE_REMOVE')
                | translate: { total: data.selectionTotal, concept: data.concept }
                }}
            </h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content" fxLayout="row">
        <form [formGroup]="manageTagForm" class="full-width p-20 site-info-form" fxLayout="column">
            <iot-platform-ui-detail-popup-display-property
                    [property]="'MANAGE_TAGS_FORM.ADD_OR_REMOVE_DIALOG.NUMBER_OF_ITEMS' | translate"
                    [value]="data.selectionTotal | numberFormat"
                    class="add-or-remove-tags-dialog--disclaimer"
            ></iot-platform-ui-detail-popup-display-property>
            <p class="add-or-remove-tags-dialog--disclaimer">{{ 'MANAGE_TAGS_FORM.ADD_OR_REMOVE_DIALOG.BP_MESSAGE' | translate }}</p>
            <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-form-field color="accent" fxFlex>
                        <mat-label>{{ 'IOT_DICTIONARY.CATEGORY' | translate }}</mat-label>
                        <mat-select (selectionChange)="onCategoryChange($event)" [placeholder]=""
                                    formControlName="category"
                                    required>
                            <mat-option *ngFor="let category of categories"
                                        [value]="category">{{ category.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-progress-spinner *ngIf="categoriesLoading$ | async" [diameter]="14" color="accent"
                                          mode="indeterminate"></mat-progress-spinner>
                </div>

                <mat-form-field color="accent" fxFlex="50">
                    <mat-label>{{ 'IOT_DICTIONARY.LABEL' | translate }}</mat-label>
                    <mat-select [placeholder]="" formControlName="label" required>
                        <mat-option *ngFor="let label of labels" [value]="label">{{ label.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </section>
            <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular" mat-button>{{ 'SITES.INFO_FORM.CANCEL' | translate }}</button>
        <button (click)="save()" [disabled]="!manageTagForm.valid" class="button-regular" color="accent"
                mat-raised-button>
            {{ 'IOT_DICTIONARY.SAVE' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
