import { Component, effect, Input, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AnalyticsService } from '@iot-platform/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { ChatEvent, FavoriteView, Filter, IotAction, MasterViewEngineEvent } from '@iot-platform/models/common';
import { AssetEventsGridData, DeviceEventsGridData, I4BGrid, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { AssetEvent, Event, AssetVariable, DeviceVariable, Log, CommentContext, Site, Concept, DeviceEvent } from '@iot-platform/models/i4b';
import { WidgetOptions } from '@iot-platform/models/widgets';
import { VariableChartDialogComponent } from '@iot-platform/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NavigationApi } from '../../containers/+state/navigation.api';
import { AssetEventsApi } from '../../features/asset-events/+state/asset-events.api';
import { DeviceEventsApi } from '../../features/device-events/+state/device-events.api';
import { EventDetailPopupComponent } from '../event-detail-popup/event-detail-popup.component';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
@Component({
  selector: 'iot4bos-ui-events-by-concept-grid',
  templateUrl: './events-by-concept-grid.component.html',
  styleUrl: './events-by-concept-grid.component.scss'
})
export class EventsByConceptGridComponent {
  mandatoryFilters: WritableSignal<Filter[]> = signal([]);
  currentEventType: WritableSignal<string | null> = signal(null);
  masterview: WritableSignal<string> = signal('');
  userPermissions: WritableSignal<{ key: string; value: boolean }[]> = signal([]);

  @Input() set permissions(value: { key: string; value: boolean }[]) {
    this.userPermissions.set(value);
  }
  @Input() set masterviewName(value: string) {
    this.masterview.set(value);
  }
  @Input() origin!: string;
  @Input() concept!: string;
  @Input() displayFilterEngine = false;
  @Input() grid: I4BGrid<I4BGridOptions, AssetEventsGridData> | I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  @Input() set mandatoryFiltersInput(value: Filter[]) {
    this.mandatoryFilters.set(value);
  }
  @Input() set eventType(value: string) {
    this.currentEventType.set(value);
  }

  analytic: AnalyticsService = new AnalyticsService(this.concept + '_info_page');

  gridSort$!: Observable<I4BGridSort[] | undefined>;
  selectedEvent$ = this.store.select(fromGrids.getSelectedItemInSelectedGrid) as Observable<AssetEvent | undefined>;
  selectedEvent: WritableSignal<Event | null> = signal(null);

  reloadMasterViewAfterClose = false;

  // Comments
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  comments: WritableSignal<Log[]> = signal([]);
  commentsLoading: WritableSignal<boolean> = signal(true);
  commentsContexts!: CommentContext[];
  CONCEPT = Concept;

  currentFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  currentFavoriteView$: Observable<FavoriteView | null> = of(null);

  canUpdateEvent = false;
  canReadAsset = false;
  canReadDevice = false;
  canReadSite = false;

  constructor(
    private readonly store: Store,
    private readonly authorizationService: AuthorizationService,
    private readonly dialog: MatDialog,
    private readonly assetEventsApi: AssetEventsApi,
    private readonly deviceEventsApi: DeviceEventsApi,
    private readonly navigationApi: NavigationApi,
    private readonly translateService: TranslateService
  ) {
    this.canReadAsset = this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.READ);
    this.canReadDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    this.canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);

    effect(
      () => {
        this.currentFilters$.next(this.mandatoryFilters().concat(this.currentFilters$.getValue()));
        this.loadData();

        this.gridSort$ = this.grid ? this.store.select(fromGrids.getSortByGrid(this.grid?.id as string)) : of([]);
        this.canUpdateEvent = this.userPermissions().find((p: { key: string; value: boolean }) => p.key === 'canUpdateEvent')?.value as boolean;
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        const currentEventType = this.currentEventType();

        if (currentEventType === 'asset-events') {
          this.comments.set([...this.assetEventsApi.assetEventComments()]);
          this.commentsLoading.set(this.assetEventsApi.assetEventCommentsLoading());
        } else if (currentEventType === 'device-events') {
          this.comments.set([...this.deviceEventsApi.deviceEventComments()]);
          this.commentsLoading.set(this.deviceEventsApi.deviceEventCommentsLoading());
        }
      },
      { allowSignalWrites: true }
    );
  }

  onApplyFilters(filters: Filter[]) {
    this.currentFilters$.next(filters);
    const gaLog = Object.entries(filters)
      .map(([_, value]) => `${value['criteriaKey']}`)
      .join(', ');
    this.analytic.log('filters_actions', 'apply_filters', `Applied filters : ${gaLog}`);
    this.loadData();
  }

  loadData(): void {
    this.store.dispatch(
      GridsDbActions.selectGridAndLoadData({
        gridId: 'default',
        masterview: this.masterview(),
        filters: this.currentFilters$.getValue()
      })
    );
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'open':
        this.onSelectEvent(event.rawData.id);
        this.openEventDetail(event.rawData);
        break;
      case 'snooze':
      case 'acknowledge':
      case 'close':
        this.onSelectEvent(event.rawData.id);
        this.onUpdateStatus([event.rawData], event.type);
        break;
      case 'bulkSnooze':
        const eventsToSnooze = event.rawData.filter((e: AssetEvent) => e.status === 'active' && e.snoozeQuota !== 0);
        this.onBulkStatusUpdate(eventsToSnooze, 'snooze');
        break;
      case 'bulkAcknowledge':
        const eventsToAcknowledge = event.rawData.filter((e: AssetEvent) => e.status === 'active');
        this.onBulkStatusUpdate(eventsToAcknowledge, 'acknowledge');
        break;
      case 'bulkClose':
        const eventsToClose = event.rawData.filter((e: AssetEvent) => e.status === 'acknowledged');
        this.onBulkStatusUpdate(eventsToClose, 'close');
        break;

      case 'openComments':
        this.onSelectEvent(event.rawData.id);
        this.onOpenComments(event.rawData);
        break;
      case 'navigateToAsset':
        this.navigationApi.openAssetDetail({ ...event.rawData.context.asset, site: { ...event.rawData.context.site } }, this.origin);
        break;
      case 'navigateToDevice':
        this.navigationApi.openDeviceDetail({ ...event.rawData.context.device, site: { ...event.rawData.context.site } }, this.origin);
        break;
      case 'openGraph':
        this.openGraph(event.rawData);
        break;
      default:
        break;
    }
  }

  onSelectEvent(itemId: string) {
    if (this.grid && itemId) {
      this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid.id as string, itemId }));
    }
  }

  onUpdateStatus(events: AssetEvent[], status: string) {
    this.analytic.log('grid_actions', 'single_status_update', status);
    if (this.currentEventType() === 'asset-events') {
      this.assetEventsApi.updateStatusByAssetEventId(
        events.map((assetEvent) => assetEvent.id),
        status
      );
    } else {
      this.deviceEventsApi.updateStatusByDeviceEventId(
        events.map((deviceEvent) => deviceEvent.id),
        status
      );
    }
    this.reloadMasterViewAfterClose = true;
  }

  onBulkStatusUpdate(events: AssetEvent[], status: string) {
    this.analytic.log('grid_actions', 'open_bulk_status_update', status);
    this.dialog
      .open(PopupComponent, {
        data: {
          type: 'confirm',
          value: this.translateService.instant('EVENTS.BULK_STATUS_UPDATE.CONFIRMATION_MESSAGES.' + status.toUpperCase(), { total: events.length })
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation && events.length) {
          this.analytic.log('grid_actions', 'bulk_status_update', `${status} - ${events.length} events`);
          this.onUpdateStatus(events, status);
        }
      });
  }

  openEventDetail(event: AssetEvent) {
    this.analytic.log('grid_actions', 'open_event_detail');
    this.selectedEvent.set(event);

    if (this.currentEventType() === 'asset-events') {
      this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(event);
      this.assetEventsApi.loadComments(event);
    } else {
      this.deviceEventsApi.loadEventDetailPopupDataByDeviceEvent(event);
      this.deviceEventsApi.loadComments(event);
    }

    const detailPopup = this.dialog.open(EventDetailPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: { eventType: this.currentEventType(), canUpdateEvent: this.canUpdateEvent, gridId: this.grid?.id, event }
    });

    detailPopup.componentInstance.updateStatus.subscribe((status: string) => {
      this.onSelectEvent(event.id);
      if (this.currentEventType() === 'asset-events') {
        this.assetEventsApi.updateStatusByAssetEventId([event.id], status);
      } else {
        this.deviceEventsApi.updateStatusByDeviceEventId([event.id], status);
      }
      this.reloadMasterViewAfterClose = true;
    });

    detailPopup.componentInstance.addComment.subscribe((value: string) => {
      this.onSelectEvent(event.id);
      this.onAddComment(value);
    });

    detailPopup.componentInstance.navigateToSite.subscribe((site: Site) => {
      this.navigationApi.openSiteDetail(site, this.origin);
      detailPopup.close();
    });
  }

  onOpenComments(event: Event) {
    this.analytic.log('grid_actions', 'open_comment_panel');
    this.selectedEvent.set(event);

    if (this.currentEventType() === 'asset-events') {
      this.assetEventsApi.loadComments(event);
      this.commentsContexts = [
        { name: Concept.EVENT, checked: true, disabled: false },
        { name: Concept.ASSET, checked: false, disabled: !this.canReadAsset },
        { name: Concept.SITE, checked: false, disabled: !this.canReadSite }
      ];
    } else {
      this.deviceEventsApi.loadComments(event);
      this.commentsContexts = [
        { name: Concept.EVENT, checked: true, disabled: false },
        { name: Concept.DEVICE, checked: false, disabled: !this.canReadDevice },
        { name: Concept.SITE, checked: false, disabled: !this.canReadSite }
      ];
    }

    this.sidenav.open();
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.onAddComment(event.value as string);
        break;
      case IotAction.EDIT:
        this.onEditComment(event.value as Log);
        break;
      case IotAction.DELETE:
        this.onDeleteComment(event.value as string);
        break;
      case IotAction.CLOSE:
        this.onCloseComments();
        break;
      default:
        break;
    }
  }

  onAddComment(comment: string) {
    this.analytic.log('grid_actions', 'add_detail');
    if (this.currentEventType() === 'asset-events') {
      this.assetEventsApi.addComment(this.selectedEvent() as AssetEvent, comment);
    } else {
      this.deviceEventsApi.addComment(this.selectedEvent() as DeviceEvent, comment);
    }
  }

  onEditComment(comment: Log) {
    if (this.currentEventType() === 'asset-events') {
      this.assetEventsApi.editComment(this.selectedEvent()?.id as string, comment);
    } else {
      this.deviceEventsApi.editComment(this.selectedEvent()?.id as string, comment);
    }
  }

  onDeleteComment(commentId: string) {
    if (this.currentEventType() === 'asset-events') {
      this.assetEventsApi.deleteComment(this.selectedEvent() as AssetEvent, commentId);
    } else {
      this.deviceEventsApi.deleteComment(this.selectedEvent() as DeviceEvent, commentId);
    }
  }

  onCloseComments() {
    this.sidenav.close();
  }

  openGraph(event: AssetEvent) {
    this.analytic.log('grid_actions', 'open_chart');
    let data: { variables: DeviceVariable[] | AssetVariable[]; variableType: string; title?: string; options?: WidgetOptions };
    if (this.currentEventType() === 'asset-events') {
      data = {
        variables: [
          {
            ...event.context.assetVariable,
            asset: { id: event.context.asset?.id, name: event.context.asset?.name }
          } as unknown as AssetVariable
        ],
        variableType: 'assetVariable'
      };
    } else {
      data = {
        variables: [
          {
            ...event.context.deviceVariable,
            device: { id: event.context.device?.id, name: event.context.device?.name }
          } as unknown as DeviceVariable
        ],
        variableType: 'deviceVariable'
      };
    }

    this.dialog.open(VariableChartDialogComponent, { width: '990px', data });
  }
}
