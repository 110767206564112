import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Role } from '@iot-platform/models/common';
import { PopupComponent } from '../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { SortUtil } from '@iot-platform/iot-platform-utils';

@Component({
  selector: 'iot4bos-backoffice-ui-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss', '../../style/admin.style.scss']
})
export class RolesListComponent implements OnChanges {
  @Input() roles: Role[] = [];
  @Input() selectedRole: Role;
  @Input() showActionMenu = false;

  @Output() selectRole: EventEmitter<Role> = new EventEmitter();
  @Output() editRole: EventEmitter<Role> = new EventEmitter();
  @Output() deleteRole: EventEmitter<Role> = new EventEmitter();
  @Output() renameRole: EventEmitter<Role> = new EventEmitter();

  sortedRoles: Role[] = [];

  constructor(public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('roles')) {
      this.sortedRoles = [...changes.roles.currentValue];
      this.sortedRoles.sort(SortUtil.sortByName);
    }
  }

  onDeleteRoleButtonClick(role: Role) {
    this.confirmDeletion(role);
  }

  onSelectRoleButtonClick(role: Role) {
    this.selectRole.emit(role);
    this.selectedRole = role;
  }

  getTooltip(role: Role): string {
    return role.businessProfileTotal !== 0 ? `Role is linked to ${role.businessProfileTotal} Business Profiles` : '';
  }

  isDeletePossible(role: Role): boolean {
    return role.businessProfileTotal !== 0;
  }

  onRenameRoleButtonClick(role: Role) {
    this.renameRole.emit(role);
  }

  onEditRoleButtonClick(role: Role) {
    this.editRole.emit(role);
  }

  private confirmDeletion(roleToDelete: Role) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: roleToDelete.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteRole.emit(roleToDelete);
      }
    });
  }
}
