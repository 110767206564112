import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { IotToolbarDefaultButton, IotToolbarDispatchActionType, REFRESH_BUTTON_CONFIG } from '@iot-platform/iot-platform-ui';

import { CommonApiRequest, CommonIndexedPagination, Connector, Environment, Filter, MasterViewEngineEvent, ToolbarSize } from '@iot-platform/models/common';

import { Store } from '@ngrx/store';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DeviceConnectorsUiActions } from '../../+state/actions';
import { DeviceConnectorDetailPopupComponent } from '../../components/device-connector-detail-popup/device-connector-detail-popup.component';
import { DeviceConnectorEditFormComponent } from '../../components/device-connector-edit-form/device-connector-edit-form.component';

@Component({
  selector: 'iot4bos-ui-device-connectors-shell',
  templateUrl: './device-connectors-shell.component.html',
  styleUrls: ['./device-connectors-shell.component.scss']
})
export class DeviceConnectorsShellComponent implements OnInit {
  analytic: AnalyticsService = new AnalyticsService('connectors_shell');
  masterViewConnectorsButtonList!: IotToolbarDefaultButton[];

  grid$ = this.store.select(fromGrids.getDefaultConnectorsGrid);
  gridConcept = 'connectors';

  gridSort$ = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getSortByGrid(grid.id as string));
      } else {
        return of([]);
      }
    })
  );

  totalConnectors$ = this.grid$.pipe(map((grid) => (grid?.data?.response?.pagination as CommonIndexedPagination)?.total | 0));

  connectorsLoaded$: Observable<boolean> = this.grid$.pipe(
    switchMap((grid) => (grid ? this.store.select(fromGrids.getDataLoadedByGrid(grid.id as string)) : of(false)))
  );

  canCreateConnector: boolean;
  canUpdateConnector: boolean;
  canDeleteConnector: boolean;
  userPermissions;
  currentFilters: Filter[] = [];

  toolbarSize: string = ToolbarSize.SMALL;

  destroyed$: Subject<void> = new Subject();

  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly dialog: MatDialog,
    private readonly authz: AuthorizationService,
    private readonly store: Store
  ) {
    this.canCreateConnector = this.authz.applyAuthorization(AuthorizationConcept.CONNECTOR, AuthorizationType.CREATE);
    this.canUpdateConnector = this.authz.applyAuthorization(AuthorizationConcept.CONNECTOR, AuthorizationType.UPDATE);
    this.canDeleteConnector = this.authz.applyAuthorization(AuthorizationConcept.CONNECTOR, AuthorizationType.DELETE);
    this.userPermissions = [
      { key: 'canCreateConnector', value: this.canCreateConnector },
      { key: 'canUpdateConnector', value: this.canUpdateConnector },
      { key: 'canDeleteConnector', value: this.canDeleteConnector }
    ];

    this.initToolbarButtonList();
  }

  ngOnInit(): void {
    combineLatest([this.store.select(fromAuth.selectSelectedBusinessProfileForAccount), this.store.select(fromGrids.getAllGrids), this.grid$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([businessProfile, grids, grid]) => {
        if (businessProfile && grids && !grid) {
          this.selectGridAndLoadData();
        }
      });

    this.connectorsLoaded$.pipe(takeUntil(this.destroyed$)).subscribe((dataLoaded: boolean) => {
      this.masterViewConnectorsButtonList.map((button) => {
        if (button.dispatchAction.type === IotToolbarDispatchActionType.REFRESH_PAGE) {
          button.disabled = !dataLoaded;
        }
        return button;
      });
    });
  }

  loadData(): void {
    this.grid$.pipe(takeUntil(this.destroyed$), take(1)).subscribe((grid) => {
      if (grid?.data) {
        const pagination: CommonIndexedPagination = grid.data.response.pagination as CommonIndexedPagination;
        const request: CommonApiRequest = {
          limit: pagination.limit,
          page: pagination.currentPage,
          filters: this.currentFilters,
          concept: this.gridConcept,
          variables: grid.gridOptions.variableNames,
          tags: grid.gridOptions.tagIds,
          endPoint: this.environment.api.endpoints.connectorConnectors
        };
        this.store.dispatch(GridsDbActions.loadGridData({ request }));
      }
    });
  }

  initToolbarButtonList(): void {
    this.masterViewConnectorsButtonList = [new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 0)];
  }

  onToolbarEvent(event: { type: string; options?: any }): void {
    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.onRefreshData();
        break;
      default:
        break;
    }
  }

  onRefreshData(): void {
    this.analytic.log('toolbar_actions', 'refresh_data');
    this.loadData();
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    this.onSelectConnector(event.rawData);
    switch (event.type) {
      case 'open':
        this.analytic.log('grid_actions', 'open_connector_detail');
        this.dialog.open(DeviceConnectorDetailPopupComponent, {
          width: '900px',
          disableClose: false,
          data: { connector: event.rawData }
        });
        break;
      case 'edit':
        this.editConnector(event.rawData);
        break;
      default:
        break;
    }
  }

  editConnector(connector: Connector): void {
    this.analytic.log('grid_actions', 'open_edit_connector');
    this.dialog
      .open(DeviceConnectorEditFormComponent, {
        width: '600px',
        disableClose: true,
        data: { connector }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((connectorToUpdate) => {
        if (connectorToUpdate) {
          this.store.dispatch(DeviceConnectorsUiActions.updateConnector({ connectorToUpdate }));
          this.analytic.log('grid_actions', 'edit_connector');
        }
      });
  }

  onSelectConnector(selectedConnector: Connector): void {
    this.store.dispatch(DeviceConnectorsUiActions.selectConnector({ selectedConnector }));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private selectGridAndLoadData(): void {
    this.store.dispatch(
      GridsDbActions.selectGridAndLoadData({
        gridId: 'default',
        masterview: this.gridConcept,
        filters: this.currentFilters,
        endPoint: this.environment.api.endpoints.connectorConnectors
      })
    );
  }
}
