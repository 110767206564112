import { inject, Injectable, Signal } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { CommonApiRequest, FavoriteView, Filter, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Device, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { DeviceConnectorsUiActions, DevicesCommentsActions, DevicesDbActions } from '../actions';
import * as fromDevices from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class DevicesFacade {
  private readonly store: Store = inject(Store);

  allDevices$ = this.store.select(fromDevices.getAllDevices);
  device$ = this.store.select(fromDevices.getDevice);
  grid$ = this.store.select(fromGrids.getDefaultDevicesGrid);
  grids$ = this.store.select(fromGrids.getDevicesGrids);
  gridConfiguration = this.store.selectSignal(fromGrids.selectDeviceGridsConfiguration);
  devicesUpdated$ = this.store.select(fromDevices.getDevicesUpdated);
  deviceVarLoaded$ = this.store.select(fromDevices.getDeviceVarLoaded);
  siteLoaded$ = this.store.select(fromDevices.getSiteLoaded);
  site$ = this.store.select(fromDevices.getSite);
  status$ = this.store.select(fromDevices.getStatusList);
  allVariables$ = this.store.select(fromDevices.getAllVariables);
  variable$ = this.store.select(fromDevices.getVariable);
  callLogs$ = this.store.select(fromDevices.getCallLogs);
  callLogLoading$ = this.store.select(fromDevices.getCallLogsLoading);
  callLogLoaded$ = this.store.select(fromDevices.getCallLogsLoaded);
  selectedDevice$ = this.store.select(fromDevices.getSelectedDevice);
  getCallLogsMVSettings$ = this.store.select(fromDevices.getCallLogsMVSettings);
  tags$ = this.store.select(fromDevices.getTags);
  tagsLoaded$ = this.store.select(fromDevices.getTagsLoaded);
  favoriteViews$ = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDevices);

  currentFavoriteView$ = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDevices);
  currentFilters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewDevices);
  loadingFavoriteViews$ = this.store.select(fromFavoriteViews.getLoading);
  //
  deviceFavoriteViewsConfiguration$ = this.store.select(fromFavoriteViews.selectDeviceFavoriteViewsConfiguration);
  timeseries$ = this.store.select(fromDevices.getTimeseries);
  //
  deviceComments: Signal<Log[]> = this.store.selectSignal(fromDevices.selectAllDeviceComments);
  deviceCommentsLoading: Signal<boolean> = this.store.selectSignal(fromDevices.selectDeviceCommentsLoading);
  //

  deviceLoaded$ = (gridId: string) => this.store.select(fromGrids.getDataLoadedByGrid(gridId));

  addDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.addDevice({ deviceToAdd: device }));
  }

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'devices' }));
  }

  saveMVSettings(name: string, values: any) {
    this.store.dispatch(DevicesDbActions.saveMVSettings({ name, values }));
  }

  loadCallLogsMetadata() {
    this.store.dispatch(DevicesDbActions.loadCallLogsMVSettings({ settingName: 'device-call-logs' }));
  }

  saveCallLogsMVSettings(name: string, values: any) {
    this.store.dispatch(DevicesDbActions.saveCallLogsMVSettings({ name, values }));
  }

  loadDevices(request: CommonApiRequest) {
    this.store.dispatch(GridsDbActions.loadGridData({ request: { concept: 'devices', ...request } }));
  }

  loadGrids() {
    this.store.dispatch(GridsDbActions.loadGrids({ concept: 'devices' }));
  }

  loadDeviceById(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadDeviceById({ deviceId }));
  }

  loadDeviceCallLogById(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadCallLogsByDeviceId({ deviceId }));
  }

  loadDeviceStatusList() {
    this.store.dispatch(DevicesDbActions.loadDeviceStatusList());
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(DevicesDbActions.loadSiteById({ siteId }));
  }

  loadTagsByDeviceId(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadTagsByDeviceId({ deviceId }));
  }

  selectDevice(selectedDevice: Device) {
    this.store.dispatch(DevicesDbActions.selectDevice({ selectedDevice }));
  }

  loadVariablesByDeviceId(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadVariablesByDeviceId({ deviceId }));
  }

  updateDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.updateDevice({ deviceToUpdate: device }));
  }

  activateDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.activateDevice({ deviceToActivate: device }));
  }

  resetDevice(deviceToReset: Device) {
    this.store.dispatch(DevicesDbActions.resetDevice({ deviceToReset }));
  }

  deleteDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.deleteDevice({ deviceToDelete: device }));
  }

  bulkUpdateDevices(devices: Device[]) {
    this.store.dispatch(DevicesDbActions.bulkUpdateDevices({ devicesToUpdate: devices }));
  }

  updateVariable(variable: DeviceVariable) {
    this.store.dispatch(DevicesDbActions.updateDeviceVariable({ deviceVariableToUpdate: variable }));
  }

  deleteVariable(variables: DeviceVariable[]) {
    this.store.dispatch(DevicesDbActions.deleteDeviceVariables({ deviceVariablesToDelete: variables }));
  }

  updateTagsByDeviceId(deviceId: string, tags: TagCategory[]) {
    this.store.dispatch(DevicesDbActions.updateTagsByDeviceId({ deviceId, tags }));
  }

  sendConnectorCommand(device: Device, command: any) {
    this.store.dispatch(DeviceConnectorsUiActions.sendConnectorCommand({ device, command }));
  }

  bulkSendConnectorCommand(devices: Device[], command: any) {
    this.store.dispatch(DeviceConnectorsUiActions.bulkSendConnectorCommand({ devices, command }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'devices', favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'devices', filters }));
  }

  loadTimeseriesByVariableId(deviceId: string, variableId: string, start: string, end: string, limit: number) {
    this.store.dispatch(DevicesDbActions.loadTimeseriesByDeviceVariableId({ deviceId, variableId, start, end, limit }));
  }

  resetDeviceVariablesLastValues(deviceVariables: DeviceVariable[]) {
    this.store.dispatch(DevicesDbActions.resetDeviceVariablesLastValues({ deviceVariables }));
  }

  moveDevices(devicesToMove: Device[], originSite?: Site): void {
    this.store.dispatch(DevicesDbActions.moveDevices({ devicesToMove, originSite }));
  }

  loadComments(device: Device): void {
    this.store.dispatch(DevicesCommentsActions.loadComments({ device }));
  }

  addComment(deviceId: string, comment: string): void {
    this.store.dispatch(DevicesCommentsActions.addComment({ deviceId, comment }));
  }

  editComment(deviceId: string, comment: Log): void {
    this.store.dispatch(DevicesCommentsActions.editComment({ deviceId, comment }));
  }

  deleteComment(deviceId: string, commentId: string): void {
    this.store.dispatch(DevicesCommentsActions.deleteComment({ deviceId, commentId }));
  }

  updateDeviceInCurrentGrid(item: Device): void {
    const gridId = this.gridConfiguration()?.currentGrid?.id;
    if (gridId) {
      this.store.dispatch(GridsDbActions.updateItemInGridData({ gridId, item, concept: 'devices' }));
    }
  }
}
