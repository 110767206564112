import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailValidators } from '@iot-platform/iot-platform-utils';

@Component({
  selector: 'iot-platform-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss', '../../scss/style.scss']
})
export class LoginFormComponent implements OnInit {
  @Input() hasError = false;
  @Input() isI4BLogin = false;

  hide = true;
  form!: UntypedFormGroup;
  enableGauge!: boolean;

  @Output() resetPassword: EventEmitter<any> = new EventEmitter();
  @Output() signIn: EventEmitter<{ username: string; password: string }> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  get email() {
    return this.form.get('email') as AbstractControl;
  }

  get password() {
    return this.form.get('password') as AbstractControl;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, EmailValidators.isValid()]),
      password: new UntypedFormControl(null, [Validators.required])
    });
  }

  onChangeEmail(value: string) {
    this.email.setValue(value.toLowerCase());
  }

  onResetPassword() {
    this.resetPassword.emit();
  }

  onSignIn() {
    if (this.form.valid) {
      this.signIn.emit({ username: this.email.value, password: this.password.value });
    }
  }
}
