<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-title">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="" fxLayout="row" fxLayoutAlign="start center">
                {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.TITLE' | translate }} - {{ version$ | async }}
                <span style="margin-left: 20px"><mat-spinner *ngIf="isLoading" diameter="20"
                                                             mode="indeterminate"></mat-spinner> </span>
            </h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>
    <mat-card-content class="dialog-card-content" fxLayout="column">
        <form [formGroup]="mvSettingsForm" class="tp-popup" fxLayout="column">
            <mat-form-field appearance="fill" color="accent">
                <mat-label>{{ 'IOT_DICTIONARY.VIEW' | translate }}</mat-label>
                <mat-select (selectionChange)="initColumns($event)" [disabled]="changesMade">
                    <mat-option *ngFor="let concept of concepts" [value]="concept">{{ concept }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex="48">
                        <p>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.AVAILABLE_COLUMNS' | translate }}</p>
                        <div
                                #availableColumnsList="cdkDropList"
                                (cdkDropListDropped)="drop($event)"
                                [cdkDropListConnectedTo]="[selectedColumnsList]"
                                [cdkDropListData]="availableColumns"
                                cdkDropList
                                class="dd-list"
                        >
                            <div *ngFor="let column of availableColumns" cdkDrag
                                 class="dd-item">{{ column.catalogName }}</div>
                        </div>
                    </div>

                    <div fxFlex="48">
                        <p>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.DEFAULT_COLUMNS' | translate }}</p>
                        <div
                                #selectedColumnsList="cdkDropList"
                                (cdkDropListDropped)="drop($event)"
                                [cdkDropListConnectedTo]="[availableColumnsList]"
                                [cdkDropListData]="selectedColumns"
                                cdkDropList
                                class="dd-list"
                        >
                            <div *ngFor="let column of selectedColumns; let index = index" cdkDrag class="dd-item">
                                <p fxFlex>{{ column.catalogName }}</p>
                                {{ index + 1 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="clearAll()" class="button-regular" mat-button>
            {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.RESET_ALL_SETTINGS' | translate }}
        </button>
        <span fxFlex></span>
        <button (click)="restoreDefaultSettings()" [disabled]="!selectedSettingName || isLoading || !changesMade" class="button-regular"
                mat-button>
            {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.RESET_MV_SETTINGS' | translate }}
        </button>
        <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
        <button (click)="save()" [disabled]="selectedColumns.length < 3 || isLoading || !changesMade" class="button-regular" color="accent"
                mat-raised-button>
            {{ 'IOT_DICTIONARY.SAVE' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
