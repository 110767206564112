<div [ngClass]="{ 'table-container--sticky': sticky }" class="table-container">
    <table (matSortChange)="onSortChange($event)" [dataSource]="masterViewDataSource" [data]='tableData'
           [observerSelectors]='observerSelectors' [pagination]='(pagination$ | async)' [sticky]='sticky'
           i4b-table-engine-master-view-table-full-height
           mat-table matSort multiTemplateDataRows>
        <ng-container *ngIf="bluePrint?.multipleSelection" [matColumnDef]="bluePrint?.selectionColumn.id">
            <th *matHeaderCellDef [fxFlex]="bluePrint?.selectionColumn.width"
                [fxLayoutAlign]="bluePrint?.selectionColumn.type"
                mat-header-cell>
                <mat-checkbox
                        (change)="toggleAllSelection()"
                        [checked]="isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        class="mve-select-checkbox"
                ></mat-checkbox>
                <span *ngIf="totalSelected > 1" [textContent]="totalSelected"
                      class="table-total-selected fw-600"></span>
            </th>
            <td *matCellDef="let element" [fxFlex]="bluePrint?.selectionColumn.width"
                [fxLayoutAlign]="bluePrint?.selectionColumn.type"
                mat-cell>
                <mat-checkbox (change)="toggleSelection(element)" (click)="$event.stopPropagation()"
                              [checked]="isChecked(element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container *ngFor="let column of columnsToDisplay" [matColumnDef]="column.id">
            <th
                    *matHeaderCellDef
                    [disabled]="!column.sortable"
                    [fxFlex]="column.width"
                    [fxLayoutAlign]="column.type"
                    [mat-sort-header]="column.sortProperty"
                    mat-header-cell
            >
                <i4b-table-engine-master-view-header-container
                        [column]="column"></i4b-table-engine-master-view-header-container>
            </th>
            <td *matCellDef="let element" [fxFlex]="column.width" [fxLayoutAlign]="column.type"
                data-cy='i4b-table-engine-master-view-cell-container' mat-cell>
                <i4b-table-engine-master-view-cell-container
                        (dispatchEvent)="onDispatchEvent($event)"
                        [column]="column"
                        [element]="element"
                        [userPermissions]="userPermissions"
                ></i4b-table-engine-master-view-cell-container>
            </td>
        </ng-container>

        <ng-container *ngIf="bluePrint?.multipleSelection" [matColumnDef]="bluePrint?.buttonColumn.id">
            <th *matHeaderCellDef [fxFlex]="bluePrint?.buttonColumn.width"
                [fxLayoutAlign]="bluePrint?.buttonColumn.type" class="table-buttons"
                mat-header-cell>
                <div *ngIf="totalSelected > 1" class="app-round-button-mv action">
                    <button *ngIf="isCallToActionVisible" [matMenuTriggerFor]="menu" color="accent" mat-icon-button>
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div *ngFor="let action of bluePrint?.buttonColumn.bulkActions">
                            <button (click)="onBulkActionClick(action.key)" *ngIf="bulkActionVisibility[action.key]"
                                    mat-menu-item>
                                {{ action.label | translate | uppercase }}
                            </button>
                        </div>
                    </mat-menu>
                </div>
            </th>
            <td *matCellDef="let element" [fxFlex]="bluePrint?.buttonColumn.width"
                [fxLayoutAlign]="bluePrint?.buttonColumn.type"
                class="table-buttons" data-cy='i4b-table-engine-call-to-action-cell'
                mat-cell>
                <i4b-table-engine-call-to-action-cell
                        (actionClicked)="onSingleActionClick($event)"
                        *ngIf="isCallToActionVisible"
                        [actions]="bluePrint?.buttonColumn.singleActions"
                        [element]="element"
                        [userPermissions]="userPermissions"
                >
                </i4b-table-engine-call-to-action-cell>
            </td>
        </ng-container>

        <ng-container *ngIf="!bluePrint?.multipleSelection && bluePrint?.buttonColumn"
                      [matColumnDef]="bluePrint?.buttonColumn.id">
            <th *matHeaderCellDef [fxFlex]="bluePrint?.buttonColumn.width"
                [fxLayoutAlign]="bluePrint?.buttonColumn.type" class="table-buttons"
                mat-header-cell></th>
            <td *matCellDef="let element" [fxFlex]="bluePrint?.buttonColumn.width"
                [fxLayoutAlign]="bluePrint?.buttonColumn.type"
                class="table-buttons" data-cy='i4b-table-engine-call-to-action-cell'
                mat-cell>
                <i4b-table-engine-call-to-action-cell
                        (actionClicked)="onSingleActionClick($event)"
                        *ngIf="isCallToActionVisible"
                        [actions]="bluePrint?.buttonColumn.singleActions"
                        [element]="element"
                        [userPermissions]="userPermissions"
                >
                </i4b-table-engine-call-to-action-cell>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="columnsToDisplayIds" class="table-row-header" mat-header-row></tr>
        <tr
                (click)="openDetail(element)"
                *matRowDef="let element; columns: columnsToDisplayIds"
                [class.checked]="isChecked(element)"
                [class.selected]="isRowSelected(element)"
                class="table-row-content"
                data-cy='i4b-table-engine-row'
                mat-row
        ></tr>
        <!--    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-row-detail"></tr>-->
    </table>

    <div *ngIf='withLoadMoreButton else paginatorTmpl;' class='load-more' fxLayout="column"
         fxLayoutAlign="center center">
        <button (click)="onLoadMore()" *ngIf='(pagination$ | async)?.hasMore' [disabled]='loading'
                class="button-regular"
                mat-button>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-progress-spinner
                        *ngIf="loading"
                        [diameter]="20"
                        color="accent"
                        mode="indeterminate"></mat-progress-spinner>
                {{ 'IOT_DICTIONARY.LOAD_MORE' | translate }}
            </div>
        </button>
    </div>

    <ng-template #paginatorTmpl>
        <mat-paginator
          #fullDatasetPaginator
          (page)="onPageChange($event)"
          *ngIf="useFullyLoadedDataset"
          [length]="(pagination$ | async).total"
          [pageIndex]="(pagination$ | async).currentPage"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="(pagination$ | async).limit"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>

        <mat-paginator
          #onePageDatasetPaginator
          (page)="onPageChange($event)"
          *ngIf="!useFullyLoadedDataset"
          [disabled]="pendingRequest$"
          [length]="(pagination$ | async).total"
          [pageIndex]="(pagination$ | async).currentPage"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="(pagination$ | async).limit"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
    </ng-template>
</div>
<ng-template #exportViewRef></ng-template>
