<div fxLayout="row wrap" fxLayoutGap="4px">
  <div
    *ngFor="let contact of selectedItem.expandedContacts"
    class="related-contact-chip"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="4px"
    [ngClass]="{ warning: contact.incorrectEmailSetting || contact.incorrectSmsSetting }"
  >
    <span [ngClass]="{ 'fw-800': contact.id === currentContact?.id }">{{ contact.firstname }}</span>
    <span [ngClass]="{ 'fw-800': contact.id === currentContact?.id }">{{ contact.lastname }}</span>
    <mat-icon
      *ngIf="contact.notificationSupports.email"
      [ngClass]="{ warning: contact.incorrectEmailSetting }"
      [matTooltip]="(contact.incorrectEmailSetting ? 'SITES.NOTIFICATIONS_TAB.INVALID_EMAIL' : 'IOT_DICTIONARY.EMAIL') | translate"
      [matTooltipClass]="contact.incorrectEmailSetting ? 'warning-tooltip' : 'regular-tooltip capitalize-first-letter'"
      >mail</mat-icon
    >
    <mat-icon
      *ngIf="contact.notificationSupports.sms"
      [ngClass]="{ warning: contact.incorrectSmsSetting }"
      [matTooltip]="(contact.incorrectSmsSetting ? 'SITES.NOTIFICATIONS_TAB.INVALID_PHONE_NUMBER' : 'IOT_DICTIONARY.SMS') | translate"
      [matTooltipClass]="contact.incorrectSmsSetting ? 'warning-tooltip' : 'regular-tooltip uppercase'"
      >sms</mat-icon
    >
  </div>
</div>
<span *ngIf="!selectedItem.expandedContacts.length">{{ 'SITES.NOTIFICATIONS_TAB.NO_CONTACT' | translate }}</span>
