import { Component, Input, OnInit } from '@angular/core';
import { DateIntervalUtils, SortUtil } from '@iot-platform/iot-platform-utils';
import { RichVariableColumn as VariablesHelper } from '@iot-platform/models/grid-engine';
import { AssetVariable, AssetVariableThreshold } from '@iot-platform/models/i4b';
import { LastValueWidgetOptions, WidgetInstance } from '@iot-platform/models/widgets';
import { Moment } from 'moment-timezone';
import * as moment from 'moment/moment';
import { WidgetsService } from '../../services/widgets.service';

@Component({
  selector: 'iot-platform-ui-last-value-widget',
  templateUrl: './last-value-widget.component.html',
  styleUrls: ['./last-value-widget.component.scss']
})
export class LastValueWidgetComponent implements OnInit {
  @Input() widget!: WidgetInstance;

  variable: AssetVariable = this.widget?.data.variables[0];
  variableLoading = true;
  activatedThreshold!: AssetVariableThreshold | null;
  eventIconParameters: { displayIcon: boolean; name: string; tooltip: string } = { displayIcon: false, tooltip: '', name: '' };

  isRecentDay = false;
  isRecentHour = false;
  isFutureDay = false;
  isNextDay = false;

  constructor(private widgetsService: WidgetsService) {}

  ngOnInit(): void {
    this.widgetsService.getAssetVariableById(this.options?.variable.id).subscribe(
      (v) => {
        v.thresholds.values.sort(SortUtil.sortByProperty('position'));
        this.variable = v;
        this.activatedThreshold = this.getActivatedThreshold();
        this.variableLoading = false;
        this.setEventIconParameters(this.variable);
        this.processDateIntervals(moment(), this.variable.lastValue?.datetime ?? '');
      },
      () => (this.variableLoading = false)
    );
  }

  get options(): LastValueWidgetOptions | null {
    return this.widget ? (this.widget.options as LastValueWidgetOptions) : null;
  }

  getActivatedThreshold(): AssetVariableThreshold | null {
    if (this.variable.lastValue) {
      const nearestThreshold = VariablesHelper.getNearestThreshold(this.variable, this.variable.lastValue.value);
      return VariablesHelper.isValidThresholdCondition(nearestThreshold) ? nearestThreshold : null;
    } else {
      return null;
    }
  }

  setEventIconParameters(variable: AssetVariable): void {
    if (!!variable.totalActiveEvents && !variable.totalAcknowledgedEvents) {
      this.eventIconParameters = { displayIcon: true, name: 'active', tooltip: 'WIDGETS.LAST_VALUE.EVENT_ICON.TOOLTIPS.ACTIVE' };
    } else if (!!variable.totalActiveEvents && !!variable.totalAcknowledgedEvents) {
      this.eventIconParameters = { displayIcon: true, name: 'ongoing', tooltip: 'WIDGETS.LAST_VALUE.EVENT_ICON.TOOLTIPS.ONGOING' };
    } else if (!variable.totalActiveEvents && !!variable.totalAcknowledgedEvents) {
      this.eventIconParameters = { displayIcon: true, name: 'acknowledged', tooltip: 'WIDGETS.LAST_VALUE.EVENT_ICON.TOOLTIPS.ACKNOWLEDGED' };
    } else {
      this.eventIconParameters = { displayIcon: false, tooltip: '', name: '' };
    }
  }

  processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay = DateIntervalUtils.isDateInThePastDay(now, date);
    this.isRecentHour = DateIntervalUtils.isDateInThePastHour(now, date);
    this.isNextDay = DateIntervalUtils.isDateInTheNextDay(now, date);
    this.isFutureDay = DateIntervalUtils.isDateInTheFutureDays(now, date);
  }
}
