<form [formGroup]="selectForm">
    <mat-form-field class="select-field" color="accent">
        <mat-label>{{ data.criteriaLabel | translate }}</mat-label>
        <mat-select
                (selectionChange)="onSelectionChange()"
                [compareWith]="compareFn"
                [multiple]="data.multiSelect"
                data-cy="iot-platform-ui-select-field"
                formControlName="select"
        >
            <mat-option
                    *ngFor="let option of data.list"
                    [disabled]="data.multiSelect && currentFiltersSize >= maxFilters"
                    [value]="option"
                    data-cy="iot-platform-ui-select-field-option"
            >{{ option.value | translate }}</mat-option
            >
        </mat-select>
    </mat-form-field>
</form>
