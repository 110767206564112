import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from '@iot-platform/auth';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { IconModule } from '../components/icon/icon.module';
import { SharedModule } from '../shared.module';
import { VariableChartDialogComponent } from './variable-chart-dialog/variable-chart-dialog.component';
import { VariableChartComponent } from './variable-chart.component';
import { VariableChartService } from './variable-chart.service';
import { VariableItemComponent } from './variable-item/variable-item.component';

@NgModule({
  imports: [SharedModule, IconModule, IotPlatformPipesModule, HighchartsChartModule],
  declarations: [VariableChartComponent, VariableItemComponent, VariableChartDialogComponent],
  exports: [VariableChartComponent, VariableChartDialogComponent],
  providers: [
    TranslateService,
    VariableChartService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class VariableChartModule {}
