import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-ai-credentials-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    TranslateModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  templateUrl: './ai-credentials-dialog.component.html',
  styleUrl: './ai-credentials-dialog.component.scss'
})
export class AiCredentialsDialogComponent {
  form: UntypedFormGroup;

  constructor(private readonly dialogRef: MatDialogRef<AiCredentialsDialogComponent>) {
    this.form = new UntypedFormGroup({
      aws_access_key_id: new UntypedFormControl('', [Validators.required]),
      aws_secret_access_key: new UntypedFormControl('', [Validators.required]),
      aws_session_token: new UntypedFormControl('', [Validators.required])
    });
  }

  save() {
    this.dialogRef.close({
      aws_access_key_id: this.form.controls['aws_access_key_id'].value,
      aws_secret_access_key: this.form.controls['aws_secret_access_key'].value,
      aws_session_token: this.form.controls['aws_session_token'].value
    });
  }
}
