<div (mouseleave)="toggleIconInfo(false, $event)" (mouseover)="toggleIconInfo(true, $event)" fxFlexFill fxLayout="row" fxLayoutAlign="start center">
  <div (click)="onDispatchEvent($event)" [class.clickable]="!minimalDisplay" class="basic-click-cell fw-600">
    {{ variable | valueUnitFormat | infoDisplay }}
  </div>
  @if (!minimalDisplay) {
      @if (eventIconProperties.isDisplayed) {
        <mat-icon
          (click)="openFollowedVariableEvents($event)"
          [matTooltipClass]="'regular-tooltip'"
          [matTooltip]="eventIconProperties.tooltip | translate"
          [svgIcon]="eventIconProperties.iconName"
          class="icon-notification"
          matTooltipPosition="above"
        ></mat-icon>
      }
      @if (displayGraphIcon) {
        <mat-icon
          (click)="openGraphsByVariable($event)"
          [matTooltipClass]="'regular-tooltip'"
          [matTooltip]="'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_GRAPH' | translate"
          class="icon-graphs"
          matTooltipPosition="above"
          >assessment
        </mat-icon>
      }
      @if (displayInfoIcon) {
        <mat-icon
          (click)="openVariableConfiguration($event)"
          (mouseover)="toggleIconInfo(true, $event)"
          [matTooltipClass]="'regular-tooltip'"
          [matTooltip]="'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_THE_VARIABLE_CONFIGURATION' | translate"
          class="icon-configuration"
          matTooltipPosition="above"
          >info
        </mat-icon>
      }
  }
</div>
