import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter, FilterDefaultOptions } from '@iot-platform/models/common';
import { Subject } from 'rxjs';
import { DateRangeElement } from '../../../date-range';
import { FilterComponent } from '../../models/filter.component';

@Component({
  selector: 'iot-platform-ui-date-interval-field',
  templateUrl: './date-interval-field.component.html',
  styleUrls: ['./date-interval-field.component.scss']
})
export class DateIntervalFieldComponent implements FilterComponent {
  @Input() data: FilterDefaultOptions;
  @Output() dispatchFilterValue: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();

  startDate: DateRangeElement;
  endDate: DateRangeElement;
  clearDateRangeFilters$: Subject<boolean> = new Subject<boolean>();

  onDateRangeChange({ startDate, endDate }): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  onValidation(): void {
    const filterStart: Filter = {};
    const filterEnd: Filter = {};
    const filters: Filter[] = [];
    filterStart.criteriaKey = this.data.criteriaKey + 'Start';
    filterStart.criteriaLabel = this.data.criteriaLabel + '_START';
    filterEnd.criteriaKey = this.data.criteriaKey + 'End';
    filterEnd.criteriaLabel = this.data.criteriaLabel + '_END';
    if (this.startDate) {
      filterStart.value = this.startDate.value;
      filterStart.label = this.startDate.label;
      filters.push(filterStart);
    }
    if (this.endDate) {
      filterEnd.value = this.endDate.value;
      filterEnd.label = this.endDate.label;
      filters.push(filterEnd);
    }
    this.dispatchFilterValue.emit(filters);
    this.clearDateRangeFilters$.next(true);
  }
}
