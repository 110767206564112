import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Filter, TagCategory, TagLabel } from '@iot-platform/models/common';

interface TagBody {
  tagCategory: TagCategory;
  tag: TagLabel;
}

@Component({
  selector: 'iot-platform-ui-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  category: string;
  label: string;
  dateLabel: string;
  iconName: string;
  bckgColor: string;
  color: string;
  tagClass = false;

  @Input() isTag: boolean;
  @Input() chip: TagBody | Filter | TagCategory;
  @Input() chipButton: { display: boolean; name?: string };

  @Output() chipButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() chipClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.setChipParameters();
  }

  setChipParameters(): void {
    if (this.chip.hasOwnProperty('criteriaKey')) {
      const chip = this.chip as Filter;
      this.category = chip.criteriaLabel;
      if (chip.dateLabel) {
        this.dateLabel = chip.dateLabel;
      } else {
        this.label = chip.label;
      }
      this.iconName = null;
      this.color = '';
      this.bckgColor = '';
    } else if (this.chip.hasOwnProperty('tag')) {
      const chip = this.chip as TagBody;
      this.category = chip.tagCategory.name;
      this.label = chip.tag.name;
      this.iconName = chip.tagCategory.concept?.toLowerCase();
      this.color = '';
      this.bckgColor = chip.tagCategory.color;
      this.tagClass = chip.tag.selected;
    }

    if (this.isTag) {
      const chip = this.chip as TagCategory;
      this.category = chip.name;
      this.label = chip.labels[0].name;
      this.iconName = chip.concept?.toLowerCase();
      this.color = '';
      this.bckgColor = chip.color;
    }
  }

  onChipButtonClick(event: MouseEvent, chip: any): void {
    event.stopPropagation();
    this.chipButtonClick.emit(chip);
  }

  onChipClick(event: MouseEvent, chip: any): void {
    event.stopPropagation();
    this.chipClick.emit(chip);
  }
}
