import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import {
  CountryAutocompleteFieldComponent,
  DateIntervalFieldComponent,
  DynamicListFieldMultipleSelectsComponent,
  DynamicListFieldSingleSelectComponent,
  InputFieldComponent,
  MultipleInputsFieldComponent,
  SelectFieldComponent,
  TimezoneAutocompleteFieldComponent
} from './fields';

import { FilterComponent } from './models/filter.component';

@Injectable()
export class FilterComponentFactory {
  readonly selector!: string;
  readonly componentType!: Type<any>;
  readonly ngContentSelectors: string[] = [];
  readonly inputs!: {
    propName: string;
    templateName: string;
  }[];
  readonly outputs!: {
    propName: string;
    templateName: string;
  }[];

  createComponent(elementType: string, viewContainerRef: ViewContainerRef): ComponentRef<FilterComponent> | null {
    if (viewContainerRef) {
      switch (elementType) {
        case 'input':
          return viewContainerRef.createComponent<InputFieldComponent>(InputFieldComponent);
        case 'multiple-inputs':
          return viewContainerRef.createComponent<MultipleInputsFieldComponent>(MultipleInputsFieldComponent);
        case 'select':
          return viewContainerRef.createComponent<SelectFieldComponent>(SelectFieldComponent);
        case 'date-interval':
          return viewContainerRef.createComponent<DateIntervalFieldComponent>(DateIntervalFieldComponent);
        case 'dynamic-list-single-select':
          return viewContainerRef.createComponent<DynamicListFieldSingleSelectComponent>(DynamicListFieldSingleSelectComponent);
        case 'dynamic-list-multiple-selects':
          return viewContainerRef.createComponent<DynamicListFieldMultipleSelectsComponent>(DynamicListFieldMultipleSelectsComponent);
        case 'timezone-autocomplete':
          return viewContainerRef.createComponent<TimezoneAutocompleteFieldComponent>(TimezoneAutocompleteFieldComponent);
        case 'country-autocomplete':
          return viewContainerRef.createComponent<CountryAutocompleteFieldComponent>(CountryAutocompleteFieldComponent);
        default:
          return null;
      }
    } else {
      return null;
    }
  }
}
