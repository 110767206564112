import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { DynamicDataResponse, Filter } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import * as Leaflet from 'leaflet';
import { Layer, PopupOptions } from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet.markercluster';
import { cloneDeep, debounce, get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapClustersHelper } from '../../helpers/map-clusters.helper';
import { MapLayersHelper } from '../../helpers/map-layers.helper';
import { MapMarkersHelper } from '../../helpers/map-markers.helper';
import {
  IotGeoJsonFeature,
  IotGeoJsonRouteFeature,
  IotMapActionType,
  IotMapDisplayMode,
  IotMapDisplayType,
  IotMapEvent,
  IotMapMarkerPopup
} from '../../models';
import { MapNavigationEvent } from '../../models/iot-map-navigation-event.model';
import { MapPopupService } from '../../services/map-popup.service';
import { MapFacade } from '../../state/facades/map.facade';
import LayersOptions = Leaflet.Control.LayersOptions;

Leaflet.Icon.Default.imagePath = 'assets/map';

const MAX_ZOOM = 18;
const MIN_ZOOM = 2;

@Component({
  selector: 'iot-platform-maps-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements AfterViewInit, OnDestroy, OnChanges {
  analytic: AnalyticsService = new AnalyticsService('map');
  @Input() concept = 'sites';
  @Input() filters: Filter[] = [];
  @Input() displayMode: IotMapDisplayMode = 'Basic';
  @Input() displayType: IotMapDisplayType = IotMapDisplayType.CLUSTER;
  /*
        Display chunks of dataset only in the displayed area of the map
        This can resolve performance issue displayType = IotMapDisplayType.POINT
         */
  @Input() displayChunks = false;
  @Input() zoom = 6;
  @Input() defaultCoordinates: number[] = [48, 2.5];

  @Output() dispatchEvent: EventEmitter<IotMapEvent> = new EventEmitter<IotMapEvent>();
  @Output() dispatchMapNavigationEvent: EventEmitter<MapNavigationEvent<Site | Asset | Device>> = new EventEmitter<MapNavigationEvent<Site | Asset | Device>>();

  map!: Leaflet.Map;
  features: IotGeoJsonFeature[] = [];
  routes: IotGeoJsonRouteFeature[] = [];
  selectedMarker!: Leaflet.Marker | null;
  markers$: BehaviorSubject<Leaflet.Marker[]> = new BehaviorSubject<Leaflet.Marker[]>([]);
  assetVariable: any;

  clusterOptions: Leaflet.MarkerClusterGroupOptions = {
    iconCreateFunction: (cluster: Leaflet.MarkerCluster) => MapClustersHelper.createClusterIcon(cluster, this.concept, this.displayMode),
    spiderfyDistanceMultiplier: 1.1,
    maxClusterRadius: 70
  };

  layersControlOptions: LayersOptions = { position: 'topleft', collapsed: false };

  baseLayers: {
    [name: string]: Leaflet.Layer;
  } = {
    OpenStreetMap: Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: MAX_ZOOM,
      attribution: undefined,
      minZoom: MIN_ZOOM
    }) as Leaflet.Layer
  };
  routeLayers = {};

  options: Leaflet.MapOptions = {
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    center: this.defaultPosition,
    zoomControl: true,
    attributionControl: false
  };

  destroy$: Subject<void> = new Subject<void>();
  displayPanelInfo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedFeature$: BehaviorSubject<IotGeoJsonFeature | undefined> = new BehaviorSubject<IotGeoJsonFeature | undefined>(undefined);

  loading$: Observable<boolean> = this.mapFacade.loading$;
  refresh$: Observable<boolean> = this.mapFacade.refresh$;

  applicablePopup$: BehaviorSubject<IotMapMarkerPopup> = new BehaviorSubject<IotMapMarkerPopup>(this.popupService.getPopup(this.concept, this.displayMode));
  selectedLayers$: BehaviorSubject<{ [concept: string]: string }> = new BehaviorSubject<{ [concept: string]: string }>(
    this.storage.get(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY)
      ? JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY))
      : { sites: 'Basic', assets: 'Basic', devices: 'CCF' }
  );

  popupLoadingContent = `<b class='leaflet-popup-content__section'>${this.translateService.instant('CARD_LOADER.LOADING')}</b>`;

  constructor(
    private readonly mapFacade: MapFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone,
    private readonly translateService: TranslateService,
    private readonly storage: LocalStorageService,
    private readonly popupService: MapPopupService
  ) {
    this.refresh$.subscribe((refresh) => {
      if (refresh) {
        this.loadData();
      }
    });

    this.markers$.subscribe((clusters: Leaflet.Marker[]) => {
      this.removeMarkers();

      const feature: Leaflet.MarkerClusterGroup = new Leaflet.MarkerClusterGroup(this.clusterOptions);
      feature.addLayers(clusters);
      if (this.map && feature.getBounds().isValid()) {
        this.map.addLayer(feature);
        this.map.fitBounds(feature.getBounds());
      }
    });

    this.selectedLayers$.subscribe((layers: { [concept: string]: string }) => {
      this.storage.set(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY, JSON.stringify(layers));
      const currentDisplayMode: IotMapDisplayMode = MapLayersHelper.getDisplayModeByLayer(this.concept, layers[this.concept]);
      this.applicablePopup$.next(this.popupService.getPopup(this.concept, currentDisplayMode));
      if (this.selectedMarker) {
        this.selectedMarker.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode));
        this.selectedMarker = null;
      }
      this.displayPanelInfo$.next(false);
    });
  }

  get defaultPosition(): Leaflet.LatLng {
    return Leaflet.latLng(this.defaultCoordinates as Leaflet.LatLngTuple);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'defaultCoordinates.currentValue')) {
      this.defaultCoordinates = changes['defaultCoordinates'].currentValue;
      this.options.center = this.defaultPosition;
    }
    if (get(changes, 'filters.currentValue')) {
      this.cleanRoutes();
      this.mapFacade.clearRoutes();
      this.displayPanelInfo$.next(false);
      this.loadData();
    }
    if (get(changes, 'concept')) {
      this.mapFacade.setConcept(changes['concept'].currentValue);
      this.applicablePopup$.next(this.popupService.getPopup(this.concept, this.displayMode));
    }
  }

  ngAfterViewInit(): void {
    const timeout = setTimeout(() => {
      this.map.invalidateSize();
      if (this.markers$.getValue().length > 0) {
        this.markers$.next(this.markers$.getValue()); // Forced to redraw map after switching to grid
      }
      clearTimeout(timeout);
    }, 100);
  }

  onMapReady(map: Leaflet.Map): void {
    this.map = map;
    this.dispatchEvent.emit({
      type: IotMapActionType.MAP_READY,
      map,
      popup: this.applicablePopup$ ? this.applicablePopup$.getValue() : this.popupService.getPopup(this.concept, this.displayMode)
    });

    this.initFeatures();
    this.initRoutes();
    this.onMapMoveEnd();
    // this.hidePanelOnZoomChanged();
    this.manageAdditionalBaseLayers();
  }

  hidePanelOnZoomChanged(): void {
    this.map.on('zoomstart', () => {
      this.displayPanelInfo$.next(false);
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode));
      this.selectedMarker = null;
      this.selectedFeature$.next(undefined);
      this.cdr.detectChanges();
    });
  }

  onMapMoveEnd(): void {
    this.map.on(
      'moveend',
      debounce(() => {
        this.dispatchEvent.emit({
          type: IotMapActionType.MAP_MOVE_END,
          map: this.map
        });
        if (this.displayChunks) {
          this.removeMarkers();
          this.initMarkers();
        }
      }, 200)
    );
  }

  loadData(): void {
    const selectedLayerConfig = MapLayersHelper.getSelectedLayerConfig(this.concept, this.selectedLayers$.getValue()[this.concept]);
    if (selectedLayerConfig) {
      const additionalFilter: Filter | undefined = selectedLayerConfig.additionalFilter;
      this.mapFacade.getAll({
        concept: this.concept,
        displayMode: selectedLayerConfig.displayMode,
        filters: additionalFilter ? [...this.filters, additionalFilter] : this.filters
      });
    } else {
      this.mapFacade.getAll({ concept: this.concept, displayMode: this.displayMode, filters: this.filters });
    }
  }

  cleanRoutes(): void {
    if (this.map) {
      this.map.eachLayer((layer: Leaflet.Layer) => {
        if (layer instanceof Leaflet.GeoJSON) {
          this.map.removeLayer(layer);
        }
      });
      this.cdr.detectChanges();
    }
  }

  initRoutes(): void {
    combineLatest([this.mapFacade.routesLoaded$, this.mapFacade.routesLoading$, this.mapFacade.hasRoutes$, this.mapFacade.currentRoutes$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cleanRoutes();
      });
  }

  initFeatures(): void {
    this.mapFacade.currentFeatures$.pipe(takeUntil(this.destroy$)).subscribe((features: IotGeoJsonFeature[]) => {
      this.features = [...features];
      if (features.length === 0) {
        this.map.panTo(this.defaultPosition);
        this.map.setZoom(3);
      }
      this.initMarkers();
    });
    if (!this.features.length) {
      this.loadData();
    }
  }

  initMarkers(): void {
    this.zone.runOutsideAngular(() => {
      const markers: Leaflet.Marker[] = [];
      this.features.forEach((feature: IotGeoJsonFeature | IotGeoJsonRouteFeature) => {
        if (this.displayType === IotMapDisplayType.CLUSTER || this.displayType === IotMapDisplayType.POINT) {
          const marker: Leaflet.Marker = this.generateMarker(feature as IotGeoJsonFeature);
          if (this.hasValidCoordinates(marker) && this.isMarkerInMapBound(marker)) {
            markers.push(marker);
          }
          if (this.hasValidCoordinates(marker) && this.displayType === IotMapDisplayType.POINT) {
            this.setMapMinZoom(markers);
            this.map.addLayer(marker);
          }
        }
      });
      if (this.displayType === IotMapDisplayType.CLUSTER) {
        this.markers$.next([...markers]);
      }
      this.cdr.detectChanges();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMapMinZoom(markers: Leaflet.Marker[]): void {
    // this.map.setMinZoom(markers.length > 500 ? 7 : 0);
  }

  getPopup(data: DynamicDataResponse, feature: IotGeoJsonFeature): IotMapMarkerPopup {
    const popup: IotMapMarkerPopup = new IotMapMarkerPopup({ ...this.applicablePopup$.getValue().options, data, feature });
    popup.templateRows = this.applicablePopup$.getValue().templateRows;
    return popup.build();
  }

  generateRoutePoint(feature: IotGeoJsonFeature): Leaflet.Marker {
    const marker: Leaflet.Marker = Leaflet.marker(Leaflet.latLng([feature.geometry.coordinates[1], feature.geometry.coordinates[0]] as Leaflet.LatLngTuple), {
      draggable: false,
      icon: Leaflet.divIcon({
        html: '<svg style="width: 3px; height: 3px;"><circle r="3" cx="3" cy="3" stroke-width="1" fill="#bf6660"></circle><svg>',
        iconSize: [0, 0],
        iconAnchor: [3, 3]
      })
    });
    marker.feature = feature;

    return marker;
  }

  generateMarker(feature: IotGeoJsonFeature): Leaflet.Marker {
    const marker: Leaflet.Marker = Leaflet.marker(Leaflet.latLng([feature.geometry.coordinates[1], feature.geometry.coordinates[0]] as Leaflet.LatLngTuple), {
      draggable: false,
      icon: MapMarkersHelper.getMarkerIcon(feature, this.displayMode)
    });
    marker.feature = feature;
    if (get(this.applicablePopup$.getValue(), 'displayPopup')) {
      const popupOptions: PopupOptions = {
        autoClose: true,
        closeButton: false,
        offset: Leaflet.point(-160, -25)
      };
      marker.bindPopup(get(this.applicablePopup$.getValue(), 'loadData') ? this.popupLoadingContent : this.getPopup(null, feature), popupOptions);
    }
    marker.on('click', (event: Leaflet.LeafletMouseEvent) => this.markerClicked(event, feature));
    marker.on('mouseover', (event: Leaflet.LeafletMouseEvent) => this.markerHovered(event, feature));
    marker.on('mouseout', (event: Leaflet.LeafletMouseEvent) => this.markerLeaved(event, feature));
    return marker;
  }

  markerHovered(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.analytic.log('map-actions', 'marker-hovered');
    event.target.openPopup();
    if (this.selectedMarker?.feature?.properties.id !== event.target.feature.properties.id) {
      event.target.setIcon(MapMarkersHelper.getMarkerIconHover(feature, this.displayMode));
    }
  }

  markerLeaved(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    event.target.closePopup();
    if (this.selectedMarker?.feature?.properties.id !== event.target.feature.properties.id) {
      event.target.setIcon(MapMarkersHelper.getMarkerIcon(feature, this.displayMode));
    }
  }

  markerClicked($event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.analytic.log('map-actions', 'marker-clicked');
    this.cleanRoutes();
    this.mapFacade.clearRoutes();
    this.dispatchEvent.emit({
      type: IotMapActionType.MARKER_CLICK,
      marker: $event.target,
      feature
    });
    this.mapFacade.saveMapUiState($event.target);
    if (!this.displayPanelInfo$.getValue()) {
      if (this.selectedMarker?.feature?.properties.id !== $event.target.feature.properties.id) {
        this.selectedMarker = $event.target;
        this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode));
      } else {
        $event.target.setIcon(MapMarkersHelper.getMarkerIcon(feature, this.displayMode));
        this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode));
      }
      this.displayPanelInfo$.next(true);
    } else {
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode));
      this.selectedMarker = cloneDeep($event.target);
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode));
    }
    this.selectedFeature$.next(feature);

    this.cdr.detectChanges();
  }

  hasValidCoordinates(marker: Leaflet.Marker): boolean {
    const latLong: Leaflet.LatLng = marker.getLatLng();
    return Math.abs(latLong.lat) <= 90 && Math.abs(latLong.lng) <= 180;
  }

  isMarkerInMapBound(marker: Leaflet.Marker): boolean {
    return this.displayChunks ? this.map.getBounds().contains(marker.getLatLng()) : true;
  }

  removeMarkers(): void {
    if (this.map) {
      this.map.eachLayer((layer: Leaflet.Layer) => {
        if (layer instanceof Leaflet.Marker || layer instanceof Leaflet.MarkerCluster || layer instanceof Leaflet.MarkerClusterGroup) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  initCurrentPosition(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.map.panTo(Leaflet.latLng([position.coords.latitude, position.coords.longitude]));
        },
        () => {
          this.map.panTo(this.defaultPosition);
        }
      );
    }
  }

  onElementSelection(event: MapNavigationEvent<Site | Asset | Device>) {
    this.zone.run(() => {
      this.dispatchMapNavigationEvent.emit(event);
    });
  }

  onDisplaySegments(event: { layers: Layer[]; action: 'add' | 'remove' }) {
    if (event.action === 'add') {
      event.layers
        .sort((a: Leaflet.Layer, b: Leaflet.Layer) => (a > b ? 1 : -1))
        .forEach((layer) => {
          this.map.addLayer(layer);
          this.cdr.detectChanges();
        });
    } else {
      event.layers.forEach((layer) => {
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
          this.cdr.detectChanges();
        }
      });
    }

    const bounds: any[] = [];
    this.map.eachLayer((layer: Leaflet.Layer) => {
      if (layer instanceof Leaflet.GeoJSON) {
        bounds.push(layer.getBounds());
      }
    });

    if (bounds.length > 0) {
      this.map.fitBounds(bounds);
    }
  }

  onClosePanelInfo() {
    this.analytic.log('map-actions', 'close-panel-info');
    this.displayPanelInfo$.next(false);
    this.cleanRoutes();
    if (this.selectedMarker) {
      this.selectedMarker.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode));
      this.selectedMarker = null;
    }
  }

  onBaseLayerChange = (e: Leaflet.LayersControlEvent) => {
    const selectedLayerConfig = MapLayersHelper.getSelectedLayerConfig(this.concept, e.name);

    if (selectedLayerConfig) {
      this.displayMode = selectedLayerConfig.displayMode;
      const additionalFilter: Filter | undefined = selectedLayerConfig.additionalFilter;
      this.mapFacade.getAll({
        concept: this.concept,
        displayMode: selectedLayerConfig.displayMode,
        filters: additionalFilter ? [...this.filters, additionalFilter] : this.filters
      });
      this.cleanRoutes();
      this.mapFacade.clearRoutes();
      this.dispatchEvent.emit({
        type: IotMapActionType.CHANGE_DISPLAY_MODE,
        displayMode: this.displayMode
      });

      this.selectedLayers$.next({ ...this.selectedLayers$.getValue(), [this.concept]: e.name });
      this.analytic.log('map-actions', 'selected-layer-changed', `[${this.concept}] : ${e.name}`);
    }
  };

  ngOnDestroy(): void {
    this.cleanRoutes();

    this.destroy$.next();
    this.destroy$.complete();
  }

  private manageAdditionalBaseLayers() {
    const layerControlForCurrentConcept: { [layerName: string]: Leaflet.LayerGroup } = MapLayersHelper.getLayersByConcept(this.concept);
    const layerControl = Leaflet.control.layers(layerControlForCurrentConcept, {}, this.layersControlOptions);
    layerControl.addTo(this.map);
    const activeLayer: string = this.selectedLayers$.getValue()[this.concept];
    if (activeLayer) {
      layerControlForCurrentConcept[activeLayer].addTo(this.map);
    }

    this.map.on('baselayerchange', this.onBaseLayerChange);
  }
}
