<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ 'DEVICES.CONNECTORS.EDIT_FORM.TITLE' | translate: param }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20" fxLayout="row">
        <form [formGroup]="editConnectorFrom" class="full-width" fxLayout="column" fxLayoutGap="10px">

            <mat-form-field color="accent" fxFlex>
                <mat-label>{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.NAME' | translate }}</mat-label>
                <input #nameInput [maxlength]="nameMaxLength" formControlName="name" matInput placeholder="" required/>
                <mat-error
                        *ngIf="name.invalid">{{ 'DEVICES.CONNECTORS.EDIT_FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
                <mat-hint *ngIf="displayDuplicateMessage$ | async" align="start">
                    <span class="dialog-card-input-error">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</span>
                </mat-hint>
                <mat-hint align="end">{{ nameInput.value?.length || 0 }}/{{ nameMaxLength }}</mat-hint>
            </mat-form-field>

            <mat-form-field color="accent">
                <mat-label>{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.ATTACHED_ENTITIES' | translate }}</mat-label>
                <mat-select (selectionChange)="checkConnectorNameUnicity()"
                            formControlName="entities" multiple
                            placeholder=""
                            required>
                    <mat-select-trigger *ngIf="entities.value">{{ getEntitiesFormatted() }}</mat-select-trigger>
                    <mat-option *ngFor="let entity of attachedEntitiesNotVisible?.concat(entityList)"
                                [disabled]="disableEntity(entity)"
                                [value]="entity">{{ entity.label }}</mat-option>
                </mat-select>
                <mat-error
                        *ngIf="entities.value.length > entitiesMaxLength">{{ 'DEVICES.CONNECTORS.EDIT_FORM.ERROR_MESSAGE.TOO_MANY_ENTITIES' | translate }}</mat-error>
                <mat-hint align="end">{{ entities.value?.length || 0 }}/{{ entitiesMaxLength }}</mat-hint>
            </mat-form-field>

            <mat-form-field color="accent" fxFlex>
                <mat-label>{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.DESCRIPTION' | translate }}</mat-label>
                <input
                  #descriptionInput
                  [maxlength]="descriptionMaxLength"
                  formControlName="description"
                  matInput
                  placeholder=""
                />
                <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/{{ descriptionMaxLength }}</mat-hint>
            </mat-form-field>

        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular"
                mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
        <button (click)="save()"
                [disabled]="!editConnectorFrom.valid || !editConnectorFrom.touched || (isDisabled$ | async)"
                class="button-regular"
                color="accent"
                mat-raised-button>
            {{ 'IOT_DICTIONARY.UPDATE' | translate | uppercase }}
        </button>
    </mat-card-actions>
</mat-card>
