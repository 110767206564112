import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxChange,
  MatCheckboxDefaultOptions
} from '@angular/material/checkbox';
import { IotGeoJsonRouteFeature } from '@iot-platform/iot-platform-maps';
import * as Leaflet from 'leaflet';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { MapFacade } from '../../state/facades/map.facade';
import { MapPopupService } from '../../services/map-popup.service';

export interface Route {
  start: string;
  end: string;
  durationMs: number;
  color: string;
  day: string;
  layer: Leaflet.Layer;
  layerLabel: string;
  checked: boolean;
  indeterminate: boolean;
  routes: Route[];
}

@Component({
  selector: 'iot-platform-maps-map-panel-info-popup',
  templateUrl: './map-panel-info-popup.component.html',
  styleUrls: ['./map-panel-info-popup.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions
    }
  ]
})
export class MapPanelInfoPopupComponent implements OnInit {
  gRoutes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  @Output() displayRoutes: EventEmitter<any> = new EventEmitter();
  @Output() displaySegments: EventEmitter<any> = new EventEmitter();

  hasRoutes = false;
  routesLoaded = false;
  loading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    readonly mapFacade: MapFacade,
    private readonly zone: NgZone,
    private readonly mapPopupService: MapPopupService
  ) {}

  ngOnInit(): void {
    this.zone.run(() => {
      combineLatest([this.mapFacade.routesLoaded$, this.mapFacade.routesLoading$, this.mapFacade.hasRoutes$, this.mapFacade.currentRoutes$]).subscribe(
        ([routesLoaded, routesLoading, hasRoutes, routes]) => {
          if (hasRoutes && routesLoaded && !routesLoading && routes.length > 0) {
            this.initRoutes(routes);
          } else {
            this.gRoutes$.next([]);
          }
          this.routesLoaded = routesLoaded;
          // this.routes = routes;
          this.loading = routesLoading;
          this.hasRoutes = hasRoutes;
          this.cdr.detectChanges();
        }
      );
    });
  }

  initRoutes(featureRoutes) {
    const routes: Route[] = [];
    if (featureRoutes.length > 0) {
      featureRoutes
        ?.filter((feature) => feature?.properties.durationMs > 0 && feature.geometry.type === 'LineString')
        .forEach((route: IotGeoJsonRouteFeature, index) => {
          const colors: string[] = ['#0096FF', '#0047AB', '#6F8FAF', '#1434A4', '#6082B6', '#1F51FF', '#0F52BA', '#4169E1', '#4682B4', '#0437F2'];

          const routeColor = colors[index % colors.length];
          const routeLayer: Leaflet.GeoJSON = Leaflet.geoJson(route, {
            style: () => ({
              stroke: true,
              weight: 5,
              color: routeColor
            }),
            onEachFeature: (f, layer) => {
              layer.bindPopup(this.mapPopupService.getPopupRoute(f.properties));
              layer.on({});
            }
          });
          const layerLabel: string = moment(route.properties['start']).format('HH:mm:ss') + ' - ' + moment(route.properties['end']).format('HH:mm:ss');
          const day: string = moment(route.properties['start']).format('DD/MM/yyyy');
          routes.push({
            color: routeColor,
            layerLabel,
            layer: routeLayer,
            day,
            checked: false, // moment(route.properties['start']).isSame(moment(), 'day'), boxes are checked but routes are not added to map
            indeterminate: false,
            routes: [],
            start: route.properties['start'],
            end: route.properties['end'],
            durationMs: route.properties['durationMs']
          });
        });

      const gRoutes = routes.reduce((acc: any, route: any) => {
        const day = route.day;
        if (!acc.find((r) => r.day === day)) {
          acc.push({ day, routes: [route] });
        } else {
          acc.find((r) => r.day === day).routes.push(route);
        }

        const sortedRoutes = acc.sort((a: any, b: any) => {
          a = moment(a.date);
          b = moment(b.date);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        return sortedRoutes;
      }, []);

      const todayRoute: Route = gRoutes.find((r) => r.day === moment().format('DD/MM/yyyy'));

      if (todayRoute) {
        this.setAll(todayRoute, true);
        // this.displaySegments.emit({ layers: todayRoute.routes.map((route) => route.layer), action: 'add' });
      }

      this.gRoutes$.next(gRoutes);
      // this.cdr.detectChanges();
    }
  }

  someSubRoutesChecked(route: Route): boolean {
    return route.routes.some((t) => t.checked) && !route.routes.every((t) => t.checked);
  }

  allSubRoutesChecked(route: Route): boolean {
    return route.routes.every((t) => t.checked);
  }

  setAll(dayRoute: Route, checked: boolean) {
    this.zone.run(() => {
      if (dayRoute.routes === null) {
        return;
      }
      dayRoute.routes.forEach((t) => (t.checked = checked));
      this.displaySegments.emit({ layers: dayRoute.routes.map((route) => route.layer), action: checked ? 'add' : 'remove' });
      this.cdr.detectChanges();
    });
  }

  onRouteChanged(event: MatCheckboxChange, selectedRoute: any) {
    this.displaySegments.emit({ layers: [selectedRoute.layer], action: event.checked ? 'add' : 'remove' });
  }

  getDuration(subRoute: Route) {
    return moment.duration(subRoute.durationMs).asHours().toFixed(0) + 'h';
  }

  clear() {
    this.gRoutes$.next([]);
    this.displaySegments.emit({ layers: [], action: 'remove' });
  }
}
