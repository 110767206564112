@if (displayMode === 'value') {
  <span (click)="onDispatchEvent($event)" class="value-cell">
  {{ variable | valueUnitFormat | infoDisplay }}
</span>
}

@if (displayMode === 'datetime') {
  <span
    [ngClass]="{ recentDay: isRecentDay, recentHour: isRecentHour, futureDay: isFutureDay, nextDay: isNextDay }"
    class="date-cell"
  >
  {{ variable?.lastValue?.datetime | dateFormat | infoDisplay }}
</span>
}
