import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';

@Component({
  selector: 'iot-platform-ui-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  popup: any;
  message: string;

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: 'delete' | 'error' | 'reset' | 'info' | 'confirm' | 'subscribe' | 'unsubscribe';
      value: string | { errorType: string; errorMessage: string; errorCode: string } | { code: number; error: string };
      cssClass?: string;
    }
  ) {
    this.build(this.data.type).subscribe((value) => {
      // this.popup = value;
      if (this.data && this.data.value) {
        this.message = this.getMessage(this.data, value);
        this.popup = value.value ? value.value : value;
        // this.message = value;
      }
    });
  }

  get color() {
    return {
      important:
        this.data.type === 'error' ||
        this.data.type === 'delete' ||
        this.data.type === 'reset' ||
        this.data.type === 'confirm' ||
        this.data.type === 'unsubscribe'
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMessage(data: any, value: any) {
    let message = '';
    if (data.value.error?.error?.error) {
      message = data.value?.type + ' ' + this.translateService.instant(data.value.error.error.error);
    } else if (data.type === 'error' && data.value.hasOwnProperty('error')) {
      message = 'Unable to reach the service'; // data.value.type;
    } else if (data.value) {
      message = data.value;
    } else {
      message = 'An error has occurred, please try again later';
    }
    return message;
  }

  close(value: boolean) {
    this.dialogRef.close(value);
  }

  private build(type: string): Observable<any> {
    switch (type) {
      case 'delete':
        return this.translateService.get('POPUP.DELETE_TYPE');
      case 'subscribe':
        return this.translateService.get('POPUP.SUBSCRIBE_TYPE');
      case 'unsubscribe':
        return this.translateService.get('POPUP.UNSUBSCRIBE_TYPE');
      case 'error':
        return this.translateService.get('POPUP.ERROR_TYPE');
      case 'reset':
        return this.translateService.get('POPUP.RESET_TYPE');
      case 'info':
        return this.translateService.get('POPUP.INFO_TYPE');
      case 'confirm':
        return this.translateService.get('POPUP.CONFIRM_TYPE');
      default:
        return of(this.data);
    }
  }
}
