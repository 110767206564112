<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign=" center">
            <h1 class="dialog-card-title">{{ 'EXPORTS.TITLE' | translate }}</h1>
        </mat-toolbar>
    </mat-card-title>

    <mat-progress-bar *ngIf="displayLoader" [value]="loaderValue" class="exports-loader" color="accent"
                      mode="buffer"></mat-progress-bar>

    <form [formGroup]="exportsForm" class="full-width">
        <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="10px">
            <mat-form-field color="accent" floatLabel="never" fxFlex>
                <span matTextPrefix>{{ time }}-</span>
                <mat-label>{{ 'EXPORTS.NAME' | translate }}</mat-label>
                <input [placeholder]="" formControlName="name" matInput pattern="\S.*"
                       required/>
                <span matTextSuffix>.csv</span>
                <mat-error
                        *ngIf="name.invalid && name.errors.pattern">{{ 'EXPORTS.ERRORS.NAME' | translate }}</mat-error>
            </mat-form-field>
            <p class="dialog-form-tips">{{ 'EXPORTS.TIPS' | translate }}</p>
        </mat-card-content>

        <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
            <button (click)="close()" class="button-regular" mat-button
                    type="button">{{ 'EXPORTS.CANCEL' | translate }}</button>
            <button
                    (click)="action()"
                    [disabled]="(!exportsForm.valid && !isFileDownloaded) || isFileDownloading"
                    class="button-regular"
                    color="accent"
                    mat-raised-button
                    type="submit"
            >
                {{ action$ | async }}
            </button>
        </mat-card-actions>
    </form>
</mat-card>
