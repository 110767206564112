import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export function existLanguageValidator(languages: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => languages.includes(control.value) ? { exist: { value: control.value } } : null;
}

@Component({
  selector: 'i18n-translation-create-form',
  templateUrl: './translation-create-form.component.html',
  styleUrls: ['./translation-create-form.component.scss']
})
export class TranslationCreateFormComponent implements OnInit {
  public createForm: UntypedFormGroup;

  constructor(public matDialogRef: MatDialogRef<TranslationCreateFormComponent>, @Inject(MAT_DIALOG_DATA) public data: { languages: string[] }) {}

  public get languageKey(): AbstractControl {
    return this.createForm.get('key');
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public initForm(): void {
    this.createForm = new UntypedFormGroup({
      key: new UntypedFormControl('', [Validators.required, existLanguageValidator(this.data.languages)])
    });
  }

  public save(): void {
    if (this.createForm.valid) {
      this.matDialogRef.close(this.createForm.value.key);
    }
  }
}
