import { fromAuth } from '@iot-platform/auth';
import { Entity, Filter, PlatformResponse, Product } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProductCatalogsDb from './product-catalogs-db.reducers';
import * as fromProductCatalogsUi from './product-catalogs-ui.reducers';
import * as fromProductsDb from './products-db.reducers';
import * as fromProductsUi from './products-ui.reducers';

export const productsFeatureKey = 'products';

export interface ProductsState {
  [fromProductCatalogsDb.productCatalogsDbFeatureKey]: fromProductCatalogsDb.State;
  [fromProductCatalogsUi.productCatalogsUiFeatureKey]: fromProductCatalogsUi.State;
  [fromProductsDb.productsDbFeatureKey]: fromProductsDb.State;
  [fromProductsUi.productsUiFeatureKey]: fromProductsUi.State;
}

export interface State {
  [productsFeatureKey]: ProductsState;
}

export function reducers(state: ProductsState | undefined, action: Action) {
  return combineReducers({
    [fromProductCatalogsDb.productCatalogsDbFeatureKey]: fromProductCatalogsDb.reducer,
    [fromProductCatalogsUi.productCatalogsUiFeatureKey]: fromProductCatalogsUi.reducer,
    [fromProductsDb.productsDbFeatureKey]: fromProductsDb.reducer,
    [fromProductsUi.productsUiFeatureKey]: fromProductsUi.reducer
  })(state, action);
}

export const selectProductsState = createFeatureSelector<ProductsState>(productsFeatureKey);

// Catalogs
export const selectCatalogsDbState = createSelector(selectProductsState, (state: ProductsState) => state ? state[fromProductCatalogsDb.productCatalogsDbFeatureKey] : null);
export const selectCatalogsUiState = createSelector(selectProductsState, (state: ProductsState) => state ? state[fromProductCatalogsUi.productCatalogsUiFeatureKey] : null);

// Products
export const selectProductsDbState = createSelector(selectProductsState, (state: ProductsState) => state ? state[fromProductsDb.productsDbFeatureKey] : null);
export const selectProductsUiState = createSelector(selectProductsState, (state: ProductsState) => state ? state[fromProductsUi.productsUiFeatureKey] : null);

// Auth
const getAuthState = createFeatureSelector(fromAuth.authFeatureKey);

// *************//
//   CATALOGS   //
// *************//

export const {
  selectIds: getCatalogsIds,
  selectEntities: getCatalogsEntities,
  selectAll: getAllCatalogs,
  selectTotal: getTotalCatalogs
} = fromProductCatalogsDb.adapter.getSelectors(selectCatalogsDbState);

export const getSelectedCatalogId = createSelector(selectCatalogsDbState, fromProductCatalogsDb.getSelectedCatalogId);
export const getSelectedCatalog = createSelector(selectCatalogsDbState, getSelectedCatalogId, (entities, selectedId) => selectedId && entities[selectedId]);
//
export const getCatalogsLoading = createSelector(selectCatalogsUiState, fromProductCatalogsUi.getCatalogsLoading);
export const getCatalogsLoaded = createSelector(selectCatalogsUiState, fromProductCatalogsUi.getCatalogsLoaded);

// *************//
//   PRODUCTS   //
// *************//

export const {
  selectIds: getProductsIds,
  selectEntities: getProductsEntities,
  selectAll: getAllProducts,
  selectTotal: getTotalProducts
} = fromProductsDb.adapter.getSelectors(selectProductsDbState);

export const getSelectedProductId = createSelector(selectProductsDbState, fromProductsDb.getSelectedProductId);
export const getSelectedProduct = createSelector(selectProductsDbState, getSelectedProductId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedProductWithCompleteCatalog = createSelector(selectCatalogsDbState, getSelectedProduct, (entities, selected) => ({
    ...selected,
    catalog: entities[selected.catalog.id]
  }));

export const getProductsPagination = createSelector(selectProductsDbState, fromProductsDb.getPagination);
export const getProductFilters = createSelector(selectProductsDbState, fromProductsDb.getProductFilters);

export const getProductsAsPlatformResponse = createSelector(
  getAllCatalogs,
  getAllProducts,
  getProductFilters,
  getProductsPagination,
  getAuthState,
  fromAuth.selectSelectedEntityForSession,
  (catalogs, products, filters, pagination, authState, sessionEntity) => {
    if (catalogs.length && products.length && sessionEntity) {
      let preFilteredProducts: PlatformResponse = {
        data: products.map((p) => ({ ...p, catalog: catalogs.find((cat) => cat.id === p.catalog.id) })),
        currentPage: pagination.currentPage,
        hasMore: pagination.hasMore,
        limit: pagination.limit,
        maxPage: pagination.maxPage,
        total: pagination.total,
        next: pagination.next,
        prev: pagination.prev
      };

      preFilteredProducts = {
        ...preFilteredProducts,
        data: preFilteredProducts.data
          .map((p) => ({ ...p, isEditable: isProductEditable(p, sessionEntity) }))
          .sort((a, b) => (a.identifier.toLowerCase() > b.identifier.toLowerCase() ? 1 : -1))
      };

      if (!filters.length) {
        return preFilteredProducts;
      }

      const data = preFilteredProducts.data.filter((p: Product) => {
        const entityFilters = filters.filter((f) => f.criteriaKey === 'entityId');
        const catNameFilters = filters.filter((f) => f.criteriaKey === 'productCatalogId');
        const productNameFilters = filters.filter((f) => f.criteriaKey === 'productName');
        const productIdFilters = filters.filter((f) => f.criteriaKey === 'productIdentifier');

        let isFiltered = false;
        if (productNameFilters && productNameFilters.length > 0) {
          productNameFilters.forEach((filter: Filter) => {
            if (p.name.toLowerCase().includes(filter.value.toLowerCase())) {
              isFiltered = true;
            }
          });
        }

        if (productIdFilters && productIdFilters.length > 0) {
          productIdFilters.forEach((filter: Filter) => {
            if (p.identifier.toLowerCase().includes(filter.value.toLowerCase())) {
              isFiltered = true;
            }
          });
        }

        if (catNameFilters && catNameFilters.length > 0) {
          catNameFilters.forEach((filter: Filter) => {
            if (p.catalog.id.toLowerCase() === filter.value.toLowerCase()) {
              isFiltered = true;
            }
          });
        }

        if (entityFilters && entityFilters.length > 0) {
          entityFilters.forEach((filter: Filter) => {
            p.catalog.entities.forEach((entity: Entity) => {
              if (entity.id.toLowerCase() === filter.value.toLowerCase()) {
                isFiltered = true;
              }
            });
          });
        }
        return isFiltered;
      });

      return {
        ...preFilteredProducts,
        data,
        currentPage: 0,
        hasMore: false,
        limit: 100,
        maxPage: 0,
        total: data.length
      };
    } else {
      return {
        data: [],
        currentPage: 0,
        hasMore: false,
        limit: 100,
        maxPage: 1,
        total: 0
      };
    }
  }
);

export const getFilteredProductTotal = createSelector(getProductsAsPlatformResponse, (response) => response.data.length);
//
export const getProductsLoading = createSelector(selectProductsUiState, fromProductsUi.getProductsLoading);
export const getProductsLoaded = createSelector(selectProductsUiState, fromProductsUi.getProductsLoaded);

function isProductEditable(product: Product, sessionEntity: Entity): boolean {
  if (product.catalog.entities) {
    return !!product.catalog.entities.filter((entity) => entity.level >= sessionEntity.level).length;
  }
  return false;
}
