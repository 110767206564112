<form [formGroup]="inputsForm" class="multiple-inputs-container" fxLayout='row wrap' fxLayoutAlign="start center"
      fxLayoutGap="30px">
    <div *ngFor="let input of inputs" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field class="input-field" color="accent">
            <mat-label>{{ input.placeholder | translate }}</mat-label>
            <input
                    (keyup.enter)="onInputValidation(input.criteriaKey)"
                    [formControlName]="input.criteriaKey"
                                        matInput
            />
        </mat-form-field>

        <button
                (click)="onInputValidation(input.criteriaKey)"
                [disabled]="!inputsForm.get(input.criteriaKey).value"
                [ngClass]="{ 'add-button-on': inputsForm.get(input.criteriaKey).value }"
                class="add-button-off"
                color="accent"
                mat-mini-fab
                type="button"
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>
</form>
