import { Connector, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { DeviceConnectorsDbActions, DevicesDbActions } from '../actions';

export const deviceConnectorsDbFeatureKey = 'deviceConnectorsDb';

export interface State extends EntityState<Connector> {
  selectedConnectorId: string;
  pagination: Pagination;
  error: any;
}

export const adapter: EntityAdapter<Connector> = createEntityAdapter<Connector>({
  selectId: (connector: Connector) => connector.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedConnectorId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 10 },
  error: null
});

export const deviceConnectorsDbReducer = createReducer(
  initialState,
  on(DeviceConnectorsDbActions.loadConnectorsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: 10,
        maxPage: response.maxPage,
        total: response.total,
        next: null,
        prev: null
      }
    })
  ),
  on(DeviceConnectorsDbActions.loadConnectorsFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(DeviceConnectorsDbActions.selectConnectorSuccess, (state: State, { selectedConnector }) => ({ ...state, selectedConnectorId: selectedConnector.id })),
  //
  on(DeviceConnectorsDbActions.addConnectorSuccess, (state: State, { addedConnector }) => adapter.addOne(addedConnector, state)),
  on(DeviceConnectorsDbActions.addConnectorFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(DeviceConnectorsDbActions.updateConnectorSuccess, (state: State, { updatedConnector }) =>
    adapter.updateOne(
      {
        id: updatedConnector.id,
        changes: updatedConnector
      },
      state
    )
  ),
  on(DeviceConnectorsDbActions.updateConnectorFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(DeviceConnectorsDbActions.deleteConnectorSuccess, (state: State, { deletedConnector }) => adapter.removeOne(deletedConnector.id, state)),
  on(DeviceConnectorsDbActions.deleteConnectorFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(DeviceConnectorsDbActions.sendConnectorCommandFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(DeviceConnectorsDbActions.refreshCommandStatusSuccess, (state: State, { commandStatuses }) => ({ ...state, deviceCommandStatuses: commandStatuses })),
  on(DeviceConnectorsDbActions.refreshCommandStatusFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(DevicesDbActions.loadDeviceById, (state: State) => ({ ...state, deviceCommandStatuses: null })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceConnectorsDbReducer(state, action);
}

export const getSelectedConnectorId = (state: State) => state.selectedConnectorId;
export const getPagination = (state: State) => state.pagination;
