<ul *ngIf="list.length" fxLayout="column">
  <li *ngFor="let item of list" fxLayoutGap="10px" fxLayoutAlign="start center">
    <mat-checkbox
      *ngIf="displayCheckbox"
      [checked]="checkboxStatusByItem[item.id]"
      (change)="onItemSelection(item, $event)"
    ></mat-checkbox>
    <span
      fxFlex
      class="list-item"
      [ngClass]="{ selected: item.id === selectedItem?.id }"
      (click)="selectItem.emit(item)"
    >{{ item.name }}</span
    >
  </li>
</ul>
