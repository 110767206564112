import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, IotPlatformPipesModule, NumberFormatPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { BasicInfoComponent, FollowedVariablesDetailsComponent } from '@iot-platform/shared/iot4bos/asset/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MapComponent } from './components';
import { MapPanelInfoPopupComponent } from './components/map-panel-info-popup/map-panel-info-popup.component';
import { MapPanelInfoToolboxComponent } from './components/map-panel-info-toolbox/map-panel-info-toolbox.component';
import { MapPanelInfoComponent } from './components/map-panel-info/map-panel-info.component';
import { MapPanelInfoService } from './components/map-panel-info/map-panel-info.service';
import { MapSpinnerComponent } from './components/map-spinner/map-spinner.component';
import { MapPopupService } from './services/map-popup.service';
import { MapEffects } from './state/effects/map.effects';
import * as fromMap from './state/reducers';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    StoreModule.forFeature(fromMap.featureKey, fromMap.reducers),
    EffectsModule.forFeature([MapEffects]),
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    MatOptionModule,
    MatSelectModule,
    SharedModule,
    BasicInfoComponent,
    FollowedVariablesDetailsComponent
  ],
  declarations: [MapComponent, MapSpinnerComponent, MapPanelInfoComponent, MapPanelInfoToolboxComponent, MapPanelInfoPopupComponent],
  exports: [MapComponent, MapSpinnerComponent],
  providers: [MapPanelInfoService, NumberFormatPipe, TranslateService, DateFormatPipe, MapPopupService]
})
export class IotPlatformMapsModule {}
