<mat-chip-option
        (click)="onChipClick($event, chip)"
        [ngStyle]="{ color: color, backgroundColor: bckgColor }"
        [selectable]="false"
        class="chip"
        disableRipple="true"
>
    <div fxLayout="row" fxLayoutAlign="start center"
         fxLayoutGap="4px">

        <mat-icon *ngIf="iconName" [svgIcon]="iconName"></mat-icon>
        <span class="chip-text">
    <span class="chip-category">{{ category | translate }}</span> : <span
                *ngIf="dateLabel">{{ dateLabel | dateFormat | translate }}</span
        ><span *ngIf="!dateLabel">{{ label | translate }}</span>
  </span>
        <mat-icon (click)="onChipButtonClick($event, chip)" *ngIf="chipButton.display"
                  class="chip-button">{{ chipButton.name }}</mat-icon>
    </div>
</mat-chip-option>
