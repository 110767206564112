import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Connector, Entity } from '@iot-platform/models/common';

import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { EntitiesService } from '../../../../../../../shared/src/lib/entities.service';
import { DeviceConnectorsService } from '../../../../../../../shared/src/lib/services/device-connectors.service';

@Component({
  selector: 'iot4bos-ui-device-connector-edit-form',
  templateUrl: './device-connector-edit-form.component.html',
  styleUrls: ['./device-connector-edit-form.component.scss']
})
export class DeviceConnectorEditFormComponent implements OnInit, OnDestroy {
  editConnectorFrom: UntypedFormGroup;

  entityList: Entity[];
  attachedEntitiesVisible: Entity[];
  attachedEntitiesNotVisible: Entity[];

  nameMaxLength = 50;
  entitiesMaxLength = 5;
  descriptionMaxLength = 300;

  param: { connectorName: string } = { connectorName: '' };

  displayDuplicateMessage$: Subject<boolean> = new Subject<boolean>();
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  initialName = '';
  connectorList: Connector[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private matDialogRef: MatDialogRef<DeviceConnectorEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { connector: Connector },
    private entitiesService: EntitiesService,
    private deviceConnectorsService: DeviceConnectorsService
  ) {}

  ngOnInit(): void {
    const keyboard$ = fromEvent(document, 'keyup');

    this.subscriptions.push(
      keyboard$
        .pipe(
          tap(() => this.isDisabled$.next(true)),
          debounceTime(500),
          tap(() => {
            if (this.name.value && this.connectorList.length) {
              this.checkConnectorNameUnicity();
            }
          })
        )
        .subscribe()
    );

    this.initForm();

    this.subscriptions.push(
      this.entitiesService.getHierarchicallySortedEntities().subscribe((entities) => {
        this.entityList = entities;
        this.setAttachedEntities();
        this.editConnectorFrom.controls['entities'].patchValue([...this.attachedEntitiesNotVisible, ...this.attachedEntitiesVisible]);
        this.entities.enable();
      }),
      this.deviceConnectorsService.getAll({ limit: 500, filters: [], page: 0 }).subscribe((response) => (this.connectorList = response.data))
    );
    this.param.connectorName = this.data.connector.name;
  }

  initForm(): void {
    this.editConnectorFrom = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.connector.name, [Validators.required, Validators.maxLength(this.nameMaxLength)]),
      description: new UntypedFormControl(this.data.connector.description, [Validators.maxLength(this.descriptionMaxLength)]),
      entities: new UntypedFormControl([], [Validators.required, Validators.maxLength(this.entitiesMaxLength)])
    });

    this.initialName = this.data.connector.name;
    this.entities.disable();
  }

  setAttachedEntities(): void {
    this.attachedEntitiesVisible = this.entityList.filter((entity) => this.data.connector.entities.find((e) => e.id === entity.id));
    this.attachedEntitiesNotVisible = this.data.connector.entities.filter((entity) => !this.attachedEntitiesVisible.find((e) => e.id === entity.id));
  }

  checkConnectorNameUnicity() {
    if (this.initialName === this.name.value.trim()) {
      this.isDisabled$.next(!this.editConnectorFrom.dirty);
      this.displayDuplicateMessage$.next(false);
    } else {
      const duplicateConnector = this.connectorList.find((connector) => connector.name === this.name.value.trim() && connector.id !== this.data.connector.id);

      if (duplicateConnector) {
        this.isDisabled$.next(true);
        this.displayDuplicateMessage$.next(true);
      } else {
        this.isDisabled$.next(!this.editConnectorFrom.dirty);
        this.displayDuplicateMessage$.next(false);
      }
    }
  }

  get name(): AbstractControl {
    return this.editConnectorFrom.get('name');
  }

  get description(): AbstractControl {
    return this.editConnectorFrom.get('description');
  }

  get entities(): AbstractControl {
    return this.editConnectorFrom.get('entities');
  }

  disableEntity(entity): boolean {
    if (this.entities.value && this.attachedEntitiesNotVisible && entity) {
      return (
        (!this.entities.value.find((e) => e.id === entity.id) && this.entities.value.length >= this.entitiesMaxLength) ||
        !!this.attachedEntitiesNotVisible.find((e) => e.id === entity.id)
      );
    }
  }

  getEntitiesFormatted() {
    return this.entities.value.map((entity) => entity.name).join(', ');
  }

  close(): void {
    this.matDialogRef.close();
  }

  save(): void {
    const connectorToUpdate: Connector = {
      id: this.data.connector.id,
      name: this.name.value,
      description: this.description.value,
      entities: [...this.entities.value]
      // families: [...this.data.connector.families],
      // requestConfiguration: {
      //   authentication: this.data.connector.requestConfiguration.authentication ? { ...this.data.connector.requestConfiguration.authentication } : null,
      //   commands: this.data.connector.requestConfiguration.commands ? [...this.data.connector.requestConfiguration.commands] : []
      // }
    };

    this.matDialogRef.close(connectorToUpdate);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
