import { NgModule } from '@angular/core';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { BulkManageTagDialogComponent } from './bulk-manage-tag-dialog.component';

@NgModule({
  declarations: [BulkManageTagDialogComponent],
  imports: [SharedModule, DetailPopupModule, IotPlatformPipesModule],
  exports: [BulkManageTagDialogComponent]
})
export class BulkManageTagDialogModule {}
