<div fxLayout="row wrap" fxLayoutGap="20px" class="dashboards-shell--container">
  @for (widget of dashboard()?.widgets; track widget.id) {
    @if (loading()) {
      <iot-platform-ui-card-loader backgroundClass="dashboard-widget"></iot-platform-ui-card-loader>
    } @else {
        <figure class="highcharts-figure" [ngClass]="{'visible': !loading(), 'invisible': loading()}">
          <highcharts-chart [Highcharts]="Highcharts" [options]="mainChartOptions[widget.id]"> </highcharts-chart>
        </figure>
    }
  } @empty {
    <div class="fw-600 fs-16 dashboards-shell--select-message">{{ 'DASHBOARDS.SELECT_A_DASHBOARD' | translate | uppercase }}</div>
  }
</div>
