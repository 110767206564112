import {
  Component,
  ComponentRef,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  Output,
  signal,
  Signal,
  ViewChild,
  ViewContainerRef,
  WritableSignal
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { FavoriteFiltersByConcept, FavoriteView, Filter, UserAccount, UserPreferences } from '@iot-platform/models/common';
import { cloneDeep, isEqual } from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { FavoriteFilterEngineDirective } from './directives/favorite-filter-engine.directive';
import { FilterEngineDirective } from './directives/filter-engine.directive';

import { FilterComponentFactory } from './filter-component-factory';
import { ManageFavoriteFiltersPopupComponent } from './manage-favorite-filters-popup/manage-favorite-filters-popup.component';
import { FilterConfiguration, FilterCriteriaConfiguration, FilterEngineMode } from './models';
import { FilterComponent } from './models/filter.component';
import { FilterEngineSettingsService } from './services/filter-engine-settings.service';
import { FilterEngineService } from './services/filter-engine.service';

@Component({
  selector: 'iot-platform-ui-filter-engine',
  templateUrl: './filter-engine.component.html',
  styleUrls: ['./filter-engine.component.scss']
})
export class FilterEngineComponent {
  FILTER_CRITERIA_BUTTON_DEFAULT_TITLE = 'FILTER_ENGINE.FILTER_CRITERIA_DEFAULT_TITLE';
  filterCriteriaButtonTitle: WritableSignal<string> = signal(this.FILTER_CRITERIA_BUTTON_DEFAULT_TITLE);
  masterViewName: WritableSignal<string | null> = signal(null);
  clearFilters: WritableSignal<boolean> = signal(false);
  favoriteView: WritableSignal<FavoriteView | null> = signal(null);
  filtersFromSource: WritableSignal<Filter[]> = signal([]);
  filtersFromSource$: Observable<Filter[]> = toObservable(this.filtersFromSource);
  workingFilters: WritableSignal<Filter[]> = signal([]);
  MAX_FILTERS = 20;
  currentFiltersNotHidden: Signal<Filter[]> = computed(() => this.workingFilters().filter((f) => !f.isHidden && f.criteriaKey !== 'includeSubEntities'));
  categories: Signal<FilterCriteriaConfiguration[]> = toSignal(
    toObservable(this.masterViewName).pipe(
      filter((name) => !!name),
      debounceTime(100),
      switchMap((mvName: string) => this.filterEngineService.getFilterCriteriaByConcept(mvName)),
      tap(() => this.clearFilterEngineViewContainer())
    )
  ) as Signal<FilterCriteriaConfiguration[]>;
  displayResetToFavoriteViewFiltersButton: Signal<boolean> = computed(() => {
    if (!!this.workingFilters() && !!this.favoriteView()) {
      return !isEqual(this.workingFilters().sort(), this.favoriteView()?.filters?.sort());
    } else {
      return false;
    }
  });
  // Favorite Filters
  FilterEngineMode = FilterEngineMode;
  modeControl = new FormControl(FilterEngineMode.FAVORITE);
  currentMode: Signal<FilterEngineMode> = toSignal(this.modeControl.valueChanges) as Signal<FilterEngineMode>;
  userPreferences: Signal<UserPreferences> = toSignal(this.filterEngineSettingsService.userPreferences$) as Signal<UserPreferences>;
  user: Signal<UserAccount> = toSignal(this.filterEngineSettingsService.account$) as Signal<UserAccount>;
  @Input() expanded = false;

  //
  @Input() readonly = false;
  @Input() displayActionButtons = false;
  @Input() displayManageFavoriteFilters = true;
  @Input() withFavoriteFilters = false;
  @Output() applyFilters: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
  @ViewChild(FilterEngineDirective, { static: false }) iotPlatformUiFilterEngine!: FilterEngineDirective;
  @ViewChild(FavoriteFilterEngineDirective, { static: false }) iotPlatformUiFavoriteFilterEngine!: FavoriteFilterEngineDirective;
  private injector = inject(Injector);

  constructor(
    private readonly filterEngineService: FilterEngineService,
    private readonly filterEngineSettingsService: FilterEngineSettingsService,
    private readonly filterComponentFactory: FilterComponentFactory,
    private readonly dialog: MatDialog
  ) {
    this.initFavoriteModeEffect();
    this.initFavoriteViewEffect();
    this.initFilterEffect();
    this.initClearFiltersEffect();
  }

  @Input() set masterView(value: string) {
    this.masterViewName.set(value);
  }

  @Input() set clearAppliedFilters(value: boolean) {
    this.clearFilters.set(value);
  }

  @Input() set currentFavoriteView(value: FavoriteView | null) {
    this.favoriteView.set(value);
  }

  @Input() set currentFilters(value: Filter[]) {
    this.filtersFromSource.set(value);
  }

  static clearViewContainerRef(viewContainerRef: ViewContainerRef): void {
    viewContainerRef.clear();
  }

  static getFilterByUserPreferences(filterCriteria: FilterCriteriaConfiguration[], favoriteFilters: FavoriteFiltersByConcept): FilterConfiguration[] {
    const flatFilters = Object.values(favoriteFilters).flat();
    flatFilters.sort(SortUtil.sortByOrder);
    const flatFilterCriteria: FilterConfiguration[] = filterCriteria.map((fc) => fc.options).flat();

    return flatFilters.reduce((acc: FilterConfiguration[], f: { name: string; order: number }) => {
      const matchingFilterCriteria = flatFilterCriteria.find((fc) => fc.key === f.name);
      if (matchingFilterCriteria) {
        acc.push(matchingFilterCriteria);
      }
      return acc;
    }, []);
  }

  resetFilterCriteriaButtonTitle(): void {
    this.filterCriteriaButtonTitle.set(this.FILTER_CRITERIA_BUTTON_DEFAULT_TITLE);
  }

  initViewContainerRef(): ViewContainerRef {
    if (this.withFavoriteFilters && this.modeControl.value === FilterEngineMode.FAVORITE) {
      return this.iotPlatformUiFavoriteFilterEngine.viewContainerRef;
    } else {
      const viewContainerRef = this.iotPlatformUiFilterEngine.viewContainerRef;
      viewContainerRef.clear();
      return viewContainerRef;
    }
  }

  addField(criteria: any) {
    if (this.modeControl.value === FilterEngineMode.CLASSIC) {
      this.filterCriteriaButtonTitle.set(criteria.fullLabel);
    }
    const componentRef: ComponentRef<FilterComponent> | null = this.filterComponentFactory.createComponent(criteria.element, this.initViewContainerRef());

    if (componentRef) {
      componentRef.instance.data = criteria.options;
      componentRef.instance.currentFiltersSize = this.currentFiltersNotHidden().length;
      componentRef.instance.maxFilters = this.MAX_FILTERS;

      if (criteria.options.multiSelect) {
        if (criteria.key === 'eventType' && criteria.options.criteriaKey === 'algo') {
          componentRef.instance.currentFilters$ = this.filtersFromSource$.pipe(map((filters) => filters.filter((f) => f.criteriaKey === 'algo')));
        } else if (criteria.key === 'eventStatus' && criteria.options.criteriaKey === 'isActive') {
          componentRef.instance.currentFilters$ = this.filtersFromSource$.pipe(map((filters) => filters.filter((f) => f.criteriaKey === 'isActive')));
        } else if (criteria.key === 'timezone' && criteria.options.criteriaKey === 'teamPlanningTimezoneDetailsName') {
          componentRef.instance.currentFilters$ = this.filtersFromSource$.pipe(
            map((filters) => filters.filter((f) => f.criteriaKey === 'teamPlanningTimezoneDetailsName'))
          );
        } else {
          componentRef.instance.currentFilters$ = this.filtersFromSource$.pipe(
            map((filters) => filters.filter((f) => f.criteriaKey === criteria.options.criteriaKey))
          );
        }
      }

      componentRef.instance.dispatchFilterValue.subscribe((componentOutput: Filter | Filter[]) => {
        if (componentOutput) {
          if (componentOutput instanceof Array) {
            componentOutput.forEach((f: Filter) => {
              this.checkFilterDuplicate(f, criteria.options.multiSelect ?? false);
            });
          } else {
            this.checkFilterDuplicate(componentOutput, criteria.options.multiSelect ?? false);
          }
          this.onApplyFilters();
        }
      });
    }
  }

  checkFilterDuplicate(possibleDuplicateFilter: Filter, multiSelect = false): void {
    if (this.currentFiltersNotHidden().length < this.MAX_FILTERS) {
      if (!this.workingFilters().find((f) => f.criteriaKey === possibleDuplicateFilter.criteriaKey && f.value === possibleDuplicateFilter.value)) {
        this.workingFilters.update((value: Filter[]) => {
          value.push(possibleDuplicateFilter);
          return value;
        });
      } else {
        if (multiSelect) {
          const newFilters = this.workingFilters();
          if (possibleDuplicateFilter.criteriaKey !== 'includeSubEntities') {
            const index = newFilters.findIndex(
              (f: Filter) => f.criteriaKey === possibleDuplicateFilter.criteriaKey && f.value === possibleDuplicateFilter.value
            );
            newFilters.splice(index, 1);
          }
          if (possibleDuplicateFilter.criteriaKey === 'includeSubEntities' && !this.workingFilters().find((f) => f.criteriaKey === 'entityId')) {
            const indexHidden = newFilters.findIndex((f: Filter) => f.criteriaKey === 'includeSubEntities');
            if (indexHidden >= 0) {
              newFilters.splice(indexHidden, 1);
            }
          }
          this.workingFilters.set(newFilters);

          if (!this.workingFilters().length) {
            this.clearFilterEngineViewContainer();
          }
        }
      }
    }
  }

  removeOneFilter(filterToRemove: Filter): void {
    const newFilters: Filter[] = this.workingFilters();
    const index: number = newFilters.findIndex((currentFilter: Filter) => currentFilter === filterToRemove);
    newFilters.splice(index, 1);

    if (filterToRemove.criteriaKey === 'entityId' && !newFilters.find((f) => f.criteriaKey === 'entityId')) {
      const indexHidden: number = newFilters.findIndex((currentFilter: Filter) => currentFilter.criteriaKey === 'includeSubEntities');
      if (indexHidden >= 0) {
        newFilters.splice(indexHidden, 1);
      }
    }
    this.workingFilters.set(newFilters);

    this.onApplyFilters();

    if (!this.workingFilters().length) {
      this.clearFilterEngineViewContainer();
    }
  }

  clearFilterEngineViewContainer(): void {
    // We don't want to reset the favorite FE each time we reset the filters. Only the classic FE
    if (this.iotPlatformUiFilterEngine) {
      FilterEngineComponent.clearViewContainerRef(this.iotPlatformUiFilterEngine.viewContainerRef);
    }
    this.resetFilterCriteriaButtonTitle();
  }

  onApplyFilters() {
    this.applyFilters.emit(this.workingFilters());
  }

  onClearAllFilters(): void {
    this.workingFilters.set([]);
    this.onApplyFilters();
    this.clearFilterEngineViewContainer();
  }

  onResetFavoriteView(): void {
    this.workingFilters.set([...(this.favoriteView()?.filters ?? [])]);
    this.onApplyFilters();
    this.clearFilterEngineViewContainer();
  }

  openManageFavoriteFilters(): void {
    this.dialog
      .open(ManageFavoriteFiltersPopupComponent, {
        data: {
          masterView: this.masterViewName(),
          filterCriteria: this.categories(),
          userPreferences: this.userPreferences()
        },
        disableClose: true,
        maxWidth: '1100px',
        minWidth: '600px'
      })
      .afterClosed()
      .subscribe((updatedFavoriteFilters) => {
        if (updatedFavoriteFilters) {
          const preferencesToUpdate: UserPreferences = {
            ...this.userPreferences(),
            favoriteFilters: {
              ...this.userPreferences().favoriteFilters,
              [this.masterViewName() as string]: { ...updatedFavoriteFilters }
            }
          };
          const userToUpdate: UserAccount = { ...cloneDeep(this.user()), preferences: preferencesToUpdate };
          this.filterEngineSettingsService.saveUserPreferences(userToUpdate, preferencesToUpdate);
        }
      });
  }

  private initFavoriteModeEffect(): void {
    effect(
      () => {
        const currentMode = this.currentMode() ?? FilterEngineMode.FAVORITE;
        const pref: UserPreferences = this.userPreferences();
        const categories: FilterCriteriaConfiguration[] = this.categories();
        const masterViewName = this.masterViewName();
        let favoriteFilters: FilterConfiguration[] = [];

        if (categories) {
          if (!!masterViewName && !!pref?.favoriteFilters && !!pref.favoriteFilters[masterViewName] && this.displayManageFavoriteFilters) {
            favoriteFilters = FilterEngineComponent.getFilterByUserPreferences(categories, pref.favoriteFilters[masterViewName]);
          } else {
            categories.some((category: FilterCriteriaConfiguration) =>
              category.options.some((option: FilterConfiguration) => {
                if (option.defaultFavorite) {
                  favoriteFilters.push(option);
                }
              })
            );
            favoriteFilters.sort(SortUtil.sortByKey);
          }
        }

        if (favoriteFilters.length && currentMode === FilterEngineMode.FAVORITE && this.iotPlatformUiFavoriteFilterEngine) {
          FilterEngineComponent.clearViewContainerRef(this.iotPlatformUiFavoriteFilterEngine?.viewContainerRef);
          favoriteFilters.forEach((criteria) => this.addField(criteria));
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  private initFavoriteViewEffect(): void {
    effect(
      () => {
        const fv: FavoriteView | null = this.favoriteView();
        if (fv) {
          this.clearFilterEngineViewContainer();
          const fvFilters: Filter[] = fv?.filters ?? [];
          this.workingFilters.set([...fvFilters]);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private initFilterEffect(): void {
    effect(
      () => {
        const filters: Filter[] = this.filtersFromSource() ?? [];
        if (filters) {
          this.workingFilters.set([...filters]);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private initClearFiltersEffect(): void {
    effect(
      () => {
        const isClearFiltersTriggered: boolean = this.clearFilters();
        if (isClearFiltersTriggered) {
          this.clearFilterEngineViewContainer();
        }
      },
      { allowSignalWrites: true }
    );
  }
}
