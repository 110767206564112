<form [formGroup]="categoryForm">
  <mat-form-field [ngClass]="{ warning: warning }" class="variable-unit" color="accent">
    <mat-label>{{ 'ASSETS.VARIABLE_FORM.UNIT' | translate }}</mat-label>
    <input (keyup)="autocompleteOnEnter($event)" [matAutocomplete]="autoGroup" formControlName="unit" matInput required type="text" />
    <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-optgroup
        *ngFor="let category of unitsByCategoryFiltered$ | async"
        [label]="'ASSETS.VARIABLE_FORM.UNIT_LIST_CATEGORIES.' + category.name | translate"
      >
        <mat-option *ngFor="let unit of category.units" [value]="unit">
          {{ unit }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>
