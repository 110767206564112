<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ title$ | async }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20">
        <form [formGroup]="businessProfileFrom" fxLayout="column">
            <iot-platform-ui-async-autocomplete
                    (reset)="resetEntity()"
                    (search)="resetEntity()"
                    (selectionChanged)="onEntitySelection($event)"
                    [data]="sortedEntities"
                    [disabled]="!!data?.organization || !!data?.businessProfile"
                    [displayKey]="'label'"
                    [displaySearchIcon]="false"
                    [filterKey]="'label'"
                    [initialItem]="initialEntity"
                    [minLength]="1"
                    [placeholder]="'IOT_DICTIONARY.ENTITY'"
                    [required]="true"
                    [showSpinner]="entitiesLoading"
                    [standaloneMode]="true"
            >
            </iot-platform-ui-async-autocomplete>
            <mat-form-field class="" color="accent">
                <mat-label>{{ 'ADMIN.DIALOG.ADD_BP.PLACEHOLDER_NAME' | translate }}</mat-label>
                <input [placeholder]="" formControlName="nameControl" matInput maxlength="50" required/>
                <mat-hint *ngIf="displayDuplicateMessage$ | async" align="end">
                    <span class="dialog-card-input-error">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</span>
                </mat-hint>
            </mat-form-field>

            <iot-platform-ui-timezone-autocomplete
                    (reset)="resetTimezone()"
                    (selectionChange)="onTimezoneSelection($event)"
                    [clearOnSelect]="false"
                    [displayOffset]="true"
                    [initialItem]="initialTZ"
                    [required]="true"
                    fxFlex="25%"
            >
            </iot-platform-ui-timezone-autocomplete>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-label
                        id="chart-period-radio-group-label">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_DEFAULT_PERIOD' | translate }}</mat-label>
                <mat-radio-group
                        [value]="chartDefaultPeriod"
                        aria-labelledby="chart-period-radio-group-label"
                        class="example-radio-group"
                        color="accent"
                        formControlName="chartPeriodControl"
                        fxLayout="row"
                        fxLayoutGap="10px"
                >
                    <mat-radio-button
                            [value]="1">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_1_DAY' | translate }}</mat-radio-button>
                    <mat-radio-button
                            [value]="7">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_7_DAY' | translate }}</mat-radio-button>
                    <mat-radio-button
                            [value]="30">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_30_DAY' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="slider-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-label
                        id="stock-visibility-radio-group-label">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.STOCK_SITES' | translate }}</mat-label>
                <mat-slide-toggle color="accent" formControlName="stockVisibilityControl">
                    {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.VISIBLE' | translate }}
                </mat-slide-toggle>
            </div>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular"
                mat-button>{{ 'ADMIN.DIALOG.ADD_BP.CANCEL' | translate }}</button>
        <button
                (click)="save()"
                [disabled]="!businessProfileFrom.valid || (nameControl.touched && isDisabled$ | async)"
                class="button-regular"
                color="accent"
                mat-raised-button
        >
            {{ 'ADMIN.DIALOG.ADD_BP.SAVE' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
