<mat-card-content class="p-20 mve-detail-popup-container" fxLayout="column" fxLayoutGap="20px">
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span [style.background]="data?.favoriteView.color" class="color"></span>
        <div fxFlex fxLayout="column" fxLayoutGap="6px">
            <span class="mve-detail-popup-title fw-600">{{ data?.favoriteView.name }}</span>
            <iot-platform-ui-detail-popup-display-property
                    property="{{ 'IOT_DICTIONARY.MASTER_VIEW' | translate }}:"
                    value="{{ 'IOT_DICTIONARY.' + data?.favoriteView.masterView.toLowerCase() | translate }}"
            ></iot-platform-ui-detail-popup-display-property>
        </div>
        <div fxFlex="40%" fxFlexAlign="start" fxLayout="column" fxLayoutGap="6px">
            <iot-platform-ui-detail-popup-display-property
                    margin="6px"
                    property="{{ 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION' | translate }}:"
                    value="{{ data?.favoriteView?.description }}"
            ></iot-platform-ui-detail-popup-display-property>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="10px">
        <iot-platform-ui-detail-popup-section-header
                headerTitle="{{ 'IOT_DICTIONARY.PARAMETERS' | translate }}"></iot-platform-ui-detail-popup-section-header>
        <div fxLayout="row" fxLayoutGap="30px">
            <mat-checkbox [checked]="data?.favoriteView.pinned" [disabled]="true" color="accent">
                {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.FV_HOME' | translate }}
            </mat-checkbox>
            <mat-checkbox [checked]="data?.favoriteView.shared" [disabled]="true" color="accent">
                {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.FV_SHARED' | translate }}
            </mat-checkbox>
        </div>
    </section>

    <section fxLayout="column" fxLayoutGap="10px">
        <iot-platform-ui-detail-popup-section-header
                headerTitle="{{ 'IOT_DICTIONARY.FILTERS' | translate }}"></iot-platform-ui-detail-popup-section-header>
        <mat-chip-set *ngIf="data.favoriteView.filters" fxLayout="row wrap">
            <iot-platform-ui-chip *ngFor="let filter of data.favoriteView.filters" [chipButton]="{ display: false }"
                                  [chip]="filter"></iot-platform-ui-chip>
        </mat-chip-set>
    </section>

    <section fxLayout="column" fxLayoutGap="10px">
        <iot-platform-ui-detail-popup-section-header
                headerTitle="{{ 'IOT_DICTIONARY.GRID' | translate }}"></iot-platform-ui-detail-popup-section-header>
        <iot-platform-ui-detail-popup-display-property
                property="{{ 'IOT_DICTIONARY.NAME' | translate }}:"
                value="{{ gridName | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
    </section>

    <iot-platform-ui-detail-popup-metadata-footer
            [data]="data.favoriteView"></iot-platform-ui-detail-popup-metadata-footer>
</mat-card-content>
