import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DateFormatModule, LinkifyPipeModule } from '@iot-platform/pipes';

@Component({
  selector: 'iot-platform-ui-chat-conversation-loader',
  standalone: true,
  imports: [CommonModule, DateFormatModule, LinkifyPipeModule, MatButtonModule, MatIconModule, FlexLayoutModule],
  templateUrl: './chat-conversation-loader.component.html',
  styleUrl: './chat-conversation-loader.component.scss'
})
export class ChatConversationLoaderComponent {}
