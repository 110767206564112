import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot-platform-ui-status-button',
  standalone: true,
  imports: [MatButtonModule, NgClass, FlexLayoutModule, MatTooltipModule, TranslateModule, NgStyle],
  templateUrl: './status-button.component.html',
  styleUrl: './status-button.component.scss'
})
export class StatusButtonComponent {
  @Input() cssClassName = '';
  @Input() style: any;
  @Input() color = 'primary';
  @Input() tooltip = '';
  @Input() disabled = false;
  @Input() preventMainAction = false;
  @Input() actionLabel!: string;
  @Input() actionSubLabel!: string;
  @Input() displayNestedAction = false;
  @Input() nestedActionLabel!: string;
  @Input() onDispatchEvent!: () => void; // Used for dynamic injection inputs
  @Output() dispatchEvent: EventEmitter<void> = new EventEmitter<void>();
}
