import { Injectable, Signal } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { CommonApiRequest, FavoriteView, Filter, Product, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Asset, AssetVariable, CommandType, Log } from '@iot-platform/models/i4b';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { AssetsCommentsActions, AssetsUiActions } from '../actions';
import * as fromAssets from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class AssetsFacade {
  grid$ = this.store.select(fromGrids.getDefaultAssetsGrid);
  grids$ = this.store.select(fromGrids.getAssetsGrids);

  variable$ = this.store.select(fromAssets.getVariable);
  site$ = this.store.select(fromAssets.getSite);
  siteLoaded$ = this.store.select(fromAssets.getSiteLoaded);
  tags$ = this.store.select(fromAssets.getTags);
  tagsLoaded$ = this.store.select(fromAssets.getTagsLoaded);

  favoriteViews$ = this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssets);
  currentFavoriteView$ = this.store.select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewAssets);
  currentFilters$ = this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets);
  loadingFavoriteViews$ = this.store.select(fromFavoriteViews.getLoading);
  assetFavoriteViewsConfiguration$ = this.store.select(fromFavoriteViews.selectAssetFavoriteViewsConfiguration);

  assetComments: Signal<Log[]> = this.store.selectSignal(fromAssets.selectAllAssetComments);
  assetCommentsLoading: Signal<boolean> = this.store.selectSignal(fromAssets.selectAssetCommentsLoading);

  constructor(private readonly store: Store) {}

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'assets' }));
  }

  addAsset(asset: Asset) {
    this.store.dispatch(AssetsUiActions.addAsset({ assetToAdd: asset }));
  }

  addAssetByTemplateId(assetTemplateId: string, siteId: string) {
    this.store.dispatch(AssetsUiActions.addAssetByTemplateId({ assetTemplateId, siteId }));
  }

  addAssetVariable(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.addAssetVariable({ assetVariableToAdd: assetVariable }));
  }

  loadAssetById(assetId: string) {
    this.store.dispatch(AssetsUiActions.loadAssetById({ assetId }));
  }

  loadAssets(request: CommonApiRequest) {
    this.store.dispatch(
      GridsDbActions.loadGridData({
        request: { concept: 'assets', ...request }
      })
    );
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(AssetsUiActions.loadSiteById({ siteId }));
  }

  loadTagsByAssetId(assetId: string) {
    this.store.dispatch(AssetsUiActions.loadTagsByAssetId({ assetId }));
  }

  updateTagsByAssetId(assetId: string, tags: TagCategory[]) {
    this.store.dispatch(AssetsUiActions.updateTagsByAssetId({ assetId, tags }));
  }

  loadVariablesByAssetId(assetId: string) {
    this.store.dispatch(AssetsUiActions.loadVariablesByAssetId({ assetId }));
  }

  updateAsset(asset: Asset) {
    this.store.dispatch(AssetsUiActions.updateAsset({ assetToUpdate: asset }));
  }

  updateAssetFromMV(asset: Asset, request: CommonApiRequest) {
    this.store.dispatch(AssetsUiActions.updateAssetFromMV({ assetToUpdate: asset, request }));
  }

  deleteAsset(asset: Asset) {
    this.store.dispatch(AssetsUiActions.deleteAsset({ assetToDelete: asset }));
  }

  updateAssetVariable(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.updateAssetVariable({ assetVariableToUpdate: assetVariable }));
  }

  updateAssetVariableThresholds(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.updateAssetVariableThresholds({ assetVariableToUpdate: assetVariable }));
  }

  updateFollowedAssetVariable(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.updateFollowedAssetVariable({ assetVariableToUpdate: assetVariable }));
  }

  deleteAssetVariables(assetVariables: AssetVariable[]) {
    this.store.dispatch(AssetsUiActions.deleteAssetVariables({ assetVariablesToDelete: assetVariables }));
  }

  assignProductToAsset(product: Product, asset: Asset) {
    this.store.dispatch(AssetsUiActions.assignProductToAsset({ product, asset }));
  }

  removeProductFromAsset(product: Product, asset: Asset) {
    this.store.dispatch(AssetsUiActions.removeProductFromAsset({ product, asset }));
  }

  selectAsset(selectedAsset: Asset) {
    this.store.dispatch(AssetsUiActions.selectAsset({ selectedAsset }));
  }

  saveMVSettings(name: string, values: any) {
    this.store.dispatch(AssetsUiActions.saveMVSettings({ name, values }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'assets', favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'assets', filters }));
  }

  sendCommand(asset: Asset, command: { command: CommandType }) {
    this.store.dispatch(AssetsUiActions.sendCommand({ asset, command }));
  }

  resetAssetVariablesLastValues(assetVariables: AssetVariable[]) {
    this.store.dispatch(AssetsUiActions.resetAssetVariablesLastValues({ assetVariables }));
  }

  bulkSendCommand(assetsIds: string[], command: { command: CommandType }): void {
    this.store.dispatch(AssetsUiActions.bulkSendCommand({ assetsIds, command }));
  }

  loadComments(asset: Asset): void {
    this.store.dispatch(AssetsCommentsActions.loadComments({ asset }));
  }

  addComment(assetId: string, comment: string): void {
    this.store.dispatch(AssetsCommentsActions.addComment({ assetId, comment }));
  }

  editComment(assetId: string, comment: Log): void {
    this.store.dispatch(AssetsCommentsActions.editComment({ assetId, comment }));
  }

  deleteComment(assetId: string, commentId: string): void {
    this.store.dispatch(AssetsCommentsActions.deleteComment({ assetId, commentId }));
  }
}
