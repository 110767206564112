import { Component, effect, inject, Input, OnDestroy, signal, WritableSignal } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { Dashboard, DashboardWidget } from '@iot-platform/models/dashboards';
import * as Highcharts from 'highcharts';
import HC_Drilldown from 'highcharts/modules/drilldown';
import HC_export_data from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_NoData from 'highcharts/modules/no-data-to-display';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardsHelper } from '../../helpers/dashboards.helper';
import { DashboardsService } from '../../services/dashboards.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_NoData(Highcharts);
HC_Drilldown(Highcharts);

@Component({
  selector: 'iot-platform-dashboards-dashboards-shell',
  templateUrl: './dashboards-shell.component.html',
  styleUrl: './dashboards-shell.component.scss'
})
export class DashboardsShellComponent implements OnDestroy {
  @Input() set currentFilters(value: Filter[]) {
    this.filters.set(value);
  }
  @Input() set currentDashboard(value: Dashboard) {
    this.dashboard.set(value);
  }

  filters: WritableSignal<Filter[]> = signal([]);
  dashboard: WritableSignal<Dashboard> = signal(undefined);
  Highcharts: typeof Highcharts = Highcharts;
  mainChartOptions: { [widgetId: string]: Highcharts.Options } = {};
  loading: WritableSignal<boolean> = signal(true, {});

  dashboardsService = inject(DashboardsService);
  helper = inject(DashboardsHelper);
  destroy$ = new Subject<void>();

  constructor() {
    effect(
      () => {
        if (this.dashboard()) {
          this.loading.set(true);
          this.dashboardsService
            .getDashboardData(this.dashboard(), this.filters())
            .pipe(takeUntil(this.destroy$))
            .subscribe((widgetData: DashboardWidget[]) => {
              this.loading.set(false);
              widgetData.forEach((widget) => (this.mainChartOptions[widget.id] = this.helper.getHighchartsOptions(widget)));
            });
        } else {
          this.loading.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
