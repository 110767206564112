import { Injectable } from '@angular/core';
import { AbstractProductExportsService } from '@iot-platform/iot-platform-ui';
import { fromProducts } from '@iot-platform/iot4bos-backoffice-ui';
import { PlatformResponse } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductExportsService extends AbstractProductExportsService {
  constructor(private readonly store: Store) {
    super();
  }

  getProducts$(): Observable<PlatformResponse> {
    return this.store.select(fromProducts.getProductsAsPlatformResponse);
  }
}
