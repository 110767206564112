import { Component, EventEmitter, Input, OnInit, Output, WritableSignal, signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { AssetVariableThreshold, AssetVariableThresholds } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { VariableThresholdsFormComponent } from '../variable-thresholds-form/variable-thresholds-form.component';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    VariableThresholdsFormComponent
  ],
  selector: 'shared-iot4bos-asset-ui-variable-thresholds-configuration',
  templateUrl: './variable-thresholds-configuration.component.html'
})
export class VariableThresholdsConfigurationComponent implements OnInit {
  @Input() thresholds: AssetVariableThresholds | null;
  @Input() readonly = false;

  @Output() thresholdsChange: EventEmitter<AssetVariableThresholds | null> = new EventEmitter<AssetVariableThresholds>();

  form = new UntypedFormGroup({});
  initialThresholds: AssetVariableThreshold[] = [];
  currentThresholds: AssetVariableThreshold[] | null = [];
  activeThresholds: number[] = [];
  operatorList: string[] = ['<', '=', '>'];
  thresholdsMax = 5;

  addThresholdCheckbox: WritableSignal<boolean> = signal(false);

  get thresholdOperatorControl(): AbstractControl {
    return this.form.get('thresholdOperator');
  }

  get thresholdValuesControl(): AbstractControl {
    return this.form.get('thresholdValues');
  }

  ngOnInit(): void {
    this.initAddThresholdCheckbox();
    this.initThresholds();
    this.initForm();
  }

  onAddThresholdCheckboxChange(checked: boolean): void {
    this.addThresholdCheckbox.set(checked);
    this.emitThresholdsChangeEvent();
  }

  onOperatorChange(): void {
    this.emitThresholdsChangeEvent();
  }

  onAddThreshold(position: number): void {
    this.activeThresholds.push(position);
    this.initialThresholds.push(null);
    this.currentThresholds.push(null);
    this.emitThresholdsChangeEvent();
  }

  onDeleteThreshold(position: number): void {
    this.initialThresholds.splice(position - 1, 1);
    this.currentThresholds?.splice(position - 1, 1);
    this.activeThresholds.splice(position - 1, 1);
    this.emitThresholdsChangeEvent();
  }

  onChangeThreshold(position: number, threshold?: AssetVariableThreshold): void {
    this.currentThresholds?.splice(position - 1, 1, threshold);
    this.thresholdValuesControl.setValue(this.currentThresholds);
    this.emitThresholdsChangeEvent();
  }

  validThresholdsConfig(): boolean {
    if (this.currentThresholds?.length === 0) {
      return false;
    }

    return this.currentThresholds.findIndex((threshold) => !threshold) === -1;
  }

  private initAddThresholdCheckbox(): void {
    this.addThresholdCheckbox.set(!!this.thresholds?.operator);
  }

  private initThresholds(): void {
    this.initialThresholds = this.thresholds?.values
      ? cloneDeep(this.thresholds?.values)
          ?.sort(SortUtil.sortByProperty('position'))
          .filter((threshold) => threshold.value !== null && threshold.value !== undefined)
      : [];

    this.initialThresholds.forEach((_, index) => this.activeThresholds.push(index + 1));
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      thresholdOperator: new UntypedFormControl({ value: this.thresholds?.operator ?? this.operatorList[0], disabled: this.readonly }),
      thresholdValues: new UntypedFormControl(this.initialThresholds)
    });
  }

  private emitThresholdsChangeEvent(): void {
    if (!this.addThresholdCheckbox()) {
      this.thresholdsChange.emit({ operator: null, values: [] });
    } else if (this.validThresholdsConfig()) {
      this.thresholdsChange.emit({ operator: this.thresholdOperatorControl.value, values: this.currentThresholds ?? [] });
    } else {
      this.thresholdsChange.emit(null);
    }
  }
}
