<div class="full-width container" fxLayout="column" fxLayoutAlign="start stretch">

    <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" class="association-toolbars"
         fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span
              (click)="onUpdateUserPreferences('assets')"
              [matTooltip]="(displayBy === 'assets' ? 'SITES.ASSOCIATIONS.DISPLAYED_BY_ASSETS' : 'SITES.ASSOCIATIONS.DISPLAY_BY_ASSETS') | translate"
              [ngClass]="{ 'display-by-toggle': displayBy !== 'assets', 'display-by-active': displayBy === 'assets' }"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
      >
        <mat-icon [color]="displayBy === 'assets' ? 'accent' : '#b0b0b0'" svgIcon="asset"></mat-icon>
        <span class="association-toolbars-title">{{ 'SITES.ASSOCIATIONS.ASSETS' | translate }}</span>
      </span>
            <hr class="regular-1px-line" fxFlex/>
            <button
                    (click)="dispatchAction.emit({ action: 'add-asset' })"
                    *ngIf="canCreateAsset && !navigationMode"
                    [matTooltip]="'SITES.ASSOCIATIONS.ADD_ASSET' | translate"
                    class="light-button"
                    mat-mini-fab
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="below"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span
              (click)="onUpdateUserPreferences('devices')"
              [matTooltip]="(displayBy === 'devices' ? 'SITES.ASSOCIATIONS.DISPLAYED_BY_DEVICES' : 'SITES.ASSOCIATIONS.DISPLAY_BY_DEVICES') | translate"
              [ngClass]="{ 'display-by-toggle': displayBy !== 'devices', 'display-by-active': displayBy === 'devices' }"
              class="display-by-toggle"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
      >
        <!--        <iot-platform-ui-icon name="device" [color]="displayBy === 'devices' ? '#4F79B7' : '#b0b0b0'" class="toolbar-device-icon"></iot-platform-ui-icon>-->
        <mat-icon [color]="displayBy === 'devices' ? 'accent' : '#b0b0b0'" svgIcon="device"></mat-icon>
        <span class="association-toolbars-title">{{ 'SITES.ASSOCIATIONS.DEVICES' | translate }}</span>
      </span>
            <hr class="regular-1px-line" fxFlex/>
            <button
                    (click)="dispatchAction.emit({ action: 'add-device' })"
                    *ngIf="canCreateDevice && !navigationMode"
                    [matTooltip]="'SITES.ASSOCIATIONS.ADD_DEVICE' | translate"
                    class="light-button"
                    mat-mini-fab
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="below"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <section *ngIf="!associationsLoaded">
        <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" fxLayoutAlign="space-between center"
             fxLayoutGap="30px">
            <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex></iot-platform-ui-card-loader>
            <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex></iot-platform-ui-card-loader>
        </div>
    </section>
    <section
            [ngClass]="{ 'association-section': navigationMode && associationsLoaded, invisible: !associationsLoaded, visible: associationsLoaded }">
        <div
                *ngIf="!navigationMode && ((!hasAssets && canCreateAsset) || (!hasDevices && canCreateDevice))"
                [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'"
                class="association-section"
                fxLayoutAlign="space-between center"
                fxLayoutGap="30px"
        >
            <div fxFlex>
                <iot-platform-ui-default-card
                        (click)="dispatchAction.emit({ action: 'add-asset' })"
                        *ngIf="!hasAssets && canCreateAsset"
                        [backgroundClass]="'h-82'"
                        [title]="'SITES.ASSOCIATIONS.ADD_ASSET' | translate"
                ></iot-platform-ui-default-card>
            </div>

            <div fxFlex>
                <iot-platform-ui-default-card
                        (click)="dispatchAction.emit({ action: 'add-device' })"
                        *ngIf="!hasDevices && canCreateDevice"
                        [backgroundClass]="'h-82'"
                        [title]="'SITES.ASSOCIATIONS.ADD_DEVICE' | translate"
                ></iot-platform-ui-default-card>
            </div>
        </div>

        <div *ngIf="associationsLoaded && associations[displayBy].length">
            <div *ngFor="let association of associations[displayBy]" class="association-section">
                <iot4bos-ui-asset-device-association-item
                        (dispatchAction)="dispatchAction.emit($event)"
                        [association]="association"
                        [canCreateAsset]="canCreateAsset"
                        [canCreateDevice]="canCreateDevice"
                        [displayBy]="displayBy"
                        [navigationMode]="navigationMode"
                        [selectedItemId]="selectedItem?.id"
                ></iot4bos-ui-asset-device-association-item>
            </div>
        </div>
    </section>
</div>
