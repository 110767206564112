<form
  [formGroup]="thresholdForm"
  [ngClass]="{
    'threshold-form-container': !readonly,
    'step-validated': !readonly && thresholdForm.valid,
    'untouched-form': !readonly && !thresholdForm.dirty,
    'step-not-validated': !readonly && !thresholdForm.valid
  }"
  class="threshold-form"
  fxLayout="column"
  fxLayoutAlign="start"
>
  <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap="10px">
    <div class="asset-variable-threshold-position" fxFlex="2%">{{ positionControl.value }}</div>

    <ng-container *ngIf="!!algos?.length">
      <mat-button-toggle-group (change)="onToggleThresholdType($event)" [disabled]="readonly" formControlName="thresholdType" fxFlex="7rem">
        <mat-button-toggle [value]="ThresholdTypes.STATIC">{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.STATIC' | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="ThresholdTypes.DYNAMIC">{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.DYNAMIC' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>

    <mat-form-field color="accent" fxFlex="16%">
      <mat-label>{{ 'TAG_EDITOR.COLOR' | translate }}</mat-label>
      <mat-select (selectionChange)="setCellColor()" formControlName="lineColor" required>
        <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
          <span [style.background-color]="lineColorControl.value" class="threshold-color-round"></span>
        </mat-select-trigger>
        <mat-option *ngFor="let color of lineColors" [value]="color">
          <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span [style.background-color]="color" class="threshold-color-round"></span>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!isDynamic" color="accent" fxFlex="20%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.NAME_PLACEHOLDER' | translate }}</mat-label>
      <input #nameInput formControlName="name" matInput maxlength="20" required />
      <mat-hint align="end">{{ nameInput.value?.length || 0 }}/20</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="isDynamic" color="accent" fxFlex="20%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.ALGO_PLACEHOLDER' | translate }}</mat-label>
      <mat-select (selectionChange)="onAlgoSelectionChange()" formControlName="algo" required>
        <mat-option *ngFor="let algo of algos" [value]="algo">
          <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            {{ algo.name }}
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!isDynamic || (hasValue && !isCalculationLoading)" color="accent" fxFlex="20%">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.VALUE_PLACEHOLDER' | translate }}</mat-label>
      <input formControlName="value" matInput required step="0.01" type="number" />
    </mat-form-field>

    <ng-container *ngIf="!readonly && isDynamic">
      <button
        (click)="calculateDynamicThreshold()"
        *ngIf="!hasValue && !isCalculationLoading"
        [disabled]="algoControl?.invalid || lineColorControl?.invalid"
        class="button-regular calculate-dynamic-threshold-btn"
        color="accent"
        fxFlex="15%"
        mat-raised-button
      >
        {{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.CALCULATE' | translate }}
      </button>
      <mat-progress-spinner *ngIf="isCalculationLoading" [diameter]="20" color="accent" fxFlex="10%" mode="indeterminate"></mat-progress-spinner>
    </ng-container>

    <button
      (click)="deleteThreshold.emit()"
      *ngIf="!readonly"
      [disabled]="position === 1"
      [ngClass]="{ 'layout-invisible': position === 1 }"
      color="warn"
      fxFlex="5%"
      mat-icon-button
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div *ngIf="errorMessage" class="threshold-form__error" fxFlex>
    {{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.ERROR_MESSAGES.' + errorMessage | translate }}
  </div>
</form>
