<mat-card class="dialog-card variable-chart">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ data?.title ? data.title : data.variables[0]?.name }}</h1>
            <span fxFlex></span>
            <div>
                <button
                        (click)="toggleTableView()"
                        [matTooltipClass]="'regular-tooltip'"
                        [matTooltip]="(displayTableView ? 'VARIABLE_CHART.HIDE_TABLE_VIEW' : 'VARIABLE_CHART.DISPLAY_TABLE_VIEW') | translate"
                        [ngClass]="{ 'display-table-active': displayTableView }"
                        mat-icon-button
                >
                    <mat-icon>table_chart</mat-icon>
                </button>
                <button (click)="enterFullscreen()" *ngIf="!fullscreen" class="fullscreen-button" mat-icon-button>
                    <mat-icon class="material-icons"> fullscreen</mat-icon>
                </button>
                <button (click)="exitFullscreen()" *ngIf="fullscreen" class="fullscreen-button" mat-icon-button>
                    <mat-icon class="material-icons"> fullscreen_exit</mat-icon>
                </button>
                <button (click)="close()" cdkFocusInitial class="regular-round-button" mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-toolbar>
    </mat-card-title>

    <iot4bos-ui-variable-chart [data]="data?.variables" [options]="data?.options"
                               [variableType]="data.variableType"></iot4bos-ui-variable-chart>
</mat-card>
