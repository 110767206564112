import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptor } from '@iot-platform/auth';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DetailPopupCardsModule, ExportsComponent, UiModule, provideExportsSettings } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, IotPlatformPipesModule, UserNamePipe } from '@iot-platform/pipes';
import { SharedModule, VariableChartModule, VariableChartService } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { DeviceEventsService } from '@iot-platform/shared/services';
import { TableEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EventDetailPopupModule } from '../../components/event-detail-popup/event-detail-popup.module';
import { MvCellModule } from '../../components/mv-cell/mv-cell.module';
import { ProductExportsService } from '../../services/product-exports.service';
import { DeviceEventsApi } from './+state/device-events.api';
import { DeviceEventsCommentsEffects } from './+state/effects/device-events-comments.effects';
import { DeviceEventsEffects } from './+state/effects/device-events.effects';
import * as fromDeviceEvents from './+state/reducers';
import { DeviceEventsShellComponent } from './containers/device-events-shell/device-events-shell.component';
import { CommentsPanelComponent } from '@iot-platform/shared/iot4bos-ui';

const deviceEventsRoutes: Routes = [{ path: '', component: DeviceEventsShellComponent }];

@NgModule({
  imports: [
    SharedModule,
    UiModule,
    IotPlatformPipesModule,
    HttpClientModule,
    MvCellModule,
    DetailPopupCardsModule,
    StoreModule.forFeature(fromDeviceEvents.deviceEventsFeatureKey, fromDeviceEvents.reducers),
    EffectsModule.forFeature([DeviceEventsEffects, DeviceEventsCommentsEffects]),
    RouterModule.forChild(deviceEventsRoutes),
    FavoriteViewsModule,
    VariableChartModule,
    ExportsComponent,
    TableEngineModule,
    EventDetailPopupModule,
    GridEngineModule,
    CommentsPanelComponent
  ],
  declarations: [DeviceEventsShellComponent],
  exports: [RouterModule],
  providers: [
    DeviceEventsService,
    DeviceEventsApi,
    VariableChartService,
    DateFormatPipe,
    UserNamePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideExportsSettings({
      productExportsService: ProductExportsService
    })
  ]
})
export class DeviceEventsModule {}
