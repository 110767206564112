<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign=" center">
            <h1 class="dialog-card-title">{{ 'EXPORTS.TITLE' | translate }}</h1>
        </mat-toolbar>
    </mat-card-title>

    <mat-progress-bar *ngIf="loading$ | async" [value]="progressBarValue$ | async" class="exports-loader" color="accent"
                      mode="buffer"></mat-progress-bar>

    <form [formGroup]="form" class="full-width">
        <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="10px">

            <mat-form-field color="accent" fxFlex>
                <mat-label>{{ 'EXPORTS.TYPE' | translate }}</mat-label>
                <mat-select (selectionChange)='onTypeChange($event)' [placeholder]="" formControlName="type"
                            required>
                    <mat-option *ngFor="let t of types" [value]="t">{{ t }}</mat-option>
                </mat-select>
                <mat-error *ngIf="type.invalid">{{ 'EXPORTS.ERRORS.TYPE' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field color="accent" floatLabel="never" fxFlex>
                <span matTextPrefix>{{ time }}-</span>
                <mat-label>{{ 'EXPORTS.NAME' | translate }}</mat-label>
                <input [placeholder]="" formControlName="name" matInput pattern="\S.*"
                       required/>
                <mat-error
                        *ngIf="name.invalid && name.errors.pattern">{{ 'EXPORTS.ERRORS.NAME' | translate }}</mat-error>
            </mat-form-field>
            <p *ngIf="data?.totalElements > 80000" class="export-limit-warning">{{ 'EXPORTS.LIMIT' | translate }}</p>
            <p class="dialog-form-tips">{{ 'EXPORTS.TIPS' | translate }}</p>
        </mat-card-content>

        <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
            <button (click)="close()" class="button-regular" mat-button
                    type="button">{{ 'EXPORTS.CANCEL' | translate }}</button>
            <button
                    (click)="submit()"
                    [disabled]="form.invalid || (loading$ | async)"
                    class="button-regular"
                    color="accent"
                    mat-raised-button
                    type="submit"
            >
                {{ (canDownload ? 'EXPORTS.DOWNLOAD' : 'EXPORTS.CLOSE') | translate }}
            </button>
        </mat-card-actions>
    </form>
</mat-card>
