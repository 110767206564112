import { Sort } from '@angular/material/sort';
import { Connector, FavoriteView, Filter, gridSortModel, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { CommandType, Device, DeviceCallLog, DeviceVariable, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const addDevice = createAction('[Devices] Add Device', props<{ deviceToAdd: Device }>());
export const addDeviceSuccess = createAction('[Devices] Add Device Success', props<{ addedDevice: Device }>());
export const addDeviceFailure = createAction('[Devices] Add Device Failure', props<{ error: any }>());

export const updateDevice = createAction('[Devices] Update Device', props<{ deviceToUpdate: Device }>());
export const updateDeviceSuccess = createAction('[Devices] Update Device Success', props<{ updatedDevice: Device }>());
export const updateDeviceFailure = createAction('[Devices] Update Device Failure', props<{ error: any }>());

export const activateDevice = createAction('[Devices] Activate Device', props<{ deviceToActivate: Device }>());
export const activateDeviceSuccess = createAction('[Devices] Activate Device Success', props<{ activatedDevice: Device }>());
export const activateDeviceFailure = createAction('[Devices] Activate Device Failure', props<{ error: any }>());

export const bulkUpdateDevices = createAction('[Devices] Bulk Update Devices', props<{ devicesToUpdate: Device[] }>());
export const bulkUpdateDevicesSuccess = createAction('[Devices] Bulk Update Devices Success', props<{ updatedDevices: Device[] }>());
export const bulkUpdateDevicesFailure = createAction('[Devices] Bulk Update Devices Failure', props<{ errors: any }>());

export const resetDevice = createAction('[Devices] Reset Device', props<{ deviceToReset: Device }>());
export const resetDeviceSuccess = createAction('[Devices] Reset Device Success', props<{ resetDevice: Device }>());
export const resetDeviceFailure = createAction('[Devices] Reset Device Failure', props<{ error: any }>());

export const addDeviceVariable = createAction('[Devices] Add Device Variable', props<{ deviceVariableToAdd: DeviceVariable }>());
export const addDeviceVariableSuccess = createAction('[Devices] Add Device Variable Success', props<{ addedDeviceVariable: DeviceVariable }>());
export const addDeviceVariableFailure = createAction('[Devices] Add Device Variable Failure', props<{ error: any }>());

export const updateDeviceVariable = createAction('[Devices] Update Device Variable', props<{ deviceVariableToUpdate: DeviceVariable }>());
export const updateDeviceVariableSuccess = createAction('[Devices] Update Device Variable Success', props<{ updatedDeviceVariable: DeviceVariable }>());
export const updateDeviceVariableFailure = createAction('[Devices] Update Device Variable Failure', props<{ error: any }>());

export const updateTagsByDeviceId = createAction('[Devices] Update Tags By Device Id', props<{ deviceId: string; tags: TagCategory[] }>());
export const updateTagsByDeviceIdSuccess = createAction('[Devices] Tags By Device Id Success', props<{ tags: TagCategory[] }>());
export const updateTagsByDeviceIdFailure = createAction('[Devices] Tags By Device Id Failure', props<{ error: any }>());

export const deleteDevice = createAction('[Devices] Delete Device', props<{ deviceToDelete: Device }>());
export const deleteDeviceSuccess = createAction('[Devices] Delete Device Success', props<{ deletedDevice: Device }>());
export const deleteDeviceFailure = createAction('[Devices] Delete Device Failure', props<{ error: any }>());

export const deleteDeviceVariables = createAction('[Devices] Delete Device Variable', props<{ deviceVariablesToDelete: DeviceVariable[] }>());
export const deleteDeviceVariableSuccess = createAction('[Devices] Delete Device Variable - ', props<{ response: I4BBulkOperationApiResponse }>());
export const deleteDeviceVariableFailure = createAction('[Devices] Delete Device Variable Failure', props<{ error: any }>());

export const loadDeviceById = createAction('[Devices] Load Device By Id', props<{ deviceId: string }>());
export const loadDeviceByIdSuccess = createAction('[Devices] Load Device By Id Success', props<{ loadedDevice: Device }>());
export const loadDeviceByIdFailure = createAction('[Devices] Load Device By Id Failure', props<{ error: any }>());

export const loadCallLogsByDeviceId = createAction('[Devices] Load Call Logs By Device Id', props<{ deviceId: string }>());
export const loadCallLogsByDeviceIdSuccess = createAction('[Devices] Load Call Logs By Device Id Success', props<{ loadedCallLogs: DeviceCallLog[] }>());
export const loadCallLogsByDeviceIdFailure = createAction('[Devices] Load Call Logs By Device Id Failure', props<{ error: any }>());

export const selectDevice = createAction('[Devices] Select Device State', props<{ selectedDevice: Device }>());
export const selectDeviceSuccess = createAction('[Devices] Select Device Success', props<{ selectedDevice: Device }>());
export const selectDeviceFailure = createAction('[Devices] Select Device Failure', props<{ error: any }>());

export const saveCurrentFavoriteView = createAction('[Devices] Save Current Favorite View', props<{ currentFavoriteView: FavoriteView }>());
export const saveCurrentFilters = createAction('[Devices] Save Current Filters', props<{ currentFilters: Filter[] }>());

export const loadDeviceConnectorList = createAction('[Devices] Load Device Connector List');
export const loadDeviceConnectorListSuccess = createAction('[Devices] Load Device Connector List Success', props<{ connectors: Connector[] }>());
export const loadDeviceConnectorListFailure = createAction('[Devices] Load Device Connector List Failure', props<{ error: any }>());

export const loadDeviceStatusList = createAction('[Devices] Load Devices Connector List');
export const loadDeviceStatusListSuccess = createAction('[Devices] Load Device Status List Success', props<{ status: string[] }>());
export const loadDeviceStatusListFailure = createAction('[Devices] Load Device Status List Failure', props<{ error: any }>());

export const loadDevices = createAction('[Devices] Load Devices', props<{ request: PlatformRequest }>());
export const loadDevicesSuccess = createAction('[Devices] Load Devices Success', props<{ response: PlatformResponse }>());
export const loadDevicesFailure = createAction('[Devices] Load Devices Failure', props<{ error: any }>());

export const loadSiteById = createAction('[Devices] Load Site By Id', props<{ siteId: string }>());
export const loadSiteByIdSuccess = createAction('[Devices] Load Site By Id Success', props<{ site: Site }>());
export const loadSiteByIdFailure = createAction('[Devices] Load Site By Id Failure', props<{ error: any }>());

export const loadVariablesByDeviceId = createAction('[Devices] Load Variables By Device Id', props<{ deviceId: string }>());
export const loadVariablesByDeviceIdSuccess = createAction('[Devices] Load Variables By Device Id Success', props<{ deviceVariables: DeviceVariable[] }>());
export const loadVariablesByDeviceIdFailure = createAction('[Devices] Load Variables By Device Id Failure', props<{ error: any }>());

export const loadTagsByDeviceId = createAction('[Devices] Load Tags By Device Id', props<{ deviceId: string }>());
export const loadTagsByDeviceIdSuccess = createAction('[Devices] Load Tags By Device Id Success', props<{ tags: TagCategory[] }>());
export const loadTagsByDeviceIdFailure = createAction('[Devices] Load Tags By Device Id Failure', props<{ error: any }>());

export const loadMVSettings = createAction('[Devices] Load Devices Settings', props<{ settingName: string }>());
export const loadMVSettingsSuccess = createAction('[Devices] Load MV Devices Settings Success', props<{ settings: any }>());
export const loadMVSettingsFailure = createAction('[Devices] Load MV Devices Settings Failure', props<{ error: any }>());
//
export const saveMVSettings = createAction('[Devices] Save Devices Settings', props<{ name: string; values: any }>());
export const saveMVSettingsSuccess = createAction('[Devices] Save MV Devices Settings Success', props<{ settings: any }>());
export const saveMVSettingsFailure = createAction('[Devices] Save MV Devices Settings Failure', props<{ error: any }>());
//
export const loadCallLogsMVSettings = createAction('[Devices] Load Devices Call Logs Settings', props<{ settingName: string }>());
export const loadCallLogsMVSettingsSuccess = createAction('[Devices] Load MV Devices Call Logs Settings Success', props<{ callLogsSettings: any }>());
export const loadCallLogsMVSettingsFailure = createAction('[Devices] Load MV Devices Call Logs Settings Failure', props<{ error: any }>());
//
export const saveCallLogsMVSettings = createAction('[Devices] Save Devices Call Logs Settings', props<{ name: string; values: any }>());
export const saveCallLogsMVSettingsSuccess = createAction('[Devices] Save Call Logs MV Devices Settings Success', props<{ callLogsSettings: any }>());
export const saveCallLogsMVSettingsFailure = createAction('[Devices] Save Call Logs MV Devices Settings Failure', props<{ error: any }>());

export const loadTimeseriesByDeviceVariableId = createAction(
  '[Devices] Load Timeseries By Device Id',
  props<{ deviceId: string; variableId: string; start: string; end: string; limit: number }>()
);
export const loadTimeseriesByDeviceVariableIdSuccess = createAction('[Devices] Load Timeseries By Device Id Success', props<{ timeseries: any[] }>());
export const loadTimeseriesByDeviceVariableIdFailure = createAction('[Devices] Load Timeseries By Device Id Failure', props<{ error: any }>());

export const saveInitialSort = createAction('[Devices] Save Initial sort', props<{ initialSort: gridSortModel[] | Sort }>());

export const resetDeviceVariablesLastValues = createAction('[Devices] Reset Device Variables Last Values', props<{ deviceVariables: DeviceVariable[] }>());
export const resetDeviceVariablesLastValuesSuccess = createAction(
  '[Devices] Reset Device Variables Last Values - ',
  props<{ response: I4BBulkOperationApiResponse }>()
);
export const resetDeviceVariablesLastValuesFailure = createAction('[Devices] Reset Device Variables Last Values Failure', props<{ error: any }>());

export const moveDevices = createAction('[Devices] Move Devices', props<{ devicesToMove: Device[]; originSite?: Site }>());
export const moveDevicesSuccess = createAction('[Devices] Move Device Success', props<{ updatedDevices: Device[]; currentSite: Site; originSite?: Site }>());
export const moveDevicesFailure = createAction('[Devices] Move Device Failure', props<{ errors: any[] }>());

export const bulkAddOrRemoveTag = createAction('[Devices] Bulk Add or Remove Tag', props<{ isAddition: boolean; tagLabelId: string; devicesIds: string[] }>());
export const bulkAddTagSuccess = createAction('[Devices] Bulk Add Tag - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkAddTagFailure = createAction('[Devices] Bulk Add Tag Failure', props<{ error: any }>());
export const bulkRemoveTagSuccess = createAction('[Devices] Bulk Remove Tag - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkRemoveTagFailure = createAction('[Devices] Bulk Remove Tag Failure', props<{ error: any }>());
//
export const bulkSendCommand = createAction('[Devices] Bulk Send Command', props<{ devicesIds: string[]; command: { command: CommandType } }>());
export const bulkSendCommandSuccess = createAction('[Devices] Bulk Send Command - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkSendCommandFailure = createAction('[Devices] Bulk Send Command Failure', props<{ error: any }>());
