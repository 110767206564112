import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ChatEvent, ChatMessage } from '@iot-platform/models/common';
import { DateFormatModule, LinkifyPipeModule } from '@iot-platform/pipes';

@Component({
  standalone: true,
  imports: [MatIconModule, FlexLayoutModule, DateFormatModule, MatButtonModule, LinkifyPipeModule],
  selector: 'iot-platform-ui-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent {
  @Input() message!: ChatMessage;

  @Output() dispatchAction: EventEmitter<ChatEvent> = new EventEmitter<ChatEvent>();
}
