import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SortEntitiesUtil } from '@iot-platform/iot-platform-utils';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { DateFormatModule, IotPlatformPipesModule } from '@iot-platform/pipes';
import { IconModule } from '@iot-platform/shared/components';
import { DynamicListFieldService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { merge } from 'lodash';
import { noop, of } from 'rxjs';
import { AsyncAutocompleteMultipleSelectsModule } from '../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { AsyncAutocompleteModule } from '../async-autocomplete/async-autocomplete.module';
import { ChipModule } from '../chip/chip.module';
import { CountryAutocompleteModule } from '../country-autocomplete/country-autocomplete.module';
import { DateRangeModule } from '../date-range/date-range.module';
import { TimezoneAutocompleteMultipleSelectsModule } from '../timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.module';
import { TimezoneAutocompleteModule } from '../timezone-autocomplete/timezone-autocomplete.module';
import { FavoriteFilterEngineDirective } from './directives/favorite-filter-engine.directive';
import { FilterEngineDirective } from './directives/filter-engine.directive';
import {
  CountryAutocompleteFieldComponent,
  DateIntervalFieldComponent,
  DynamicListFieldMultipleSelectsComponent,
  DynamicListFieldSingleSelectComponent,
  InputFieldComponent,
  MultipleInputsFieldComponent,
  SelectFieldComponent,
  TimezoneAutocompleteFieldComponent
} from './fields';
import { FilterComponentFactory } from './filter-component-factory';
import { FILTER_ENGINE_SETTINGS } from './filter-engine-settings.providers';
import { FilterEngineComponent } from './filter-engine.component';
import { ManageFavoriteFiltersPopupComponent } from './manage-favorite-filters-popup/manage-favorite-filters-popup.component';
import { FilterEngineSettings } from './models';
import { AbstractFavoriteFilterEngineService } from './services/abstract-favorite-filter-engine.service';
import { FilterEngineSettingsService } from './services/filter-engine-settings.service';
import { FilterEngineService } from './services/filter-engine.service';

export class DummyFavoriteFilterEngineService {
  saveUserPreferences = () => noop();

  getAccount$() {
    return of({} as UserAccount);
  }

  getUserPreferences$() {
    return of({} as UserPreferences);
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    ChipModule,
    IotPlatformPipesModule,
    TranslateModule,
    IconModule,
    DateFormatModule,
    MatProgressSpinnerModule,
    DateRangeModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatTooltipModule,
    DragDropModule,
    MatCardModule,
    MatToolbarModule
  ],
  exports: [FilterEngineComponent],
  declarations: [
    FilterEngineComponent,
    FilterEngineDirective,
    FavoriteFilterEngineDirective,
    InputFieldComponent,
    MultipleInputsFieldComponent,
    SelectFieldComponent,
    DateIntervalFieldComponent,
    DynamicListFieldSingleSelectComponent,
    DynamicListFieldMultipleSelectsComponent,
    TimezoneAutocompleteFieldComponent,
    CountryAutocompleteFieldComponent,
    ManageFavoriteFiltersPopupComponent
  ],
  providers: [FilterEngineService, FilterComponentFactory, DynamicListFieldService, SortEntitiesUtil, FilterEngineSettingsService]
})
export class FilterEngineModule {
  private static currentSettings: FilterEngineSettings;

  static withSettings(settings: Partial<FilterEngineSettings>): ModuleWithProviders<FilterEngineModule> {
    if (settings) {
      this.currentSettings = merge(this.currentSettings, settings);
    }
    const providers = [];
    if (settings?.favoriteFilterEngineService) {
      providers.push({
        provide: AbstractFavoriteFilterEngineService,
        useClass: settings.favoriteFilterEngineService
      });
    }
    return {
      ngModule: FilterEngineModule,
      providers: [
        ...providers,
        {
          provide: FILTER_ENGINE_SETTINGS,
          useValue: {
            ...this.currentSettings
          }
        }
      ]
    };
  }

  static withTestingSettings(): ModuleWithProviders<FilterEngineModule> {
    return {
      ngModule: FilterEngineModule,
      providers: [
        {
          provide: AbstractFavoriteFilterEngineService,
          useClass: DummyFavoriteFilterEngineService
        },
        {
          provide: FILTER_ENGINE_SETTINGS,
          useValue: {
            favoriteFilterEngineService: DummyFavoriteFilterEngineService,
            options: null
          }
        }
      ]
    };
  }
}
