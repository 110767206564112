import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetAction, WidgetEvent, WidgetInstance } from '@iot-platform/models/widgets';

@Component({
  selector: 'iot-platform-ui-widget-item',
  templateUrl: './widget-item.component.html',
  styleUrls: ['./widget-item.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'hostCssClass'
  }
})
export class WidgetItemComponent implements OnInit {
  @Input() public widget!: WidgetInstance;

  @Output() dispatchEvent: EventEmitter<WidgetEvent> = new EventEmitter<WidgetEvent>();

  public hostCssClass = '';

  public get menu() {
    return this.widget.options.menu;
  }

  public ngOnInit(): void {
    this.hostCssClass = `widget-item_${this.widget.type.toLowerCase()}`;
  }

  public onWidgetClick(): void {
    this.dispatchEvent.emit({
      action: WidgetAction.PREVIEW,
      widget: this.widget
    });
  }

  public onDispatchEvent(action: WidgetAction): void {
    this.dispatchEvent.emit({
      action,
      widget: this.widget
    });
  }
}
