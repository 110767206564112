<div fxLayout="column" fxFlex fxLayoutAlign="space-between stretch">
  <h2 class="layout-login-regular-title login-select-authentication--title">{{ 'LOGIN.TITLE.LOGIN' | translate }}</h2>

  @if (ssoDisabled) {
    <section class="btn-container" fxLayout="column" fxLayoutAlign="center stretch">
      <button mat-button type="button" data-cy="login-select-authentication-classic-authentication-button" (click)="signInWithEmailPassword.emit()" class="">
        {{ 'LOGIN.LOGIN_WITH_EMAIL_PASSWORD' | translate }}
      </button>
    </section>
  } @else {
    <section fxFlex fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center stretch">
      <div class="btn-container" fxLayout="column" fxLayoutAlign="center stretch">
        <button
          mat-raised-button
          type="button"
          data-cy="login-select-authentication-sso-authentication-button"
          (click)="signInWithSSO.emit()"
          class="sso-action"
        >
          <mat-icon [svgIcon]="'air_liquide_logo'"></mat-icon>&nbsp;{{ 'LOGIN.SSO_CONNECT' | translate }}
        </button>
      </div>
      <div class="btn-container" fxLayout="column" fxLayoutAlign="center stretch">
        <button
          mat-button
          type="button"
          data-cy="login-select-authentication-email-password-button"
          (click)="signInWithEmailPassword.emit()"
          class="email-password-action"
        >
          {{ 'LOGIN.LOGIN_WITH_EMAIL_PASSWORD' | translate }}
        </button>
      </div>
    </section>
  }

  @if (carmUrl) {
    <div class="login-select-authentication--no-account-message" fxLayoutAlign="start center">
      <span>{{ 'LOGIN.NO_ACCOUNT' | translate }}</span>
      <a fxLayoutAlign="start center" [href]="carmUrl" target="_blank" data-cy="login-select-authentication-request-account-link"
        >&nbsp;{{ 'LOGIN.REQUEST_ACCOUNT' | translate }}&nbsp;<mat-icon>open_in_new</mat-icon></a
      >
    </div>
  }
</div>
