import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-site-detail-assets',
  templateUrl: './site-detail-assets.component.html',
  styleUrls: ['./site-detail-assets.component.scss']
})
export class SiteDetailAssetsComponent {
  @Input() assets: Asset[] = [];
  @Input() totalAssets = 0;
  @Input() isAssetLoaded = false;

  @Output() selectAsset: EventEmitter<Asset> = new EventEmitter<Asset>();
}
