<div *ngIf="navBlock?.display" data-cy="iot-platform-ui-sidenav-block">
    <header *ngIf="navBlock.title" class="title fw-600">
        <span>{{ navBlock.title | translate }}</span>
        <hr class="regular-1px-line flex-1"/>
    </header>
    <section>
        <iot4bos-ui-sidenav-items *ngFor="let item of navBlock.items; trackBy: trackById"
                                  [item]="item"></iot4bos-ui-sidenav-items>
    </section>
</div>
