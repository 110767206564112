import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BuildInfo } from '@iot-platform/models/common';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { BuildInfoService } from './services/build-info.service';

@Component({
  standalone: true,
  imports: [NgIf, AsyncPipe, FlexLayoutModule],
  selector: 'iot-platform-ui-build-info',
  templateUrl: './build-info.component.html',
  styleUrls: ['./build-info.component.scss']
})
export class BuildInfoComponent implements OnInit {
  @Input() label!: string;
  @Input() build!: BuildInfo;
  @Input() showBuildNumber = true;
  @Output() openReleaseNotes: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  build$!: Observable<BuildInfo>;

  constructor(private readonly buildInfoService: BuildInfoService) {}

  ngOnInit() {
    if (!this.build) {
      this.build$ = this.buildInfoService.getBuildInfo().pipe(shareReplay(1));
    } else {
      this.build$ = of(this.build);
    }
  }

  onOpenReleaseNotesButtonClick() {
    this.openReleaseNotes.emit(true);
  }
}
