import { BusinessProfile, Entity, UserAccount } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthApiActions, AuthBusinessProfilesApiActions, AuthPageActions } from '../actions';

export const authApiFeatureKey = 'authApi';

export interface State {
  loggedIn: boolean;
  loggedOut: boolean;
  tokenExpired: boolean;
  businessProfileInitialized: boolean;
  idToken: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  currentUser: UserAccount | null;
  cognitoUser?: CognitoUser | null;
  userId?: string | null;
  firstLogIn?: boolean;
  businessProfiles?: BusinessProfile[] | null;
  selectedBusinessProfile?: BusinessProfile | null;
  currentEntity?: Entity | null;
  currentEntityId?: string;
  privileges?: any;
  preferences?: any;
  isUserAdmin: boolean;
  initialStateFromStore?: any;
  loggedInWithSSO: boolean;
  ssoTokenExpiresAt: string;
}

export const emptyState: State = {
  loggedIn: false,
  businessProfileInitialized: false,
  loggedOut: false,
  tokenExpired: true,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  currentUser: null,
  cognitoUser: null,
  userId: null,
  firstLogIn: false,
  businessProfiles: null,
  selectedBusinessProfile: null,
  currentEntity: null,
  privileges: null,
  preferences: null,
  isUserAdmin: false,
  initialStateFromStore: {},
  loggedInWithSSO: false,
  ssoTokenExpiresAt: ''
};

export const reducer = createReducer(
  emptyState,
  on(AuthPageActions.signIn, () => emptyState),

  // This action will be dispatched in auth module constructor
  // Old behaviour : on(AuthApiActions.retrieveSession, () => initialStateFromStore),
  // New Behaviour :
  on(AuthApiActions.setInitialStateFromStore, (state: State, { initialState }) => ({
    ...state,
    initialStateFromStore: initialState
  })),
  on(AuthApiActions.signOutSuccess, (state: State) => ({
    ...state,
    loggedOut: true
  })),
  on(AuthApiActions.retrieveSession, (state: State) => ({
    ...state,
    ...state.initialStateFromStore
  })),
  on(
    AuthApiActions.signInWithSSOSuccess,
    AuthApiActions.retrieveSsoSessionSuccess,
    (state: State, { idToken, accessToken, refreshToken }): State => ({
      ...state,
      loggedIn: true,
      loggedInWithSSO: true,
      accessToken,
      idToken,
      refreshToken
    })
  ),
  on(
    AuthApiActions.refreshSsoTokensSuccess,
    (state: State, { idToken, accessToken }): State => ({
      ...state,
      accessToken,
      idToken
    })
  ),
  on(AuthApiActions.signInSuccess, (state: State, { cognitoUser }) => ({
    ...state,
    cognitoUser,
    loggedIn: true,
    tokenExpired: false,
    idToken: cognitoUser ? cognitoUser.getSignInUserSession().getIdToken().getJwtToken() : null,
    refreshToken: cognitoUser ? cognitoUser.getSignInUserSession().getRefreshToken().getToken() : null,
    userId: cognitoUser ? cognitoUser.getUsername() : null
  })),
  on(AuthApiActions.retrieveSessionSuccess, (state: State, { cognitoUser }) => ({
    ...state,
    cognitoUser,
    loggedIn: true,
    tokenExpired: false,
    idToken: cognitoUser ? cognitoUser.getSignInUserSession().getIdToken().getJwtToken() : null,
    refreshToken: cognitoUser ? cognitoUser.getSignInUserSession().getRefreshToken().getToken() : null,
    userId: cognitoUser ? cognitoUser.getUsername() : null
  })),
  on(AuthApiActions.retrieveSessionFailure, (state: State) => {
    localStorage.clear();
    const s = {
      ...state,
      loggedIn: false,
      loggedOut: true,
      tokenExpired: true,
      idToken: null,
      accessToken: null,
      refreshToken: null,
      currentUser: null,
      cognitoUser: null,
      firstLogIn: false,
      businessProfiles: null,
      selectedBusinessProfile: null,
      currentEntity: null,
      privileges: null,
      preferences: null,
      isUserAdmin: false
    };

    return s;
  }),
  on(AuthApiActions.loadAccountSuccess, (state: State, { account }) => ({
    ...state,
    currentUser: account,
    userId: account.id,
    businessProfiles: account.businessProfiles,
    preferences: account.preferences
  })),
  on(AuthApiActions.setBusinessProfiles, (state: State, { businessProfiles }) => ({
    ...state,
    businessProfiles: [...businessProfiles],
    businessProfileInitialized: true
  })),
  on(AuthApiActions.loadPrivilegesSuccess, (state: State, { privileges }) => ({
    ...state,
    privileges
  })),
  on(AuthPageActions.loadPrivileges, (state: State) => ({
    ...state,
    privileges: null
  })),
  on(AuthApiActions.signInFailure, (state: State, { error }) => ({ ...state, error })),
  on(AuthPageActions.signOut, () => ({ ...emptyState })),
  on(AuthApiActions.signOutFailure, (state: State) => ({ ...state })),
  on(AuthApiActions.changePasswordSuccess, (state: State) => ({ ...state, loggedIn: false })),
  on(AuthPageActions.requireNewPassword, (state: State) => ({ ...state, loggedIn: true })),
  on(AuthApiActions.changePasswordFailure, (state: State) => ({ ...state })),
  on(AuthApiActions.forgotPasswordSuccess, (state: State) => ({ ...state })),
  on(AuthApiActions.forgotPasswordFailure, (state: State) => ({ ...state })),
  on(AuthApiActions.checkIfUserIsAdminSuccess, (state: State, { isAdminUser }) => ({ ...state, isUserAdmin: isAdminUser })),
  on(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess, (state: State, { selectedBusinessProfile }) => ({
    ...state,
    selectedBusinessProfile
  })),
  on(AuthApiActions.refreshTokenSuccess, (state: State, { refreshed }) => ({
    ...state,
    idToken: refreshed.getSignInUserSession().getIdToken().getJwtToken(),
    refreshToken: refreshed.getSignInUserSession().getRefreshToken().getToken()
  })),
  on(AuthApiActions.refreshSsoTokensSuccess, AuthApiActions.signInWithSSOSuccess, (state: State, { expiresIn }) => ({
    ...state,
    ssoTokenExpiresAt: new Date(Date.now() + parseInt(`${expiresIn}`) * 1000).toISOString()
  })),
  on(AuthBusinessProfilesApiActions.loadSelectedEntityForSessionSuccess, (state: State, { selectedEntity }) => ({ ...state, currentEntity: selectedEntity }))
);

export const getLoggedIn = (state: State) => state.loggedIn;
export const getAccount = (state: State) => state.currentUser;
export const getBusinessProfilesForAccount = (state: State) => state.businessProfiles;
export const getSelectedBusinessProfileForAccount = (state: State) => state.selectedBusinessProfile;
export const getSelectedEntityForSession = (state: State) => state.currentEntity;
export const getSelectedBusinessProfileTimezone = (state: State) => state.selectedBusinessProfile.timezoneDetails;
export const getPreferencesForAccount = (state: State) => state.preferences;
export const getPrivileges = (state: State) => state.privileges;
export const getCurrentUser = (state: State) => state.currentUser;
export const getIdToken = (state: State) => state.idToken;
export const getRefreshToken = (state: State) => state.refreshToken;
export const getSsoTokenExpiresAt = (state: State) => state.ssoTokenExpiresAt;
export const getLoggedOut = (state: State) => state.loggedOut;
export const getUserId = (state: State) => state.userId;
export const getIsUserAdmin = (state: State) => state.isUserAdmin;
export const getBusinessProfileInitialized = (state: State) => state.businessProfileInitialized;
