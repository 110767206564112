import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CustomEncoder, ENVIRONMENT, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { CommonIndexedPagination, Environment, PlatformRequest, PlatformResponse, Product, TagCategory } from '@iot-platform/models/common';
import { Dashboard } from '@iot-platform/models/dashboards';
import {
  Asset,
  AssetCommandResponse,
  AssetTemplate,
  AssetVariable,
  CommandType,
  Concept,
  I4BBulkOperationApiResponse,
  Log,
  Site
} from '@iot-platform/models/i4b';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  getManyBySiteId(siteId: string, limit: number = 100, page: number = 0): Observable<Asset[]> {
    return this.http
      .get(this.environment.api.url + this.environment.api.endpoints.assets + '?siteId=' + siteId + '&limit=' + limit + '&page=' + page)
      .pipe(map((results: any) => results.content));
  }

  getAll(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page ? request.page.toString(10) : '0');

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<Asset[]>(`${this.environment.api.url}${this.environment.api.endpoints.assets}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  getAssetById(assetId: string): Observable<Asset> {
    return this.http.get<Asset>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}`);
  }

  getTagsByAssetId(assetId: string): Observable<TagCategory[]> {
    return this.http
      .get(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}/tags`)
      .pipe(map((data: { page: NonNullable<unknown>; content: TagCategory[] }) => data.content));
  }

  putTagsByAssetId(assetId: string, tags: TagCategory[]): Observable<TagCategory[]> {
    return this.http
      .put(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}/tags`, {
        tags
      })
      .pipe(map((data: { page: NonNullable<unknown>; content: TagCategory[] }) => data.content));
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.http.get<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}`);
  }

  post(asset: Asset): Observable<Asset> {
    return this.http.post<Asset>(this.environment.api.url + this.environment.api.endpoints.assets, asset);
  }

  patch(asset: Asset): Observable<Asset> {
    return this.http.patch<Asset>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + asset.id, asset);
  }

  put(asset: Asset): Observable<Asset> {
    return this.http.put<Asset>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + asset.id, asset);
  }

  delete(asset: Asset): Observable<any> {
    return this.http.delete<any>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + asset.id);
  }

  saveTableState(selectedAsset: Asset): Observable<Asset> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ASSETS_TABLE_STATE_KEY, JSON.stringify(selectedAsset));
    return of(selectedAsset);
  }

  assignProductToAsset(product: Product, asset: Asset): Observable<Asset> {
    return this.http
      .put<any>(`${this.environment.api.url}${this.environment.api.endpoints.products}/${product.id}/assets/${asset.id}`, null)
      .pipe(map(() => asset));
  }

  removeProductFromAsset(product: Product, asset: Asset): Observable<Asset> {
    return this.http
      .delete<any>(`${this.environment.api.url}${this.environment.api.endpoints.products}/${product.id}/assets/${asset.id}`, null)
      .pipe(map(() => asset));
  }

  sendCommand(assetId: string, command: { command: CommandType }): Observable<AssetCommandResponse> {
    return this.http.post<AssetCommandResponse>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}/commands`, command);
  }

  getAssetTemplatesVisibleByEntityId(entityId: string): Observable<AssetTemplate[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('entityId', entityId);

    return this.http
      .get<{ page: any; content: AssetTemplate[] }>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}`, { params })
      .pipe(map((response) => response.content));
  }

  addAssetByTemplateId(assetTemplateId: string, siteId: string): Observable<Asset> {
    return this.http.post<Asset>(
      `${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplateId}${this.environment.api.endpoints.assets}`,
      { site: { id: siteId }, status: 'production' }
    );
  }

  getAssetTemplateById(assetTemplateId: string): Observable<AssetTemplate> {
    return this.http.get<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${assetTemplateId}`);
  }

  resetAssetVariablesLastValues(assetVariables: AssetVariable[]): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.resetAssetVariablesLastValues}`, {
      variablesIds: assetVariables.map((variable) => variable.id)
    });
  }

  getSites(entityId: string): Observable<Site[]> {
    return this.http
      .get<{ content: Site[]; page: CommonIndexedPagination }>(`${this.environment.api.url}${this.environment.api.endpoints.sites}?entityId=${entityId}`)
      .pipe(map((response: { content: Site[]; page: CommonIndexedPagination }) => response.content));
  }

  bulkAddOrRemoveTag(isAddition: boolean, assetsIds: string[], tagLabelId: string): Observable<I4BBulkOperationApiResponse> {
    return this.http.post<I4BBulkOperationApiResponse>(
      `${this.environment.api.url}${isAddition ? this.environment.api.endpoints.assetsBulkAddTag : this.environment.api.endpoints.assetsBulkRemoveTag}`,
      { assetsIds, tagLabelId }
    );
  }

  bulkSendCommand(assetsIds: string[], command: CommandType): Observable<AssetCommandResponse> {
    return this.http.post<AssetCommandResponse>(`${this.environment.api.url}${this.environment.api.endpoints.assetsBulkSendCommands}`, {
      assetsIds,
      command
    });
  }

  loadComments(asset: Asset): Observable<Log[]> {
    const canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);

    return forkJoin([
      this.http.get<Log[]>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${asset.id}${this.environment.api.endpoints.logs}`),
      canReadSite
        ? this.http.get<Log[]>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${asset.site?.id}${this.environment.api.endpoints.logs}`)
        : of([])
    ]).pipe(
      map(([assetLogs, siteLogs]) => [
        ...assetLogs.map((log: Log) => ({ ...log, concept: Concept.ASSET, icon: 'asset' })),
        ...siteLogs.map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' }))
      ])
    );
  }

  addComment(assetId: string, comment: string): Observable<Log> {
    return this.http
      .post<Log>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}${this.environment.api.endpoints.logs}`, {
        comment
      })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.ASSET, icon: 'asset' })));
  }

  editComment(assetId: string, comment: Log): Observable<Log> {
    return this.http
      .put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}${this.environment.api.endpoints.logs}/${comment.id}`, {
        comment: comment.comment
      })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.ASSET, icon: 'asset' })));
  }

  deleteComment(assetId: string, commentId: string): Observable<string> {
    return this.http
      .delete<void>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${assetId}${this.environment.api.endpoints.logs}/${commentId}`)
      .pipe(map(() => commentId));
  }

  getDashboards(): Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(`assets/data/asset-dashboards.json`);
  }
}
