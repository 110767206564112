import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AuthModule, AuthService } from '@iot-platform/auth';
import { ApiInterceptorModule, LocalStorageModule, provideCoreTrackingProviders, provideDefaultProvidersToken } from '@iot-platform/core';
import { I18nModule } from '@iot-platform/i18n';
import { Iot4bosUiModule, TrackingSettingsProviderService, trackingSettings } from '@iot-platform/iot4bos-ui';
import { AppName } from '@iot-platform/models/common';
import { NotificationModule } from '@iot-platform/notification';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { DEFAULT_ROUTER_FEATURENAME, FullRouterStateSerializer, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, provideStore, StoreModule } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AuthPageActions } from '../../../../libs/auth/src/lib/state/actions';
import { AssetEventsByAssetLogsEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/asset-events-by-asset-logs.effects';
import { AssetEventsByAssetEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/asset-events-by-asset.effects';
import { AssetEventsBySiteLogsEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/asset-events-by-site-logs.effects';
import { AssetEventsBySiteEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/asset-events-by-site.effects';
import { DeviceEventsByDeviceLogsEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/device-events-by-device-logs.effects';
import { DeviceEventsByDeviceEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/device-events-by-device.effects';
import { DeviceEventsBySiteLogsEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/device-events-by-site-logs.effects';
import { DeviceEventsBySiteEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/device-events-by-site.effects';
import { NavigationEffects } from '../../../../libs/iot4bos-ui/src/lib/containers/+state/effects/navigation.effects';
import { AssetEventsEffects } from '../../../../libs/iot4bos-ui/src/lib/features/asset-events/+state/effects/asset-events.effects';
import { AssetsEffects } from '../../../../libs/iot4bos-ui/src/lib/features/assets/+state/effects/assets.effects';
import { DeviceEventsEffects } from '../../../../libs/iot4bos-ui/src/lib/features/device-events/+state/effects/device-events.effects';
import { DeviceConnectorsEffects } from '../../../../libs/iot4bos-ui/src/lib/features/devices/+state/effects/device-connectors.effects';
import { DevicesEffects } from '../../../../libs/iot4bos-ui/src/lib/features/devices/+state/effects/devices.effects';
import { PoEventsEffects } from '../../../../libs/iot4bos-ui/src/lib/features/po-events/+state/effects/po-events.effects';
import { SitesEffects } from '../../../../libs/iot4bos-ui/src/lib/features/sites/+state/effects/sites.effects';
import { CalendarsEffects } from '../../../../libs/on-call-management/src/lib/features/calendars/+state/effects/calendars.effects';
import { EscalationProtocolsEffects } from '../../../../libs/on-call-management/src/lib/features/escalation-protocol/+state/effects/escalation-protocols.effects';
import { TeamPlanningsEffects } from '../../../../libs/on-call-management/src/lib/features/team-planning/+state/effects/team-plannings.effects';
import { TopicsEffects } from '../../../../libs/on-call-management/src/lib/features/topics/+state/effects/topics.effects';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router.module';

export function clearState(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    if (action.type === AuthPageActions.signOut.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<unknown>[] = [clearState];

const storeSettings = {
  metaReducers,
  runtimeChecks: {
    strictStateImmutability: false,
    strictActionImmutability: false,
    strictStateSerializability: false,
    strictActionSerializability: false
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    LocalStorageModule.forRoot({ prefix: environment.storage.appPrefix }),
    AppRouterModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}, storeSettings),
    EffectsModule.forRoot([
      SitesEffects,
      AssetsEffects,
      DevicesEffects,
      PoEventsEffects,
      NavigationEffects,
      AssetEventsEffects,
      AssetEventsByAssetEffects,
      AssetEventsByAssetLogsEffects,
      AssetEventsBySiteEffects,
      AssetEventsBySiteLogsEffects,
      DeviceEventsEffects,
      DeviceEventsByDeviceEffects,
      DeviceEventsByDeviceLogsEffects,
      DeviceEventsBySiteEffects,
      DeviceEventsBySiteLogsEffects,
      TopicsEffects,
      CalendarsEffects,
      TeamPlanningsEffects,
      DeviceConnectorsEffects,
      EscalationProtocolsEffects
    ]),
    SharedModule,
    Iot4bosUiModule,
    I18nModule.forRoot({ langs: ['fr', 'en', 'de', 'es', 'it'] }),
    I18nModule.getInstance(),
    AuthModule.forRoot({ appName: AppName.IOT4BOS }),
    NotificationModule.forRoot({ disableErrorMessagesAfterLogout: true }),
    HttpClientModule,
    AgGridModule,
    FullCalendarModule,
    ApiInterceptorModule.forRoot({ includeAppVersionInHeader: true })
  ],
  providers: [
    provideStore(
      {
        [DEFAULT_ROUTER_FEATURENAME]: routerReducer
      },
      storeSettings
    ),
    provideStoreDevtools({ maxAge: 30, logOnly: environment.production }),
    provideRouterStore({ serializer: FullRouterStateSerializer }),
    provideDefaultProvidersToken({ environment }),
    provideCoreTrackingProviders(trackingSettings, TrackingSettingsProviderService),
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
