import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { IotPlatformPipesModule, ValueUnitFormatPipeModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { IconModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { VariableChartModule } from '../../../../../../shared/src/lib/variable-chart/variable-chart.module';
import { LastValueWidgetComponent } from './components/last-value-widget/last-value-widget.component';
import { TimeseriesWidgetComponent } from './components/timeseries-widget/timeseries-widget.component';
import { WidgetItemComponent } from './components/widget-item/widget-item.component';
import { WidgetsContainerComponent } from './components/widgets-container/widgets-container.component';
import { WidgetLoaderDirective } from './directives/widget-loader.directive';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    FlexLayoutModule,
    IconModule,
    MatCardModule,
    IotPlatformPipesModule,
    HighchartsChartModule,
    VariableChartModule,
    ValueUnitFormatPipeModule,
    CdkDropList,
    CdkDrag,
    CdkDropListGroup,
    CdkDragHandle
  ],
  declarations: [WidgetLoaderDirective, TimeseriesWidgetComponent, LastValueWidgetComponent, WidgetsContainerComponent, WidgetItemComponent],
  exports: [WidgetsContainerComponent, TimeseriesWidgetComponent, LastValueWidgetComponent]
})
export class WidgetsModule {}
