import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile } from '@iot-platform/models/common';
import * as moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  appDateFormat: string = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appDateFormats?.momentFullFormat;
  defaultFormat = 'yyyy-MM-DD HH:mm:ss';
  businessProfile: BusinessProfile = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY));
  private defaultTz = 'utc';

  constructor(
    @Inject(LOCALE_ID) private readonly localee: string,
    private readonly storage: LocalStorageService
  ) {
    super(localee);
  }

  override transform(value?: any, format?: string, timezoneOffset?: string): any {
    // eslint-disable-next-line no-underscore-dangle
    const _format: string = format && !!format.toString().trim().length ? format : this.appDateFormat ?? this.defaultFormat;
    if (!!value) {
      if (!!timezoneOffset) {
        return moment(value).utcOffset(timezoneOffset).format(_format);
      } else if (this.businessProfile?.timezoneDetails?.name) {
        return moment.tz(value, this.businessProfile.timezoneDetails.name).format(_format);
      } else if (this.businessProfile?.timezone) {
        return moment(value).utcOffset(this.businessProfile.timezone).format(_format);
      } else {
        return moment.tz(value, this.defaultTz).format(_format);
      }
    } else {
      return null;
    }
  }
}
