<div class='organization-detail'>
  <span *ngIf="!organization" class="regular-background no-profile" fxFlexFill fxLayoutAlign="center center">{{
    'ADMIN.ORGANIZATIONS.DETAIL.SELECT_AN_ORGANIZATION' | translate | uppercase
      }}</span>

    <div *ngIf="organization" class="regular-background full-height p-20" fxFlexFill fxLayout="column">
        <mat-toolbar color="accent" fxLayoutAlign="space-between center" style="flex-shrink: 0;">
            <h2 fxFlex fxLayout="row" fxLayoutAlign="start center">
                {{ organization?.name }}
            </h2>
            <button
                    (click)="onLockUnlockOrganization(!organization?.isLocked)"
                    *ngIf="canUpdateOrganization && (organization?.isLocked || isTopLevelAdmin)"
                    [disabled]="!isTopLevelAdmin"
                    [matTooltip]="(organization?.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate"
                    class="regular-round-button organization-detail__lock-btn"
                    mat-icon-button
                    matTooltipClass="regular-tooltip"
                    matTooltipPosition="below"
            >
                <mat-icon>{{ organization?.isLocked ? 'lock_open_outline' : 'lock_outline'}}</mat-icon>
            </button>
            <ng-container *ngIf='!organization?.isLocked || isTopLevelAdmin'>
                <button
                        (click)="editOrganizationName(organization)"
                        *ngIf="canUpdateOrganization"
                        [disabled]="organization?.isLocked"
                        [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.EDIT_ORGANIZATION_NAME' | translate"
                        class="regular-round-button organization-detail__edit-btn"
                        mat-icon-button
                        matTooltipClass="regular-tooltip"
                        matTooltipPosition="below"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                        (click)="onDeleteOrganizationButtonClick(organization)"
                        *ngIf="canDeleteOrganization"
                        [disabled]="organization?.isLocked"
                        [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.DELETE_ORGANIZATION' | translate"
                        class="regular-round-button organization-detail__delete-btn"
                        mat-icon-button
                        matTooltipClass="regular-tooltip"
                        matTooltipPosition="below"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-container>
        </mat-toolbar>

        <mat-tab-group color="accent" fxFlex mat-align-tabs="left" mat-stretch-tabs>
            <mat-tab *ngIf="canReadBusinessProfile">
                <ng-template mat-tab-label>
                    {{ 'ADMIN.ORGANIZATIONS.DETAIL.BUSINESS_PROFILES' | translate | uppercase }}
                    ({{ businessProfiles?.length || 0 }})
                    <mat-progress-spinner
                            *ngIf="businessProfilesPendingStatus"
                            [diameter]="14"
                            color="accent"
                            mode="indeterminate"
                            style="margin-left: 8px;"
                    ></mat-progress-spinner>
                    <span *ngIf="!businessProfilesPendingStatus" style="width: 20px;"></span>
                </ng-template>
                <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                    <h2>{{ businessProfiles?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.BUSINESS_PROFILES' | translate | uppercase }}</h2>
                    <button
                            (click)="addBusinessProfile.emit(organization)"
                            *ngIf="canCreateBusinessProfile"
                            [matTooltipClass]="'regular-tooltip'"
                            [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_BUSINESS_PROFILES' | translate"
                            class="regular-round-button"
                            mat-icon-button
                            matTooltipPosition="below"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-toolbar>

                <iot4bos-backoffice-ui-business-profiles-list
                        (deleteBusinessProfile)="onDeleteBusinessProfile($event)"
                        (selectBusinessProfile)="onSelectBusinessProfile($event)"
                        [canDeleteBusinessProfile]="canDeleteBusinessProfile"
                        [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                        [profiles]="businessProfiles"
                >
                </iot4bos-backoffice-ui-business-profiles-list>
            </mat-tab>
            <mat-tab *ngIf="canReadBusinessProfile">
                <ng-template mat-tab-label>
                    {{ 'ADMIN.ORGANIZATIONS.DETAIL.ROLES' | translate | uppercase }} ({{ roles?.length || 0 }})
                    <mat-progress-spinner *ngIf="rolesPendingStatus" [diameter]="14" color="accent"
                                          mode="indeterminate" style="margin-left: 8px;"></mat-progress-spinner>
                    <span *ngIf="!rolesPendingStatus" style="width: 20px;"></span>
                </ng-template>

                <div fxFlex fxLayout="row">
                    <div fxFlex fxLayout="column">
                        <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                            <h2>{{ roles?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.ROLES' | translate | uppercase }}</h2>
                            <button
                                    (click)="addRole.emit(organization)"
                                    *ngIf="isCurrentUserAdmin"
                                    [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ROLES' | translate"
                                    class="regular-round-button"
                                    mat-icon-button
                                    matTooltipClass="regular-tooltip"
                                    matTooltipPosition="below"
                            >
                                <!--<iot-platform-ui-icon name="add_role" color="#394C5A"></iot-platform-ui-icon>-->
                                <mat-icon color="#394C5A" svgIcon="add_role"></mat-icon>
                            </button>
                        </mat-toolbar>

                        <div class="organization-detail-role-list" fxLayout="row" fxLayoutAlign="start start">
                            <iot4bos-backoffice-ui-roles-list
                                    (deleteRole)="onDeleteRole($event)"
                                    (editRole)="onAuthorizationsChanged($event)"
                                    (renameRole)="onRenameRole($event)"
                                    (selectRole)="onSelectRole($event)"
                                    [roles]="roles"
                                    [selectedRole]="selectedRole"
                                    [showActionMenu]="isCurrentUserAdmin"
                                    fxFlex="40"
                            >
                            </iot4bos-backoffice-ui-roles-list>
                            <span *ngIf="!selectedRole" class="background no-profile" fxFlex="60">{{
                              'ADMIN.ORGANIZATIONS.DETAIL.SELECT_A_ROLE' | translate | uppercase
                                }}</span>
                            <iot4bos-backoffice-ui-roles-crud
                                    *ngIf="selectedRole"
                                    [adminConceptsFromEntitySession]="adminConceptsFromEntitySession"
                                    [isAdmin]="isCurrentUserAdmin"
                                    [readonly]="true"
                                    [role]="selectedRole"
                                    fxFlex="60"
                            >
                            </iot4bos-backoffice-ui-roles-crud>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="canReadTags">
                <ng-template mat-tab-label>
                    {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAGS' | translate | uppercase }} ({{ tags?.length }})
                    <mat-progress-spinner *ngIf="tagsPendingStatus" [diameter]="14" color="accent"
                                          mode="indeterminate" style="margin-left: 8px;"></mat-progress-spinner>
                    <span *ngIf="!tagsPendingStatus" style="width: 20px;"></span>
                </ng-template>
                <div class="organization-detail-tags-zone" fxFlex style="width: 100%; height: 100%;">
                    <div fxFlex fxLayout="column">
                        <mat-toolbar class="organization-detail-tags-zone__toolbar" color="primary"
                                     fxLayoutAlign="space-between center">
                            <h2>
                                {{ tags?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAG_CATEGORIES' | translate | uppercase }}
                                / {{ getTagsTotal(tags) }}
                                {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAG_LABELS' | translate | uppercase }}
                            </h2>
                        </mat-toolbar>

                        <div class="organization-detail-tags-zone__content">
                            <div *ngFor="let concept of conceptList$ | async" class="organization-detail-tags-list">
                                <iot-platform-ui-tag-category-list-by-concept
                                        (manageTags)="onManageTags($event)"
                                        [canCreate]="false"
                                        [canManageTags]="canCreateTags && canUpdateTags && canDeleteTags"
                                        [concept]="concept"
                                        [labelOnly]="true"
                                        [tagCategoriesByConcept]="getTagCategoriesByConcept(concept)"
                                ></iot-platform-ui-tag-category-list-by-concept>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="isCurrentUserAdmin">
                <ng-template mat-tab-label>
        <span fxLayoutAlign="start center"
        >{{ 'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS_AND_CONCEPTS' | translate | uppercase }}&nbsp;
          <mat-icon
                  [matTooltipPosition]="'below'"
                  [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS_AND_CONCEPTS_TOOLTIP' | translate"
                  matTooltipClass="regular-tooltip"
          >warning</mat-icon
          >
        </span>
                    <mat-progress-spinner
                            *ngIf="administratorsPendingStatus"
                            [diameter]="14"
                            color="accent"
                            mode="indeterminate"
                            style="margin-left: 8px;"
                    ></mat-progress-spinner>
                    <span *ngIf="!administratorsPendingStatus" style="width: 20px;"></span>
                </ng-template>

                <div fxLayout="row" fxLayoutGap="10px">
                    <section fxFlex="50%" fxLayout="column">
                        <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                            <h2>{{ administrators?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS' | translate | uppercase }}</h2>
                            <button
                                    (click)="addAdministratorToOrganization.emit(true)"
                                    [disabled]="organization.isLocked"
                                    [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.MANAGE_ADMINISTRATORS' | translate"
                                    class="regular-round-button"
                                    mat-icon-button
                                    matTooltipClass="regular-tooltip"
                                    matTooltipPosition="below"
                            >
                                <!--<iot-platform-ui-icon name="edit_admin" color="#394C5A"></iot-platform-ui-icon>-->
                                <mat-icon>person_add_outlined</mat-icon>
                            </button>
                        </mat-toolbar>
                        <iot4bos-backoffice-ui-users-list [users]="administrators"></iot4bos-backoffice-ui-users-list>
                    </section>

                    <section fxFlex="50%" fxLayout="column">
                        <mat-toolbar color="primary" fxLayoutAlign="space-between center">
                            <h2>{{ organization?.adminConcepts?.length || 0 }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.CONCEPTS' | translate | uppercase }}</h2>
                            <button
                                    (click)="manageConcepts.emit({ organization: organization, adminConceptsFromEntitySession: adminConceptsFromEntitySession })"
                                    [disabled]="organization.isLocked"
                                    [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.MANAGE_CONCEPTS' | translate"
                                    class="regular-round-button"
                                    mat-icon-button
                                    matTooltipClass="regular-tooltip"
                                    matTooltipPosition="below"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-toolbar>
                        <iot4bos-backoffice-ui-organization-concepts-selection
                                [adminConceptsFromEntitySession]="adminConceptsFromEntitySession"
                                [organization]="organization"
                                [readonly]="true"
                        ></iot4bos-backoffice-ui-organization-concepts-selection>
                    </section>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
