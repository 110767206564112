import { NgClass, NgComponentOutlet, NgStyle, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, OnDestroy, Output, Signal, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverviewCard, OverviewCardActions, OverViewCardSection } from '@iot-platform/models/common';
import { DateFormatModule, InfoDisplayModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { OverviewCardStore } from './overview-card.store';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    InfoDisplayModule,
    DateFormatModule,
    DetailPopupModule,
    MatButtonModule,
    CardLoaderModule,
    MatExpansionModule,
    MatTooltipModule,
    NgTemplateOutlet,
    UpperCasePipe,
    NgStyle,
    NgClass,
    NgComponentOutlet,
    MatDividerModule
  ],
  selector: 'iot-platform-ui-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewCardComponent implements OnDestroy {
  currentCard: WritableSignal<OverviewCard | null> = signal(null);

  @Input() loading = false;
  @Output() cardButtonClicked: EventEmitter<OverviewCardActions> = new EventEmitter<OverviewCardActions>();

  canDisplayCTA: Signal<boolean>;

  protected overviewCardStore: OverviewCardStore = inject(OverviewCardStore);

  constructor() {
    this.canDisplayCTA = computed(() => {
      const currentCard = this.currentCard();
      const visibleActions = get(currentCard, 'actions', [])?.filter(
        (action) => (action?.visible !== undefined && action.visible) || action?.visible === undefined
      );
      return visibleActions.length > 0;
    });
  }

  @Input() set overviewCard(value: OverviewCard) {
    this.currentCard.set(value);
  }

  onImgError(): void {
    this.currentCard.update(
      (card) =>
        ({
          ...card,
          image: null
        }) as OverviewCard
    );
  }

  onSectionExpandChange(section: OverViewCardSection, index: number, expanded: boolean): void {
    this.overviewCardStore.expandChange({
      key: this.currentCard()?.expansionPanelsOptions?.key as string,
      entityId: this.currentCard()?.expansionPanelsOptions?.entityId as string,
      section,
      index,
      expanded
    });
    section?.expandedChange?.(index, expanded);
  }

  getSectionExpandedByIndex = (section: OverViewCardSection, index: number) =>
    this.overviewCardStore.getPanelExpandedByIndex(
      this.currentCard()?.expansionPanelsOptions?.key as string,
      this.currentCard()?.expansionPanelsOptions?.entityId as string,
      section,
      index,
      section?.expanded
    );

  ngOnDestroy() {
    if (this.currentCard()?.expansionPanelsOptions?.clearOnDestroy) {
      this.overviewCardStore.clear(this.currentCard()?.expansionPanelsOptions?.key as string);
    }
  }
}
