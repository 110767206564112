import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { FilterEngineModule } from '@iot-platform/iot-platform-ui';
import { NumberFormatPipeModule } from '@iot-platform/pipes';
import { CommentsPanelComponent } from '@iot-platform/shared/iot4bos-ui';
import { TranslateModule } from '@ngx-translate/core';
import { EventsByConceptGridComponent } from './events-by-concept-grid.component';

@NgModule({
  declarations: [EventsByConceptGridComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatSidenavModule,
    GridEngineModule,
    FilterEngineModule,
    FilterEngineModule,
    TranslateModule,
    NumberFormatPipeModule,
    CommentsPanelComponent
  ],
  exports: [EventsByConceptGridComponent]
})
export class EventsByConceptGridModule {}
