import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DynamicDataService } from '@iot-platform/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import { DynamicDataResponse, Environment } from '@iot-platform/models/common';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IotGeoJsonFeature, IotGeoJsonFeatureCollection, IotGeoJsonRouteFeature } from '../models/iot-geo-json-object.model';
import { IotMapRequest } from '../models/iot-map-request.model';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly httpClient: HttpClient,
    private readonly dynamicDataService: DynamicDataService
  ) {}

  loadGeoLocations(request: IotMapRequest): Observable<IotGeoJsonFeature[]> {
    let params: HttpParams = ApiHelpers.getHttpParams(request);
    params = params.set('displayMode', request.displayMode);
    return this.httpClient
      .get<IotGeoJsonFeatureCollection>(`${this.environment.api.url}${this.environment.api.endpoints.worldMap}/${request.concept}`, { params })
      .pipe(map((response: IotGeoJsonFeatureCollection) => response.features as IotGeoJsonFeature[]));
  }

  getFieldId(concept: string): string {
    if (concept === 'devices') {
      return 'deviceId';
    } else if (concept === 'assets') {
      return 'assetId';
    }
    return 'siteId';
  }

  loadMarkerDetails(concept: string, feature: IotGeoJsonFeature): Observable<DynamicDataResponse> {
    const fieldId: string = this.getFieldId(concept);
    return this.dynamicDataService.getDynamicData(concept, {
      useCache: true,
      cacheFieldId: fieldId,
      pathParams: [fieldId],
      rawData: { [fieldId]: get(feature, 'properties.id', '') }
    });
  }

  loadRoute(assetId: string, limit: number, start: Date, end: Date, minutesBetweenSegments: number): Observable<IotGeoJsonRouteFeature[]> {
    return this.httpClient
      .get<IotGeoJsonFeatureCollection>(
        `${this.environment.api.url}${
          this.environment.api.endpoints['assets']
        }/${assetId}/geojson?limit=${limit}&minutesBetweenSegments=${minutesBetweenSegments}&start=${start.toISOString()}&end=${end.toISOString()}&format=segments`,
        {}
      )
      .pipe(
        map(
          (response: any) =>
            /* const returned = [
            {
              type: 'Feature',
              properties: {
                start: response.features[0].properties.datetime,
                end: response.features[response.features.length - 1].properties.datetime
              },
              geometry: {
                type: 'LineString',
                coordinates: response.features.map((feature: IotGeoJsonFeature) => {
                  return [feature.geometry.coordinates[0], feature.geometry.coordinates[1]];
                })
              }
            }
          ];
          return [...returned, ...response.features];*/
            response.features
        )
      );
  }
}
