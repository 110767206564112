<div class="device-card">
  <div class="device-card-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="device-card-col" fxFlex="360px" fxFlex.lt-md="100%"><img [src]="imgPath" alt="" class="device-card-img" /></div>
    <div class="device-card-col" fxFlex="100%">
      <div class="device-card-header" fxLayout="row" fxLayoutAlign="start end">
        <span class="device-card-name">{{ device?.name | infoDisplay }}</span>
        <span class="device-card-type">{{ device?.type | infoDisplay: 'hyphen' : 'family' : 'model' : 'firmware' }}</span>
        @if (getDisplayCTA()) {
          <div class="device-card-buttons">
            <button [matMenuTriggerFor]="menu" class="regular-round-button" mat-icon-button><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu">
              @if (getDisplayActivateButton()) {
                <button (click)="onActivate()" mat-menu-item>
                  {{ 'DEVICES.CARD.ACTIVATE' | translate | uppercase }}
                </button>
              }
              @if (getDisplayEditButton()) {
                <button (click)="onEdit()" mat-menu-item>
                  {{ 'DEVICES.CARD.EDIT' | translate | uppercase }}
                </button>
              }
              @if (getDisplayMoveToButton()) {
                <button (click)="onMoveTo()" mat-menu-item>{{ 'DEVICES.CARD.MOVE_TO' | translate | uppercase }}</button>
              }
              @if (getDisplayResetButton()) {
                <button (click)="reset.emit(device)" [disabled]="isResetDisabled" mat-menu-item>
                  {{ 'DEVICES.CARD.RESET' | translate | uppercase }}
                </button>
              }
              @if (getDisplayDecommissionButton()) {
                <button (click)="decommissionDevice.emit()" mat-menu-item>
                  {{ 'DEVICES.CARD.DECOMMISSION' | translate | uppercase }}
                </button>
              }
              @if (getDisplayDeleteButton()) {
                <button (click)="deleteDevice.emit(device)" [disabled]="isDeleteDisabled()" mat-menu-item>
                  {{ 'DEVICES.CARD.DELETE' | translate | uppercase }}
                </button>
              }
              @if (getDisplayConfigureButton()) {
                <hr class="action-separation" />
                <button (click)="configure.emit(device)" mat-menu-item>
                  {{ 'DEVICES.CARD.CONFIGURE' | translate | uppercase }}
                </button>
              }
              @if (getDisplayStartMaintenanceButton() || getDisplayEditOrEndMaintenanceButton()) {
                <hr class="action-separation" />
                @if (getDisplayStartMaintenanceButton()) {
                  <button (click)="startMaintenance.emit()" mat-menu-item>
                    {{ 'DEVICES.CARD.START_MAINTENANCE' | translate | uppercase }}
                  </button>
                }
                @if (getDisplayEditOrEndMaintenanceButton()) {
                  <button (click)="editMaintenance.emit()" mat-menu-item>
                    {{ 'DEVICES.CARD.EDIT_MAINTENANCE' | translate | uppercase }}
                  </button>
                  <button (click)="endMaintenance.emit()" mat-menu-item>
                    {{ 'DEVICES.CARD.END_MAINTENANCE' | translate | uppercase }}
                  </button>
                }
              }
              @if (getDisplayCommandButtons()) {
                <hr class="action-separation" />
                @for (command of commandList; track command) {
                  <div [ngClass]="{ 'device-card-button-tooltip-wrap': isCommandDisabled }" fxLayoutAlign="start center" fxLayoutGap="6px">
                    <button (click)="commandExec.emit(CommandType[command])" [disabled]="isCommandDisabled" mat-menu-item>
                      {{ 'DEVICES.CARD.' + command | translate | uppercase }}
                    </button>
                    @if (isCommandDisabled) {
                      <mat-icon [matTooltip]="'DEVICES.CARD.ACTION_INFORMATION_TOOLTIP' | translate" matTooltipClass="regular-tooltip">info_outline</mat-icon>
                    }
                  </div>
                }
              }
              @if (getDisplayHistoryButton()) {
                <hr class="action-separation" />
                <button (click)="onOpenChangeLog()" class="device-card__history-btn" mat-menu-item>
                  {{ 'AUDIT_TRAIL.CTA.HISTORY' | translate | uppercase }}
                </button>
              }
            </mat-menu>
          </div>
        }
      </div>

      <ul class="device-card-list" fxLayout="row wrap">
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.IDENTIFIER' | translate }}<span class="device-card-value">{{ device?.identifier | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.INCOMING_CONNECTOR' | translate }}<span class="device-card-value">{{ device?.incomingConnector?.name | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.ENDPOINT' | translate }}
          @if (device?.communication && device?.communication?.support === 'IP') {
            <a [href]="computedURL" class="device-card-value device-endpoint-link" target="_blank">{{ device.communication.endpoint | infoDisplay }}</a>
          } @else {
            <span class="device-card-value">{{ device?.communication?.endpoint | infoDisplay }}</span>
          }
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.OUTGOING_CONNECTOR' | translate }}<span class="device-card-value">{{ device?.outgoingConnector?.name | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.STATUS' | translate }}
          @if (device.status) {
            <span class="device-card-value"  [ngClass]="{ 'device-card-maintenance': device.status.name === DeviceStatusName.maintenance }">
              {{ 'DEVICES.CARD.STATUS_LIST.' + device?.status.name | infoDisplay | translate }} ({{ device?.status.datetime | dateFormat | infoDisplay }})
            </span>
          }
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.SUPPORT' | translate }}
          @if (device?.communication) {
            <span class="device-card-value">{{ device?.communication?.support | infoDisplay }}</span>
          }
        </li>
        @if (device?.outgoingConnector?.requestConfiguration?.authentication === 'login') {
          <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
            {{ 'DEVICES.CARD.LOGIN' | translate }}<span class="device-card-value">{{ device?.credential?.login | infoDisplay }}</span>
          </li>
          <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
            {{ 'DEVICES.CARD.PASSWORD' | translate }}<span class="device-card-value">{{ device?.credential?.password ? '•••••' : '-' }}</span>
          </li>
        }
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.ICCID' | translate }}
          @if (device?.communication?.operator && device?.communication?.iccid) {
            <span (click)="onIccidClick()" class="device-card-value device-endpoint-link with-cursor">{{ device?.communication?.iccid | infoDisplay }}</span>
          }
          @if (!device?.communication?.operator || !device?.communication?.iccid) {
            <span class="device-card-value">{{ device?.communication?.iccid | infoDisplay }}</span>
          }
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.OPERATOR' | translate }}<span class="device-card-value">{{ device?.communication?.operator | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.AFFILIATE' | translate }}<span class="device-card-value">{{ device?.affiliate | infoDisplay }}</span>
        </li>
      </ul>

      <p [innerHTML]="device?.description | linkify | infoDisplay" class="device-card-description"></p>

      <div class="device-card-footer"></div>
    </div>
  </div>
</div>
