import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssetVariableSelectorComponent } from './asset-variable-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AssetVariableSelectorComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, TranslateModule, MatSelectModule],
  exports: [AssetVariableSelectorComponent]
})
export class AssetVariableSelectorModule {}
