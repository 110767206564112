import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'i18n-translation-edit-form',
  templateUrl: './translation-edit-form.component.html',
  styleUrls: ['./translation-edit-form.component.scss']
})
export class TranslationEditFormComponent implements OnInit {
  public editFrom: UntypedFormGroup;
  public translations: { key: string; required: boolean; value: string }[] = [];

  constructor(public matDialogRef: MatDialogRef<TranslationEditFormComponent>, @Inject(MAT_DIALOG_DATA) public data: { languages: string[]; rowData }) {}

  public ngOnInit(): void {
    this.translations = [
      {
        key: 'key',
        required: true,
        value: this.data.rowData.key
      }
    ];
    this.data.languages.forEach((lang: string) => {
      this.translations.push({
        key: lang,
        required: false,
        value: this.data.rowData[lang].value
      });
    });
    this.initForm();
  }

  public close(): void {
    this.matDialogRef.close();
  }

  public initForm(): void {
    const controls = {};
    this.translations.forEach((item: { key: string; required: boolean; value: string }) => {
      controls[item.key] = new UntypedFormControl(item.value, item.required ? [Validators.required] : []);
    });
    this.editFrom = new UntypedFormGroup(controls);
  }

  public save(): void {
    if (this.editFrom.valid) {
      const newValues = {
        ...this.data.rowData,
        key: this.editFrom.value.key
      };
      this.data.languages.forEach((lang: string) => {
        newValues[lang].value = this.editFrom.value[lang];
      });
      this.matDialogRef.close(newValues);
    }
  }
}
