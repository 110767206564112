import { NgModule } from '@angular/core';
import { CardLoaderModule, ChipModule, DetailPopupModule, EventSeverityDisplayModule, DetailPopupCardsModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { IconModule } from '@iot-platform/shared/components';
import { EventDetailPopupComponent } from './event-detail-popup.component';
import { EventTimelineLogComponent } from './event-timeline-log/event-timeline-log.component';
import { EventTimelineComponent } from './event-timeline/event-timeline.component';
import { EventTopicListComponent } from './event-topics/event-topic-list.component';
import { CommentsPanelComponent } from '@iot-platform/shared/iot4bos-ui';

@NgModule({
  imports: [
    SharedModule,
    DetailPopupModule,
    ChipModule,
    CardLoaderModule,
    IconModule,
    IotPlatformPipesModule,
    DetailPopupCardsModule,
    EventSeverityDisplayModule,
    CommentsPanelComponent
  ],
  declarations: [EventDetailPopupComponent, EventTimelineComponent, EventTimelineLogComponent, EventTopicListComponent]
})
export class EventDetailPopupModule {}
