import { Injectable } from '@angular/core';

import { PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { AssetEvent } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';
import { UserPreferencesService } from '@iot-platform/users';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AssetEventsService } from '../../../../../../shared/src/lib/services/asset-events.service';

import { AssetEventsBySiteDbActions, AssetEventsBySiteUiActions } from '../actions';
import * as fromNavigation from '../reducers';

@Injectable()
export class AssetEventsBySiteEffects {
  loadAssetEventsBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.loadAssetEventsBySite),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getAssetEventsBySitePreviousSiteId)))),
      switchMap(([action, previousSiteId]) => {
        let request: PlatformRequest;
        if (!previousSiteId || previousSiteId === action.request.filters.find((filter) => filter.criteriaKey === 'siteId').value) {
          request = { ...action.request };
        }
        if (previousSiteId !== action.request.filters.find((filter) => filter.criteriaKey === 'siteId')?.value) {
          request = { ...action.request, page: 0 };
        }
        return this.assetEventsService.getAssetEvents(request).pipe(
          map((response: PlatformResponse) =>
            AssetEventsBySiteDbActions.loadAssetEventsBySiteSuccess({
              response,
              siteId: action.request.filters.find((filter) => filter.criteriaKey === 'siteId').value
            })
          ),
          catchError((error) => of(AssetEventsBySiteDbActions.loadAssetEventsBySiteFailure({ error })))
        );
      })
    )
  );

  loadTotalActiveAssetEventsBySite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.loadTotalActiveAssetEventsBySite),
      switchMap((action) =>
        this.assetEventsService.getAssetEvents(action.request).pipe(
          map((response) => AssetEventsBySiteDbActions.loadTotalActiveAssetEventsBySiteSuccess({ totalActiveEvents: response.total })),
          catchError((error) => of(AssetEventsBySiteDbActions.loadTotalActiveAssetEventsBySiteFailure({ error })))
        )
      )
    )
  );

  loadAssetEventsBySiteAndLoadTotalActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.loadAssetEventsBySite),
      map((action) => {
        const newRequest: PlatformRequest = {
          ...action.request,
          limit: 0,
          page: 0,
          filters: [...action.request.filters, { criteriaKey: 'eventStatus', value: 'active' }]
        };
        return AssetEventsBySiteUiActions.loadTotalActiveAssetEventsBySite({ request: newRequest });
      })
    )
  );

  updateStatusByAssetEventIdBySiteThenLoadTotalActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteSuccess),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getAssetEventsBySitePreviousSiteId)))),
      map(([_, previousSiteId]) => {
        const newRequest: PlatformRequest = {
          limit: 0,
          page: 0,
          filters: [
            { criteriaKey: 'eventStatus', value: 'active' },
            { criteriaKey: 'siteId', value: previousSiteId }
          ]
        };
        return AssetEventsBySiteUiActions.loadTotalActiveAssetEventsBySite({ request: newRequest });
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.updateStatusByAssetEventIdBySite),
      concatMap((action) =>
        this.assetEventsService.putStatus(action.status).pipe(
          map((assetEvent: AssetEvent) => AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteSuccess({ assetEvent })),
          catchError((error) => of(AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.bulkUpdateStatusByAssetEventIdBySite),
      concatMap((action) => this.assetEventsService.bulkUpdateStatus(action.assetEventIds, action.status)),
      mergeMap((results) =>
        results.pipe(
          map((assetEvent) => AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteSuccess({ assetEvent })),
          catchError((error) => of(AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.saveTableStateBySite),
      switchMap((action) =>
        this.assetEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: AssetEvent; checked: AssetEvent[] }) =>
            AssetEventsBySiteDbActions.saveTableBySiteStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(AssetEventsBySiteDbActions.saveTableBySiteStateFailure({ error })))
        )
      )
    )
  );

  loadMvAssetEventsBySiteSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteUiActions.loadMvAssetEventsBySiteSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsSuccess({ settings })),
          catchError((error) => of(AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsBySiteDbActions.saveTableBySiteStateFailure,
          AssetEventsBySiteDbActions.loadAssetEventsBySiteFailure,
          AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteFailure,
          AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsBySiteUiActions.loadAssetEventsBySite,
          AssetEventsBySiteUiActions.updateStatusByAssetEventIdBySite,
          AssetEventsBySiteUiActions.loadMvAssetEventsBySiteSettings
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsBySiteDbActions.loadAssetEventsBySiteSuccess,
          AssetEventsBySiteDbActions.loadAssetEventsBySiteFailure,
          AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteSuccess,
          AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteFailure,
          AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsSuccess,
          AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private assetEventsService: AssetEventsService,
    private notificationService: NotificationService,
    private userPrefService: UserPreferencesService,
    private store: Store<fromNavigation.State>
  ) {}
}
