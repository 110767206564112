import { Component, Input } from '@angular/core';

import { TagCategory } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-ui-tag-label-list',
  templateUrl: './tag-label-list.component.html',
  styleUrls: ['./tag-label-list.component.scss']
})
export class TagLabelListComponent {
  @Input() tagCategory: TagCategory;
  @Input() labelOnly: boolean;
}
