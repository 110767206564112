<section class="device-info-toolbar" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
    <div *ngIf="displayLoader" fxLayoutAlign="start center" style="width: 100px;">
        <mat-spinner [diameter]="20" color="accent"></mat-spinner>
    </div>
    <p *ngIf="!displayLoader" style="font-size: 14px; min-width: 100px;">{{ totalFiltered }} / {{ total }}</p>

    <div fxFlex fxLayoutAlign="space-around center">
        <form (ngSubmit)="apply()" [formGroup]="toolbarForm" fxFlex fxLayoutAlign="space-around center">
            <div fxLayoutAlign="space-around center" style="padding: 4px;">
                <mat-form-field *ngIf="withNameFilter" class="search" color="accent"
                                style="width: 120px; margin-left: 8px; margin-right: 8px;">
                    <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
                    <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_NAME' | translate }}</mat-label>
                    <input (change)="onFilterNameValueChange($event)" (keyup.enter)="onFilterNameValueChange($event)"
                           formControlName="name" matInput
                           type="text"/>
                </mat-form-field>

                <mat-form-field *ngIf="withDescriptionFilter" class="search" color="accent"
                                style="width: 120px; margin-left: 8px; margin-right: 8px;">
                    <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
                    <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_DESCRIPTION' | translate }}</mat-label>
                    <input (change)="onFilterDescriptionValueChange($event)" formControlName="description" matInput
                           type="text"/>
                </mat-form-field>

                <mat-form-field *ngIf="withGroupFilter" color="accent"
                                style="width: 120px; margin-left: 8px; margin-right: 8px;">
                    <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_GROUP' | translate }}</mat-label>
                    <mat-select
                            (selectionChange)="onGroupSelectionChange($event)"
                            [placeholder]=""
                            formControlName="group"
                    >
                        <mat-option *ngFor="let group of deviceVariableGroups"
                                    [value]="group.key">{{ group.key | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="withUnitFilter" color="accent"
                                style="width: 120px; margin-left: 8px; margin-right: 8px;">
                    <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_UNIT' | translate }}</mat-label>
                    <mat-select
                            (selectionChange)="onUnitSelectionChange($event)"
                            [placeholder]=""
                            formControlName="unit"
                    >
                        <mat-option *ngFor="let unit of variableUnits" [value]="unit">{{ unit }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="withFileFilter" color="accent"
                                style="width: 120px; margin-left: 8px; margin-right: 8px;">
                    <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_FILE' | translate }}</mat-label>
                    <mat-select
                            (selectionChange)="onFileSelectionChange($event)"
                            [placeholder]=""
                            formControlName="file"
                    >
                        <mat-option *ngFor="let file of deviceVariableFiles"
                                    [value]="file.key">{{ file.key | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="withLinkedFilter" color="accent"
                                style="width: 120px; margin-left: 8px; margin-right: 8px;">
                    <mat-label>{{ 'linked' }}</mat-label>
                    <mat-select (selectionChange)="onLinkedSelectionChange($event)" [placeholder]=""
                                formControlName="linked">
                        <mat-option *ngFor="let link of ['all', false, true]" [value]="link">{{ link }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        <button (click)="clear()" color="accent" mat-button>{{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}</button>
    </div>

    <!--<button mat-button (click)="onManageColumns()">C</button>-->
</section>
