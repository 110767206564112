import { TagCategory } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

export abstract class AbstractTagEditorService {
  abstract getCategories$(): Observable<TagCategory[]>;

  abstract getListTagsByOrganizationForEdition(organizationId: string, withParents: boolean, concept: string): void;

  abstract addTagToOrganization(tagCategory: TagCategory): void;

  abstract updateTagInOrganization(tagCategory: TagCategory): void;

  abstract removeTagFromOrganization(tagCategory: TagCategory): void;
}
