import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Site } from '@iot-platform/models/i4b';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-detail-site-card',
  templateUrl: './detail-site-card.component.html',
  styleUrls: ['./detail-site-card.component.scss']
})
export class DetailSiteCardComponent {
  @Input() site: Site;
  @Input() extraFill = false;

  @Output() selectSite: EventEmitter<Site> = new EventEmitter<Site>();
}
