<div [style.height]="chat.options.height?.()" [style.width]="chat.options.width?.()" class="chat-container"
     data-cy="iot-platform-ui-chat" fxLayout="column">
  @if (chat.header) {
    <iot-platform-ui-chat-header [header]="chat.header"
                                 (dispatchAction)="dispatchAction.emit($event)"></iot-platform-ui-chat-header>
  }
  <iot-platform-ui-chat-body (dispatchAction)="dispatchAction.emit($event)" [body]="chat.body"
                             fxFlex></iot-platform-ui-chat-body>
  @if (chat.footer) {
    <iot-platform-ui-chat-footer [footer]="chat.footer"
                                 (dispatchAction)="dispatchAction.emit($event)"></iot-platform-ui-chat-footer>
  }
</div>
