<div
  [style.margin-top]="margin"
  class="iot-platform-ui-detail-popup-display-property"
  data-cy="iot-platform-ui-detail-popup-display-property"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="stretch center"
  fxLayoutGap="6px"
>
  <span
    class="capitalize-first-letter iot-platform-ui-detail-popup-display-property-label"
    data-cy="iot-platform-ui-detail-popup-display-property-label"
    fxFlexAlign="start"
    >{{ property }}</span
  >
  @if(!loading) {
  <span
    [matTooltip]="'' + value"
    class="fw-600 iot-platform-ui-detail-popup-display-property-value"
    data-cy="iot-platform-ui-detail-popup-display-property-value"
    fxFlexAlign="center"
    matTooltipClass="regular-tooltip"
    >{{ value }}</span
  >
  } @else {
  <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate"></mat-progress-spinner>
  }
  <ng-content></ng-content>
</div>
