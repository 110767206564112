import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DateFormatModule, LinkifyPipeModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-chatbot-source-comment',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, DateFormatModule, MatIconModule, TranslateModule, LinkifyPipeModule, MatButtonModule],
  templateUrl: './chatbot-source-comment.component.html',
  styleUrl: './chatbot-source-comment.component.scss'
})
export class ChatbotSourceCommentComponent {
  @Input() source;
}
