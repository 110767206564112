import { EnvironmentProviders, makeEnvironmentProviders, Type } from '@angular/core';
import { of } from 'rxjs';
import { AbstractProductExportsService } from './abstract-product-exports.service';

import { ExportsService } from './exports.service';

export { ExportsComponent } from './exports.component';
export { AbstractProductExportsService };

export function provideExportsSettings(settings: { productExportsService: Type<AbstractProductExportsService> }): EnvironmentProviders {
  return makeEnvironmentProviders([ExportsService, { provide: AbstractProductExportsService, useClass: settings.productExportsService }]);
}

export function provideExportsTestingSettings(): EnvironmentProviders {
  return makeEnvironmentProviders([
    ExportsService,
    {
      provide: AbstractProductExportsService,
      useValue: {
        getProducts$: () => of({} as NonNullable<never>)
      }
    }
  ]);
}
