import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';
import { FeatureAppShellSettings } from '../models/app-shell-settings.model';
import { AbstractAppShellService } from '../services/abstract-app-shell.service';

export const FEATURE_APP_SHELL_SETTINGS = new InjectionToken<FeatureAppShellSettings>('FEATURE_APP_SHELL_SETTINGS');

export function provideFeatureAppShellSettings(settings: FeatureAppShellSettings & { appShellService: Type<AbstractAppShellService> }): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: AbstractAppShellService,
      useClass: settings.appShellService
    },
    {
      provide: FEATURE_APP_SHELL_SETTINGS,
      useValue: {
        allowChatBot: settings.allowChatBot,
        allowRedirectToMyProfile: settings.allowRedirectToMyProfile,
        allowSwitchBusinessProfile: settings.allowSwitchBusinessProfile,
        logo: settings.logo
      }
    }
  ]);
}
