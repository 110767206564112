<!-- BODY HEADER SECTION -->
<div class="iot-platform-ui-chat-body" data-cy="iot-platform-ui-chat-body" fxFlex fxLayout="column">
  <section class="iot-platform-ui-chat-body__header">
    @if (currentBody()?.header?.ref) {
      @if (currentBody()?.header?.inputs) {
        <ng-container *ngComponentOutlet="currentBody()?.header?.ref; inputs: currentBody()?.header?.inputs" />
      } @else {
        <ng-container [ngTemplateOutlet]="currentBody()?.header?.ref" />
      }
    }
  </section>
  <!-- BODY CONTENT SECTION -->
  <section class="iot-platform-ui-chat-body__content" fxFlex>
    @if (currentBody()?.content?.ref) {
      @if (currentBody()?.content?.inputs) {
        <ng-container *ngComponentOutlet="currentBody()?.content?.ref; inputs: currentBody?.()?.content?.inputs" />
      } @else {
        <ng-container [ngTemplateOutlet]="currentBody()?.content?.ref" />
      }
    } @else {
      @if (currentBody()?.content?.loading?.() && !currentBody()?.content?.conversationLoader()) {
        <iot-platform-ui-card-loader fxLayoutAlign="center center" fxFlex></iot-platform-ui-card-loader>
      } @else {
        @if (!!currentBody()?.content?.messages?.()?.length) {
          <div #chatBody class="iot-platform-ui-chat-body__content_messages" fxFlex>
            <div fxLayout="column" fxLayoutGap="14px">
              @for (message of currentBody()?.content?.messages?.(); track message) {
                @if (message?.ref) {
                  @if (message?.inputs) {
                    <ng-container *ngComponentOutlet="message?.ref; inputs: message?.inputs" />
                  } @else {
                    <ng-container [ngTemplateOutlet]="message?.ref" />
                  }
                } @else {
                  <iot-platform-ui-chat-message [message]="message" (dispatchAction)="dispatchAction.emit($event)"></iot-platform-ui-chat-message>
                }
              }
              @if (currentBody()?.content?.loading?.() && currentBody()?.content?.conversationLoader()) {
               <iot-platform-ui-chat-conversation-loader></iot-platform-ui-chat-conversation-loader>
              }
            </div>
          </div>
        } @else if (currentBody()?.content?.emptyBodyLabel?.()) {
          <div class="iot-platform-ui-chat-body__content_no_messages" fxLayoutAlign="center center" fxFlex fxLayout="column">
            {{ currentBody()?.content?.emptyBodyLabel?.() | translate }}
          </div>
        }
      }
    }
  </section>
</div>
