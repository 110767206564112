import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ChatEvent, ChatFooter } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [MatIconModule, FlexLayoutModule, TranslateModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatButtonModule],
  selector: 'iot-platform-ui-chat-footer',
  templateUrl: './chat-footer.component.html',
  styleUrls: ['./chat-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFooterComponent {
  @Input() footer!: ChatFooter;

  @Output() dispatchAction: EventEmitter<ChatEvent> = new EventEmitter<ChatEvent>();

  form: UntypedFormGroup = new UntypedFormGroup({
    message: new UntypedFormControl(null, [Validators.required, Validators.maxLength(300)])
  });

  get message(): AbstractControl {
    return this.form.get('message') as AbstractControl;
  }
}
