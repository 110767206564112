<iot-platform-ui-toolbar-v2
  (dispatchToolbarEvent)="onToolbarEvent($event)"
  [buttonList]="masterViewPoEventsButtonList"
  [isDataLoaded]="poEventRulesLoaded$ | async"
  [name]="'PO_EVENTS.NAME_TOOLBAR' | translate"
  [total]="(pagination$ | async)?.total"
  [size]="toolbarSize"
>
</iot-platform-ui-toolbar-v2>

<iot-platform-ui-filter-engine
  [masterView]="'po-event-rules'"
  [expanded]="filterEngineOpened"
  [displayActionButtons]="true"
  [withFavoriteFilters]="true"
  [currentFavoriteView]="currentFavoriteView$ | async"
  [currentFilters]="currentFilters$ | async"
  (applyFilters)="applyFilters($event)"
>
</iot-platform-ui-filter-engine>

<div class="layout-master-view-engine-container">
  <grid-engine-grid-engine-component
    (dispatchMasterViewEngineEvent)="onEventDispatched($event)"
    (pageChange)="onPageChange($event)"
    [gridData]="poEventRules$ | async"
    [gridMeta]="mvSettings$ | async"
    [userPermissions]="userPermissions"
    class="layout-ag-grid-shell"
    fxFlex
  >
  </grid-engine-grid-engine-component>
</div>
