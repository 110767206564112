import { ColDef, GridApi, GridOptions, GridReadyEvent, IRowNode, RowClickedEvent } from '@ag-grid-community/core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationDictionary } from '../../models/translation-dictionary.model';

@Component({
  selector: 'i18n-translation-table-view',
  templateUrl: './translation-table-view.component.html',
  styleUrls: ['./translation-table-view.component.scss']
})
export class TranslationTableViewComponent implements OnChanges, OnInit {
  @Input() data: TranslationDictionary[] = [];
  @Input() editMode: boolean;
  @Input() languages: string[] = [];

  @Output() gridReady: EventEmitter<{ gridApi: GridApi }> = new EventEmitter<{ gridApi: GridApi }>();
  @Output() rowClicked: EventEmitter<RowClickedEvent> = new EventEmitter<RowClickedEvent>();
  @Output() selectionChanged: EventEmitter<IRowNode[]> = new EventEmitter<IRowNode[]>();

  gridOptions: GridOptions;
  columnDefs: ColDef[];
  components;

  gridApi: GridApi;
  keyFieldId = 'key';

  constructor() {
    this.gridOptions = {
      headerHeight: 30,
      defaultColDef: {
        cellStyle: { height: '100%', display: 'flex ', 'align-items': 'center ' },
        editable: false,
        sortable: true,
        flex: 1,
        filter: true,
        resizable: true
      },
      tooltipShowDelay: 0,
      pagination: false,
      rowHeight: 40,
      rowSelection: 'multiple',
      // restrict row selections via checkbox selection
      suppressRowClickSelection: true,
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      onRowClicked: (event: RowClickedEvent) => this.onRowClicked(event),
      onSelectionChanged: () => this.onSelectionChanged()
    };

    this.components = {};
  }

  public ngOnInit(): void {
    this.initColumnDefs();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.editMode && changes.editMode.previousValue !== null) {
      this.handleEditMode();
    }
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridReady.emit({
      gridApi: params.api
    });
  }

  public initColumnDefs(): void {
    const columnDefs: ColDef[] = [
      {
        colId: this.keyFieldId,
        headerName: this.keyFieldId.toUpperCase(),
        field: this.keyFieldId,
        tooltipField: this.keyFieldId,
        checkboxSelection: false
      }
    ];
    this.languages.forEach((lang: string) => {
      columnDefs.push({
        colId: lang,
        headerName: lang.toUpperCase(),
        field: `${lang}.value`,
        tooltipField: `${lang}.value`,
        cellClass: (params) => (params && params.data && params.data[lang] && params.data[lang].missing ? 'bg-red' : '')
      });
    });
    this.columnDefs = columnDefs;
  }

  public handleEditMode(): void {
    if (this.gridApi) {
      // Toggle checkbox selection
      const columnDefs: ColDef[] = [...this.gridApi.getColumnDefs()];
      columnDefs[0].checkboxSelection = this.editMode;
      this.gridApi.updateGridOptions({ columnDefs });

      // Deselect all rows when read only mode
      if (!this.editMode) {
        this.gridApi.stopEditing();
        this.gridApi.deselectAll();
      }

      // Refresh key cells
      const params = {
        force: true,
        suppressFlash: false,
        columns: [this.keyFieldId]
      };
      this.gridApi.refreshCells(params);
    }
  }

  public onRowClicked(event): void {
    this.rowClicked.emit(event);
  }

  public onSelectionChanged(): void {
    this.selectionChanged.emit(this.gridApi.getSelectedNodes());
  }
}
