<mat-card class="dialog-card" fxLayout="column">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ title$ | async }}</h1>
            <button (click)="closeOnCancel()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content" fxLayout="column">
        <div class="p-20">
            <section [formGroup]="manageTagForm" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px">
                <mat-form-field class="concept-select" color="accent">
                    <mat-label>{{ 'MANAGE_TAGS_FORM.CONCEPT_PLACEHOLDER' | translate }}</mat-label>
                    <mat-select [placeholder]=""
                                formControlName="concept">
                        <mat-select-trigger fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <!--<iot-platform-ui-icon [name]="concept.value.toLowerCase()" [color]="'#394C5A'" [ngClass]="['concept-icon', 'concept-icon' + concept.value.toLowerCase()]"></iot-platform-ui-icon>-->
                            <mat-icon [color]="'#394C5A'"
                                      [ngClass]="['concept-icon', 'concept-icon' + concept.value.toLowerCase()]"
                                      [svgIcon]="concept.value.toLowerCase()"></mat-icon>
                            <span class="concept-name">{{ concept.value }}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let concept of data.concepts" [value]="concept">
              <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <!--<iot-platform-ui-icon [name]="concept" [color]="'#394C5A'" [ngClass]="['concept-icon', 'concept-icon' + concept]"></iot-platform-ui-icon>-->
                <mat-icon [color]="'#394C5A'" [svgIcon]="concept"></mat-icon>
                <span class="concept-name">{{ concept | uppercase }}</span>
              </span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-progress-spinner *ngIf="!areTagsLoaded" [diameter]="20" color="accent"
                                      mode="indeterminate"></mat-progress-spinner>
            </section>

            <section class="tag-lists">
                <mat-accordion [multi]="true" class="tag-list-accordion">
                    <mat-expansion-panel
                            *ngFor="let tagsByEntity of allTags[concept.value.toUpperCase()]"
                            [disabled]="
              (!tagsByEntity.tagCategories?.length && !data.editable) ||
              (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
            "
                            [hideToggle]="
              (!tagsByEntity.tagCategories?.length && !data.editable) ||
              (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
            "
                    >
                        <mat-expansion-panel-header class="entity-header">
                            <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                <span
                        [ngClass]="{
                    'no-tag':
                      (!tagsByEntity.tagCategories?.length && !data.editable) ||
                      (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
                  }"
                        class="entity-name-selected"
                >
                  <span class="entity-name">{{ tagsByEntity.entityName }}</span>
                  <span *ngIf="getSelectedTagsByEntityTotal(tagsByEntity.tagCategories) !== 0"
                        class="entity-tag-selected">
                    {{ getSelectedTagsByEntityTotal(tagsByEntity.tagCategories) }}{{ 'MANAGE_TAGS_FORM.TAG_SELECTED' | translate }}
                  </span>
                </span>
                                <div class="panel-header-line" fxFlex></div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngFor="let tagCategory of tagsByEntity.tagCategories" class="tag-by-entity-container"
                             fxLayout="row" fxLayoutAlign="start start">
                            <div
                                    *ngIf="(tagCategory?.entityId === data.currentEntityId && !data.editable) || tagCategory?.entityId !== data.currentEntityId"
                                    class="tag-by-entity"
                                    fxLayout="row"
                            >
                                <div class="tag-category-color-name" fxLayout="row" fxLayoutAlign="start center"
                                     fxLayoutGap="12px">
                                    <div [ngStyle]="{ backgroundColor: tagCategory?.color }"
                                         class="tag-category-color"></div>
                                    <span class="tag-category-name capitalize-first-letter">{{ tagCategory?.name }}</span>
                                </div>
                                <mat-chip-set
                                        *ngIf="(tagCategory?.entityId === data.currentEntityId && !data.editable) || tagCategory?.entityId !== data.currentEntityId"
                                        [disabled]="data.editable"
                                        class="tag-list-wrapper"
                                        fxFlex
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxLayoutGap="4px"
                                >
                                    <mat-chip-option
                                            (click)="!data.editable && !tag.selected ? addSelectedTag(tagCategory, tag) : ''"
                                            *ngFor="let tag of tagCategory?.labels"
                                            [class.disabled]="tag.selected"
                                            [class.not-disabled]="!tag.selected"
                                            [ngStyle]="{ backgroundColor: tagCategory?.color }"
                                            [selectable]="false"
                                            disableRipple="true"
                                    >
                                        {{ tag.name }}
                                    </mat-chip-option>
                                </mat-chip-set>
                            </div>
                        </div>
                        <iot-platform-ui-tag-editor
                                (changeValue)="onEditorChangeValue($event)"
                                *ngIf="tagsByEntity?.entityId === data.currentEntityId && data.editable"
                                [concept]="concept.value"
                                [organizationId]="data.currentEntityId"
                        ></iot-platform-ui-tag-editor>
                    </mat-expansion-panel>
                </mat-accordion>
            </section>
        </div>

        <section *ngIf="!data.editable" class="selected-tags" fxLayout="row" fxLayoutAlign="start center"
                 fxLayoutGap="20px">
            <div class="fw-600">{{ 'MANAGE_TAGS_FORM.SELECTED' | translate }}</div>
            <mat-chip-set *ngIf="selectedTags.length" fxFlex>
                <mat-chip-option
                        (click)="canRemove && removeSelectedTag(selectedTag)"
                        *ngFor="let selectedTag of selectedTags"
                        [class.removed]="!selectedTag.selected"
                        [class.selected]="selectedTag.selected"
                        [ngStyle]="{ backgroundColor: selectedTag.color, cursor: canRemove ? 'pointer' : 'default' }"
                        [selectable]="false"
                        disableRipple="true"
                        fxLayoutAlign="start center"
                        fxLayoutGap="4px"
                >
                    <mat-icon [svgIcon]="selectedTag.concept | lowercase"></mat-icon>
                    <span>{{ selectedTag.categoryName }} : {{ selectedTag.name }}</span>
                    <mat-icon *ngIf='canRemove'>close</mat-icon>
                </mat-chip-option>
            </mat-chip-set>
        </section>
    </mat-card-content>

    <mat-card-actions *ngIf="!data.editable" class="dialog-card-actions" fxLayoutAlign="end center">
        <button (click)="closeOnCancel()" class="button-regular"
                mat-button>{{ 'MANAGE_TAGS_FORM.CANCEL' | translate }}</button>
        <button (click)="save()" [disabled]="disableSaveButton()" class="button-regular" color="accent"
                mat-raised-button>
            {{ 'MANAGE_TAGS_FORM.CONFIRM' | translate }}
        </button>
    </mat-card-actions>

    <mat-card-actions *ngIf="data.editable" class="dialog-card-actions" fxLayoutAlign="end center">
        <button (click)="closeOnCancel()" [disabled]="!canClose" class="button-regular" color="accent"
                mat-raised-button>{{ 'MANAGE_TAGS_FORM.DONE' | translate }}</button>
    </mat-card-actions>
</mat-card>
