import { Component, Input } from '@angular/core';
import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-device-details-connectors',
  templateUrl: './device-details-connectors.component.html',
  styleUrls: ['./device-details-connectors.component.scss']
})
export class DeviceDetailsConnectorsComponent {
  @Input() device: Device | null = null;
}
