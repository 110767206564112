import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { IotMapFacade } from '@iot-platform/iot-platform-maps';
import { TagCategory } from '@iot-platform/models/common';
import { Device, DeviceCallLog, DeviceVariable, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { UserPreferencesService } from '@iot-platform/users';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { VariableChartService } from 'libs/shared/src/lib/variable-chart/variable-chart.service';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DevicesDbActions } from '../actions';
import * as fromDevices from '../reducers';
import { DevicesService, DeviceStatusService, DeviceVariablesService } from '@iot-platform/shared/services';
import { DevicesFacade } from '../facades/devices.facade';

@Injectable()
export class DevicesEffects {
  addDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.addDevice),
      concatMap((action) =>
        this.devicesService.post(action.deviceToAdd).pipe(
          map((device: Device) => DevicesDbActions.addDeviceSuccess({ addedDevice: device })),
          catchError((error) => of(DevicesDbActions.addDeviceFailure({ error })))
        )
      )
    )
  );

  deleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.deleteDevice),
      concatMap((action) =>
        this.devicesService.delete(action.deviceToDelete).pipe(
          map((deletedDevice: Device) => DevicesDbActions.deleteDeviceSuccess({ deletedDevice })),
          catchError((error) => of(DevicesDbActions.deleteDeviceFailure({ error })))
        )
      )
    )
  );

  loadDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadDevices),
      switchMap((action) =>
        this.devicesService.getAll(action.request).pipe(
          map((response) => DevicesDbActions.loadDevicesSuccess({ response })),
          catchError((error) => of(DevicesDbActions.loadDevicesFailure({ error })))
        )
      )
    )
  );

  loadDeviceById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadDeviceById),
      switchMap((action) =>
        this.devicesService.getById(action.deviceId).pipe(
          map((device) => DevicesDbActions.loadDeviceByIdSuccess({ loadedDevice: device })),
          catchError((error) => of(DevicesDbActions.loadDeviceByIdFailure({ error })))
        )
      )
    )
  );

  loadVariablesByDeviceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadVariablesByDeviceId),
      switchMap((action) =>
        this.deviceVariablesService.getManyByDeviceId(action.deviceId).pipe(
          map((deviceVariables: DeviceVariable[]) => DevicesDbActions.loadVariablesByDeviceIdSuccess({ deviceVariables })),
          catchError((error) => of(DevicesDbActions.loadVariablesByDeviceIdFailure({ error })))
        )
      )
    )
  );

  navigateToNewlyAddedDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesDbActions.addDeviceSuccess),
        tap((action) => {
          this.navigation.selectDeviceAvecLeSite(action.addedDevice);
        })
      ),
    { dispatch: false }
  );

  loadSiteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadSiteById),
      switchMap((action) =>
        this.devicesService.getSiteById(action.siteId).pipe(
          map((site) => DevicesDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(DevicesDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadDeviceStatusList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadDeviceStatusList),
      switchMap((_) =>
        this.deviceStatusService.getAll().pipe(
          map((status) => DevicesDbActions.loadDeviceStatusListSuccess({ status })),
          catchError((error) => of(DevicesDbActions.loadDeviceStatusListFailure({ error })))
        )
      )
    )
  );

  updateDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.updateDevice),
      concatMap((action) =>
        this.devicesService.updateDevice(action.deviceToUpdate).pipe(
          withLatestFrom(this.store.select(fromGrids.getDefaultDevicesGrid)),
          switchMap(([device, grid]) =>
            grid?.id
              ? [
                  DevicesDbActions.updateDeviceSuccess({ updatedDevice: device }),
                  GridsDbActions.updateItemInGridData({ gridId: grid.id, item: device, concept: 'devices' })
                ]
              : [DevicesDbActions.updateDeviceSuccess({ updatedDevice: device })]
          ),
          catchError((error) => of(DevicesDbActions.updateDeviceFailure({ error })))
        )
      )
    )
  );

  activateDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.activateDevice),
      concatMap((action) =>
        this.devicesService.activateDevice(action.deviceToActivate).pipe(
          map((device) => DevicesDbActions.activateDeviceSuccess({ activatedDevice: device })),
          catchError((error) => of(DevicesDbActions.activateDeviceFailure({ error })))
        )
      )
    )
  );

  activateDeviceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesDbActions.activateDeviceSuccess),
        tap((action) => this.devicesFacade.updateDeviceInCurrentGrid(action.activatedDevice))
      ),
    { dispatch: false }
  );

  // TODO : check this point we should probably avoid if/else loop
  bulkUpdateDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.bulkUpdateDevices),
      mergeMap((action) =>
        this.devicesService.bulkUpdateDevices(action.devicesToUpdate).pipe(
          concatMap((results: { devices: Device[]; errors: HttpErrorResponse[] }) => {
            if (results.devices.length > 0 && results.errors.length > 0) {
              return [
                DevicesDbActions.bulkUpdateDevicesSuccess({ updatedDevices: results.devices }),
                DevicesDbActions.bulkUpdateDevicesFailure({ errors: results.errors })
              ];
            } else if (results.devices.length > 0 && results.errors.length === 0) {
              return [DevicesDbActions.bulkUpdateDevicesSuccess({ updatedDevices: results.devices })];
            } else if (results.devices.length === 0 && results.errors.length > 0) {
              return [DevicesDbActions.bulkUpdateDevicesFailure({ errors: results.errors })];
            }
          })
        )
      )
    )
  );

  resetDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.resetDevice),
      switchMap((action) =>
        this.devicesService.resetDevice(action.deviceToReset).pipe(
          concatMap((resetDevice: Device) => [
            DevicesDbActions.updateDeviceSuccess({ updatedDevice: resetDevice }),
            DevicesDbActions.resetDeviceSuccess({ resetDevice }),
            DevicesDbActions.loadVariablesByDeviceId({ deviceId: resetDevice.id }),
            DevicesDbActions.loadTagsByDeviceId({ deviceId: resetDevice.id })
          ]),
          catchError((error) => of(DevicesDbActions.resetDeviceFailure({ error })))
        )
      )
    )
  );

  loadMVDevicesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => DevicesDbActions.loadMVSettingsSuccess({ settings })),
          catchError((error) => of(DevicesDbActions.loadMVSettingsFailure({ error })))
        )
      )
    )
  );

  saveMVDevicesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.saveMVSettings),
      switchMap((action) =>
        this.userPrefService.saveMySettingsAndGetOnlyActive(action.name, action.values).pipe(
          map((response) => DevicesDbActions.saveMVSettingsSuccess({ settings: response })),
          catchError((error) => of(DevicesDbActions.saveMVSettingsFailure({ error })))
        )
      )
    )
  );

  loadCallLogsMVDevicesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadCallLogsMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((callLogsSettings) => DevicesDbActions.loadCallLogsMVSettingsSuccess({ callLogsSettings })),
          catchError((error) => of(DevicesDbActions.loadCallLogsMVSettingsFailure({ error })))
        )
      )
    )
  );

  saveCallLogsMVDevicesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.saveCallLogsMVSettings),
      switchMap((action) =>
        this.userPrefService.saveMySettingsAndGetOnlyActive(action.name, action.values).pipe(
          map((callLogsSettings) => DevicesDbActions.saveCallLogsMVSettingsSuccess({ callLogsSettings })),
          catchError((error) => of(DevicesDbActions.saveCallLogsMVSettingsFailure({ error })))
        )
      )
    )
  );

  saveMVDevicesSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.saveMVSettingsSuccess),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(fromDevices.getPagination), this.store.select(fromFavoriteViews.getFiltersForMasterViewDevices)))
      ),
      exhaustMap(([_, pagination, filters]) =>
        of(DevicesDbActions.loadDevices({ request: { limit: pagination.limit, filters, page: pagination.currentPage } }))
      )
    )
  );

  updateDeviceVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.updateDeviceVariable),
      concatMap((action) =>
        this.deviceVariablesService.update(action.deviceVariableToUpdate).pipe(
          concatMap((variable: DeviceVariable) => [
            DevicesDbActions.updateDeviceVariableSuccess({ updatedDeviceVariable: variable }),
            DevicesDbActions.loadVariablesByDeviceId({ deviceId: action.deviceVariableToUpdate.device.id })
          ]),
          catchError((error) => of(DevicesDbActions.updateDeviceVariableFailure({ error })))
        )
      )
    )
  );

  deleteDeviceVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.deleteDeviceVariables),
      concatMap((action) =>
        this.deviceVariablesService.deleteMany(action.deviceVariablesToDelete).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => [
            DevicesDbActions.deleteDeviceVariableSuccess({ response }),
            DevicesDbActions.loadVariablesByDeviceId({ deviceId: action.deviceVariablesToDelete[0].device.id as string })
          ]),
          catchError((error) => of(DevicesDbActions.deleteDeviceVariableFailure({ error })))
        )
      )
    )
  );

  loadDeviceCallLogById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadCallLogsByDeviceId),
      switchMap((action) =>
        this.devicesService.getCallLogById(action.deviceId).pipe(
          map((callLogs: DeviceCallLog[]) => DevicesDbActions.loadCallLogsByDeviceIdSuccess({ loadedCallLogs: callLogs })),
          catchError((error) => of(DevicesDbActions.loadCallLogsByDeviceIdFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.selectDevice),
      switchMap((action) =>
        this.devicesService.saveTableState(action.selectedDevice).pipe(
          map((selectedDevice: Device) => DevicesDbActions.selectDeviceSuccess({ selectedDevice })),
          catchError((error) => of(DevicesDbActions.selectDeviceFailure({ error })))
        )
      )
    )
  );

  loadTagsByDeviceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadTagsByDeviceId),
      mergeMap((action) =>
        this.devicesService.getTagsByDeviceId(action.deviceId).pipe(
          map((tags: TagCategory[]) => DevicesDbActions.loadTagsByDeviceIdSuccess({ tags })),
          catchError((error) => of(DevicesDbActions.loadTagsByDeviceIdFailure({ error })))
        )
      )
    )
  );

  updateTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.updateTagsByDeviceId),
      concatMap((action) =>
        this.devicesService.putTagsByDeviceId(action.deviceId, action.tags).pipe(
          map((tags: TagCategory[]) => DevicesDbActions.updateTagsByDeviceIdSuccess({ tags })),
          catchError((error) => of(DevicesDbActions.updateTagsByDeviceIdFailure({ error })))
        )
      )
    )
  );

  loadTimeseriesByVariableId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.loadTimeseriesByDeviceVariableId),
      switchMap((action) =>
        this.chartVariablesService.loadTimeseriesByVariableId('device', action.deviceId, action.variableId, action.start, action.end, action.limit).pipe(
          map((timeseries) => DevicesDbActions.loadTimeseriesByDeviceVariableIdSuccess({ timeseries })),
          catchError((error) => of(DevicesDbActions.loadTimeseriesByDeviceVariableIdFailure({ error })))
        )
      )
    )
  );

  resetDeviceVariablesLastValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.resetDeviceVariablesLastValues),
      switchMap((action) =>
        this.devicesService.resetDeviceVariablesLastValues(action.deviceVariables).pipe(
          concatMap((response) => [
            DevicesDbActions.resetDeviceVariablesLastValuesSuccess({ response }),
            DevicesDbActions.loadVariablesByDeviceId({ deviceId: action.deviceVariables[0].device.id })
          ]),
          catchError((error) => of(DevicesDbActions.resetDeviceVariablesLastValuesFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkOperation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesDbActions.resetDeviceVariablesLastValuesSuccess, DevicesDbActions.deleteDeviceVariableSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  moveDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.moveDevices),
      withLatestFrom(this.store.select(fromGrids.getDefaultDevicesGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewDevices)),
      switchMap(([action, grid, filters]) =>
        this.devicesService.bulkUpdateDevices(action.devicesToMove).pipe(
          concatMap((results: { devices: Device[]; errors: HttpErrorResponse[] }) => {
            if (results.devices.length > 0 && results.errors.length > 0) {
              if (!action.originSite) {
                return [
                  DevicesDbActions.moveDevicesSuccess({ updatedDevices: results.devices, currentSite: action.devicesToMove[0].site }),
                  DevicesDbActions.moveDevicesFailure({ errors: results.errors }),
                  GridsDbActions.loadGridData({
                    request: {
                      filters,
                      limit: grid?.data.response.pagination.limit,
                      concept: grid?.masterview.toLowerCase(),
                      page: grid?.data.response.pagination.currentPage,
                      variables: grid?.gridOptions.variableNames,
                      tags: grid?.gridOptions.tagIds,
                      endPoint: grid?.gridOptions.endPoint
                    }
                  })
                ];
              } else {
                return [
                  DevicesDbActions.moveDevicesSuccess({
                    updatedDevices: results.devices,
                    currentSite: action.devicesToMove[0].site,
                    originSite: action.originSite
                  }),
                  DevicesDbActions.moveDevicesFailure({ errors: results.errors })
                ];
              }
            } else if (results.devices.length > 0 && results.errors.length === 0) {
              if (!action.originSite) {
                return [
                  DevicesDbActions.moveDevicesSuccess({
                    updatedDevices: results.devices,
                    currentSite: action.devicesToMove[0].site
                  }),
                  GridsDbActions.loadGridData({
                    request: {
                      filters,
                      limit: grid?.data.response.pagination.limit,
                      concept: grid?.masterview.toLowerCase(),
                      page: grid?.data.response.pagination.currentPage,
                      variables: grid?.gridOptions.variableNames,
                      tags: grid?.gridOptions.tagIds,
                      endPoint: grid?.gridOptions.endPoint
                    }
                  })
                ];
              } else {
                return [
                  DevicesDbActions.moveDevicesSuccess({
                    updatedDevices: results.devices,
                    currentSite: action.devicesToMove[0].site,
                    originSite: action.originSite
                  })
                ];
              }
            } else if (results.devices.length === 0 && results.errors.length > 0) {
              return [DevicesDbActions.moveDevicesFailure({ errors: results.errors })];
            }
          })
        )
      )
    )
  );

  moveDevicesThenUpdateDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesDbActions.moveDevicesSuccess),
        tap((action) => {
          if (action.updatedDevices.length > 1) {
            if (!!action.originSite) {
              // More than one device moved => stay on stock page and refresh the data
              this.navigation.selectLeStock(action.originSite, {
                limit: 100,
                page: 0,
                filters: [{ criteriaKey: 'siteId', value: action.originSite.id }]
              });
            }
          } else if (action.currentSite.type === 'stock') {
            // One device moved to stock => go to new stock page
            this.navigation.selectLeStock(action.currentSite, {
              limit: 100,
              page: 0,
              filters: [{ criteriaKey: 'siteId', value: action.currentSite.id }]
            });
          } else {
            // One device moved from devices MV or device info page to customer/production site => go to new device page
            this.navigation.selectDeviceAvecLeSite(action.updatedDevices[0]);
          }
        })
      ),
    { dispatch: false }
  );

  bulkAddOrRemoveTagByDevicesIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.bulkAddOrRemoveTag),
      concatLatestFrom(() => [this.store.select(fromGrids.getDefaultDevicesGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewDevices)]),
      concatMap(([action, grid, filters]) =>
        this.devicesService.bulkAddOrRemoveTag(action.isAddition, action.devicesIds, action.tagLabelId).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => [
            action.isAddition ? DevicesDbActions.bulkAddTagSuccess({ response }) : DevicesDbActions.bulkRemoveTagSuccess({ response }),
            GridsDbActions.loadGridData({
              request: {
                filters,
                limit: grid?.data.response.pagination.limit,
                concept: grid?.masterview.toLowerCase(),
                page: grid?.data.response.pagination.currentPage,
                variables: grid?.gridOptions.variableNames,
                tags: grid?.gridOptions.tagIds,
                endPoint: grid?.gridOptions.endPoint
              }
            })
          ]),
          catchError((error) => of(action.isAddition ? DevicesDbActions.bulkAddTagFailure({ error }) : DevicesDbActions.bulkRemoveTagFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkAddOrRemoveTagByDevicesIds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesDbActions.bulkAddTagSuccess, DevicesDbActions.bulkRemoveTagSuccess, DevicesDbActions.bulkSendCommandSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  bulkSendCommandByDevicesIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesDbActions.bulkSendCommand),
      withLatestFrom(this.store.select(fromGrids.getDefaultDevicesGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewDevices)),
      switchMap(([action, grid, filters]) =>
        this.devicesService.bulkSendCommand(action.devicesIds, action.command.command).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => [
            DevicesDbActions.bulkSendCommandSuccess({ response }),
            GridsDbActions.loadGridData({
              request: {
                filters,
                limit: grid?.data.response.pagination.limit,
                concept: grid?.masterview.toLowerCase(),
                page: grid?.data.response.pagination.currentPage,
                variables: grid?.gridOptions.variableNames,
                tags: grid?.gridOptions.tagIds,
                endPoint: grid?.gridOptions.endPoint
              }
            })
          ]),
          catchError((error) => of(DevicesDbActions.bulkSendCommandFailure({ error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DevicesDbActions.updateDeviceSuccess,
          DevicesDbActions.activateDeviceSuccess,
          DevicesDbActions.updateDeviceVariableSuccess,
          DevicesDbActions.bulkUpdateDevicesSuccess,
          DevicesDbActions.updateTagsByDeviceIdSuccess,
          DevicesDbActions.deleteDeviceVariableSuccess,
          DevicesDbActions.deleteDeviceSuccess,
          DevicesDbActions.resetDeviceSuccess,
          DevicesDbActions.saveMVSettingsSuccess,
          DevicesDbActions.moveDevicesSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DevicesDbActions.addDeviceFailure,
          DevicesDbActions.addDeviceVariableFailure,
          DevicesDbActions.deleteDeviceFailure,
          DevicesDbActions.deleteDeviceVariableFailure,
          DevicesDbActions.loadDeviceByIdFailure,
          DevicesDbActions.loadDeviceByIdFailure,
          DevicesDbActions.selectDeviceFailure,
          DevicesDbActions.loadDeviceConnectorListFailure,
          DevicesDbActions.loadDeviceStatusListFailure,
          DevicesDbActions.loadDevicesFailure,
          DevicesDbActions.loadSiteByIdFailure,
          DevicesDbActions.loadVariablesByDeviceIdFailure,
          DevicesDbActions.updateDeviceFailure,
          DevicesDbActions.activateDeviceFailure,
          DevicesDbActions.updateDeviceVariableFailure,
          DevicesDbActions.bulkUpdateDevicesFailure,
          DevicesDbActions.updateTagsByDeviceIdFailure,
          DevicesDbActions.loadTimeseriesByDeviceVariableIdFailure,
          DevicesDbActions.deleteDeviceVariableFailure,
          DevicesDbActions.deleteDeviceFailure,
          DevicesDbActions.resetDeviceFailure,
          DevicesDbActions.resetDeviceVariablesLastValuesFailure,
          DevicesDbActions.loadMVSettingsFailure,
          DevicesDbActions.saveMVSettingsFailure,
          DevicesDbActions.moveDevicesFailure,
          DevicesDbActions.bulkAddTagFailure,
          DevicesDbActions.bulkRemoveTagFailure,
          DevicesDbActions.bulkSendCommandFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    {
      dispatch: false
    }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DevicesDbActions.loadDevices,
          DevicesDbActions.activateDevice,
          DevicesDbActions.loadDeviceById,
          DevicesDbActions.loadCallLogsByDeviceId,
          DevicesDbActions.loadSiteById,
          DevicesDbActions.loadTagsByDeviceId,
          DevicesDbActions.resetDevice,
          DevicesDbActions.resetDeviceVariablesLastValues,
          DevicesDbActions.loadVariablesByDeviceId,
          DevicesDbActions.loadMVSettings,
          DevicesDbActions.saveMVSettings,
          DevicesDbActions.moveDevices,
          DevicesDbActions.bulkAddOrRemoveTag,
          DevicesDbActions.bulkSendCommand
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    {
      dispatch: false
    }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DevicesDbActions.loadDeviceByIdSuccess,
          DevicesDbActions.activateDeviceSuccess,
          DevicesDbActions.activateDeviceFailure,
          DevicesDbActions.loadCallLogsByDeviceIdSuccess,
          DevicesDbActions.loadSiteByIdSuccess,
          DevicesDbActions.loadTagsByDeviceIdSuccess,
          DevicesDbActions.loadDevicesSuccess,
          DevicesDbActions.loadDeviceByIdFailure,
          DevicesDbActions.loadCallLogsByDeviceIdFailure,
          DevicesDbActions.resetDeviceFailure,
          DevicesDbActions.resetDeviceVariablesLastValuesSuccess,
          DevicesDbActions.resetDeviceVariablesLastValuesFailure,
          DevicesDbActions.loadVariablesByDeviceIdSuccess,
          DevicesDbActions.loadVariablesByDeviceIdFailure,
          DevicesDbActions.loadMVSettingsSuccess,
          DevicesDbActions.loadMVSettingsFailure,
          DevicesDbActions.saveMVSettingsSuccess,
          DevicesDbActions.saveMVSettingsFailure,
          DevicesDbActions.moveDevicesSuccess,
          DevicesDbActions.moveDevicesFailure,
          DevicesDbActions.bulkAddTagSuccess,
          DevicesDbActions.bulkAddTagFailure,
          DevicesDbActions.bulkRemoveTagSuccess,
          DevicesDbActions.bulkRemoveTagFailure,
          DevicesDbActions.bulkSendCommandSuccess,
          DevicesDbActions.bulkSendCommandFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    {
      dispatch: false
    }
  );

  setCurrentFavoriteView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.setCurrentFavoriteView),
        filter((action) => get(action, 'masterView') === 'devices'),
        tap((action) => {
          this.mapFacade.getAll({ concept: action.masterView, displayMode: 'default', filters: get(action, 'favoriteView.filters', []) });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly navigation: NavigationApi,
    private readonly store: Store,
    private readonly devicesService: DevicesService,
    private readonly deviceStatusService: DeviceStatusService,
    private readonly deviceVariablesService: DeviceVariablesService,
    private readonly notificationService: NotificationService,
    private readonly chartVariablesService: VariableChartService,
    private readonly userPrefService: UserPreferencesService,
    private readonly mapFacade: IotMapFacade,
    private readonly devicesFacade: DevicesFacade
  ) {}
}
