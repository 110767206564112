import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TagLabel } from '@iot-platform/models/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { ManageTagsFormComponent } from '../../manage-tags-form/manage-tags-form.component';

@Component({
  standalone: true,
  imports: [
    MatCardModule,
    MatToolbarModule,
    AsyncPipe,
    MatIconModule,
    MatFormFieldModule,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule
  ],
  selector: 'iot-platform-ui-edit-tag-label-dialog',
  templateUrl: './edit-tag-label-dialog.component.html',
  styleUrls: ['./edit-tag-label-dialog.component.scss']
})
export class EditTagLabelDialogComponent implements OnInit {
  editTagLabelForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ManageTagsFormComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: { label: TagLabel; tagNameMaxLength: number; categoryLabels: TagLabel[] }
  ) {}

  get title$(): Observable<string> {
    return this.translateService.get('TAG_CATEGORY_LIST_COMPONENT.EDIT_TAG_LABEL_DIALOG.TITLE');
  }

  get label(): AbstractControl {
    return this.editTagLabelForm.get('label');
  }

  ngOnInit() {
    this.editTagLabelForm = new UntypedFormGroup({
      label: new UntypedFormControl(
        this.data.label.name,
        [Validators.maxLength(this.data.tagNameMaxLength), Validators.required],
        [this.checkLabelName.bind(this)]
      )
    });
  }

  checkLabelName(name: UntypedFormControl) {
    if (!this.editTagLabelForm) {
      return of(null);
    }

    const identicalLabels = this.data.categoryLabels.filter((label) => name.value.trim().toLowerCase() === label.name.trim().toLowerCase());

    return this.data.label.name.trim().toLowerCase() === name.value.trim().toLowerCase()
      ? of(null)
      : identicalLabels.length
      ? of({ duplicate: true })
      : of(null);
  }

  closeOnCancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.label.value.trim()) {
      const newLabel: TagLabel = { id: this.data.label.id, name: this.label.value.trim() };
      this.dialogRef.close(newLabel);
    }
  }
}
