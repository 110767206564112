import { Injectable } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { IotMapFacade } from '@iot-platform/iot-platform-maps';
import { TagCategory } from '@iot-platform/models/common';
import { Asset, AssetCommandResponse, AssetVariable, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { UserPreferencesService } from '@iot-platform/users';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AssetVariablesService } from '../../../../../../../shared/src/lib/services/asset-variables.service';
import { AssetsService } from '../../../../../../../shared/src/lib/services/assets.service';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import * as fromNavigation from '../../../../containers/+state/reducers';
import { AssetsDbActions, AssetsUiActions } from '../actions';

@Injectable()
export class AssetsEffects {
  addAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.addAsset),
      concatMap((action) =>
        this.assetsService.post(action.assetToAdd).pipe(
          map((asset: Asset) => AssetsDbActions.addAssetSuccess({ addedAsset: asset })),
          catchError((error) => of(AssetsDbActions.addAssetFailure({ error })))
        )
      )
    )
  );

  addAssetByTemplateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.addAssetByTemplateId),
      concatMap((action) =>
        this.assetsService.addAssetByTemplateId(action.assetTemplateId, action.siteId).pipe(
          map((asset: Asset) => AssetsDbActions.addAssetSuccess({ addedAsset: asset })),
          catchError((error) => of(AssetsDbActions.addAssetFailure({ error })))
        )
      )
    )
  );

  addAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.addAssetVariable),
      concatMap((action) =>
        this.assetVariablesService.post(action.assetVariableToAdd).pipe(
          concatMap((assetVariable: AssetVariable) => [
            AssetsDbActions.addAssetVariableSuccess({ assetVariableAdded: assetVariable }),
            AssetsUiActions.loadAssetById({ assetId: assetVariable.asset.id })
          ]),
          catchError((error) => of(AssetsDbActions.addAssetVariableFailure({ error })))
        )
      )
    )
  );

  loadAssetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.loadAssetById),
      switchMap((action) =>
        this.assetsService.getAssetById(action.assetId).pipe(
          map((asset: Asset) => AssetsDbActions.loadAssetByIdSuccess({ asset })),
          catchError((error) => of(AssetsDbActions.loadAssetByIdFailure({ error })))
        )
      )
    )
  );

  loadAssetByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDbActions.loadAssetByIdSuccess),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      filter(([_, grid]) => !!grid?.id),
      map(([result, grid]) => GridsDbActions.updateItemInGridData({ gridId: grid.id, item: result.asset, concept: 'assets' }))
    )
  );

  loadAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.loadAssets),
      switchMap((action) =>
        this.assetsService.getAll(action.request).pipe(
          map((response) => AssetsDbActions.loadAssetsSuccess({ response })),
          catchError((error) => of(AssetsDbActions.loadAssetsFailure({ error })))
        )
      )
    )
  );

  loadMVAssetSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.loadMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => AssetsDbActions.loadMVSettingsSuccess({ settings })),
          catchError((error) => of(AssetsDbActions.loadMVSettingsFailure({ error })))
        )
      )
    )
  );

  saveMVAssetSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.saveMVSettings),
      switchMap((action) =>
        this.userPrefService.saveMySettingsAndGetOnlyActive(action.name, action.values).pipe(
          map((response) => AssetsDbActions.saveMVSettingsSuccess({ settings: response })),
          catchError((error) => of(AssetsDbActions.saveMVSettingsFailure({ error })))
        )
      )
    )
  );

  loadVariablesByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.loadVariablesByAssetId),
      switchMap((action) =>
        this.assetVariablesService.getManyByAssetId(action.assetId, 1000).pipe(
          map((allVariables: AssetVariable[]) => AssetsDbActions.loadVariablesByAssetIdSuccess({ allVariables })),
          catchError((error) => of(AssetsDbActions.loadVariablesByAssetIdFailure({ error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.selectAsset),
      switchMap((action) =>
        this.assetsService.saveTableState(action.selectedAsset).pipe(
          map((selectedAsset: Asset) => AssetsDbActions.selectAssetSuccess({ selectedAsset })),
          catchError((error) => of(AssetsDbActions.selectAssetFailure({ error })))
        )
      )
    )
  );

  updateAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.updateAsset),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      concatMap(([action, grid]) =>
        this.assetsService.put(action.assetToUpdate).pipe(
          concatMap((asset: Asset) => {
            const DEFAULT_ACTIONS = [
              AssetsDbActions.updateAssetSuccess({ updatedAsset: asset }),
              AssetsUiActions.loadVariablesByAssetId({ assetId: asset.id })
            ];
            return grid?.id ? [...DEFAULT_ACTIONS, GridsDbActions.updateItemInGridData({ gridId: grid.id, item: asset, concept: 'assets' })] : DEFAULT_ACTIONS;
          }),
          catchError((error) => of(AssetsDbActions.updateAssetFailure({ error })))
        )
      )
    )
  );

  updateAssetFromMV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.updateAssetFromMV),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      concatMap(([action, grid]) =>
        this.assetsService.put(action.assetToUpdate).pipe(
          concatMap((asset: Asset) => {
            const DEFAULT_ACTIONS = [
              AssetsDbActions.updateAssetSuccess({ updatedAsset: asset }),
              AssetsUiActions.loadVariablesByAssetId({ assetId: asset.id as string }),
              GridsDbActions.loadGridData({ request: action.request })
            ];
            return grid?.id ? [...DEFAULT_ACTIONS, GridsDbActions.updateItemInGridData({ gridId: grid.id, item: asset, concept: 'assets' })] : DEFAULT_ACTIONS;
          }),
          catchError((error) => of(AssetsDbActions.updateAssetFailure({ error })))
        )
      )
    )
  );

  deleteAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.deleteAsset),
      concatMap((action) =>
        this.assetsService.delete(action.assetToDelete).pipe(
          map(() => AssetsDbActions.deleteAssetSuccess({ deletedAsset: action.assetToDelete })),
          catchError((error) => of(AssetsDbActions.deleteAssetFailure({ error })))
        )
      )
    )
  );

  deleteAssetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDbActions.deleteAssetSuccess),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultAssetsGrid)),
      mergeMap(([action, grid]) => (grid?.id ? of(GridsDbActions.removeItemInGridData({ gridId: grid.id, item: action.deletedAsset })) : of(null)))
    )
  );

  updateAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.updateAssetVariable),
      concatMap((action) =>
        this.assetVariablesService.put(action.assetVariableToUpdate).pipe(
          concatMap((assetVariable: AssetVariable) => [
            AssetsDbActions.updateAssetVariableSuccess({ assetVariableUpdated: assetVariable }),
            AssetsUiActions.loadAssetById({ assetId: assetVariable.asset.id as string })
          ]),
          catchError((error) => of(AssetsDbActions.updateAssetVariableFailure({ error })))
        )
      )
    )
  );

  updateAssetVariableThresholds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.updateAssetVariableThresholds),
      concatLatestFrom(() => [this.store.select(fromGrids.getDefaultAssetsGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets)]),
      concatMap(([action, grid, filters]) =>
        this.assetVariablesService.updateThresholds(action.assetVariableToUpdate).pipe(
          concatMap((assetVariable: AssetVariable) => {
            const actions: Action[] = [AssetsDbActions.updateAssetVariableSuccess({ assetVariableUpdated: assetVariable })];
            if (grid) {
              actions.push(
                GridsDbActions.loadGridData({
                  request: {
                    filters,
                    limit: grid?.data.response.pagination.limit,
                    concept: grid?.masterview.toLowerCase(),
                    page: grid?.data.response.pagination.currentPage,
                    variables: grid?.gridOptions.variableNames,
                    tags: grid?.gridOptions.tagIds,
                    endPoint: grid?.gridOptions.endPoint
                  }
                })
              );
            }
            return actions;
          }),
          catchError((error) => of(AssetsDbActions.updateAssetVariableFailure({ error })))
        )
      )
    )
  );

  updateFollowedAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.updateFollowedAssetVariable),
      concatMap((action) =>
        this.assetVariablesService.put(action.assetVariableToUpdate).pipe(
          switchMap((assetVariable) => this.assetsService.getAssetById(assetVariable.asset.id).pipe(map((asset) => ({ asset, assetVariable })))),
          withLatestFrom(this.store.select(fromGrids.getDefaultAssetsGrid)),
          concatMap(([result, grid]) =>
            grid?.id
              ? [
                  AssetsDbActions.updateAssetVariableSuccess({ assetVariableUpdated: result.assetVariable }),
                  AssetsDbActions.loadAssetByIdSuccess({ asset: result.asset }),
                  GridsDbActions.updateItemInGridData({ gridId: grid.id, item: result.asset, concept: 'assets' })
                ]
              : [
                  AssetsDbActions.updateAssetVariableSuccess({ assetVariableUpdated: result.assetVariable }),
                  AssetsDbActions.loadAssetByIdSuccess({ asset: result.asset })
                ]
          ),
          catchError((error) => of(AssetsDbActions.updateAssetVariableFailure({ error })))
        )
      )
    )
  );

  deleteAssetVariables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.deleteAssetVariables),
      concatMap((action) =>
        this.assetVariablesService.deleteAssetVariables(action.assetVariablesToDelete).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => [
            AssetsDbActions.deleteAssetVariablesSuccess({ response }),
            AssetsUiActions.loadVariablesByAssetId({ assetId: action.assetVariablesToDelete[0].asset.id as string }),
            AssetsUiActions.loadAssetById({ assetId: action.assetVariablesToDelete[0].asset.id as string })
          ]),
          catchError((error) => of(AssetsDbActions.deleteAssetVariablesFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterDeleteAssetVariables$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsDbActions.deleteAssetVariablesSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  loadSiteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.loadSiteById),
      switchMap((action) =>
        this.assetsService.getSiteById(action.siteId).pipe(
          map((site) => AssetsDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(AssetsDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadTagsByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.loadTagsByAssetId),
      switchMap((action) =>
        this.assetsService.getTagsByAssetId(action.assetId).pipe(
          map((tags: TagCategory[]) => AssetsDbActions.loadTagsByAssetIdSuccess({ tags })),
          catchError((error) => of(AssetsDbActions.loadTagsByAssetIdFailure({ error })))
        )
      )
    )
  );

  updateTagsByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.updateTagsByAssetId),
      concatMap((action) =>
        this.assetsService.putTagsByAssetId(action.assetId, action.tags).pipe(
          map((tags: TagCategory[]) => AssetsDbActions.updateTagsByAssetIdSuccess({ tags })),
          catchError((error) => of(AssetsDbActions.updateTagsByAssetIdFailure({ error })))
        )
      )
    )
  );

  assignProductToAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.assignProductToAsset),
      switchMap((action) =>
        this.assetsService.assignProductToAsset(action.product, action.asset).pipe(
          map((asset: Asset) => AssetsDbActions.assignProductToAssetSuccess({ asset })),
          catchError((error) => of(AssetsDbActions.assignProductToAssetFailure({ error })))
        )
      )
    )
  );

  removeProductFromAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.removeProductFromAsset),
      concatMap((action) =>
        this.assetsService.removeProductFromAsset(action.product, action.asset).pipe(
          map((asset: Asset) => AssetsDbActions.removeProductFromAssetSuccess({ product: action.product, asset })),
          catchError((error) => of(AssetsDbActions.removeProductFromAssetFailure({ error })))
        )
      )
    )
  );

  navigateToNewlyAddedAsset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsDbActions.addAssetSuccess),
        tap((action) => {
          this.navigation.selectAssetAvecLeSite(action.addedAsset);
          // this.navigation.loadData('asset', action.addedAsset.id, action.addedAsset.site.id);
        })
      ),
    { dispatch: false }
  );

  modifyAssetVariableThenLoadAssociations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsDbActions.addAssetVariableSuccess, AssetsDbActions.updateAssetVariableSuccess, AssetsDbActions.deleteAssetVariablesSuccess),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromNavigation.getSite)))),
        tap(([_, site]) => {
          if (site) {
            this.navigation.loadAssociationsBySiteId(site?.id);
          }
        })
      ),
    { dispatch: false }
  );

  modifyAssetVariableThenLoadVariables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDbActions.updateAssetVariableSuccess),
      concatMap((action) => [
        AssetsUiActions.loadVariablesByAssetId({ assetId: action.assetVariableUpdated.asset.id as string }),
        AssetsUiActions.loadAssetById({ assetId: action.assetVariableUpdated.asset.id as string })
      ])
    )
  );

  assignProductThenLoadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDbActions.assignProductToAssetSuccess),
      map((action) => AssetsUiActions.loadAssetById({ assetId: action.asset.id }))
    )
  );

  removeProductThenLoadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDbActions.removeProductFromAssetSuccess),
      map((action) => AssetsUiActions.loadAssetById({ assetId: action.asset.id }))
    )
  );

  sendCommand = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.sendCommand),
      switchMap((action) =>
        this.assetsService.sendCommand(action.asset.id, action.command).pipe(
          concatMap((response: AssetCommandResponse) => [AssetsDbActions.sendCommandSuccess({ response })]),
          catchError((error) => of(AssetsDbActions.sendCommandFailure({ error })))
        )
      )
    )
  );

  resetAssetVariableLastValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.resetAssetVariablesLastValues),
      switchMap((action) =>
        this.assetsService.resetAssetVariablesLastValues(action.assetVariables).pipe(
          concatMap((response) => [
            AssetsDbActions.resetAssetVariablesLastValuesSuccess({ response }),
            AssetsUiActions.loadVariablesByAssetId({ assetId: action.assetVariables[0].asset.id })
          ]),
          catchError((error) => of(AssetsDbActions.resetAssetVariablesLastValuesFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterResetAssetVariablesLastValues$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsDbActions.resetAssetVariablesLastValuesSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  bulkAddOrRemoveTagByAssetsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.bulkAddOrRemoveTag),
      concatLatestFrom(() => [this.store.select(fromGrids.getDefaultAssetsGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets)]),
      concatMap(([action, grid, filters]) =>
        this.assetsService.bulkAddOrRemoveTag(action.isAddition, action.assetsIds, action.tagLabelId).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => [
            action.isAddition ? AssetsDbActions.bulkAddTagSuccess({ response }) : AssetsDbActions.bulkRemoveTagSuccess({ response }),
            GridsDbActions.loadGridData({
              request: {
                filters,
                limit: grid?.data.response.pagination.limit,
                concept: grid?.masterview.toLowerCase(),
                page: grid?.data.response.pagination.currentPage,
                variables: grid?.gridOptions.variableNames,
                tags: grid?.gridOptions.tagIds,
                endPoint: grid?.gridOptions.endPoint
              }
            })
          ]),
          catchError((error) => of(action.isAddition ? AssetsDbActions.bulkAddTagFailure({ error }) : AssetsDbActions.bulkRemoveTagFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkAddOrRemoveTagByAssetsIds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsDbActions.bulkAddTagSuccess, AssetsDbActions.bulkRemoveTagSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  bulkSendCommandByAssetsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsUiActions.bulkSendCommand),
      withLatestFrom(this.store.select(fromGrids.getDefaultAssetsGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets)),
      switchMap(([action, grid, filters]) =>
        this.assetsService.bulkSendCommand(action.assetsIds, action.command.command).pipe(
          concatMap((response: AssetCommandResponse) => [
            AssetsDbActions.bulkSendCommandSuccess({ response }),
            GridsDbActions.loadGridData({
              request: {
                filters,
                limit: grid?.data.response.pagination.limit,
                concept: grid?.masterview.toLowerCase(),
                page: grid?.data.response.pagination.currentPage,
                variables: grid?.gridOptions.variableNames,
                tags: grid?.gridOptions.tagIds,
                endPoint: grid?.gridOptions.endPoint
              }
            })
          ]),
          catchError((error) => of(AssetsDbActions.bulkSendCommandFailure({ error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsDbActions.addAssetSuccess,
          AssetsDbActions.addAssetVariableSuccess,
          AssetsDbActions.saveMVSettingsSuccess,
          AssetsDbActions.updateAssetSuccess,
          AssetsDbActions.updateAssetVariableSuccess,
          AssetsDbActions.deleteAssetSuccess,
          AssetsDbActions.updateTagsByAssetIdSuccess,
          AssetsDbActions.assignProductToAssetSuccess,
          AssetsDbActions.removeProductFromAssetSuccess,
          AssetsDbActions.sendCommandSuccess,
          AssetsDbActions.bulkSendCommandSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsDbActions.addAssetFailure,
          AssetsDbActions.addAssetVariableFailure,
          AssetsDbActions.loadAssetByIdFailure,
          AssetsDbActions.loadTagsByAssetIdFailure,
          AssetsDbActions.updateTagsByAssetIdFailure,
          AssetsDbActions.loadAssetsFailure,
          AssetsDbActions.selectAssetFailure,
          AssetsDbActions.loadVariablesByAssetIdFailure,
          AssetsDbActions.updateAssetFailure,
          AssetsDbActions.deleteAssetFailure,
          AssetsDbActions.updateAssetVariableFailure,
          AssetsDbActions.loadSiteByIdFailure,
          AssetsDbActions.assignProductToAssetFailure,
          AssetsDbActions.removeProductFromAssetFailure,
          AssetsDbActions.saveMVSettingsFailure,
          AssetsDbActions.loadMVSettingsFailure,
          AssetsDbActions.sendCommandFailure,
          AssetsDbActions.resetAssetVariablesLastValuesFailure,
          AssetsDbActions.bulkAddTagFailure,
          AssetsDbActions.bulkRemoveTagFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsUiActions.loadAssets,
          AssetsUiActions.loadMVSettings,
          AssetsUiActions.saveMVSettings,
          AssetsUiActions.loadAssetById,
          AssetsUiActions.updateTagsByAssetId,
          AssetsUiActions.loadTagsByAssetId,
          AssetsUiActions.loadSiteById,
          AssetsUiActions.deleteAsset,
          AssetsUiActions.updateAsset,
          AssetsUiActions.addAsset,
          AssetsUiActions.addAssetByTemplateId,
          AssetsUiActions.addAssetVariable,
          AssetsUiActions.updateAssetVariable,
          AssetsUiActions.loadVariablesByAssetId,
          AssetsUiActions.deleteAssetVariables,
          AssetsUiActions.assignProductToAsset,
          AssetsUiActions.removeProductFromAsset,
          AssetsUiActions.sendCommand,
          AssetsUiActions.resetAssetVariablesLastValues,
          AssetsUiActions.bulkAddOrRemoveTag
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetsDbActions.addAssetSuccess,
          AssetsDbActions.addAssetFailure,
          AssetsDbActions.loadAssetsFailure,
          AssetsDbActions.loadAssetsSuccess,
          AssetsDbActions.loadAssetByIdFailure,
          AssetsDbActions.loadAssetByIdSuccess,
          AssetsDbActions.loadSiteByIdSuccess,
          AssetsDbActions.updateAssetFailure,
          AssetsDbActions.updateAssetSuccess,
          AssetsDbActions.loadTagsByAssetIdFailure,
          AssetsDbActions.loadTagsByAssetIdSuccess,
          AssetsDbActions.deleteAssetFailure,
          AssetsDbActions.deleteAssetSuccess,
          AssetsDbActions.addAssetVariableFailure,
          AssetsDbActions.addAssetVariableSuccess,
          AssetsDbActions.loadVariablesByAssetIdFailure,
          AssetsDbActions.loadVariablesByAssetIdSuccess,
          AssetsDbActions.updateTagsByAssetIdSuccess,
          AssetsDbActions.updateAssetVariableFailure,
          AssetsDbActions.updateAssetVariableSuccess,
          AssetsDbActions.deleteAssetVariablesFailure,
          AssetsDbActions.deleteAssetVariablesSuccess,
          AssetsDbActions.assignProductToAssetFailure,
          AssetsDbActions.assignProductToAssetSuccess,
          AssetsDbActions.removeProductFromAssetFailure,
          AssetsDbActions.removeProductFromAssetSuccess,
          AssetsDbActions.loadMVSettingsSuccess,
          AssetsDbActions.loadMVSettingsFailure,
          AssetsDbActions.saveMVSettingsSuccess,
          AssetsDbActions.saveMVSettingsFailure,
          AssetsDbActions.sendCommandSuccess,
          AssetsDbActions.sendCommandFailure,
          AssetsDbActions.resetAssetVariablesLastValuesFailure,
          AssetsDbActions.resetAssetVariablesLastValuesSuccess,
          AssetsDbActions.bulkAddTagSuccess,
          AssetsDbActions.bulkAddTagFailure,
          AssetsDbActions.bulkRemoveTagSuccess,
          AssetsDbActions.bulkRemoveTagFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  setCurrentFavoriteView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.setCurrentFavoriteView),
        filter((action) => get(action, 'masterView') === 'assets'),
        tap((action) => {
          this.mapFacade.getAll({ concept: action.masterView, displayMode: 'default', filters: get(action, 'favoriteView.filters', []) });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly assetsService: AssetsService,
    private readonly userPrefService: UserPreferencesService,
    private readonly navigation: NavigationApi,
    private readonly assetVariablesService: AssetVariablesService,
    private readonly notificationService: NotificationService,
    private readonly mapFacade: IotMapFacade
  ) {}
}
