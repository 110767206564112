<iot-platform-ui-timezone-autocomplete
        (selectionChange)="onSelectionChange($event)" *ngIf="!data.multiSelect"
></iot-platform-ui-timezone-autocomplete>

<iot-platform-ui-timezone-autocomplete-multiple-selects
        (selectionChange)="onSelectionChange($event)"
        *ngIf="data.multiSelect"
        [currentFilters$]="currentFilters$"
        [currentFiltersSize]="currentFiltersSize"
        [data]="data"
        [maxFilters]="maxFilters"
></iot-platform-ui-timezone-autocomplete-multiple-selects>
