import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Asset, AssetStatus, AssetStatusName, Device, DeviceStatusName } from '@iot-platform/models/i4b';
import { DateFormatModule, InfoDisplayModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'iot4bos-ui-end-maintenance-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DateFormatModule,
    FormsModule,
    InfoDisplayModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatToolbarModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './end-maintenance-dialog.component.html',
  styleUrl: './end-maintenance-dialog.component.scss'
})
export class EndMaintenanceDialogComponent {
  public readonly dialogRef = inject(MatDialogRef<EndMaintenanceDialogComponent>);
  public data: { elementEndingMaintenance: Device | Asset; type: 'ASSET' | 'DEVICE' } = inject(MAT_DIALOG_DATA);
  endMaintenanceForm: UntypedFormGroup = new UntypedFormGroup({});
  availableStatuses: DeviceStatusName[] = [DeviceStatusName.running, DeviceStatusName.standby];

  constructor() {
    this.endMaintenanceForm = new UntypedFormGroup({
      switchbackStatus: new UntypedFormControl(this.data.type === 'ASSET' ? AssetStatusName.production : null, [Validators.required])
    });
  }

  get switchbackStatus(): AbstractControl {
    return this.endMaintenanceForm.get('switchbackStatus') as AbstractControl;
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    const newElement = cloneDeep(this.data.elementEndingMaintenance);
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    newElement.status = { ...newElement.status, name: this.switchbackStatus.value };

    if (this.data.type === 'ASSET') {
      newElement.status = {
        ...newElement.status,
        scheduledMaintenance: { ...(newElement.status as AssetStatus).scheduledMaintenance, active: false }
      } as AssetStatus;
    }
    this.dialogRef.close(newElement);
  }
}
