import { Filter, PlatformRequest } from '@iot-platform/models/common';
import { EscalationProtocol, EscalationProtocolLevel, Topic } from '@iot-platform/models/ocm';
import { createAction, props } from '@ngrx/store';

export const listEscalationProtocols = createAction('[EscalationProtocols] List Escalation Protocols', props<{ request: PlatformRequest }>());
//
export const loadEscalationProtocolById = createAction('[EscalationProtocols] Load Escalation Protocol By Id', props<{ protocolId: string }>());
//
export const addEscalationProtocol = createAction('[EscalationProtocols] Add Escalation Protocol', props<{ protocolToAdd: EscalationProtocol }>());
//
export const updateEscalationProtocol = createAction('[EscalationProtocols] Update Escalation Protocol', props<{ protocolToUpdate: EscalationProtocol }>());
//
export const deleteEscalationProtocol = createAction('[EscalationProtocols] Delete Escalation Protocol', props<{ protocolToDelete: EscalationProtocol }>());
//
export const selectEscalationProtocol = createAction('[EscalationProtocols] Select Escalation Protocol', props<{ protocolToSelect: EscalationProtocol }>());
//
export const navigateToEscalationProtocolDetails = createAction(
  '[EscalationProtocols] Navigate to Escalation Protocol Details',
  props<{ protocolToNavigate: EscalationProtocol }>()
);
//
export const updateEscalationProtocolTopicSubscriptions = createAction(
  '[EscalationProtocols] Update Escalation Protocol Topic Subscriptions',
  props<{ protocolId: string; topics: Topic[] }>()
);
//
export const loadTopicSubscriptionByProtocolId = createAction(
  '[EscalationProtocols] Load Topic Subscriptions By Escalation Protocol Id',
  props<{ protocolId: string }>()
);
export const saveEscalationProtocolFilters = createAction('[EscalationProtocols] Save Escalation Protocol Filters', props<{ filters: Filter[] }>());
//
export const listEscalationProtocolLevels = createAction('[EscalationProtocols] List Escalation Protocol Levels', props<{ protocolId: string }>());
//
export const addEscalationProtocolLevel = createAction(
  '[EscalationProtocols] Add Escalation Protocol Level',
  props<{ levelToAdd: EscalationProtocolLevel; protocolId: string }>()
);
//
export const updateEscalationProtocolLevel = createAction(
  '[EscalationProtocols] Update Escalation Protocol Level',
  props<{ levelToUpdate: EscalationProtocolLevel; protocolId: string }>()
);
//
export const updateEscalationProtocolLevelsOrders = createAction(
  '[EscalationProtocols] Update Escalation Protocol Levels Orders',
  props<{ levelsToUpdate: EscalationProtocolLevel[]; protocolId: string }>()
);
//
export const deleteEscalationProtocolLevelThenUpdateLevels = createAction(
  '[EscalationProtocols] Delete Escalation Protocol Level',
  props<{ levelToDelete: EscalationProtocolLevel; levelsToUpdate: EscalationProtocolLevel[]; protocolId: string }>()
);
