import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormulaType } from '@iot-platform/models/common';
import { AssetVariable, AutonomyForPGSrcVariables, Formula } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { MultipleVariablesParametersComponent } from '../multiple-variables-parameters/multiple-variables-parameters.component';
import { VariableParameterComponent } from '../variable-parameter/variable-parameter.component';

@Component({
  standalone: true,
  imports: [NgFor, FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatInputModule, VariableParameterComponent],
  selector: 'shared-iot4bos-asset-ui-autonomy-parameters-form',
  templateUrl: '../multiple-variables-parameters/multiple-variables-parameters.component.html'
})
export class AutonomyParametersFormComponent extends MultipleVariablesParametersComponent implements OnInit {
  formulaParameters: { name: string; unit: string }[] = [
    { name: 'pressure', unit: 'bar' },
    { name: 'volume', unit: 'm3' },
    { name: 'consumption', unit: 'm3/h' },
    { name: 'minimumUsablePressure', unit: 'bar' }
  ];

  assetVariableFormulaSrcVariables: AutonomyForPGSrcVariables = {
    pressure: {
      name: 'pressure',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    volume: {
      name: 'volume',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    consumption: {
      name: 'consumption',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    },
    'minimum-usable-pressure': {
      name: 'minimum-usable-pressure',
      variableId: '',
      type: 'asset-variable',
      originId: ''
    }
  };

  assetVariableFormula: Formula = {
    model: FormulaType.AUTONOMY_FOR_PG,
    parameters: {},
    srcVariables: { ...this.assetVariableFormulaSrcVariables }
  };

  invalidUnitTooltip = 'ASSETS.VARIABLE_FORM.AUTONOMY_FORM.INVALID_UNIT_TOOLTIP';
  placeholder = 'ASSETS.VARIABLE_FORM.AUTONOMY_FORM.PLACEHOLDERS.';

  get pressure(): AbstractControl {
    return this.parametersForm.get('pressure');
  }

  get volume(): AbstractControl {
    return this.parametersForm.get('volume');
  }

  get consumption(): AbstractControl {
    return this.parametersForm.get('consumption');
  }

  get minimumUsablePressure(): AbstractControl {
    return this.parametersForm.get('minimumUsablePressure');
  }

  ngOnInit() {
    const srcVariables = this.initialFormula?.srcVariables as AutonomyForPGSrcVariables;

    this.parametersForm = new UntypedFormGroup({
      pressure: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables.pressure?.variableId) : null,
        [Validators.required, this.checkVariableUnitPressure]
      ),
      volume: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables.volume?.variableId) : null,
        [Validators.required, this.checkVariableUnitVolume]
      ),
      consumption: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables.consumption?.variableId) : null,
        [Validators.required, this.checkVariableUnitConsumption]
      ),
      minimumUsablePressure: new UntypedFormControl(
        this.initialFormula ? this.initialSelectedVariables?.find((assetVar) => assetVar.id === srcVariables['minimum-usable-pressure']?.variableId) : null,
        [Validators.required, this.checkVariableUnitPressure]
      )
    });
    this.parametersForm.markAsUntouched();

    this.setFirstEmptyParameter();

    this.subscriptions.push(
      this.parametersForm.valueChanges.subscribe(() => {
        this.sendFormula();
      })
    );
    this.sendFormula();
  }

  checkVariableUnitPressure(parameter: AbstractControl): { invalidUnit: boolean } | null {
    if (parameter.value?.unit !== 'bar') {
      return { invalidUnit: true };
    }
    return null;
  }

  checkVariableUnitVolume(parameter: AbstractControl): { invalidUnit: boolean } | null {
    if (parameter.value?.unit !== 'm3') {
      return { invalidUnit: true };
    }
    return null;
  }

  checkVariableUnitConsumption(parameter: AbstractControl): { invalidUnit: boolean } | null {
    if (parameter.value?.unit !== 'm3/h') {
      return { invalidUnit: true };
    }
    return null;
  }

  assignAssetVariableToFirstEmptyParameter(assetVariable: AssetVariable): void {
    if (!!this.firstEmptyParameter && !this.formulaParameters.find((parameter) => this[parameter.name].value?.id === assetVariable.id)) {
      this[this.firstEmptyParameter.name].setValue(assetVariable);
    }
  }

  createFormula(): void {
    this.assetVariableFormulaSrcVariables = {
      pressure: {
        ...this.assetVariableFormulaSrcVariables.pressure,
        variableId: this.pressure.value.id,
        originId: this.pressure.value.asset.id
      },
      volume: {
        ...this.assetVariableFormulaSrcVariables.volume,
        variableId: this.volume.value.id,
        originId: this.volume.value.asset.id
      },
      consumption: {
        ...this.assetVariableFormulaSrcVariables.consumption,
        variableId: this.consumption.value.id,
        originId: this.consumption.value.asset.id
      },
      'minimum-usable-pressure': {
        ...this.assetVariableFormulaSrcVariables['minimum-usable-pressure'],
        variableId: this.minimumUsablePressure.value.id,
        originId: this.minimumUsablePressure.value.asset.id
      }
    };

    this.assetVariableFormula = { ...this.assetVariableFormula, srcVariables: this.assetVariableFormulaSrcVariables };
  }
}
