<div class="login-shell default-theme" data-cy="iot-platform-login-shell">
  <div class="login-shell-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%"></div>
    <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column">
      <h1 class="login-shell-title fw-600">{{ authConfig.appName }}</h1>

      @if ((loggedIn$ | async) === false && (stepEmail$ | async) === false && (stepPassword$ | async) === false && (loginWithEmailPassword$ | async) === false) {
        <iot-platform-login-select-authentication
          data-cy="iot-platform-login-select-authentication"
          fxFlex
          [ssoDisabled]="(authConfig$ | async)?.ssoDisabled"
          (signInWithSSO)="onSignInWithSSO()"
          (signInWithEmailPassword)="onSignInWithEmailPassword()"
        ></iot-platform-login-select-authentication>
      }

      @if (loginWithEmailPassword$ | async) {
        <iot-platform-login-form
          data-cy="iot-platform-login-form"
          fxFlex
          [isI4BLogin]="true"
          [hasError]="errorSignIn$ | async"
          (cancel)="onCancel()"
          (resetPassword)="onResetPassword()"
          (signIn)="onSignIn($event)"
        ></iot-platform-login-form>
      }

      @if ((loggedIn$ | async) && (requireNewPassword$ | async)) {
        <iot-platform-login-change-temporary-password-form
          data-cy="iot-platform-login-change-temporary-password-form"
          [hasError]="errorPassword$ | async"
          (cancel)="onCancel()"
          (changeTemporaryPassword)="onChangeTemporaryPassword($event)"
        ></iot-platform-login-change-temporary-password-form>
      }

      @if (stepEmail$ | async) {
        <iot-platform-login-send-code-form
          data-cy="iot-platform-login-send-code-form"
          [hasError]="errorEmail$ | async"
          [isI4BLogin]="true"
          (cancel)="onCancel()"
          (sendCode)="onSendCode($event)"
        ></iot-platform-login-send-code-form>
      }

      @if (stepPassword$ | async) {
        <iot-platform-login-reset-password-form
          data-cy="iot-platform-login-reset-password-form"
          [username]="username"
          [hasError]="errorPassword$ | async"
          (cancel)="onCancel()"
          (sendCode)="onSendCode($event)"
          (changePassword)="onChangeForgottenPassword($event)"
        ></iot-platform-login-reset-password-form>
      }

      @if ((loggedIn$ | async) && (requireNewPassword$ | async) === false) {
        <iot-platform-login-select-profile
          data-cy="iot-platform-login-select-profile"
          [businessProfiles$]="businessProfiles$"
          [loading]="this.pending$ | async"
          (selectProfile)="onSelectProfile($event)"
        ></iot-platform-login-select-profile>
      }
    </div>
  </div>
</div>
