import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fromAuth } from '@iot-platform/auth';
import { ApiInterceptorService } from '@iot-platform/core';
import { UserPreferences } from '@iot-platform/models/common';
import { fromUserPreferences, PreferencesActions, UserProfileService } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  selectLoggedOut$ = this.store.select(fromAuth.selectLoggedOut);

  constructor(
    private store: Store,
    private userProfileService: UserProfileService,
    private readonly apiInterceptorService: ApiInterceptorService,
    private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iot4bos-icon-set.svg'));
    this.apiInterceptorService.setBuildInfo();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(fromUserPreferences.getPreferences).subscribe((preferences: UserPreferences) => {
        if (preferences) {
          this.userProfileService.setThemeOverlay(preferences.appTheme);
          this.store.dispatch(PreferencesActions.changeLanguage({ lang: preferences.appLanguage }));
        }
      })
    );
  }
}
