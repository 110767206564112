import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, Injector, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterOutlet } from '@angular/router';
import {
  AuthApiActions,
  AuthBusinessProfilesApiActions,
  AuthBusinessProfilesPageActions,
  AuthFacade,
  AuthPageActions,
  AuthService,
  fromAuth
} from '@iot-platform/auth';
import { CachedUsersService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BuildInfoComponent, ChatComponent, LayoutModule, SidenavModule } from '@iot-platform/iot-platform-ui';
import { BusinessProfile, Chat, ChatEvent, IotAction, UserAccount } from '@iot-platform/models/common';
import { SidenavMenuBlock } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom, tap } from 'rxjs';
import { FeatureAppShellSettings } from '../models/app-shell-settings.model';
import { AbstractAppShellService } from '../services/abstract-app-shell.service';
import { AiChatBotService } from '../services/ai-chat-bot.service';
import { FEATURE_APP_SHELL_SETTINGS } from '../settings/app-shell-settings.provider';

@Component({
  selector: 'shared-feature-app-shell',
  standalone: true,
  imports: [
    FlexLayoutModule,
    BuildInfoComponent,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    RouterOutlet,
    SidenavModule,
    TranslateModule,
    LayoutModule,
    MatTooltipModule,
    ChatComponent
  ],
  templateUrl: './app-shell.component.html',
  styleUrl: './app-shell.component.scss',
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureAppShellComponent implements OnInit {
  appTheme = 'default-theme';
  navigationTree: Signal<SidenavMenuBlock[]>;
  businessProfiles: Signal<BusinessProfile[]>;
  businessProfile: Signal<BusinessProfile>;
  currentUser: Signal<UserAccount>;
  currentUserLoading: Signal<boolean>;
  privileges: Signal<unknown>;
  isLoggedOut: Signal<boolean>;
  signOutPending: Signal<boolean>;
  sidenavState: WritableSignal<{ isOpen: boolean }> = signal({ isOpen: true });
  protected readonly appShellService: AbstractAppShellService = inject(AbstractAppShellService);
  protected readonly settings: FeatureAppShellSettings = inject(FEATURE_APP_SHELL_SETTINGS);
  protected readonly authService: AuthService = inject(AuthService);
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly store: Store = inject(Store);
  protected readonly storage: LocalStorageService = inject(LocalStorageService);
  protected readonly cachedUsersService: CachedUsersService = inject(CachedUsersService);
  protected readonly injector: Injector = inject(Injector);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  // Chatbot Spike
  chatBotOpen: WritableSignal<boolean> = signal(false);
  chat: Signal<Chat>;

  protected readonly aiChatBotService: AiChatBotService = inject(AiChatBotService);

  constructor() {
    this.businessProfiles = this.store.selectSignal(fromAuth.selectBusinessProfilesForAccount);
    this.businessProfile = toSignal(this.appShellService.getCurrentBusinessProfile().pipe(takeUntilDestroyed(this.destroyRef)));
    this.currentUser = this.store.selectSignal(fromAuth.selectCurrentUser);
    this.currentUserLoading = this.store.selectSignal(fromAuth.selectAccountLoading);
    this.privileges = this.store.selectSignal(fromAuth.selectPrivileges);
    this.isLoggedOut = this.store.selectSignal(fromAuth.selectLoggedOut);
    this.signOutPending = this.store.selectSignal(fromAuth.selectSignOutPending);
    this.navigationTree = toSignal(this.appShellService.getNavigationTree().pipe(takeUntilDestroyed(this.destroyRef)));
    this.sidenavState.update((sideNav) => {
      const isOpen = this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY);
      sideNav.isOpen = isOpen ? isOpen === 'true' : true;
      return {
        ...sideNav
      };
    });

    if (this.settings.allowChatBot) {
      this.chat = this.aiChatBotService.chat;
    }
  }

  ngOnInit(): void {
    this.handleBusinessProfileChangeEffect();
    this.handleBusinessProfileReadyEffect();
    this.handleRetrieveSessionEffect();
    this.loadUsersEffect();
  }

  logout(): void {
    this.store.dispatch(AuthPageActions.signOut());
  }

  returnHome(): void {
    this.appShellService.returnHome();
  }

  toggleSidenav(): void {
    this.sidenavState.update((sideNav) => {
      sideNav.isOpen = !sideNav.isOpen;
      return {
        ...sideNav
      };
    });
    this.storage.set(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY, this.sidenavState().isOpen.toString());
  }

  onChangeBusinessProfile(selected: BusinessProfile, withRedirect = true): void {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile: selected,
        withRedirect
      })
    );
  }

  openMyProfile(): void {
    this.appShellService.openMyProfile();
  }

  closePreferences(): void {
    this.appShellService.closePreferences();
  }

  onChatBotClicked(): void {
    this.chatBotOpen.update((value) => !value);
  }

  refreshBusinessProfileList(): void {
    this.store.dispatch(AuthPageActions.loadAccount());
  }

  openReleaseNotes(): void {
    this.appShellService.openReleaseNotes();
  }

  onDispatchBotAction(event: ChatEvent): void {
    if (event.name === IotAction.CONFIGURE) {
      this.aiChatBotService.openCredentialsDialog();
    } else {
      this.aiChatBotService.addMessage(event, this.currentUser());
    }
  }

  private handleBusinessProfileReadyEffect() {
    effect(
      () => {
        const businessProfile = this.businessProfile();
        if (businessProfile) {
          this.store.dispatch(
            AuthBusinessProfilesApiActions.selectBusinessProfileSuccess({
              selectedBusinessProfile: businessProfile,
              withRedirect: false
            })
          );
          this.appShellService.onBusinessProfileReady(businessProfile);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private handleRetrieveSessionEffect() {
    effect(
      () => {
        const isLoggedOut = this.isLoggedOut();
        if (!isLoggedOut && this.authService.isLoggedInWithSSO()) {
          this.store.dispatch(AuthPageActions.retrieveSsoSession(this.authService.retrieveSsoTokens()));
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private loadUsersEffect() {
    effect(
      () => {
        const currentUser = this.currentUser();
        if (currentUser) {
          this.cachedUsersService.loadUsers();
        } else {
          const signOutPending = this.signOutPending();
          if (this.authService.isLoggedInWithSSO() || signOutPending) {
            return;
          }
          const isLoggedOut = this.isLoggedOut();
          if (!isLoggedOut) {
            this.store.dispatch(AuthApiActions.retrieveSession());
          } else {
            this.storage.clear();
          }
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private handleBusinessProfileChangeEffect() {
    effect(
      async () => {
        await lastValueFrom(
          this.authFacade.isBusinessProfileChanged$.pipe(
            tap((isBusinessProfileChanged: boolean) => {
              if (isBusinessProfileChanged) {
                this.appShellService.onBusinessProfileChange();
              }
            })
          )
        );
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }
}
