<div class="ag-header-group-cell-label">
  <div class="customHeaderLabel">{{ this.params?.displayName }}</div>
  @if(showExpandArrow) {
  <div
    class="customExpandButton"
    [ngClass]="this.expandState"
    (click)="expandOrCollapse()"
  >
    <i class="fa fa-arrow-right"></i>
  </div>
  }
</div>
