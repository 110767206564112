import { Signal, Type, WritableSignal } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { SectionHeaderAction, ToolbarPageType, ToolbarPageTypeOption } from '@iot-platform/iot-platform-ui';

/**
 * Simple Definition
 */
export interface FeatureMasterViewPageTypeSettings {
  defaultPageType: Signal<ToolbarPageType> | WritableSignal<ToolbarPageType>;
  options?: Signal<ToolbarPageTypeOption[]> | WritableSignal<ToolbarPageTypeOption[]>;
  visible: Signal<boolean> | WritableSignal<boolean>;
}

export interface FeatureMasterViewGridSettings {
  staticDefinitionType: Signal<boolean> | WritableSignal<boolean>;
  hidePaginator: Signal<boolean> | WritableSignal<boolean>;
  style: Signal<string> | WritableSignal<string>;
}

export interface FeatureMasterViewToolbarSettings {
  enabled: Signal<boolean> | WritableSignal<boolean>;
  withFavoriteViews: Signal<boolean> | WritableSignal<boolean>;
  withGrids: Signal<boolean> | WritableSignal<boolean>;
  toolbarName: Signal<string> | WritableSignal<string>;
  pageTypeOptions?: FeatureMasterViewPageTypeSettings;
}

export interface FeatureMasterViewHeaderSectionSettings {
  enabled: Signal<boolean> | WritableSignal<boolean>;
  title: Signal<string> | WritableSignal<string>;
  icon: Signal<string> | WritableSignal<string>;
  svgIcon: Signal<string> | WritableSignal<string>;
  styles: Signal<string> | WritableSignal<string>;
  cssClassName: Signal<string> | WritableSignal<string>;
  actions: Signal<SectionHeaderAction[]> | WritableSignal<SectionHeaderAction[]>;
}

export interface FeatureMasterViewFilterEngineSettings {
  enabled: Signal<boolean> | WritableSignal<boolean>;
  displayActionButtons: Signal<boolean> | WritableSignal<boolean>;
  withFavoriteFilters: Signal<boolean> | WritableSignal<boolean>;
  clearOnDestroy: Signal<boolean> | WritableSignal<boolean>;
}

export interface FeatureMasterViewDrawerDetailsSettings {
  position: Signal<'start' | 'end'> | WritableSignal<'start' | 'end'>;
  layout: Signal<FeatureMasterViewDrawerDetailsLayout> | WritableSignal<FeatureMasterViewDrawerDetailsLayout>;
  mode: Signal<MatDrawerMode> | WritableSignal<MatDrawerMode>;
  opened: Signal<boolean> | WritableSignal<boolean>;
  openedOnDestroy: Signal<boolean> | WritableSignal<boolean>;
  enabled: Signal<boolean> | WritableSignal<boolean>;
  toggle: Signal<boolean> | WritableSignal<boolean>;
  hasBackdrop: Signal<boolean> | WritableSignal<boolean>;
  closeOnBackdropClick: Signal<boolean> | WritableSignal<boolean>;
  customOpeningEventType: Signal<string> | WritableSignal<string>;
  componentRef: Signal<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: Type<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputs: any;
  }>;
}

export enum FeatureMasterViewDrawerDetailsLayout {
  GRID = 'grid',
  FILTER_ENGINE = 'filterEngine',
  TOOLBAR = 'toolbar',
  ALL = 'all'
}

export interface FeatureMasterViewDashboardSettings {
  enabled: Signal<boolean> | WritableSignal<boolean>;
}

export interface FeatureMasterViewMapSettings {
  enabled: Signal<boolean> | WritableSignal<boolean>;
}

export class FeatureMasterViewSettings {
  masterViewName: Signal<string> | WritableSignal<string>;
  toolbar: Partial<FeatureMasterViewToolbarSettings>;
  sectionHeader: Partial<FeatureMasterViewHeaderSectionSettings>;
  grid: Partial<FeatureMasterViewGridSettings>;
  filterEngine: Partial<FeatureMasterViewFilterEngineSettings>;
  drawerDetails: Partial<FeatureMasterViewDrawerDetailsSettings>;
  dashboard: Partial<FeatureMasterViewDashboardSettings>;
  map: Partial<FeatureMasterViewMapSettings>;
}
