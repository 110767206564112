import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TagCategory } from '@iot-platform/models/common';

import { Device, Site } from '@iot-platform/models/i4b';

import { Observable, Subscription } from 'rxjs';

import { DevicesFacade } from '../../+state/facades/devices.facade';

@Component({
  selector: 'iot4bos-ui-device-detail-popup',
  templateUrl: './device-detail-popup.component.html',
  styleUrls: ['./device-detail-popup.component.scss']
})
export class DeviceDetailPopupComponent implements OnDestroy {
  device: Device;
  isSiteLoaded$: Observable<boolean>;
  isTagsLoaded$: Observable<boolean>;
  tags$: Observable<TagCategory[]>;
  site$: Observable<Site | undefined> = this.devicesFacade.site$;

  subs: Subscription[] = [];

  @Output() selectSite: EventEmitter<Site> = new EventEmitter<Site>();
  @Output() selectDevice: EventEmitter<Device> = new EventEmitter<Device>();

  constructor(
    private devicesFacade: DevicesFacade,
    @Inject(MAT_DIALOG_DATA) public data: { device: Device; canUpdateDevice: boolean }
  ) {
    this.device = this.data.device;

    this.devicesFacade.loadTagsByDeviceId(this.device.id);
    this.devicesFacade.loadSiteById(this.device.site.id);
    this.isSiteLoaded$ = this.devicesFacade.siteLoaded$;
    this.tags$ = this.devicesFacade.tags$;
    this.isTagsLoaded$ = this.devicesFacade.tagsLoaded$;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
