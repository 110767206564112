import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, TagCategory } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagEditorService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject('environment') private readonly environment: Environment
  ) {}

  getTagCategoryNameDuplicates(entityId: string, categoryName: string): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', '0');
    params = params.set('categoryName', categoryName);
    params = params.set('entityId', entityId);
    params = params.set('withParents', 'true');
    params = params.set('withChildren', 'true');
    return this.httpClient
      .get<TagCategory[]>(this.environment.api.url + this.environment.api.endpoints.tags, { params })
      .pipe(map((tags: any) => tags.page.total > 0));
  }
}
