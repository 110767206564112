import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformDashboardsModule } from '@iot-platform/iot-platform-dashboards';
import { IotPlatformMapsModule } from '@iot-platform/iot-platform-maps';
import { DetailPopupCardsModule, DeviceInfoStatusModule, IotPlatformUiModule, WidgetsModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { CommentsPanelComponent } from '@iot-platform/shared/iot4bos-ui';
import { TableEngineModule } from '@iot-platform/table-engine';
import { TranslateModule } from '@ngx-translate/core';
import { MvCellModule } from '../../../../components/mv-cell/mv-cell.module';
import { DeviceDetailPopupComponent } from '../../components/device-detail-popup/device-detail-popup.component';
import { DeviceMoveToFormComponent } from '../../components/device-move-to-form/device-move-to-form.component';
import { DeviceOverviewModule } from '../device-overview/device-overview.module';
import { DevicesShellComponent } from './devices-shell.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    MvCellModule,
    DetailPopupCardsModule,
    TableEngineModule,
    DeviceOverviewModule,
    GridEngineModule,
    DeviceInfoStatusModule,
    IotPlatformMapsModule,
    WidgetsModule,
    IotPlatformDashboardsModule,
    CommentsPanelComponent
  ],
  declarations: [DevicesShellComponent, DeviceMoveToFormComponent, DeviceDetailPopupComponent],
  exports: [DevicesShellComponent]
})
export class DevicesShellModule {}
