import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BusinessProfile } from '@iot-platform/models/common';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'iot-platform-login-select-profile',
  templateUrl: './login-select-profile.component.html',
  styleUrls: ['./login-select-profile.component.scss']
})
export class LoginSelectProfileComponent implements OnInit, OnDestroy {
  @Input() businessProfiles$: Observable<BusinessProfile[]>;
  @Input() loading: boolean;

  @Output() selectProfile: EventEmitter<any> = new EventEmitter();

  subscription: Subscription;
  bpByEntities$: BehaviorSubject<
    {
      entityId: string;
      entityName: string;
      businessProfiles: BusinessProfile[];
    }[]
  > = new BehaviorSubject<{ entityId: string; entityName: string; businessProfiles: BusinessProfile[] }[]>([]);

  constructor(private readonly authService: AuthorizationService) {}

  ngOnInit() {
    this.subscription = this.businessProfiles$.subscribe((businessProfiles: BusinessProfile[]) => {
      if (businessProfiles && businessProfiles.length > 0) {
        this.bpByEntities$.next(this.getBpByEntity(this.sortBpByLevel(businessProfiles)));
      }
    });
  }

  onSelectProfile(profile: BusinessProfile): void {
    this.selectProfile.emit(profile);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private sortBpByLevel(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    return businessProfiles.sort((a, b) => a.level - b.level);
  }

  private sortBpByAlphabeticalOrder(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    return businessProfiles.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getBpByEntity(businessProfiles: BusinessProfile[]): {
    entityId: string;
    entityName: string;
    businessProfiles: BusinessProfile[];
  }[] {
    const bpByEntities = businessProfiles.map((businessProfile) => ({
      entityId: businessProfile.entityId,
      entityName: businessProfile.entityName,
      businessProfiles: this.sortBpByAlphabeticalOrder(
        businessProfiles.filter((curBusinessProfile) => curBusinessProfile.entityId === businessProfile.entityId)
      )
    }));

    return bpByEntities.reduce(
      (resBpByEntities, bpByEntity) =>
        !resBpByEntities.find((curBpByEntity) => curBpByEntity.entityId === bpByEntity.entityId) ? [...resBpByEntities, bpByEntity] : resBpByEntities,
      [bpByEntities[0]]
    );
  }
}
