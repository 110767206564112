<div
  class="chat-header-container"
  [style.background-color]="header.backgroundColor?.()"
  [style.color]="header.textColor?.()"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <mat-icon class="chat-header-icon">{{ header.icon?.() }}</mat-icon>
  @if (header.loading?.()) {
    <mat-progress-spinner [diameter]="16" color="primary" mode="indeterminate"></mat-progress-spinner>
  } @else {
    <span>{{ header.count?.() }}</span>
  }
  <span fxFlex>{{ header.title?.() | translate | uppercase }}</span>

  @if (header.action?.()) {
    <button (click)="dispatchAction.emit({ name: header.action?.()?.name?.() })" class="chat-header-close-button" mat-icon-button>
      <mat-icon>{{ header.action?.()?.icon?.() }}</mat-icon>
    </button>
  }
</div>
