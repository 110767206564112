<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.ADD_ADMIN.TITLE' | translate: param }}</h1>
            <button (click)="exit()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
        <section class="" fxFlex fxLayout="column">
            <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <p>{{ unselectedUsers.length }} {{ 'ADMIN.DIALOG.ADD_ADMIN.AVAILABLE_USERS' | translate | uppercase }}</p>
            </mat-toolbar>

            <iot-platform-ui-async-autocomplete
                    (search)="onSearchUsers($event)" [autocomplete]="false"
                    [data]="filteredUnselectedUsers"
                    [debounceTime]="300"
                    [displayWrapper]="displayUserWrapper"
                    [minLength]="3"
                    [showSpinner]="loadingUsers$ | async"
                    class="filter-input"
                    displayKey="email"
                    fxFlex
                    placeholder="ADMIN.DIALOG.ADD_ADMIN.FILTER_USERS"
            ></iot-platform-ui-async-autocomplete>

            <mat-list class="height-300 two-line-list" fxFlex>
                <mat-list-item *ngFor="let user of filteredUnselectedUsers" [lines]="2" class="hoverable">
                    <h3 matListItemTitle>{{ user.firstname }} {{ user.lastname }}</h3>
                    <button (click)="addAdministratorToOrganization(user)" class="regular-round-button" mat-icon-button
                            matListItemMeta>
                        <mat-icon>person_add</mat-icon>
                    </button>
                    <p class="complementary-line" matListItemLine>
                        <span>{{ 'ADMIN.DIALOG.ADD_ADMIN.LAST_ACTIVITY' | translate }}</span>
                        <span> {{ user.lastActivity | dateFormat }} </span>
                    </p>
                </mat-list-item>
            </mat-list>
        </section>

        <section class="" fxFlex fxLayout="column">
            <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
                <p>{{ currentAdministrators.length }} {{ 'ADMIN.DIALOG.ADD_ADMIN.SELECTED_ADMIN' | translate | uppercase }}</p>
            </mat-toolbar>

            <mat-form-field class="filter-input" color="accent">
                <mat-label>{{ 'ADMIN.DIALOG.ADD_ADMIN.FILTER_USERS' | translate }}</mat-label>
                <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
                <input (keyup)="filterUsers($event, currentAdministrators, true)" matInput type="text"/>
            </mat-form-field>

            <mat-list class="height-300 two-line-list" fxFlex>
                <mat-list-item *ngFor="let user of filteredCurrentAdministrators" [lines]="2" class="hoverable">
                    <h3 matListItemTitle>{{ user.firstname }} {{ user.lastname }}</h3>
                    <button (click)="removeAdministratorFromOrganization(user)" class="regular-round-button"
                            mat-icon-button
                            matListItemMeta>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <p class="complementary-line" matListItemLine>
                        <span>{{ 'ADMIN.DIALOG.ADD_ADMIN.LAST_ACTIVITY' | translate }}</span>
                        <span>{{ user.lastActivity | dateFormat }}</span>
                    </p>
                </mat-list-item>
            </mat-list>
        </section>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="exit()" class="button-regular" color="accent"
                mat-raised-button>{{ 'ADMIN.DIALOG.ADD_ADMIN.DONE' | translate }}</button>
    </mat-card-actions>
</mat-card>
