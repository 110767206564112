<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ title$ | async }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20" fxLayout="column">
        <form [formGroup]="organizationForm">
            <mat-form-field color="accent" fxFlex>
                <mat-label>{{ 'ADMIN.DIALOG.ADD_ORGANIZATION.PLACEHOLDER' | translate }}</mat-label>
                <input [placeholder]="" formControlName="name" matInput maxlength="50" required/>
                <mat-error
                        *ngIf="nameControl.invalid && nameControl.errors.duplicate">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular"
                mat-button>{{ 'ADMIN.DIALOG.ADD_ORGANIZATION.CANCEL' | translate }}</button>
        <button (click)="save()" [disabled]="!organizationForm.valid" class="button-regular" color="accent"
                mat-raised-button>{{ 'ADMIN.DIALOG.ADD_ORGANIZATION.SAVE' | translate }}</button>
    </mat-card-actions>
</mat-card>
