import { PlatformResponse, Product, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetCommandResponse, AssetVariable, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const addAssetSuccess = createAction('[Assets] Add Asset Success', props<{ addedAsset: Asset }>());
export const addAssetFailure = createAction('[Assets] Add Asset Failure', props<{ error: any }>());
//
export const addAssetVariableSuccess = createAction('[Assets] Add Asset Variable Success', props<{ assetVariableAdded: AssetVariable }>());
export const addAssetVariableFailure = createAction('[Assets] Add Asset Variable Failure', props<{ error: any }>());
//
export const loadAssetByIdSuccess = createAction('[Assets] Load Asset By Id Success', props<{ asset: Asset }>());
export const loadAssetByIdFailure = createAction('[Assets] Load Asset By Id Failure', props<{ error: any }>());
//
export const loadTagsByAssetIdSuccess = createAction('[Assets] Load Tags By Asset Id Success', props<{ tags: TagCategory[] }>());
export const loadTagsByAssetIdFailure = createAction('[Assets] Load Tags By Asset Id Failure', props<{ error: any }>());
//
export const updateTagsByAssetIdSuccess = createAction('[Assets] Update Tags By Asset Id Success', props<{ tags: TagCategory[] }>());
export const updateTagsByAssetIdFailure = createAction('[Assets] Update Tags By Asset Id Failure', props<{ error: any }>());
//
export const loadAssetsSuccess = createAction('[Assets] Load Assets Success', props<{ response: PlatformResponse }>());
export const loadAssetsFailure = createAction('[Assets] Load Assets Failure', props<{ error: any }>());
//
export const loadMVSettingsSuccess = createAction('[Assets] Load MV Assets Settings Success', props<{ settings: any }>());
export const loadMVSettingsFailure = createAction('[Assets] Load MV Assets Settings Failure', props<{ error: any }>());
//
export const saveMVSettingsSuccess = createAction('[Assets] Save MV Assets Settings Success', props<{ settings: any }>());
export const saveMVSettingsFailure = createAction('[Assets] Save MV Assets Settings Failure', props<{ error: any }>());
//
export const selectAssetSuccess = createAction('[Assets] Select Asset Success', props<{ selectedAsset: Asset }>());
export const selectAssetFailure = createAction('[Assets] Select Asset Failure', props<{ error: any }>());
//
export const loadVariablesByAssetIdSuccess = createAction('[Assets] Load Variables By Asset Id Success', props<{ allVariables: AssetVariable[] }>());
export const loadVariablesByAssetIdFailure = createAction('[Assets] Load Variables By Asset Id Failure', props<{ error: any }>());
//
export const updateAssetSuccess = createAction('[Assets] Update Asset Success', props<{ updatedAsset: Asset }>());
export const updateAssetFailure = createAction('[Assets] Update Asset Failure', props<{ error: any }>());
//
export const deleteAssetSuccess = createAction('[Assets] Delete Asset Success', props<{ deletedAsset: Asset }>());
export const deleteAssetFailure = createAction('[Assets] Delete Asset Failure', props<{ error: any }>());
//
export const updateAssetVariableSuccess = createAction('[Assets] Update Asset Variable Success', props<{ assetVariableUpdated: AssetVariable }>());
export const updateAssetVariableFailure = createAction('[Assets] Update Asset Variable Failure', props<{ error: any }>());
//
export const updateFollowedAssetVariableSuccess = createAction(
  '[Assets] Update Followed Asset Variable Success',
  props<{ assetVariableUpdated: AssetVariable }>()
);
export const updateFollowedAssetVariableFailure = createAction('[Assets] Update Followed Asset Variable Failure', props<{ error: any }>());
//
export const deleteAssetVariablesSuccess = createAction('[Assets] Delete Asset Variables - ', props<{ response: I4BBulkOperationApiResponse }>());
export const deleteAssetVariablesFailure = createAction('[Assets] Delete Asset Variables Failure', props<{ error: any }>());
//
export const loadSiteByIdSuccess = createAction('[Assets] Load Site By Site Id Success', props<{ site: Site }>());
export const loadSiteByIdFailure = createAction('[Assets] Load Site By Site Id Failure', props<{ error: any }>());
//
export const assignProductToAssetSuccess = createAction('[Assets] Assign Product to Asset Success', props<{ asset: Asset }>());
export const assignProductToAssetFailure = createAction('[Assets] Assign Product to Asset Failure', props<{ error: any }>());
//
export const removeProductFromAssetSuccess = createAction('[Assets] Remove Product from Asset Success', props<{ product: Product; asset: Asset }>());
export const removeProductFromAssetFailure = createAction('[Assets] Remove Product from Asset Failure', props<{ error: any }>());
//
export const sendCommandSuccess = createAction('[Assets] Send Command Success', props<{ response: AssetCommandResponse }>());
export const sendCommandFailure = createAction('[Assets] Send Command Failure', props<{ error: any }>());

export const resetAssetVariablesLastValuesSuccess = createAction(
  '[Assets] Reset Asset Variables Last Values - ',
  props<{ response: I4BBulkOperationApiResponse }>()
);
export const resetAssetVariablesLastValuesFailure = createAction('[Assets] Reset Asset Variables Last Values Failure', props<{ error: any }>());
//
export const bulkAddTagSuccess = createAction('[Assets] Bulk Add Tag - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkAddTagFailure = createAction('[Assets] Bulk Add Tag Failure', props<{ error: any }>());
export const bulkRemoveTagSuccess = createAction('[Assets] Bulk Remove Tag - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkRemoveTagFailure = createAction('[Assets] Bulk Remove Tag Failure', props<{ error: any }>());
//
export const bulkSendCommandSuccess = createAction('[Assets] Bulk Send Command Success ', props<{ response: AssetCommandResponse }>());
export const bulkSendCommandFailure = createAction('[Assets] Bulk Send Command Failure', props<{ error: any }>());
