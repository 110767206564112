<div class="tag-category-editor" fxFlex fxLayout="column" fxLayoutGap="6px">
    <iot-platform-ui-tag-editor-form
            (changeValue)="onChangeValue()"
            (remove)="onRemove($event)"
            (save)="onUpdateTagCategory($event)"
            *ngFor="let tagCategory of categories$ | async"
            [colors]="colors$ | async"
            [mode]="'UPDATE'"
            [tagCategory]="tagCategory"
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="10px"
    >
    </iot-platform-ui-tag-editor-form>

    <iot-platform-ui-tag-editor-form
            (changeValue)="onChangeValue()"
            (save)="onAddTagCategory($event)"
            [colors]="colors$ | async"
            [mode]="'ADD'"
            [tagCategory]="newCategory"
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="10px"
    >
    </iot-platform-ui-tag-editor-form>
</div>
