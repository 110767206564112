import { I4BCellType, I4BColumnConfiguration } from './configurations/i4b-column-configuration';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { ArrayOfObjectColumn } from './definitions/i4b-array-of-object.column';
import { I4BBasicColumn } from './definitions/i4b-basic.column';
import { I4BColumn } from './definitions/i4b-column';
import { I4BConnectorsButtonColumn } from './definitions/i4b-connectors-button.column';
import { I4bCellConcept } from './enums/i4b-cell-concept.enum';
import { HeaderType, I4BColumnHeader } from './models/i4b-column-header.model';
import { ConnectorsGridData } from './models/i4b-grid-data.model';
import { I4BGridOptions } from './models/i4b-grid-options.model';
import { I4BGrid } from './models/i4b-grid.model';
import { I4BColumnOptions } from './options/i4b-column-options';

export const DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new I4BBasicColumn(
    { displayName: 'ID' },
    {
      id: 'id',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ID',
      width: 100,
      sortProperty: 'id',
      concept: I4bCellConcept.CONNECTORS,
      isDefault: true
    },
    { order: 1 }
  ),
  new I4BBasicColumn(
    { displayName: 'NAME' },
    { id: 'name', translationKey: 'IOT_DICTIONARY.NAME', width: 150, concept: I4bCellConcept.CONNECTORS, isDefault: true },
    { order: 20 }
  ),
  new ArrayOfObjectColumn(
    { displayName: 'ATTACHED ENTITIES' },
    {
      id: 'entities',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ATTACHED_ENTITIES',
      width: 150,
      concept: I4bCellConcept.CONNECTORS,
      isDefault: true,
      cell: { type: I4BCellType.ARRAY_OF_OBJECTS, options: { attributes: ['name'] } }
    },
    { order: 30, sortable: false }
  ),
  new ArrayOfObjectColumn(
    { displayName: 'FAMILIES' },
    {
      id: 'families',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_FAMILIES',
      width: 150,
      concept: I4bCellConcept.CONNECTORS,
      isDefault: true,
      cell: { type: I4BCellType.ARRAY_OF_OBJECTS }
    },
    { order: 35, sortable: false }
  ),
  new I4BBasicColumn(
    { displayName: 'SUPPORTS' },
    {
      id: 'supports',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SUPPORT',
      width: 150,
      concept: I4bCellConcept.CONNECTORS,
      cell: { type: I4BCellType.ARRAY_OF_OBJECTS },
      isDefault: true
    },
    { order: 40 }
  ),
  new I4BBasicColumn(
    { displayName: 'CONTEXT ENTITIES' },
    {
      id: 'contextEntity',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CONTEXT_ENTITY',
      width: 150,
      concept: I4bCellConcept.CONNECTORS,
      isDefault: true
    },
    { order: 50 }
  ),
  new ArrayOfObjectColumn(
    { displayName: 'EXTERNAL API KEY IDS' },
    {
      id: 'externalApiKeyIds',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.KEY_IDS',
      width: 150,
      concept: I4bCellConcept.CONNECTORS,
      isDefault: true,
      cell: { type: I4BCellType.ARRAY_OF_OBJECTS }
    },
    { order: 60, sortable: false }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    { id: 'description', translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION', width: 150, concept: I4bCellConcept.CONNECTORS, isDefault: false },
    { order: 70 }
  ),
  new I4BBasicColumn(
    { displayName: 'CONFIGURATION URL' },
    {
      id: 'configuration.url',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CONFIGURATION_URL',
      width: 170,
      concept: I4bCellConcept.CONNECTORS,
      isDefault: false
    },
    { order: 80 }
  ),
  new I4BBasicColumn(
    { displayName: 'DATA POINTS FOR THE LAST 24H', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATA_POINTS_24H',
      id: 'datapointsLast24Hours',
      isDefault: false,
      sortProperty: 'datapointsLast24Hours',
      concept: I4bCellConcept.CONNECTORS,
      width: 220,
      cell: { type: I4BCellType.NUMBER }
    },
    { order: 90 }
  ),
  new I4BBasicColumn(
    { displayName: 'MESSAGES FOR THE LAST 24H', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.MESSAGES_LAST_24H',
      id: 'messagesLast24Hours',
      isDefault: false,
      sortProperty: 'messagesLast24Hours',
      concept: I4bCellConcept.CONNECTORS,
      width: 220,
      cell: { type: I4BCellType.NUMBER }
    },
    { order: 100 }
  )
];

export class DefaultConnectorsGrid implements I4BGrid<I4BGridOptions, ConnectorsGridData> {
  masterview = 'connectors';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId: string = null;
  data: ConnectorsGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 1000,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new I4BConnectorsButtonColumn() },
    gridSort: [{ colId: 'id', sort: 'asc', sortIndex: 0 }]
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  export(): void {}
}
