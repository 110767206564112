import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-event-severity-display',
  templateUrl: './event-severity-display.component.html',
  styleUrls: ['./event-severity-display.component.scss']
})
export class EventSeverityDisplayComponent {
  @Input() severity: string;
}
