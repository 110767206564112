<form [formGroup]="catalogForm" class="tag-editor-form-container" fxFlex fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
                (click)="onRemove(catalog)"
                *ngIf="mode === 'UPDATE'"
                [matTooltip]="'TAG_EDITOR.REMOVE' | translate"
                class="regular-round-button"
                color="warn"
                mat-icon-button
                matTooltipClass="warning-tooltip"
                matTooltipPosition="below"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <mat-form-field color="accent" fxFlex="200px">
        <mat-label>{{ 'TAG_EDITOR.CATEGORY_NAME' | translate }}</mat-label>
        <input
            #nameInput
            [placeholder]=""
            [value]="catalog.name"
            formControlName="name"
            matInput
            maxlength="20"
            required
        />
        <mat-error *ngIf="name.invalid && name.errors.duplicate">
            {{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_CATEGORY' | translate }}
        </mat-error>
        <mat-hint align="end"
        ><span>{{ nameInput.value.length || 0 }}/{{ nameMaximumLength }}</span></mat-hint
        >
    </mat-form-field>

    <mat-form-field color="accent" fxFlex>
        <mat-label>{{ 'ADMIN.PRODUCT_CATALOGS.FORM.ATTACHED_ENTITIES' | translate }}</mat-label>
        <mat-select formControlName="entities" multiple placeholder="" required>
            <mat-select-trigger *ngIf="entities.value">{{ getEntitiesFormatted() }}</mat-select-trigger>
            <mat-option *ngFor="let entity of attachedEntitiesNotVisible?.concat(entityList)"
                        [disabled]="disableEntity(entity)" [value]="entity">{{ entity?.label }}</mat-option>
        </mat-select>
        <mat-error
                *ngIf="entities.value.length > maximumEntitiesPerCatalog">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.TOO_MANY_ENTITIES' | translate }}</mat-error>
        <mat-hint align="end" fxLayoutGap="10px">
            <span>{{ entities.value?.length || 0 }}/{{ maximumEntitiesPerCatalog }}</span>
        </mat-hint>
    </mat-form-field>
    <button
            (click)="onSave(catalog)"
            *ngIf="mode === 'UPDATE'"
            [disabled]="!catalogForm.valid || (catalogForm.valid && !catalogForm.dirty)"
            class="button-regular"
            color="accent"
            mat-raised-button
    >
        {{ 'TAG_EDITOR.UPDATE' | translate | uppercase }}
    </button>
    <button (click)="onSave(catalog)" *ngIf="mode === 'ADD'" [disabled]="!catalogForm.valid" class="button-regular"
            color="accent" mat-raised-button>
        {{ 'TAG_EDITOR.ADD' | translate | uppercase }}
    </button>
</form>
