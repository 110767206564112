<form [formGroup]="inputForm" fxFlex>
    <mat-form-field color="accent" fxFlex>
        <mat-label>{{ ('PO_EVENTS.CREATION_CONFIGURATION_POPUP.INPUT_LIST.' + (placeholder$ | async)) | translate }}</mat-label>
        <mat-select (selectionChange)="onValueChange()" formControlName="value" required>
            <mat-option *ngFor="let val of choices" [value]="val">{{ val }}</mat-option>
        </mat-select>
        <mat-error
                *ngIf="value.hasError('required')">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
</form>
