import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { Asset } from '@iot-platform/models/i4b';
import { InfoDisplayModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, InfoDisplayModule, TranslateModule, DetailPopupModule],
  selector: 'shared-iot4bos-asset-ui-basic-info',
  templateUrl: './basic-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicInfoComponent {
  @Input() asset: Asset | null = null;
  @Input() displayMode: 'vertical' | 'horizontal' | 'default' = 'default';

  @Output() selectAsset: EventEmitter<Asset> = new EventEmitter<Asset>();
}
