<ng-container *ngIf="!fabIconButton">
    <button (click)="onClick($event)" [color]="buttonColor" [disabled]="disabled"
            [matTooltip]="tooltip | translate"
            [ngClass]="cssClasses"
            class="progress-bar-button" data-cy='iot-platform-ui-progress-bar-button' mat-raised-button>
        <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
        <mat-progress-bar
                *ngIf="enableGauge"
                [bufferValue]="bufferValue"
                [color]="gaugeColor"
                [mode]="mode"
                [value]="value"
                class="progress-bar-button-gauge"
        ></mat-progress-bar>
        <span class="progress-bar-button-title">{{ title }}</span>
    </button>
</ng-container>

<ng-container *ngIf="fabIconButton">
    <button (click)="onClick($event)" [color]="buttonColor" [disabled]="disabled"
            [matTooltip]="tooltip | translate"
            [ngClass]="cssClasses" class="progress-spinner-button"
            data-cy='iot-platform-ui-progress-bar-button' mat-mini-fab>
        <mat-icon>{{ icon }}</mat-icon>
        <mat-progress-spinner
                *ngIf="enableGauge"
                [color]="spinnerColor"
                [diameter]="value"
                [mode]="mode"
                class="progress-spinner-button__gauge"></mat-progress-spinner>
    </button>
</ng-container>
