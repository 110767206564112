<div data-cy="iot-platform-ui-date-range" fxLayout="column">
  <form
    [formGroup]="dateForm"
    data-cy="iot-platform-ui-date-range-form"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-sm="center center"
    fxLayoutGap="20px"
  >
    <div fxFlex="50" fxFlex.lt-sm="100%" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-form-field fxFlex="50" class="datepicker" color="accent">
        <mat-label>{{ placeholderFrom | translate }}</mat-label>
        <input
          (focus)="fromPicker.open()"
          [matDatepicker]="fromPicker"
          [max]="endDate?.value"
          [required]="required"
          data-cy="iot-platform-ui-date-range-start-date"
          formControlName="startDate"
          matInput
        />
        <mat-datepicker-toggle [disableRipple]="true" [for]="fromPicker" matTextSuffix>
          <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>

        @if (startDate.value) {
          <mat-hint (click)="resetStartDate()">{{ placeholderClear | translate }}</mat-hint>
        }
        @if (startDate.invalid && startDate.errors.required) {
          <mat-error>{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
        }
        @if (startDate.invalid && startDate.errors.matDatepickerMax) {
          <mat-error>{{ 'FORM.ERROR_MESSAGE.INVALID_DATE_RANGE' | translate }}</mat-error>
        }
      </mat-form-field>

      <div
        [ngClass]="{ 'timepicker-visible': startDate.value }"
        class="timepicker-hidden"
        fxFlex="50"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="center center"
        fxLayoutGap="5px"
      >
        <mat-form-field fxFlex="50" class="timepicker-select" color="accent">
          <mat-label>{{ placeholderHours | translate }}</mat-label>
          <mat-select [disableRipple]="true" data-cy="iot-platform-ui-date-range-start-hours" formControlName="startHours">
            @for (hour of hours; track hour) {
              <mat-option [value]="hour" data-cy="iot-platform-ui-date-range-start-hours-option">{{ hour }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <span>:</span>
        <mat-form-field fxFlex="50" class="timepicker-select" color="accent">
          <mat-label>{{ placeholderMinutes | translate }}</mat-label>
          <mat-select [disableRipple]="true" data-cy="iot-platform-ui-date-range-start-minutes" formControlName="startMinutes">
            @for (minute of minutes; track minute) {
              <mat-option [value]="minute" data-cy="iot-platform-ui-date-range-start-minutes-option">{{ minute }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="50" fxFlex.lt-sm="100%" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-form-field class="datepicker" color="accent">
        <mat-label>{{ placeholderTo | translate }}</mat-label>
        <input
          (focus)="toPicker.open()"
          [matDatepicker]="toPicker"
          [min]="startDate?.value"
          [required]="required"
          data-cy="iot-platform-ui-date-range-end-date"
          formControlName="endDate"
          matInput
        />
        <mat-datepicker-toggle [disableRipple]="true" [for]="toPicker" matTextSuffix>
          <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
        @if (endDate.value) {
          <mat-hint (click)="resetEndDate()">{{ placeholderClear | translate }}</mat-hint>
        }
        @if (endDate.invalid && endDate.errors.required) {
          <mat-error>{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
        }
        @if (endDate.invalid && endDate.errors.matDatepickerMin) {
          <mat-error>{{ 'FORM.ERROR_MESSAGE.INVALID_DATE_RANGE' | translate }}</mat-error>
        }
      </mat-form-field>

      <div
        [ngClass]="{ 'timepicker-visible': endDate.value }"
        class="timepicker-hidden"
        fxFlex="50"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="center center"
        fxLayoutGap="5px"
      >
        <mat-form-field fxFlex="50" class="timepicker-select" color="accent">
          <mat-label>{{ placeholderHours | translate }}</mat-label>
          <mat-select [disableRipple]="true" data-cy="iot-platform-ui-date-range-end-hours" formControlName="endHours">
            @for (hour of hours; track hour) {
              <mat-option [value]="hour" data-cy="iot-platform-ui-date-range-end-hours-option">{{ hour }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <span>:</span>
        <mat-form-field fxFlex="50" class="timepicker-select" color="accent">
          <mat-label>{{ placeholderMinutes | translate }}</mat-label>
          <mat-select [disableRipple]="true" data-cy="iot-platform-ui-date-range-end-minutes" formControlName="endMinutes">
            @for (minute of minutes; track minute) {
              <mat-option [value]="minute" data-cy="iot-platform-ui-date-range-end-minutes-option">{{ minute }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  @if (dateForm?.errors?.invalidRange) {
    <mat-error fxFlex>{{ 'FORM.ERROR_MESSAGE.INVALID_DATE_RANGE_IN_MONTHS' | translate: { value: diffInMonths } }}</mat-error>
  }
</div>
