<div class="default-theme app-shell" data-cy="feature-app-shell">
  <mat-sidenav-container (backdropClick)="closePreferences()" class="main-container">
    <mat-sidenav #sidenav [opened]="sidenavState().isOpen" class="navigation-bar" mode="side" data-cy="feature-app-shell-sidenav">
      <div class="navigation-bar-content" fxFlex fxLayout="column" fxLayoutAlign="space-between">
        @if (navigationTree()) {
          <mat-nav-list class="sidenav">
            @for (navBlock of navigationTree(); track navBlock.id) {
              <iot-platform-ui-sidenav-block data-cy="feature-app-shell-sidenav-block" [navBlock]="navBlock"></iot-platform-ui-sidenav-block>
            } @empty {
              ...
            }

          </mat-nav-list>
        }
        <iot-platform-ui-build-info (openReleaseNotes)="openReleaseNotes()" class="sticky"></iot-platform-ui-build-info>
      </div>
    </mat-sidenav>

    <mat-sidenav #chatBot [opened]="chatBotOpen()" mode="side" position="end" >
      <iot-platform-ui-chat [chat]="chat()" (dispatchAction)="onDispatchBotAction($event)"></iot-platform-ui-chat>
    </mat-sidenav>

    <mat-sidenav-content class="main-sidenav-content">
      <div class="sticky">
        <iot-platform-ui-header
          data-cy="feature-app-shell-header"
          (businessProfileListClicked)="refreshBusinessProfileList()"
          (changeBusinessProfile)="onChangeBusinessProfile($event)"
          (chatBotClicked)="onChatBotClicked()"
          (logoClicked)="returnHome()"
          (logout)="logout()"
          (menuClicked)="toggleSidenav()"
          (myProfile)="openMyProfile()"
          [businessProfiles]="businessProfiles()"
          [currentUserLoading]="currentUserLoading()"
          [currentUser]="currentUser()"
          [allowAIChatBot]="settings.allowChatBot"
          [allowSwitchBusinessProfile]="settings.allowSwitchBusinessProfile"
          [logo]="settings.logo"
          [allowRedirectToMyProfile]="settings.allowRedirectToMyProfile"
          [selectedBusinessProfile]="businessProfile()"
        >
        </iot-platform-ui-header>
      </div>
      <div class="main-sidenav-body">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
