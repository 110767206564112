import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ENVIRONMENT, LocalStorageService } from '@iot-platform/core';
import { Chat, ChatCustomComponent, ChatEvent, ChatMessage, Environment, IotAction, UserAccount } from '@iot-platform/models/common';
import { Log, LogType } from '@iot-platform/models/i4b';
import * as moment from 'moment';
import { AiCredentialsDialogComponent } from '../../../../shared-iot4bos-ui/src/lib/ai-credentials-dialog/ai-credentials-dialog.component';
import { ChatbotSourceCommentComponent } from '../../../../shared-iot4bos-ui/src/lib/comments/chatbot-source-comment/chatbot-source-comment.component';

interface QBusinessResponse {
  conversationId: string;
  date: string;
  failedAttachments: [];
  sourceAttributions: {
    citationNumber: number;
    title: string;
    url: string;
  }[];
  systemMessage: string;
  systemMessageId: string;
  userMessageId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AiChatBotService {
  chat: Signal<Chat>;
  commentsLoading: WritableSignal<boolean> = signal(false);
  chatMessages: WritableSignal<Log[]> = signal([
    {
      type: LogType.USER,
      id: 'toto',
      icon: 'smart_toy',
      comment: 'I’m your AI assistant. Enter a prompt to start a conversation.',
      datetime: moment().toISOString(),
      user: { id: '', name: 'AI Bot' }
    }
  ] as Log[]);
  userMessage: WritableSignal<string> = signal(undefined);

  customStyleForUserComment = { alignItems: 'flex-end', textAlign: 'end' };

  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly localStorage: LocalStorageService = inject(LocalStorageService);
  private readonly dialog: MatDialog = inject(MatDialog);

  constructor() {
    this.chat = computed(() => {
      return {
        header: {
          title: signal('AI chatbot'),
          icon: signal('smart_toy'),
          count: signal(undefined),
          loading: signal(false),
          backgroundColor: signal('#4f7ab7'),
          textColor: signal('#fff'),
          action: signal(undefined)
        },
        body: {
          header: undefined,
          content: {
            messages: signal(
              this.chatMessages()?.map((comment: Log): ChatMessage | ChatCustomComponent => {
                if (comment.type === LogType.USER) {
                  return {
                    id: signal(comment.id),
                    text: signal(comment.comment),
                    author: signal(comment.user.name),
                    date: signal(comment.datetime),
                    icon: signal(comment.icon),
                    withActions: signal(false),
                    customStyle: signal(!comment.id ? this.customStyleForUserComment : null)
                  };
                } else {
                  return { ref: ChatbotSourceCommentComponent, inputs: { source: comment } };
                }
              })
            ),
            emptyBodyLabel: signal('COMMENTS.NO_COMMENTS_FOUND'),
            loading: this.commentsLoading,
            conversationLoader: signal(true)
          }
        },
        footer: {
          label: signal('COMMENTS.INPUT_PLACEHOLDER'),
          backgroundColor: signal('#4f7ab7'),
          textColor: signal('#fff'),
          action: signal({
            name: signal(IotAction.ADD),
            icon: signal('send'),
            canDo: signal(true)
          })
        },
        options: {
          height: signal('100%'),
          width: signal('310px')
        }
      };
    });
  }

  openCredentialsDialog(): void {
    this.dialog
      .open(AiCredentialsDialogComponent, { width: '600px' })
      .afterClosed()
      .subscribe((response) => this.localStorage.set('ai_chat_bot', JSON.stringify(response)));
  }

  addMessage(event: ChatEvent, currentUser: UserAccount) {
    this.commentsLoading.set(true);

    const body = {
      userMessage: event.value
    };

    this.chatMessages.set([...this.chatMessages(), this.formatUserMessageAsLog(event, currentUser)]);

    this.httpClient.post<QBusinessResponse>(`${this.environment.api.url}/chat/prompt`, body).subscribe((response: QBusinessResponse) => {
      this.chatMessages.set([...this.chatMessages(), this.formatQBusinessResponseAsLog(response)]);

      if (response.sourceAttributions.length) {
        const sources = response.sourceAttributions.reduce((acc, value) => {
          const source = { comment: `${value.citationNumber} - ${value.title}`, url: value.url, datetime: response.date };

          acc.push(source);
          return acc;
        }, []);

        this.chatMessages.set([...this.chatMessages(), ...sources]);
      }

      this.commentsLoading.set(false);
    });
  }

  formatUserMessageAsLog(event: ChatEvent, currentUser: UserAccount): Log {
    return {
      type: LogType.USER,
      comment: event.value,
      icon: 'person',
      user: { id: currentUser.id, name: `${currentUser.firstname} ${currentUser.lastname}` },
      datetime: moment().toISOString()
    } as Log;
  }

  formatQBusinessResponseAsLog(response: QBusinessResponse): Log {
    const formattedResponse = {
      type: LogType.USER,
      id: response.systemMessageId,
      comment: response.systemMessage,
      icon: 'smart_toy',
      user: { id: '', name: 'AI Bot' },
      datetime: response.date
    } as Log;

    return formattedResponse;
  }
}
