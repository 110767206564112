<div
        (click)="handleClick($event)"
        *ngIf="item.display"
        [class.inSubMenu]="item?.level > 0"
        [style.border-left]="'6px solid ' + item.leftBorderColor"
        class="container"
        data-cy="iot-platform-ui-sidenav-item"
>
    <a
            (click)="navigate()"
            (mouseenter)="mouseEnter($event)"
            (mouseleave)="mouseLeave($event)"
            *ngIf="!item.hasChildren"
            [lines]="1"
            [ngClass]="{ 'active-item': (url$ | async) === item.path && item.disableActiveState !== true }"
            class="item-container"
            data-cy="iot-platform-ui-sidenav-item-link"
            mat-list-item
    >
        <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="item-icon" matListItemIcon></mat-icon>
        <p [innerHTML]="item.name | translate" class="item-content" matListItemTitle
           title="{{ item.name | translate }}"></p>
        <div *ngIf="item.rightIcons" class="right-icon" matListItemMeta>
            <mat-icon *ngFor="let icon of item.rightIcons" class="item-icon">{{ icon }}</mat-icon>
        </div>
    </a>

    <ng-container *ngIf="item.hasChildren">
        <div #trigger="matMenuTrigger" (mouseenter)="mouseEnter($event, trigger)" (mouseleave)="mouseLeave($event)"
             [matMenuTriggerFor]="subMenu" data-cy="iot-platform-ui-sidenav-sub-item">
            <a
                    *ngIf="item.path"
                    [lines]="1"
                    [ngClass]="{ 'active-item': (url$ | async) === item.path && item.disableActiveState !== true }"
                    [routerLink]="[item.path ?? '']"
                    class="item-container"
                    data-cy="iot-platform-ui-sidenav-sub-item-link"
                    mat-list-item
            >
                <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="item-icon" matListItemIcon></mat-icon>
                <p [innerHTML]="item.name | translate" class="item-content" matListItemTitle></p>
                <div matListItemMeta>
                    <mat-icon class="chevron-right">chevron_right</mat-icon>
                </div>
            </a>
        </div>

        <mat-menu #subMenu="matMenu" backdropClass="sidenav-backdrop-class" class="sidenav-menu-panel"
                  xPosition="after">
            <div *ngFor="let navBlock of item.submenu; trackBy: trackById">
                <iot-platform-ui-sidenav-block [navBlock]="navBlock"
                                               class="sidenav-menu-panel-block"></iot-platform-ui-sidenav-block>
            </div>
        </mat-menu>
    </ng-container>
</div>
