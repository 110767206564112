import { createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesApiActions, AuthBusinessProfilesPageActions } from '../actions';

export const authBusinessProfilesPageFeatureKey = 'authBusinessProfilesPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(AuthBusinessProfilesApiActions.selectBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false })),

  on(AuthBusinessProfilesPageActions.loadBusinessProfiles, (state: State) => ({ ...state, error: null, pending: true })),
  on(AuthBusinessProfilesApiActions.loadBusinessProfilesSuccess, (state: State) => ({ ...state, error: null, pending: false })),
  on(AuthBusinessProfilesApiActions.loadBusinessProfilesFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
