<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ 'ADMIN.TRANSLATION_MANAGEMENT.CREATE_FORM.TITLE' | translate }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content">
        <form [formGroup]="createForm" class="full-width p-20" fxLayout="column">

            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
                <mat-form-field color="accent" fxFlex>
                    <mat-label>{{ 'ADMIN.TRANSLATION_MANAGEMENT.CREATE_FORM.LANGUAGE_KEY' | translate | uppercase }}</mat-label>
                    <input [required]="true" formControlName="key" matInput placeholder=""/>
                    <ng-container *ngIf="languageKey?.invalid && languageKey?.dirty">
                        <mat-error
                                *ngIf="languageKey?.errors?.required">{{ 'ADMIN.TRANSLATION_MANAGEMENT.ERROR_MESSAGE.REQUIRED_KEY' | translate }}</mat-error>
                        <mat-error
                                *ngIf="languageKey?.errors?.exist">{{ 'ADMIN.TRANSLATION_MANAGEMENT.ERROR_MESSAGE.LANGUAGE_EXIST' | translate }}</mat-error>
                    </ng-container>
                </mat-form-field>
            </div>

        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular"
                mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
        <button (click)="save()" [disabled]="!createForm.valid || !createForm.touched" class="button-regular"
                color="accent" mat-raised-button>
            {{ 'IOT_DICTIONARY.CREATE' | translate | uppercase }}
        </button>
    </mat-card-actions>
</mat-card>
