<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.TITLE_ADMIN_POPUP' | translate }}</h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content">
        <form [formGroup]="columnsForm" class="p-20" fxFlexAlign="start start" fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="20" fxLayout="column" fxLayoutGap="10px">
                <!--        <mat-form-field color="accent">-->
                <!--          <input matInput #gridName formControlName="name" [placeholder]="'GRID_ENGINE.GRID_MANAGER_POPUP.GRID_NAME' | translate" maxlength="30" required />-->
                <!--          <mat-hint align="end">{{ gridName.value?.length ?? 0 }}/30</mat-hint>-->
                <!--        </mat-form-field>-->

                <mat-form-field color="accent">
                    <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.MASTER_VIEW' | translate }}</mat-label>
                    <mat-select (selectionChange)="onMasterViewChange($event)" formControlName="viewType">
                        <mat-option *ngFor="let gridDefinition of defaultGridsDefinitions"
                                    [value]="gridDefinition">{{ gridDefinition.viewType }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxFlex="65" fxLayout="column" fxLayoutGap="10px">
                    <mat-label>{{ 'GRID_ENGINE.DATA_LIBRARIES' | translate }}</mat-label>
                    <mat-radio-group
                            (change)="onLibraryChange($event)"
                            class="data-libraries-selection"
                            color="accent"
                            formControlName="selectedLibrary"
                            fxLayout="column"
                    >
                        <mat-radio-button *ngFor="let datalib of (selectedViewDef$ | async)?.dataLibraries"
                                          [value]="datalib">{{
                          'GRID_ENGINE.CONCEPT_LIST.' + datalib.libraryName | translate
                            }}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row">
                    <mat-form-field color="accent" fxFlex="50">
                        <mat-label>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.PAGINATION' | translate }}</mat-label>
                        <mat-select [placeholder]="" formControlName="pageSize">
                            <mat-option *ngFor="let pageSize of gridPageSizeList"
                                        [value]="pageSize">{{ pageSize }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field color="accent" fxFlex="48%">
                        <mat-label>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.AUTO_REFRESH' | translate }}</mat-label>
                        <mat-select formControlName="autoRefreshDelay">
                            <mat-option
                                    [value]="0">{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.NO_REFRESH' | translate }}</mat-option>
                            <mat-option *ngFor="let delay of delayList" [value]="delay">{{ delay }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <!--          <mat-checkbox formControlName="isAppDefault">-->
                <!--            &lt;!&ndash; {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.DEFAULT' | translate }}&ndash;&gt;-->
                <!--            APP DEFAULT-->
                <!--          </mat-checkbox>-->
                <!--          <mat-checkbox formControlName="isMineDefault">-->
                <!--            &lt;!&ndash; {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.DEFAULT' | translate }}&ndash;&gt;-->
                <!--            MINE DEFAULT-->
                <!--          </mat-checkbox>-->
                <span class="dialog-form-tips">*{{ 'GRAPH.CREATE_FORM.MANDATORY_FIELDS' | translate }}</span>
            </div>

            <div fxFlex="80" fxFlexAlign="start start">
                <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
                    <!-- {{ selectedViewDef$ | async }}
                      <iot-platform-ui-filter-engine *ngIf="selectedViewDef$ | async"
                        [masterView]="'sites'"
                       [currentFilters$]="getFilters()"
                        expanded="true"
                      >
                      </iot-platform-ui-filter-engine>-->
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxFlex="38" fxLayout="column">
                            <p style="height: 40px; line-height: 40px">
                                {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.AVAILABLE_COLUMNS' | translate: { totalColumns: availableColumns?.length ?? '' } }}
                            </p>
                            <div
                              #availableColumnsList="cdkDropList"
                              (cdkDropListDropped)="drop($event)"
                              [cdkDropListConnectedTo]="[selectedColumnsList]"
                              [cdkDropListData]="availableColumns"
                              [cdkDropListEnterPredicate]="allowDragColumns()"
                              cdkDropList
                              class="dd-list available-list"
                            >
                                <div *ngFor="let column of availableColumns" [cdkDragData]="column"
                                     cdkDrag class="accordeon-item dd-item" fxLayoutAlign="start center">
                                    <p class="concept"
                                       fxFlex="35">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                                    <p fxFlex="65">{{ column.header.displayName }}</p>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <button (click)="addBasicColumn()" [disabled]="!selectedViewDef$.getValue()"
                                        class="button-regular" color="accent"
                                        fxFlex mat-button>
                                    {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.ADD_BASIC_COLUMN' | translate }}
                                </button>
                                <button
                                        (click)="addVariableColumn()"
                                        [disabled]="!selectedViewDef$.getValue() || selectedViewDef$.getValue()?.viewType !== 'ASSETS'"
                                        class="button-regular"
                                        color="accent"
                                        fxFlex
                                        mat-button
                                >
                                    {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.ADD_VARIABLE_COLUMN' | translate }}
                                </button>
                            </div>
                        </div>

                        <div fxFlex="58">
                            <div fxLayoutAlign="space-between center">
                                <p>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SELECTED_COLUMNS' | translate: { totalColumns: selectedColumns.length ?? '' } }}</p>
                                <p (click)="setDefaultView()" *ngIf="columnsForm.get('viewType').value"
                                   class="link">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_COLUMNS' | translate }}</p>
                                <!--<button
                                  mat-icon-button
                                  color="accent"
                                  [matTooltip]="'GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_COLUMNS' | translate"
                                  [matTooltipClass]="'regular-tooltip'"
                                  [disabled]="!columnsForm.get('viewType').value"
                                  (click)="setDefaultView()"
                                >
                                  <mat-icon>refresh</mat-icon>
                                </button>-->
                            </div>
                            <div
                              #selectedColumnsList="cdkDropList"
                              (cdkDropListDropped)="drop($event)"
                              [cdkDropListConnectedTo]="[availableColumnsList]"
                              [cdkDropListData]="selectedColumns"
                              cdkDropList
                              class="dd-list selected-list"
                            >
                                <mat-expansion-panel
                                        *ngFor="let column of selectedColumns; let index = index"
                                        [cdkDragData]="column"
                                        [hideToggle]="!column.configuration.isConfigurable"
                                        cdkDrag
                                        class="dd-item"
                                        fxLayout="column"
                                >
                                    <mat-expansion-panel-header class="accordeon-item" fxLayoutGap="10px">
                                        <mat-panel-title fxFlex="30" fxLayoutGap="10px">
                                            <p>{{ index + 1 }}</p>
                                            <p class="concept">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                                        </mat-panel-title>
                                        <mat-panel-description fxFlex="70">
                                            {{ getHeaderValue(column) }}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div fxLayout="column">
                                        <div
                                                *ngIf="column?.configuration?.isConfigurable"
                                                class="dd-item--expanded-area"
                                                fxLayout="row"
                                                fxLayoutAlign="start center"
                                                fxLayoutGap="10px"
                                        >
                                            <mat-form-field color="accent" fxFlex>
                                                <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.CUSTOM_HEADER' | translate }}</mat-label>
                                                <input #customHeader [value]="column.options.overrideHeaderName ?? null"
                                                       matInput/>
                                            </mat-form-field>
                                            <button
                                                    (click)="applyNewHeader(column, customHeader.value.trim())"
                                                    [disabled]="!customHeader.value.trim()"
                                                    color="accent"
                                                    mat-button
                                                    type="button"
                                            >
                                                {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                                            </button>
                                            <button (click)="resetHeaderName(column)" color="warn" mat-icon-button>
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                        <div
                                                *ngIf="column?.configuration?.isConfigurable && column.configuration.name !== 'variable'"
                                                class="dd-item--expanded-area"
                                                fxLayout="row"
                                                fxLayoutAlign="start center"
                                                fxLayoutGap="10px"
                                        >
                                            <mat-form-field color="accent" fxFlex>
                                                <mat-label>CUSTOM PROPERTY</mat-label>
                                                <input #customProperty [value]="column.configuration.id ?? null"
                                                       matInput/>
                                            </mat-form-field>
                                            <button (click)="applyNewProperty(column, customProperty.value)"
                                                    color="accent" mat-button
                                                    type="button">
                                                {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                                            </button>
                                        </div>

                                        <div
                                                *ngIf="column?.configuration?.isConfigurable && column.configuration.name === 'variable'"
                                                class="dd-item--expanded-area"
                                                fxLayout="row"
                                                fxLayoutAlign="start center"
                                                fxLayoutGap="10px"
                                        >
                                            <mat-form-field color="accent" fxFlex>
                                                <mat-label>CUSTOM VARIABLE</mat-label>
                                                <input #customVariable [value]="column.configuration.id ?? null"
                                                       matInput/>
                                            </mat-form-field>
                                            <button (click)="applyNewVariableName(column, customVariable.value)"
                                                    color="accent" mat-button
                                                    type="button">
                                                {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
        <button (click)="save()" [disabled]="isSubmissionDisabled()" class="button-regular" color="accent"
                mat-raised-button>
            {{ actionButtonLabel$ | async | translate }}
        </button>
    </mat-card-actions>
</mat-card>
