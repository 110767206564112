import { Injectable } from '@angular/core';
import { BaseFacade, DynamicDataResponse, Filter } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import * as Leaflet from 'leaflet';
import { Observable } from 'rxjs';
import { IotGeoJsonFeature, IotMapRequest } from '../../models';
import { IotGeoJsonRouteFeature } from '../../models/iot-geo-json-object.model';
import { MapActions } from '../actions';
import { mapUiState } from '../reducers';
import { MapSelectors } from '../selectors/map.selectors';

@Injectable({
  providedIn: 'root'
})
export class MapFacade extends BaseFacade<IotGeoJsonFeature, unknown, Filter> {
  concept$: Observable<string> = this.store.select(this.selector.selectConcept);
  featureCollection$: Observable<{ [concept: string]: IotGeoJsonFeature[] }> = this.store.select(this.selector.selectFeatureCollection);
  currentFeatures$: Observable<IotGeoJsonFeature[]> = this.store.select(this.selector.selectCurrentFeatures);
  currentRoutes$: Observable<IotGeoJsonRouteFeature[]> = this.store.select(this.selector.selectCurrentRoutes);
  routesLoading$: Observable<boolean> = this.store.select(this.selector.selectRoutesLoading);
  routesLoaded$: Observable<boolean> = this.store.select(this.selector.selectRoutesLoaded);
  hasRoutes$: Observable<boolean> = this.store.select(this.selector.selectHasRoutes);
  currentFeature$: Observable<IotGeoJsonFeature> = this.store.select(this.selector.selectCurrentFeature);
  data$: Observable<DynamicDataResponse> = this.store.select(this.selector.selectData);
  refresh$: Observable<boolean> = this.store.select(this.selector.refresh);
  mapUiState$: Observable<{
    center: Leaflet.LatLng;
    zoom: number;
    selectedMarker: Leaflet.Marker;
    hasLeavedMap: boolean;
    isReturningFromOutside: boolean;
  } | null> = this.store.select(mapUiState);

  constructor(
    protected override store: Store,
    protected override selector: MapSelectors
  ) {
    super(store, selector);
  }

  getAll(request: IotMapRequest): void {
    this.store.dispatch(MapActions.loadGeoLocations(request));
  }

  loadRoute(assetId: string, limit: number, start: Date, end: Date, minutesBetweenSegments: number, order: string): void {
    this.store.dispatch(MapActions.loadRoute({ assetId, limit, start, end, minutesBetweenSegments, order }));
  }

  refreshMap(): void {
    this.store.dispatch(MapActions.refreshMap());
  }

  loadMarkerDetails(feature: IotGeoJsonFeature): void {
    this.store.dispatch(MapActions.loadMarkerDetails({ feature }));
  }

  setFilters(filters: Filter[]): void {
    this.store.dispatch(MapActions.setFilters({ filters }));
  }

  setConcept(concept: string): void {
    this.store.dispatch(MapActions.setConcept({ concept }));
  }

  saveMapUiState(state: {
    center: Leaflet.LatLng;
    zoom: number;
    selectedMarker: Leaflet.Marker;
    hasLeavedMap: boolean;
    isReturningFromOutside: boolean;
  }): void {
    this.store.dispatch(MapActions.saveMapUiState({ mapUiState: state }));
  }

  clearRoutes() {
    this.store.dispatch(MapActions.clearRoutes());
  }
}
