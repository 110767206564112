<div
  *ngIf="!!assetVariable"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
  class="asset-variable-parameter"
  [ngClass]="{
    'valid-unit': !isUnitInvalid,
    'invalid-unit': isUnitInvalid,
    'untouched-form': !isTouched
  }"
>
  <mat-icon
    [matTooltip]="invalidUnitTooltip"
    [matTooltipDisabled]="!isUnitInvalid"
    matTooltipClass="warning-tooltip"
    [ngClass]="{
      'valid-unit-icon': !isUnitInvalid,
      'invalid-unit-icon': isUnitInvalid
    }"
  >
    {{ isUnitInvalid ? 'error_outline' : 'check' }}
  </mat-icon>
  <span fxFlex>{{ assetVariable.name }} ({{ assetVariable.unit }})</span>
  <span *ngIf="assetVariable.lastRecords?.length">
    {{ assetVariable.lastRecords[0].value | numberFormat | infoDisplay }}
    &nbsp;{{ assetVariable.unit | infoDisplay }}
  </span>
  <span *ngIf="assetVariable.lastRecords?.length">
    {{ assetVariable.lastRecords[0].datetime | dateFormat }}
  </span>
  <button mat-icon-button class="regular-round-button" (click)="clearFormControl.emit()"><mat-icon>close</mat-icon></button>
</div>

<div
  *ngIf="!assetVariable"
  class="asset-variable-parameter-placeholder"
  [ngClass]="{ 'first-empty-field': isFirstEmptyParameter }"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <span>{{ placeholder }}</span>
</div>
