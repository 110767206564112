<form [formGroup]="dynamicListForm" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
    <iot-platform-ui-async-autocomplete
            (selectionChanged)="onSelectionChange($event)"
            *ngIf="data.autocomplete && !data.multiSelect"
            [data]="dynamicList"
            [displayKey]="data.selectByProperty"
            [displaySearchIcon]="false"
            [displayWrapper]="data.displayWrapper"
            [filterKey]="data?.skipAutoCompleteFilterCriteria ? null : data.selectByProperty"
            [minLength]="1"
            [placeholder]="data.placeholder"
            [showSpinner]="showLoader"
            [standaloneMode]="true"
            [tooltip]="data?.tooltip"
    >
    </iot-platform-ui-async-autocomplete>

    <iot-platform-ui-async-autocomplete-multiple-selects
            (selectionChanged)="onSelectionChange($event)"
            *ngIf="data.autocomplete && data.multiSelect"
            [clearOnSelect]="true"
            [currentFilters$]="currentFilters$"
            [currentFiltersSize]="currentFiltersSize"
            [data]="dynamicList"
            [displayKey]="data.selectByProperty"
            [displaySearchIcon]="false"
            [displayWrapper]="data.displayWrapper"
            [filterKey]="data?.skipAutoCompleteFilterCriteria ? null : data.selectByProperty"
            [minLength]="1"
            [multiSelect]="data.multiSelect"
            [placeholder]="data.placeholder"
            [showSpinner]="showLoader"
            [standaloneMode]="true"
    >
    </iot-platform-ui-async-autocomplete-multiple-selects>

    <div *ngIf="!data.autocomplete">
        <mat-form-field class="select-field" color="accent">
            <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                <span class="filter-label" fxFlex="90">{{ data.placeholder ?? data.criteriaLabel | translate }}</span>
                <mat-progress-spinner *ngIf="showLoader" [diameter]="16" color="accent" fxFlex="10"
                                      mode="indeterminate"></mat-progress-spinner>
            </mat-label>
            <mat-select
                    (selectionChange)="onSelectionChange()"
                    [compareWith]="compareFn"
                    [multiple]="data.multiSelect"
                    data-cy="iot-platform-ui-dynamic-list-field-single-select"
                    formControlName="select"
            >
                <ng-container *ngIf="!showLoader">
                    <mat-option
                            *ngFor="let option of dynamicList"
                            [disabled]="currentFiltersSize >= maxFilters"
                            [value]="option"
                            data-cy="iot-platform-ui-dynamic-list-field-single-select-option"
                    >{{ getOptionToDisplay(option) }}</mat-option
                    >
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</form>
