import { Sort } from '@angular/material/sort';
import { CommonApiRequest, FavoriteView, Filter, gridSortModel, PlatformRequest, Product, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, CommandType } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadAssets = createAction('[Assets] Load Assets', props<{ request: PlatformRequest }>());
export const loadMVSettings = createAction('[Assets] Load MV Assets Settings', props<{ settingName: string }>());
export const saveMVSettings = createAction('[Assets] Save MV Assets Settings', props<{ name: string; values: any }>());
export const addAsset = createAction('[Assets] Add Asset', props<{ assetToAdd: Asset }>());
export const addAssetVariable = createAction('[Assets] Add Asset Variable', props<{ assetVariableToAdd: AssetVariable }>());
export const loadAssetById = createAction('[Assets] Load Asset By Id', props<{ assetId: string }>());
export const loadTagsByAssetId = createAction('[Assets] Load Tags By Asset Id', props<{ assetId: string }>());
export const updateTagsByAssetId = createAction('[Assets] Update Tags By Asset Id', props<{ assetId: string; tags: TagCategory[] }>());
export const selectAsset = createAction('[Assets] Select Asset State', props<{ selectedAsset: Asset }>());
export const saveCurrentFavoriteView = createAction('[Assets] Save Current Favorite View', props<{ currentFavoriteView: FavoriteView }>());
export const saveCurrentFilters = createAction('[Assets] Save Current Filters', props<{ currentFilters: Filter[] }>());
export const loadVariablesByAssetId = createAction('[Assets] Load Variables By Asset Id', props<{ assetId: string }>());
export const updateAsset = createAction('[Assets] Update Asset', props<{ assetToUpdate: Asset }>());
export const updateAssetFromMV = createAction('[Assets] Update Asset from MV', props<{ assetToUpdate: Asset; request: CommonApiRequest }>());
export const deleteAsset = createAction('[Assets] Delete Asset', props<{ assetToDelete: Asset }>());
export const updateAssetVariable = createAction('[Assets] Update Asset Variable', props<{ assetVariableToUpdate: AssetVariable }>());
export const updateAssetVariableThresholds = createAction('[Assets] Update Asset Variable Thresholds', props<{ assetVariableToUpdate: AssetVariable }>());
export const updateFollowedAssetVariable = createAction('[Assets] Update Followed Asset Variable', props<{ assetVariableToUpdate: AssetVariable }>());
export const deleteAssetVariables = createAction('[Assets] Delete Asset Variables', props<{ assetVariablesToDelete: AssetVariable[] }>());
export const loadSiteById = createAction('[Assets] Load Site By Site Id', props<{ siteId: string }>());
export const assignProductToAsset = createAction('[Assets] Assign Product to Asset', props<{ product: Product; asset: Asset }>());
export const removeProductFromAsset = createAction('[Assets] Remove Product from Asset', props<{ product: Product; asset: Asset }>());
export const sendCommand = createAction('[Assets] Send Command', props<{ asset: Asset; command: { command: CommandType } }>());
export const saveInitialSort = createAction('[Assets] Save Initial sort', props<{ initialSort: gridSortModel[] | Sort }>());
export const addAssetByTemplateId = createAction('[Assets] Add Asset By Template Id', props<{ assetTemplateId: string; siteId: string }>());
export const resetAssetVariablesLastValues = createAction('[Assets] Reset Asset Variables Last Values', props<{ assetVariables: AssetVariable[] }>());
export const bulkAddOrRemoveTag = createAction('[Assets] Bulk Add or Remove Tag', props<{ isAddition: boolean; assetsIds: string[]; tagLabelId: string }>());
export const bulkSendCommand = createAction('[Assets] Bulk Send Command', props<{ assetsIds: string[]; command: { command: CommandType } }>());
