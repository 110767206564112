<mat-sidenav-container (backdropClick)="onCloseComments()" fxFlex hasBackdrop="true">
  <mat-sidenav-content fxLayout="column" fxLayoutGap="10px">
    @if (displayFilterEngine) {
      <iot-platform-ui-filter-engine
        [masterView]="masterview()"
        [expanded]="true"
        [displayActionButtons]="false"
        [withFavoriteFilters]="true"
        [currentFavoriteView]="currentFavoriteView$ | async"
        [currentFilters]="currentFilters$ | async"
        (applyFilters)="onApplyFilters($event)"
      ></iot-platform-ui-filter-engine>
    }

    <div class="fw-600 fs-16 events-total" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
      <span>{{ 'EVENTS.EVENTS_BY_CONCEPT.TITLE.' + currentEventType() | translate: { total: grid?.data.response.pagination?.total | numberFormat } }}</span>
      <hr class="regular-1px-line" fxFlex />
    </div>

    <grid-engine-grid-manager
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      [currentFilters]="currentFilters$ | async"
      [gridSort]="gridSort$ | async"
      [grid]="grid"
      [userPermissions]="userPermissions()"
      class="layout-master-view-engine-container"
    ></grid-engine-grid-manager>
  </mat-sidenav-content>

  <mat-sidenav #sidenav disableClose position="end">
    <shared-iot4bos-ui-comments-panel
      (commentsEvent)="onCommentsEvent($event)"
      [canDeleteComment]="canUpdateEvent"
      [canEditComment]="canUpdateEvent"
      [comments]="comments()"
      [defaultConcept]="CONCEPT.EVENT"
      [filters]="commentsContexts"
      [loading]="commentsLoading()"
      [withFilters]="true"
      [withFooter]="canUpdateEvent"
      data-cy="iot4bos-ui-events-comments"
    >
    </shared-iot4bos-ui-comments-panel>
  </mat-sidenav>
</mat-sidenav-container>
