import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DetailPopupDisplayPropertyComponent } from './detail-popup-display-property/detail-popup-display-property.component';
import { DetailPopupMetadataFooterComponent } from './detail-popup-metadata-footer/detail-popup-metadata-footer.component';
import { DetailPopupSectionHeaderComponent } from './detail-popup-section-header/detail-popup-section-header.component';

@NgModule({
  imports: [CommonModule, FlexModule, IotPlatformPipesModule, TranslateModule, MatProgressSpinnerModule, MatIconModule, MatTooltipModule],
  declarations: [DetailPopupSectionHeaderComponent, DetailPopupDisplayPropertyComponent, DetailPopupMetadataFooterComponent],
  exports: [DetailPopupSectionHeaderComponent, DetailPopupDisplayPropertyComponent, DetailPopupMetadataFooterComponent]
})
export class DetailPopupModule {}
