<div
  class="overview-card iot-platform-ui-overview-card {{ currentCard()?.cssClass }}"
  data-cy="iot-platform-ui-overview-card"
  fxFlex.lt-md="100%"
  fxLayout="row"
  fxLayout.lt-md="column"
>
  @if (loading) {
    <iot-platform-ui-card-loader data-cy="iot-platform-ui-overview-card-loader" fxFlex
                                 [backgroundClass]="'mv-detail-card h-140'" />
  } @else {
    @if (currentCard()?.image || currentCard()?.defaultImage) {
      <div class="overview-card-col overview-card-img-container" fxFlex="40%" fxFlex.lt-md="100%">
        <img data-cy="iot-platform-ui-overview-card-image" [src]="currentCard()?.image ?? currentCard()?.defaultImage"
             class="overview-card-img"
             (error)="onImgError()" />
      </div>
    } @else if (currentCard()?.defaultSvgIcon) {
      <div class="overview-card-col overview-card-img-container overview-card-img-container--default-svg" fxFlex="35%"
           fxFlex.lt-md="100%">
        <mat-icon class="overview-card-icon" data-cy="iot-platform-ui-overview-card-image"
                  [svgIcon]="currentCard()?.defaultSvgIcon" />
      </div>
    }
    <div class="overview-card-col" fxFlex>
      <div class="overview-card-header" fxLayout="row" fxLayoutAlign="start stretch">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-bottom: 16px">
          @if (currentCard()?.name) {
            <div data-cy="iot-platform-ui-overview-card-title" class="overview-card-name" fxLayout="row"
                 fxLayoutGap="5px">
              <span>{{ currentCard()?.name | infoDisplay }}</span>
              <ng-container [ngTemplateOutlet]="tmplValueMeta"
                            [ngTemplateOutletContext]="{ property: currentCard() }" />
            </div>
          }
          <ng-content select="[cardNameMeta]" />

          @if (currentCard()?.nameMetaAction) {
            <ng-container [ngTemplateOutlet]="tmplAction"
                          [ngTemplateOutletContext]="{ action: currentCard()?.nameMetaAction }" />
          }
        </div>

        @if (canDisplayCTA()) {
          <div class="overview-card-buttons app-round-button" data-cy="iot-platform-ui-overview-card-actions">
            <button #overviewCardMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="overviewCardMenu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #overviewCardMenu="matMenu">
              @for (action of currentCard()?.actions; track action.label) {
                @if ((action?.visible !== undefined && action.visible) || action?.visible === undefined) {
                  @if (action?.divider) {
                    <mat-divider></mat-divider>
                  }
                  <div fxLayoutAlign="start center" fxLayoutGap="6px">
                    <button
                      data-cy="iot-platform-ui-overview-card-action"
                      class="iot-platform-ui-overview-card-action-{{ action.type }}"
                      (click)="cardButtonClicked.emit(action.type)"
                      [disabled]="action.disabled"
                      mat-menu-item
                    >
                      {{ action.label | translate | uppercase }}
                    </button>
                    <ng-container [ngTemplateOutletContext]="{ property: action }" [ngTemplateOutlet]="tmplValueMeta" />
                  </div>
                }
              }
            </mat-menu>
          </div>
        }
      </div>

      <div class="overview-card-content">
        <mat-accordion multi>
          @for (section of currentCard()?.sections; track section; let sectionIndex = $index) {
            @if (!!section?.visibleExpression) {
              @if (section.visibleExpression(section?.data)) {
                <ng-container [ngTemplateOutlet]="tmplSection"
                              [ngTemplateOutletContext]="{ section: section, index: sectionIndex }" />
              }
            } @else {
              <ng-container [ngTemplateOutlet]="tmplSection"
                            [ngTemplateOutletContext]="{ section: section, index: sectionIndex }" />
            }
          }
        </mat-accordion>
      </div>
    </div>
  }
</div>

<ng-template #tmplSection let-index="index" let-section="section">
  @if (section?.collapsible) {
    <mat-expansion-panel
      data-cy="iot-platform-ui-overview-card-section-expansion-panel"
      class="iot-platform-ui-overview-card-section__expansion-panel {{ section?.cssClass }}"
      [expanded]="getSectionExpandedByIndex(section, index)"
      (afterExpand)="onSectionExpandChange(section, index, true)"
      (afterCollapse)="onSectionExpandChange(section, index, false)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container [ngTemplateOutlet]="tmplTitle" [ngTemplateOutletContext]="{ section: section }" />
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div
        data-cy="iot-platform-ui-overview-card-section"
        class="overview-card__section {{ section?.cssClass }}"
        [ngStyle]="section?.style"
        fxLayout="row wrap"
      >
        <ng-container [ngTemplateOutlet]="tmplProperties" [ngTemplateOutletContext]="{ section: section }" />
      </div>
    </mat-expansion-panel>
  } @else {
    <div data-cy="iot-platform-ui-overview-card-section" class="overview-card__section {{ section?.cssClass }}"
         [ngStyle]="section?.style" fxLayout="row wrap">
      <ng-container [ngTemplateOutlet]="tmplTitle" [ngTemplateOutletContext]="{ section: section }" />

      <ng-container [ngTemplateOutlet]="tmplProperties" [ngTemplateOutletContext]="{ section: section }" />
    </div>
  }
</ng-template>

<ng-template #tmplTitle let-section="section">
  <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center">
    @if (section?.title) {
      <iot-platform-ui-detail-popup-section-header
        data-cy="iot-platform-ui-overview-card-section-header"
        fxFlex="95%"
        class="overview-card-section-title"
        [headerTitle]="section.title | translate | uppercase"
      />
    }

    @if (section?.action) {
      <div fxFlex>
        <ng-container [ngTemplateOutlet]="tmplAction" [ngTemplateOutletContext]="{ action: section.action }" />
      </div>
    }
  </div>
</ng-template>

<ng-template #tmplProperties let-section="section">
  @for (property of section.properties; track property.label) {
    @if (property?.componentRef) {
      @if (!!property?.visibleExpression) {
        @if (property.visibleExpression(property?.data)) {
          <ng-container [ngTemplateOutlet]="tmplComponentRef" [ngTemplateOutletContext]="{ property: property }" />
        }
      } @else {
        <ng-container [ngTemplateOutlet]="tmplComponentRef" [ngTemplateOutletContext]="{ property: property }" />
      }
    } @else if (!!property?.visibleExpression) {
      @if (property?.visibleExpression(property?.data)) {
        <ng-container [ngTemplateOutlet]="tmplDisplayProperty" [ngTemplateOutletContext]="{ property: property }" />
      }
    } @else {
      <ng-container [ngTemplateOutlet]="tmplDisplayProperty" [ngTemplateOutletContext]="{ property: property }" />
    }
  }
</ng-template>

<ng-template #tmplComponentRef let-property="property">
  @if (property?.label) {
    <div
      [fxFlex]="property.width !== undefined && property.width !== null ? property.width : '100%'"
      class="overview-card__property {{ property?.cssClass }}"
      [ngStyle]="property?.style"
      data-cy="iot-platform-ui-overview-card-section-property"
      fxFlex.lt-md="100%"
      fxFlexLayout="row wrap"
      fxLayoutGap="10px"
    >
      @if (currentCard()?.justifyContent) {
        <span fxFlex="45%"
              class="capitalize-first-letter overview-card__property-label">{{ property.label | translate }}</span>
      } @else {
        <span class="capitalize-first-letter overview-card__property-label">{{ property.label | translate }}</span>
      }
      <span [matTooltip]="property.value" class="overview-card__property-value" matTooltipClass="regular-tooltip">
        <ng-container *ngComponentOutlet="property.componentRef.ref; inputs: property.componentRef?.inputs" />
      </span>
      <ng-container [ngTemplateOutlet]="tmplValueMeta" [ngTemplateOutletContext]="{ property: property }" />
    </div>
  } @else {
    <div [fxFlex]="property.width !== undefined && property.width !== null ? property.width : '100%'"
         fxFlex.lt-md="100%">
      <ng-container *ngComponentOutlet="property.componentRef.ref; inputs: property.componentRef?.inputs" />
      <ng-container [ngTemplateOutlet]="tmplValueMeta" [ngTemplateOutletContext]="{ property: property }" />
    </div>
  }
</ng-template>

<ng-template #tmplDisplayProperty let-property="property">
  <div
    [fxFlex]="property.width"
    [ngStyle]="property?.style"
    class="overview-card__property {{ property?.cssClass }}"
    data-cy="iot-platform-ui-overview-card-section-property"
    fxFlex.lt-md="100%"
    fxFlexLayout="row"
    fxLayoutGap="10px"
  >
    @if (currentCard()?.justifyContent) {
      <span fxFlex="45%"
            class="capitalize-first-letter overview-card__property-label">{{ property.label | translate }}</span>
    } @else {
      <span class="capitalize-first-letter overview-card__property-label">{{ property.label | translate }}</span>
    }
    <span (click)="property?.onClick?.($event)" [matTooltip]="property.value"
          [ngClass]="{'overview-card__property-value--clickable': !!property?.onClick}"
          class="overview-card__property-value"
          matTooltipClass="regular-tooltip">{{
        property.value | infoDisplay | translate
      }}</span>
    <ng-container [ngTemplateOutletContext]="{ property: property }" [ngTemplateOutlet]="tmplValueMeta" />
  </div>
</ng-template>

<ng-template #tmplValueMeta let-property="property">
  @if (property?.valueMeta?.icon) {
    @if (!!property?.valueMeta?.visibleExpression) {
      @if (property?.valueMeta?.visibleExpression?.(property?.data)) {
        <mat-icon class="overview-card__property-value-meta-icon"
                  [matTooltip]="property?.valueMeta?.tooltip | translate" matTooltipClass="regular-tooltip"
        >{{ property?.valueMeta?.icon }}
        </mat-icon>
      }
    } @else {
      <mat-icon class="overview-card__property-value-meta-icon" [matTooltip]="property?.valueMeta?.tooltip | translate"
                matTooltipClass="regular-tooltip"
      >{{ property?.valueMeta?.icon }}
      </mat-icon>
    }
  }
</ng-template>

<ng-template #tmplAction let-action="action">
  @if (action?.type && ((action?.visible !== undefined && action.visible) || action?.visible === undefined)) {
    <button
      (click)="$event.preventDefault(); $event.stopPropagation(); action?.dispatchEvent?.()"
      [disabled]="action?.disabled"
      class="light-button regular-round-button iot-platform-ui-overview-card__button-action iot-platform-ui-overview-card__button-action--{{ action.type }}"
      data-cy="iot-platform-ui-overview-card-button-action"
      [matTooltip]="action?.tooltip | translate"
      mat-mini-fab
      matTooltipClass="regular-tooltip"
      matTooltipPosition="below"
    >
      @if (action?.icon) {
        <mat-icon>{{ action?.icon }}</mat-icon>
      } @else {
        {{ action?.label | translate }}
      }
    </button>
  }
</ng-template>
