import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CronDisplayModule } from './cron-display/cron-display.module';
import { DateFormatModule } from './date-format/date-format.module';
import { EntityDisplayModule } from './entity-display/entity-display.module';
import { InfoDisplayModule } from './info-display/info-display.module';

import { LinkifyPipeModule } from './linkify-pipe/linkify-pipe.module';
import { NumberFormatPipeModule } from './number-format-pipe/number-format-pipe.module';
import { TruncateModule } from './truncate/truncate.module';
import { UserNameModule } from './user-name/user-name.module';
import { ValueUnitFormatPipeModule } from './value-unit-format/value-unit-format.pipe.module';

@NgModule({
  imports: [
    CommonModule,
    LinkifyPipeModule,
    NumberFormatPipeModule,
    CronDisplayModule,
    DateFormatModule,
    EntityDisplayModule,
    TruncateModule,
    InfoDisplayModule,
    UserNameModule,
    ValueUnitFormatPipeModule
  ],
  exports: [
    LinkifyPipeModule,
    NumberFormatPipeModule,
    CronDisplayModule,
    DateFormatModule,
    EntityDisplayModule,
    TruncateModule,
    InfoDisplayModule,
    UserNameModule,
    ValueUnitFormatPipeModule
  ],
  declarations: []
})
export class IotPlatformPipesModule {}
//
