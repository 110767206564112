import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'iot4bos-backoffice-ui-organization-concepts-selection-checkbox',
  templateUrl: './organization-concepts-selection-checkbox.component.html',
  styleUrls: ['./organization-concepts-selection-checkbox.component.scss']
})
export class OrganizationConceptsSelectionCheckboxComponent {
  @Input() concept: { name: string; isSelected: boolean };
  @Input() disabled: boolean;

  @Output() toggleConceptSelection: EventEmitter<{ name: string; isSelected: boolean }> = new EventEmitter<{ name: string; isSelected: boolean }>();

  conceptClone: { name: string; isSelected: boolean } = { name: '', isSelected: undefined };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('concept') && changes.concept.currentValue) {
      this.conceptClone = Object.assign({}, changes.concept.currentValue);
    }
  }

  toggleConceptToOrganization(conceptName: string, event: MatCheckboxChange) {
    this.toggleConceptSelection.emit({ name: conceptName, isSelected: event.checked });
  }
}
