<div (click)="onWidgetClick()" class="widget-item" fxLayout="column">
    <div [widget]="widget" fxFlex iotPlatformUiWidgetLoader></div>

    <div *ngIf="menu?.enabled" class="call-to-action" fxFlex>
        <button
                #menuTrigger="matMenuTrigger"
                (click)="$event?.stopPropagation()"
                [class.active]="menuTrigger.menuOpen"
                [matMenuTriggerFor]="widgetMenu"
                class="menu"
                mat-icon-button
        >
            <mat-icon fxAlign="end">more_vert</mat-icon>
        </button>
        <mat-menu #widgetMenu>
            <button (click)="onDispatchEvent(item?.name)" *ngFor="let item of menu?.actions" [disabled]="!item?.enabled"
                    mat-menu-item>
                {{ item?.label | translate }}
            </button>
        </mat-menu>
    </div>
</div>
