<div fxFlex fxLayout="column" fxLayoutGap="10px" [formGroup]="graphForm">
  <mat-form-field fxFlex color="accent">
    <mat-label>{{ 'GRAPH.CREATE_FORM.ASSET_PLACEHOLDER' | translate }}</mat-label>
    <mat-select #assetInput (selectionChange)="onAssetSelection($event)" formControlName="asset" required>
      @for (asset of assets; track asset.id) {
        <mat-option [value]="asset">{{ asset.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (allowMultiSelection) {
    <mat-form-field fxFlex color="accent">
      <mat-label>{{ 'GRAPH.CREATE_FORM.SELECT_ASSET_VARIABLES' | translate }}</mat-label>
      <mat-select [formControl]="variables" (selectionChange)="onVariableSelection($event)" multiple required>
        @for (v of allVariablesFilteredByAsset; track v.id) {
          <mat-option [value]="v">{{ v.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  } @else {
    <mat-form-field fxFlex color="accent">
      <mat-label>{{ 'GRAPH.CREATE_FORM.SELECT_ASSET_VARIABLES' | translate }}</mat-label>
      <mat-select [formControl]="variables" (selectionChange)="onVariableSelection($event)" required>
        @for (v of allVariablesFilteredByAsset; track v.id) {
          <mat-option [value]="v">{{ v.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</div>
