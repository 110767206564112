@if (isVisible) {
  @if (action?.divider) {
    <mat-divider></mat-divider>
  }
  <button
    data-cy="grid-engine-call-to-action-menu-item"
    mat-menu-item
    [disabled]="!isEnabled || isDisabled"
    (click)="onSingleActionClick(action.key)"
  >
    {{ action.label | translate | uppercase }}
  </button>
}
