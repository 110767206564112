import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, AuthModule, AuthService } from '@iot-platform/auth';

import { IotPlatformUiModule, UiModule } from '@iot-platform/iot-platform-ui';

import { Iot4bosBackofficeUiModule } from '@iot-platform/iot4bos-backoffice-ui';

import { LoaderModule } from '@iot-platform/notification';
import { provideOnCallManagementSettings } from '@iot-platform/on-call-management';

import { SharedModule } from '@iot-platform/shared';
import { provideFeatureAppShellSettings } from '@iot-platform/shared/feature-app-shell';

import { UserPreferencesService, UsersModule } from '@iot-platform/users';

import { TranslateService } from '@ngx-translate/core';
import { UserProfileShellComponent } from '../../../../../users/src/lib/features/profile/containers/user-profile-shell/user-profile-shell.component';

import { AssetDeviceAssociationsModule } from '../../components/asset-device-associations/asset-device-associations.module';
import { BreadcrumbModule } from '../../components/breadcrumb/breadcrumb.module';
import { EventDetailPopupComponent } from '../../components/event-detail-popup/event-detail-popup.component';
import { SitesFacade } from '../../features/sites/+state/facades/sites.facade';
import { provideSitesState } from '../../features/sites/+state/reducers';
import { TopicsService } from '../../services/topics.service';
import { AppShellService } from './app-shell.service';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@iot-platform/shared/feature-app-shell').then((m) => m.FeatureAppShellComponent),
    providers: [
      provideFeatureAppShellSettings({
        appShellService: AppShellService,
        allowChatBot: true,
        allowRedirectToMyProfile: true,
        allowSwitchBusinessProfile: true,
        logo: 'assets/images/LOGO_AIR_LIQUIDE.png'
      })
    ],
    children: [
      {
        path: 'home',
        loadChildren: () => import('../../features/home/home.module').then((module) => module.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sites',
        loadChildren: () => import('../info-display/info-display.module').then((module) => module.InfoDisplayModule),
        providers: [provideSitesState()],
        canActivate: [AuthGuard]
      },
      {
        path: 'stocks/:id',
        loadChildren: () => import('../../features/stock-management/stock-management.module').then((module) => module.StockManagementModule)
      },
      {
        path: 'devices',
        loadChildren: () => import('../../features/devices/devices.module').then((module) => module.DevicesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'assets',
        loadChildren: () => import('../../features/assets/assets.module').then((module) => module.AssetsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'asset-events',
        loadChildren: () => import('../../features/asset-events/asset-events.module').then((module) => module.AssetEventsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'device-events',
        loadChildren: () => import('../../features/device-events/device-events.module').then((module) => module.DeviceEventsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reporting',
        loadChildren: () => import('../../features/reporting/reporting.module').then((module) => module.ReportingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'po-event-rules',
        loadChildren: () => import('../../features/po-events/po-events.module').then((module) => module.PoEventsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('@iot-platform/iot4bos-backoffice-ui').then((module) => module.Iot4bosBackofficeUiModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'schedulers',
        loadChildren: () => import('../../features/schedulers/schedulers.module').then((m) => m.SchedulersModule)
      },
      {
        path: 'on-call-management',
        loadChildren: () => import('@iot-platform/on-call-management').then((module) => module.OnCallManagementModule),
        canActivate: [AuthGuard]
      },
      { path: 'my-profile', component: UserProfileShellComponent, canActivate: [AuthGuard] },
      { path: 'on-call-management', pathMatch: 'full', redirectTo: '/home' },
      { path: '', pathMatch: 'full', redirectTo: '/home' },
      { path: '**', redirectTo: '/home' }
    ]
  }
];

@NgModule({
  imports: [
    SharedModule,
    IotPlatformUiModule,
    UiModule,
    RouterModule.forChild(routes),
    BreadcrumbModule,
    AuthModule,
    UsersModule,
    Iot4bosBackofficeUiModule,
    LoaderModule,
    AssetDeviceAssociationsModule
  ],
  exports: [RouterModule],
  providers: [
    AuthService,
    TranslateService,
    SitesFacade,
    UserPreferencesService,
    provideOnCallManagementSettings({
      eventDetailPopupComponent: EventDetailPopupComponent,
      topicsService: TopicsService
    })
  ]
})
export class AppShellModule {}
