import { DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CachedUsersService } from '@iot-platform/core';
import { LightWeightUser } from '@iot-platform/models/common';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'userName',
  pure: false
})
export class UserNamePipe implements PipeTransform {
  userNameToDisplay = '-';
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly cachedUsersService: CachedUsersService = inject(CachedUsersService);

  transform(userId: string, format?: 'firstName' | 'lastName'): string {
    if (!!userId) {
      this.cachedUsersService
        .getUserById(userId)
        .pipe(
          map((user: LightWeightUser | undefined) => {
            if (user) {
              if (format === 'firstName') {
                return user.firstname;
              } else if (format === 'lastName') {
                return user.lastname;
              } else {
                return `${user.firstname} ${user.lastname}`;
              }
            } else {
              return undefined;
            }
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(
          (userName) => {
            this.userNameToDisplay = userName ?? this.userNameToDisplay;
          },
          () => {
            this.userNameToDisplay = 'N/A';
          }
        );
      return this.userNameToDisplay;
    } else {
      return '-';
    }
  }
}
