import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { GraphType } from '@iot-platform/models/common';
import { Asset, AssetVariable, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'iot-platform-dashboards-asset-variable-selector',
  templateUrl: './asset-variable-selector.component.html',
  styleUrls: ['./asset-variable-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetVariableSelectorComponent implements OnInit, OnDestroy {
  @Input() site: Site;
  @Input() assets: Asset[];
  @Input() control: AbstractControl;
  @Input() events$: Observable<AssetVariable[]>;
  @Input() disabled = false;
  @Input() variablesLoading = signal(true);
  @Input() allowMultiSelection = false;

  @Output() initialAssetVariablesState: EventEmitter<string> = new EventEmitter();

  graphForm: UntypedFormGroup;
  allVariables: AssetVariable[] = [];
  allVariablesFilteredByAsset: DeviceVariable[] | AssetVariable[] = [];
  selectedAssetId: string;
  selectedVariables: AssetVariable[] = [];
  private eventsSubscription: Subscription;
  GraphTypes = GraphType;

  constructor(
    private readonly assetsService: AssetVariablesService,
    private readonly controlContainer: ControlContainer
  ) {}

  get variables() {
    return this.graphForm.get('variables');
  }

  ngOnInit() {
    this.initForm();
    if (this.assets && this.assets.length > 0) {
      this.fetchAssetVariables();
    }
  }

  initForm(): void {
    const parent = this.control.parent;
    this.graphForm = new UntypedFormGroup({
      site: new UntypedFormControl({ value: this.site.name, disabled: true }, [Validators.required]),
      asset: new UntypedFormControl({ value: '', disabled: this.disabled }),
      variables: this.control
    });
    this.control.setParent(parent);
    this.eventsSubscription = this.events$.subscribe((vars: AssetVariable[]) => {
      this.selectedVariables = vars;
    });

    if (this.disabled) {
      this.variables.disable();
    }
  }

  onAssetSelection(event: MatSelectChange) {
    this.selectedAssetId = event.value.id;
    this.allVariablesFilteredByAsset = this.allVariables.filter((v: AssetVariable) => v.asset.id === event.value.id);
  }

  onVariableSelection(event: MatSelectChange) {
    const eventValues: AssetVariable[] = this.allowMultiSelection ? event.value : [event.value];
    if (!this.allowMultiSelection) {
      this.selectedVariables = [];
    }
    eventValues.forEach((v) => {
      if (!this.selectedVariables.includes(v)) {
        this.selectedVariables.push(v);
      }
    });

    this.selectedVariables = this.selectedVariables.reduce((acc, value: AssetVariable) => {
      if (value.asset.id === this.selectedAssetId) {
        if (eventValues.includes(value)) {
          acc.push(value);
        }
      } else {
        acc.push(value);
      }
      return acc;
    }, []);
    this.setVariablesValue(this.selectedVariables);
  }

  setVariablesValue(selectedAssetVariables: AssetVariable[]) {
    this.variables.setValue(selectedAssetVariables);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.controlContainer['form'].updateValueAndValidity();
  }

  fetchAssetVariables() {
    forkJoin(this.assets.map((value) => this.assetsService.getManyByAssetId(value.id, 1000)))
      .pipe(first())
      .subscribe((data: AssetVariable[][]) => {
        this.allVariables = [].concat(...data);
        this.variables.enable();
        this.setSelectedVariables();
        this.initialAssetVariablesState.emit(JSON.stringify(this.variables.value));
        this.variablesLoading.set(false);
        if (this.disabled) {
          this.graphForm.disable();
        }
      });
  }

  setSelectedVariables(): void {
    this.variables.value?.forEach((v: DeviceVariable | AssetVariable) => {
      const variable = this.allVariables.find((allVar) => allVar.id === v.id);
      if (variable) {
        this.selectedVariables.push(variable);
      }
    });

    this.setVariablesValue(this.selectedVariables);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
