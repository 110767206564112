import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as momentTimezones from 'moment-timezone';

import { BehaviorSubject } from 'rxjs';

type Timezone = { key: string; value: string };

@Component({
  selector: 'iot-platform-ui-timezone-autocomplete',
  templateUrl: './timezone-autocomplete.component.html',
  styleUrls: ['./timezone-autocomplete.component.scss']
})
export class TimezoneAutocompleteComponent implements OnInit {
  @Input() placeholder = 'IOT_DICTIONARY.TIMEZONE';
  @Input() initialItem: string | null = null;
  @Input() clearOnSelect = true;
  @Input() required = false;
  @Input() displayOffset = false;
  @Input() errorMessage!: string;

  @Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  timezones: Timezone[] = [];
  selectedTimezone$: BehaviorSubject<Timezone | undefined> = new BehaviorSubject<Timezone | undefined>(undefined);
  filteredTimezones$: BehaviorSubject<Timezone[]> = new BehaviorSubject<Timezone[]>([]);

  ngOnInit(): void {
    this.timezones = momentTimezones.tz
      .names()
      .map((timezone: string) => ({ key: this.displayOffset ? `${timezone} ${momentTimezones.tz(timezone).format('Z')}` : timezone, value: timezone }));

    this.filteredTimezones$.next([...this.timezones]);
    this.selectedTimezone$.next(this.timezones.find(({ value }) => value.toLowerCase().trim() === this.initialItem?.toLowerCase().trim()));
  }

  onSearch(timezone: string): void {
    this.filteredTimezones$.next(this.timezones.filter(({ key }) => key.toLowerCase().includes(timezone.toLowerCase())));
    this.reset.emit();
  }

  onReset(): void {
    this.filteredTimezones$.next([...this.timezones]);
    this.selectedTimezone$.next(undefined);
    this.reset.emit();
  }

  onSelect(selected: Timezone): void {
    if (selected) {
      this.selectionChange.emit(selected.value);
    }
  }
}
