import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, Inject, InjectionToken, Input, Optional, Signal } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AnalyticsService } from '@iot-platform/core';
import { PopupComponent } from 'libs/iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { PopupModule } from '../../../iot-platform-ui/src/lib/ui/components/popup/popup.module';
import { LoaderComponent } from './loader/loader.component';

import { NotificationService } from './notification.service';

export const DISABLE_ERROR_MESSAGES_AFTER_LOGOUT = new InjectionToken<boolean>('DISABLE_ERROR_MESSAGES_AFTER_LOGOUT');

@Component({
  standalone: true,
  imports: [AsyncPipe, LoaderComponent, MatSnackBarModule, MatDialogModule, PopupModule],
  selector: 'iot-platform-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  analytic: AnalyticsService = new AnalyticsService('display_error_popup');
  loader: Signal<boolean>;
  loggedOut!: boolean;

  constructor(
    readonly snackBar: MatSnackBar,
    readonly notificationService: NotificationService,
    readonly dialog: MatDialog,
    @Optional() @Inject(DISABLE_ERROR_MESSAGES_AFTER_LOGOUT) private disableErrorMessagesAfterLogout: boolean
  ) {
    this.loader = this.notificationService.loader;

    effect(
      () => {
        const error = this.notificationService.error();
        if (error) {
          this.displayError(error);
        }
      },
      { allowSignalWrites: true }
    );
    effect(
      () => {
        const success = this.notificationService.success();
        if (success) {
          this.displaySuccess(success);
        }
      },
      { allowSignalWrites: true }
    );
  }

  @Input() set isLoggedOut(val: boolean) {
    if (this.disableErrorMessagesAfterLogout) {
      this.loggedOut = val;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  public openErrorPopup(value: string | { code: string; error: string } | any) {
    // eslint-disable-next-line no-prototype-builtins
    if (value.hasOwnProperty('type')) {
      try {
        this.analytic.logError(
          value.type,
          `${value.error.error.code} - ${value.error.error.error}`,
          `${value.type} : ${value.error.error.code} - ${value.error.error.error}`
        );
      } catch (ex) {
        //
      }
    }
    this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'error', value }
    });
  }

  private displaySuccess(message: string, action = '', duration = 4000) {
    this.snackBar.open(message, action, { duration });
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  private displayError(error: string | { code: string; error: string } | any) {
    if (this.disableErrorMessagesAfterLogout) {
      if (this.loggedOut === false) {
        this.openErrorPopup(error);
      }
    } else {
      this.openErrorPopup(error);
    }
  }
}
