import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { IotGeoJsonFeature } from '@iot-platform/iot-platform-maps';
import { CommonGenericModel, Contact, TagCategory } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { Layer } from 'leaflet';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapNavigationEvent, MapNavigationModelType } from '../../models/iot-map-navigation-event.model';
import { MapFacade } from '../../state/facades/map.facade';
import { MapPanelInfoService } from './map-panel-info.service';

@Component({
  selector: 'iot-platform-maps-map-panel-info',
  templateUrl: './map-panel-info.component.html',
  styleUrls: ['./map-panel-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPanelInfoComponent implements OnChanges {
  @Input() feature: IotGeoJsonFeature;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectElement: EventEmitter<MapNavigationEvent<MapNavigationModelType>> = new EventEmitter<MapNavigationEvent<MapNavigationModelType>>();
  @Output() displayRoute: EventEmitter<{ asset: Asset; daysToDisplay: string }> = new EventEmitter<{ asset: Asset; daysToDisplay: string }>();
  @Output() displaySegments: EventEmitter<{ layers: Layer[]; action: 'add' | 'remove' }> = new EventEmitter<{ layers: Layer[]; action: 'add' | 'remove' }>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  model$: BehaviorSubject<CommonGenericModel | null> = new BehaviorSubject<CommonGenericModel | null>(null);
  site$: Observable<Site> = this.model$.pipe(map((response) => response as Site));
  asset$: Observable<Asset> = this.model$.pipe(map((response) => response as Asset));
  device$: Observable<Device> = this.model$.pipe(map((response) => response as Device));

  tags$: BehaviorSubject<TagCategory[]> = new BehaviorSubject<TagCategory[]>([]);
  assetsBySite$: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
  devicesBySite$: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
  contacts$: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);
  complementarySite$: BehaviorSubject<Site | null> = new BehaviorSubject<Site | null>(null);
  canReadContacts = false;

  constructor(
    private mapInfoService: MapPanelInfoService,
    private readonly cdr: ChangeDetectorRef,
    private authz: AuthorizationService,
    private mapFacade: MapFacade
  ) {
    this.canReadContacts = this.authz.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['feature'] &&
      changes['feature'].currentValue &&
      changes['feature'].currentValue.properties.id &&
      changes['feature'].currentValue.properties.concept
    ) {
      this.loading$.next(true);

      switch (changes['feature'].currentValue.properties.concept) {
        case 'site':
          this.mapInfoService.getSite(changes['feature'].currentValue.properties.id).subscribe(([site, tags, assets, devices, contacts]) => {
            this.model$.next(site);
            this.tags$.next(tags);
            this.assetsBySite$.next(assets);
            this.devicesBySite$.next(devices);
            this.contacts$.next(contacts);
            this.loading$.next(false);
            this.cdr.detectChanges();
          });
          break;
        case 'asset':
          this.mapInfoService.getAsset(changes['feature'].currentValue.properties.id).subscribe(([asset, site, tags]) => {
            this.model$.next(asset);
            this.complementarySite$.next(site);
            this.tags$.next(tags);
            this.loading$.next(false);
            this.cdr.detectChanges();
          });
          break;
        case 'device':
          this.mapInfoService.getDevice(changes['feature'].currentValue.properties.id).subscribe(([device, site, tags]) => {
            this.model$.next(device);
            this.complementarySite$.next(site);
            this.tags$.next(tags);
            this.loading$.next(false);
            this.cdr.detectChanges();
          });
          break;
      }
    }
  }

  loadRoutes(event: { asset: Asset; daysToDisplay: string; minutesBetweenSegment: number; limit: number }) {
    const now = new Date();
    const startDate = moment(now).subtract(event.daysToDisplay, 'minutes').toDate();
    this.mapFacade.loadRoute(event.asset?.id, event.limit, startDate, now, event.minutesBetweenSegment, 'desc');
  }

  closeSidebar() {
    this.mapFacade.clearRoutes();
    this.close.emit(true);
  }
}
