<iot-platform-ui-card-loader *ngIf="loading"
                             [backgroundClass]="'mv-detail-card h-40'"></iot-platform-ui-card-loader>

<div *ngIf="gRoutes$ && !loading">
  <div *ngIf="gRoutes$ && !loading" [class]="'day-section'" fxLayout="column">
    <section *ngFor="let dayRoute of gRoutes$ | async" >
    <span class="main-route-section">
      <mat-checkbox (change)="setAll(dayRoute, $event.checked)"
                    [checked]="allSubRoutesChecked(dayRoute)"
                    [indeterminate]="someSubRoutesChecked(dayRoute)"
      >
        {{dayRoute.day}}
      </mat-checkbox>
    </span>
      <span>
      <ul class="sub-route-section">
        <li *ngFor="let subRoute of dayRoute.routes">
          <mat-checkbox (change)="onRouteChanged($event, subRoute)"
                        [(ngModel)]="subRoute.checked">
            <span fxLayout="row" fxLayoutAlign="start center">
            <p>{{subRoute.layerLabel}}</p>
            <p [style.background-color]="subRoute.color" class="sub-route-color"></p>
            <p>{{ getDuration(subRoute) }}</p>
            </span>

          </mat-checkbox>

        </li>
      </ul>
    </span>
    </section>
  </div>
</div>

<div *ngIf="!hasRoutes && !loading && routesLoaded && (gRoutes$ | async).length === 0">
  <span >No routes found for period</span>
</div>


