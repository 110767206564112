import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueUnitFormatPipe } from './value-unit-format.pipe';

@NgModule({
  declarations: [],
  imports: [CommonModule, ValueUnitFormatPipe],
  exports: [ValueUnitFormatPipe]
})
export class ValueUnitFormatPipeModule {}
