<div [class]="cssClassName" [style]="styles" class="iot-platform-ui-section-header"
     data-cy="iot-platform-ui-section-header" fxLayout="row"
     fxLayoutAlign="start center" fxLayoutGap="10px">
       <span
         fxLayoutAlign="start center"
         fxLayoutGap="10px"
         matTooltipClass="regular-tooltip"
         matTooltipPosition="below"
       >
         @if (!!svgIcon) {
           <mat-icon class="iot-platform-ui-section-header__icon" [svgIcon]="svgIcon!"></mat-icon>
         } @else if (!!icon) {
           <mat-icon class="iot-platform-ui-section-header__icon">{{ icon }}</mat-icon>
         }
         @if (title) {
           <span data-cy="iot-platform-ui-section-header-title"
                 class="iot-platform-ui-section-header__title">{{ title }}</span>
         }
       </span>
  <hr class="iot-platform-ui-section-header__line regular-1px-line" fxFlex />

  @for (action of actions; track action) {
    @if (action.visible()) {
      <button
        (click)="onDispatchEven(action)"
        [class]="action.cssClassName() + ' light-button regular-round-button iot-platform-ui-section-header__action iot-platform-ui-section-header__action_' + action.action"
        [style]="action.styles()"
        data-cy="iot-platform-ui-section-header-action"
        [disabled]="action.disabled()"
        [matTooltip]="action.tooltip()! | translate"
        mat-mini-fab
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
      >
        @if (!!action.svgIcon()) {
          <mat-icon class="iot-platform-ui-section-header__action-icon" [svgIcon]="action.svgIcon()!"></mat-icon>
        } @else if (!!action.icon()) {
          <mat-icon class="iot-platform-ui-section-header__action-icon">{{ action.icon() }}</mat-icon>
        }
      </button>
    }
  }
</div>
