import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chat, ChatEvent } from '@iot-platform/models/common';
import { ChatHeaderComponent } from '../chat-header/chat-header.component';
import { ChatBodyComponent } from '../chat-body/chat-body.component';
import { ChatFooterComponent } from '../chat-footer/chat-footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgComponentOutlet } from '@angular/common';

@Component({
  standalone: true,
  imports: [ChatHeaderComponent, ChatBodyComponent, ChatFooterComponent, FlexLayoutModule, NgComponentOutlet],
  selector: 'iot-platform-ui-chat',
  templateUrl: './chat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent {
  @Input() chat!: Chat;

  @Output() dispatchAction: EventEmitter<ChatEvent> = new EventEmitter<ChatEvent>();
}
