<div [formGroup]="searchForm" class="iot-platform-ui-async-autocomplete" data-cy="iot-platform-ui-async-autocomplete">
    <mat-form-field class="full-width form-field" color="accent">
        <mat-icon *ngIf="displaySearchIcon" class="search-icon" matPrefix>search</mat-icon>
        <mat-label>{{ placeholder | translate }}</mat-label>
        <ng-container *ngIf="autocomplete; else defaultInput">
            <input
              #inputAutoComplete
              #trigger="matAutocompleteTrigger"
              [matAutocomplete]="auto"
              [placeholder]=""
              [required]="required"
              data-cy="iot-platform-ui-async-autocomplete-input-field"
              formControlName="searchKey"
              matInput
              type="text"
            />
        </ng-container>
        <ng-template #defaultInput>
            <input
                    [placeholder]=""
                    [required]="required"
                    data-cy="iot-platform-ui-async-autocomplete-input-field"
                    formControlName="searchKey"
                    matInput
                    type="text"
            />
        </ng-template>
        <button
                (click)="resetControl($event)"
                *ngIf="!showSpinner && control?.value && !control?.disabled"
                aria-label="Clear"
                class="close-btn"
                mat-icon-button
                matIconSuffix
                type="button"
        >
            <mat-icon>close</mat-icon>
        </button>
        <mat-hint *ngIf="hintMessage">{{ hintMessage }}</mat-hint>
        <mat-spinner *ngIf="showSpinner" class="form-field_spinner" color="accent" diameter="16"
                     mode="indeterminate"></mat-spinner>
        <mat-error *ngIf="required && errorMessage && control.invalid">{{ errorMessage | translate }}</mat-error>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
                      [displayWith]="displayWrapper">
        <ng-container *ngIf="!showSpinner">
            <mat-option *ngFor="let item of standaloneMode ? (filteredData$ | async) : data"
                        [matTooltip]="tooltip ? displayWrapper(item) : ''" [value]="item"
                        data-cy="iot-platform-ui-async-autocomplete-option" matTooltipClass="regular-tooltip">
                <span>{{ displayWrapper(item) }}</span>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</div>
