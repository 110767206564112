<form [formGroup]="inputForm" fxFlex>
    <mat-form-field color="accent" fxFlex>
        <mat-label>{{ ('PO_EVENTS.CREATION_CONFIGURATION_POPUP.INPUT_LIST.' + (placeholder$ | async)) | translate }}</mat-label>
        <input (change)="onValueChange()" (keyup)="onValueChange()" formControlName="value" matInput required
               type="number"/>
        <mat-hint *ngIf="minValue"
                  align="start">{{'PO_EVENTS.CREATION_CONFIGURATION_POPUP.MIN' | translate}}&nbsp;{{ minValue }}</mat-hint>
        <mat-hint *ngIf="maxValue"
                  align="end">{{'PO_EVENTS.CREATION_CONFIGURATION_POPUP.MAX' | translate}}&nbsp;{{ maxValue }}</mat-hint>
        <mat-error *ngIf="value.hasError('min')"
                   align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ERROR_MIN' | translate }}</mat-error>
        <mat-error *ngIf="value.hasError('max')"
                   align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ERROR_MAX' | translate }}</mat-error>
        <mat-error *ngIf="value.hasError('required')"
                   align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
</form>
