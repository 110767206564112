<div  class="chat-message-container" fxLayoutGap="4px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon class="message-icon" [svgIcon]="'smart_toy'" color="accent"></mat-icon>
      <span class="message-author">{{ 'AI Bot' }}</span>
  </div>

  <div class="message-container">
      <div class="message-value">
        <div class="dot-flashing"></div>
      </div>

  </div>
</div>
