<form [formGroup]="tagsForm" class="tag-editor-form-container" fxFlex fxLayout="row" fxLayoutAlign="start end"
      fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
                (click)="onRemove(tagCategory)"
                *ngIf="mode === 'UPDATE'"
                [matTooltip]="'TAG_EDITOR.REMOVE' | translate"
                class="regular-round-button"
                color="warn"
                mat-icon-button
                matTooltipClass="warning-tooltip"
                matTooltipPosition="below"
        >
            <mat-icon>delete</mat-icon>
        </button>
        <mat-form-field color="accent" fxFlex="70px">
            <mat-label>{{ 'TAG_EDITOR.COLOR' | translate }}</mat-label>
            <mat-select [(value)]="tagCategory.color" [placeholder]=""
                        formControlName="colorFormControl" required>
                <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
                    <span [style.background-color]="tagCategory?.color" class="tag-category-color-round"></span>
                </mat-select-trigger>
                <mat-option *ngFor="let color of colors" [value]="color">
          <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span [style.background-color]="color" class="tag-category-color-round"></span>
          </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field color="accent" fxFlex="200px">
        <mat-label>{{ 'TAG_EDITOR.CATEGORY_NAME' | translate }}</mat-label>
        <input
          #categoryInput
          (keyup.enter)="chipList.focus(); $event.stopPropagation()"
          [placeholder]=""
          [value]="tagCategory.name"
          formControlName="nameFormControl"
          matInput
          maxlength="20"
          required
        />
        <mat-error *ngIf="nameFormControl.invalid && nameFormControl.errors.duplicate">
            {{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_CATEGORY' | translate }}
        </mat-error>
        <mat-hint align="end"
        ><span>{{ categoryInput.value.length || 0 }}/{{ tagNameMaxLength }}</span></mat-hint
        >
    </mat-form-field>

    <mat-form-field color="accent" fxFlex>

        <mat-label>{{ 'TAG_EDITOR.TAG_NAMES' | translate }}</mat-label>
        <mat-chip-grid #chipList fxLayout="column">
            <mat-chip-row
                    (dblclick)="onEditLabel(label)"
                    (removed)="removeLabel(tagCategory, label)"
                    *ngFor="let label of tagCategory.labels"
                    [ngStyle]="{ backgroundColor: tagCategory.color }"
                    [removable]="removable"
                    [selectable]="selectable"
            >
                {{ label.name }}
                <button *ngIf="removable" [attr.aria-label]="label" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input
              #labelInput
              (matChipInputTokenEnd)="addLabel(tagCategory, $event)"
              [matChipInputAddOnBlur]="addOnBlur"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [placeholder]=""
              maxlength="20"
            />
        </mat-chip-grid>
        <mat-hint align="end" fxLayoutGap="20px">
      <span *ngIf="checkDuplicateLabel(labelInput.value)" class="tag-editor-duplicate-warning">{{
        'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_LABEL' | translate
          }}</span>
            <span *ngIf="labelInput.value">{{ labelInput.value.length || 0 }}/{{ tagNameMaxLength }}</span>
            <span [ngClass]="{ 'max-reached': tagCategory.labels?.length === maximumTagPerCategory }"
            >{{ 'TAG_EDITOR.TAGS_PER_CATEGORY' | translate }} {{ tagCategory.labels?.length || 0 }}
                /{{ maximumTagPerCategory }}</span
            >
        </mat-hint>
    </mat-form-field>
    <button
            (click)="onSave(tagCategory)"
            *ngIf="mode === 'UPDATE'"
            [disabled]="!tagsForm.valid || (tagsForm.valid && !tagsForm.dirty)"
            class="button-regular"
            color="accent"
            mat-raised-button
    >
        {{ 'TAG_EDITOR.UPDATE' | translate | uppercase }}
    </button>
    <button (click)="onSave(tagCategory)" *ngIf="mode === 'ADD'" [disabled]="!tagsForm.valid" class="button-regular"
            color="accent"
            mat-raised-button>
        {{ 'TAG_EDITOR.ADD' | translate | uppercase }}
    </button>
</form>
