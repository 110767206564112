import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'i18n-translation-details',
  templateUrl: './translation-details.component.html',
  styleUrls: ['./translation-details.component.scss']
})
export class TranslationDetailsComponent implements OnInit {
  public translations: { key: string; value: string; missing: boolean }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { languages: string[]; rowData: unknown }) {}

  public ngOnInit(): void {
    this.translations = this.data.languages.map((lang: string) => ({
        key: lang,
        ...this.data.rowData[lang]
      }));
  }
}
