<div *ngIf="actions?.length" class="app-round-button-mv" data-cy="grid-engine-call-to-action-cell"
     fxLayoutAlign="center center">
  <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" mat-icon-button>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    @for (action of params?.actions; track action.key) {
      <grid-engine-call-to-action-menu-item
        (actionClicked)="onActionClicked($event)"
        [action]="action"
        [element]="params.data"
        [userPermissions]="userPermissions"
      >

      </grid-engine-call-to-action-menu-item>
    }
  </mat-menu>
</div>
