import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { ArrayOfObjectColumn, I4BBasicColumn, I4BColumn, I4BSelectionColumn, IdColumn, KercomDeviceButtonColumn, NameColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions, KercomDevicesGridData } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_DEVICES_COLUMNS_FOR_KERCOM_DEVICES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new NameColumn(
    {
      displayName: 'NAME',
      type: HeaderType.ICON_AND_TEXT,
      icon: 'device'
    },
    {
      id: 'name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
      concept: I4bCellConcept.DEVICES,
      isDefault: true,
      width: 175,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 1, pinned: 'left' }
  ),
  new IdColumn(
    { displayName: 'IDENTIFIER' },
    {
      id: 'identifier',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_IDENTIFIER',
      concept: I4bCellConcept.DEVICES,
      isDefault: true,
      cell: { type: I4BCellType.BASIC },
      width: 250
    },
    { order: 2 }
  ),
  new I4BBasicColumn(
    { displayName: 'FAMILY', type: HeaderType.BASIC },
    {
      id: 'family',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
      isDefault: true,
      sortProperty: 'family',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 3 }
  ),
  new I4BBasicColumn(
    { displayName: 'MODEL', type: HeaderType.BASIC },
    {
      id: 'model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
      isDefault: true,
      sortProperty: 'model',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 4 }
  ),
  new I4BBasicColumn(
    { displayName: 'FIRMWARE', type: HeaderType.BASIC },
    {
      id: 'firmware',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
      isDefault: true,
      sortProperty: 'firmware',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 5 }
  ),
  new I4BBasicColumn(
    { displayName: 'SUPPORT', type: HeaderType.BASIC },
    {
      id: 'support',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_SUPPORT',
      isDefault: true,
      sortProperty: 'support',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 6 }
  ),
  new I4BBasicColumn(
    { displayName: 'COUNTRY CODE', type: HeaderType.BASIC },
    {
      id: 'countryCode',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_COUNTRY_CODE',
      isDefault: true,
      sortProperty: 'countryCode',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 7 }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      id: 'outgoingConnector',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_NAME',
      isDefault: true,
      sortProperty: 'outgoingConnector.name',
      cell: { type: I4BCellType.BASIC, valueGetter: 'outgoingConnector.name' },
      concept: I4bCellConcept.DEVICES
    },
    { order: 8 }
  ),
  new I4BBasicColumn(
    { displayName: 'ENDPOINT', type: HeaderType.BASIC },
    {
      id: 'endpoint',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_ENDPOINT',
      isDefault: true,
      sortProperty: 'endpoint',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 9 }
  ),
  new I4BBasicColumn(
    { displayName: 'HTTP PORT', type: HeaderType.BASIC },
    {
      id: 'httpPort',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_HTTP_PORT',
      isDefault: true,
      sortProperty: 'httpPort',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 10 }
  ),
  new I4BBasicColumn(
    { displayName: 'TIMEZONE', type: HeaderType.BASIC },
    {
      id: 'timezone',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TIMEZONE',
      isDefault: true,
      sortProperty: 'timezone',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 11 }
  ),
  new I4BBasicColumn(
    { displayName: 'ENTITY NAME', type: HeaderType.BASIC },
    {
      id: 'entity.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
      isDefault: true,
      sortProperty: 'entity.name',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 12 }
  ),
  new ArrayOfObjectColumn(
    { displayName: 'FILES', type: HeaderType.BASIC },
    {
      id: 'files',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_FILES',
      isDefault: true,
      sortProperty: 'files',
      width: 300,
      cell: {
        type: I4BCellType.ARRAY_OF_OBJECTS,
        options: {
          attributes: ['name'],
          expressions: [{ key: 'download', value: true }]
        }
      },
      concept: I4bCellConcept.DEVICES
    },
    { order: 13 }
  ),
  new I4BBasicColumn(
    { displayName: 'MODBUS ADDRESS', type: HeaderType.BASIC },
    {
      id: 'modbusAddress',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_MODBUS_ADDRESS',
      isDefault: true,
      sortProperty: 'modbusAddress',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 14 }
  ),
  new I4BBasicColumn(
    { displayName: 'AFFILIATE', type: HeaderType.BASIC },
    {
      id: 'affiliate',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_AFFILIATE',
      isDefault: true,
      sortProperty: 'affiliate',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 15 }
  ),
  new I4BBasicColumn(
    { displayName: 'ICCID', type: HeaderType.BASIC },
    {
      id: 'iccid',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_ICCID',
      isDefault: true,
      sortProperty: 'iccid',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 16 }
  ),
  new I4BBasicColumn(
    { displayName: 'OPERATOR', type: HeaderType.BASIC },
    {
      id: 'operator',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_OPERATOR',
      isDefault: true,
      sortProperty: 'operator',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.DEVICES
    },
    { order: 17 }
  )
];

export class DefaultKercomDevicesGrid implements I4BGrid<I4BGridOptions, KercomDevicesGridData> {
  masterview = 'kercom-devices';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: KercomDevicesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: true, className: new KercomDeviceButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}
