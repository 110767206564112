import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PlatformResponse } from '@iot-platform/models/common';
import { Device, Site } from '@iot-platform/models/i4b';

import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { DevicesService } from '../../../../../../../shared/src/lib/services/devices.service';

@Component({
  selector: 'iot4bos-ui-device-move-to-form',
  templateUrl: './device-move-to-form.component.html',
  styleUrls: ['./device-move-to-form.component.scss']
})
export class DeviceMoveToFormComponent implements OnInit, OnDestroy {
  deviceMoveToForm: UntypedFormGroup;

  filterValue$: Subject<string> = new Subject<string>();

  hasMore: boolean;
  filteredSites: Site[] = [];
  spinner: boolean;
  pageNumber = 0;
  totalSites = 0;

  originSites: Site[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private deviceService: DevicesService,
    public matDialogRef: MatDialogRef<DeviceMoveToFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { devices: Device[] }
  ) {}

  ngOnInit() {
    this.deviceMoveToForm = new UntypedFormGroup({
      destinationSite: new UntypedFormControl('', Validators.required)
    });

    this.setOriginSites();

    this.filterValue$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        switchMap((filterValue: string) => this.deviceService.filterSitesByName(filterValue, this.pageNumber))
      )
      .subscribe((data: PlatformResponse) => {
        this.filteredSites = this.filteredSites.concat(data.data);
        this.spinner = false;
        this.hasMore = data.hasMore;
        this.totalSites = data.total;
      });
  }

  displayFn(site?: Site): string | undefined {
    return site ? site.name : undefined;
  }

  setOriginSites(): void {
    const uniqueSiteIds: string[] = [...new Set(this.data.devices.map((d) => d.site?.id as string))];

    this.originSites = uniqueSiteIds.reduce((uniqueSites: Site[], uniqueSiteId: string) => {
      uniqueSites.push(
        this.data.devices
          .map((d: Device) => d.site as Site)
          .filter((s: Site) => s.id === uniqueSiteId)
          .pop()
      );
      return uniqueSites;
    }, []);
  }

  getFilteredSites(loadMore: boolean): void {
    this.spinner = true;
    if (loadMore) {
      this.pageNumber += 1;
    } else {
      this.pageNumber = 0;
      this.filteredSites = [];
    }

    if (!this.destinationSite.value.hasOwnProperty('name')) {
      this.filterValue$.next(this.destinationSite.value.toLowerCase());
    } else {
      this.filterValue$.next(this.destinationSite.value.name.toLowerCase());
    }
  }

  get destinationSite(): AbstractControl {
    return this.deviceMoveToForm.get('destinationSite');
  }

  apply() {
    const newDevices: Device[] = this.data.devices.map((device: Device) => ({
        ...device,
        site: this.destinationSite.value
      }));

    return this.matDialogRef.close(newDevices);
  }

  closeOnCancel() {
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
