import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Filter } from '@iot-platform/models/common';

import { DateFormatPipe } from '@iot-platform/pipes';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';
import { ExportsService } from './exports.service';

@Component({
  standalone: true,
  imports: [
    MatCardModule,
    MatToolbarModule,
    NgIf,
    AsyncPipe,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule
  ],
  selector: 'iot-platform-ui-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss'],
  providers: [ExportsService, DateFormatPipe]
})
export class ExportsComponent implements OnInit, OnDestroy {
  loaderValue: number;
  displayLoader = false;
  exportsForm: UntypedFormGroup;
  isFileDownloaded = false;
  isFileDownloading = false;
  action$: Observable<string>;
  subscriptions: Subscription[] = [];

  constructor(
    private exportsService: ExportsService,
    private dateFormatPipe: DateFormatPipe,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ExportsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { type: string; filters: Filter[] }
  ) {}

  get name(): AbstractControl {
    return this.exportsForm.get('name');
  }

  get time(): string {
    return this.dateFormatPipe.transform(Date.now(), 'yyyyMMdd');
  }

  ngOnInit() {
    this.action$ = this.translateService.get('EXPORTS.DOWNLOAD');
    this.exportsForm = new UntypedFormGroup({ name: new UntypedFormControl(null, [Validators.required, Validators.pattern('\\S.*')]) });
    this.subscriptions.push(
      this.exportsService.isFileDownloaded$.subscribe((value: boolean) => {
        if (value) {
          this.isFileDownloaded = true;
          this.isFileDownloading = false;
          this.action$ = this.translateService.get('EXPORTS.CLOSE');
        }
      }),
      this.exportsService.loaderValue$.subscribe((value: number) => (this.loaderValue = value))
    );
  }

  action() {
    if (this.isFileDownloaded) {
      this.dialogRef.close();
    } else {
      this.displayLoader = true;
      this.isFileDownloading = true;
      this.exportsService.getCSVFile(this.data.type, this.data.filters, this.name.value);
    }
  }

  close() {
    this.exportsService.cancel$.next(true);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe());
  }
}
