import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Filter, FilterDynamicListSingleSelectOptions } from '@iot-platform/models/common';
import { DynamicListFieldService } from '@iot-platform/shared/services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'iot-platform-ui-dynamic-list-field-single-select',
  templateUrl: './dynamic-list-field-single-select.component.html',
  styleUrls: ['./dynamic-list-field-single-select.component.scss']
})
export class DynamicListFieldSingleSelectComponent implements OnInit, OnDestroy {
  dynamicListForm: UntypedFormGroup;
  dynamicList: Array<any>;
  showLoader = true;
  selectedFilters: any = [];
  maxFilters = 20;

  subscriptions: Subscription[] = [];

  @Input() data!: FilterDynamicListSingleSelectOptions;
  @Input() currentFilters$!: Observable<Filter[]>;
  @Input() currentFiltersSize!: number;

  @Output() dispatchFilterValue: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();

  constructor(
    private readonly dynamicListFieldService: DynamicListFieldService,
    private readonly translateService: TranslateService,
    private readonly ref: ChangeDetectorRef
  ) {}

  get select(): AbstractControl {
    return this.dynamicListForm.get('select');
  }

  ngOnInit() {
    this.dynamicListForm = new UntypedFormGroup({
      select: new UntypedFormControl('')
    });

    this.dynamicListFieldService
      .getDynamicList(this.data.url, this.data?.sortMethod, this.data.arrayOrObject)
      .pipe(
        finalize(() => {
          this.ref.detectChanges();
        })
      )
      .subscribe((list: Array<any>) => {
        const values = this.data.distinctValues ? _.uniqBy(list, this.data.selectByProperty) : list;
        this.dynamicList = this.data.dataTransform ? this.data.dataTransform(values) : values;
        this.showLoader = false;
      });

    if (this.data.multiSelect) {
      this.subscriptions.push(
        this.currentFilters$.subscribe((filters: Filter[]) => {
          this.selectedFilters = [];
          if (filters.length) {
            filters.forEach((filter: Filter) => {
              if (this.data.filterBy && this.data.labelToDisplay) {
                this.selectedFilters.push({ id: filter.value, value: filter.label });
              } else {
                this.selectedFilters.push(filter.value);
              }
            });
          }
          this.select.setValue(this.selectedFilters);
        })
      );
    }
  }

  onSelectionChange(value?: any): void {
    const filter: Filter = {};
    filter.criteriaKey = this.data.criteriaKey;
    filter.criteriaLabel = this.data.criteriaLabel;
    filter.data = this.dynamicList;
    filter.displayWrapper = this.data.displayWrapper;

    if (!this.data.multiSelect) {
      if (this.data.autocomplete) {
        filter.value = this.data.filterBy ? value[this.data.filterBy] : value;
        filter.label = this.data.labelToDisplay ? value[this.data.labelToDisplay] : value;
      } else {
        filter.value = this.data.filterBy ? this.select.value[this.data.filterBy] : this.select.value;
        filter.label = this.data.labelToDisplay ? this.select.value[this.data.labelToDisplay] : this.select.value;
      }
    } else {
      if (this.data.autocomplete) {
        filter.value = this.data.filterBy ? value[this.data.filterBy] : value;
        filter.label = this.data.labelToDisplay ? value[this.data.labelToDisplay] : value;
      } else {
        let diff;
        if (this.selectedFilters.length > this.select.value.length) {
          diff = this.getDifference(this.selectedFilters, this.select.value);
          this.currentFiltersSize--;
        } else {
          diff = this.getDifference(this.select.value, this.selectedFilters);
          this.currentFiltersSize++;
        }
        filter.value = this.data.filterBy ? diff[this.data.filterBy] : diff;
        filter.label = this.data.labelToDisplay ? diff[this.data.labelToDisplay] : diff;
        this.selectedFilters = [...this.select.value];
      }
    }

    const filterHidden: Filter = {};
    if (this.data.includeSubEntities) {
      filterHidden.criteriaKey = 'includeSubEntities';
      filterHidden.criteriaLabel = '';
      filterHidden.label = '';
      filterHidden.value = true;
      filterHidden.isHidden = true;
    }

    this.dispatchFilterValue.emit(this.data.includeSubEntities ? [filter, filterHidden] : [filter]);

    if (!this.data.multiSelect) {
      this.dynamicListForm.reset();
    }
  }

  getOptionToDisplay(option): string {
    let dataToDisplay = option;
    if (this.data.selectByProperty) {
      dataToDisplay = option[this.data.selectByProperty];
    }
    if (this.data.translatedKey) {
      dataToDisplay = this.translateService.instant(this.data.translatedKey + dataToDisplay);
    }
    return dataToDisplay;
  }

  getDifference(array1: any, array2: any): any {
    return array1.filter((obj1: any) => !array2.some((obj2: any) => (this.data.filterBy && this.data.labelToDisplay ? obj1.id === obj2.id : obj1 === obj2)))[0];
  }

  compareFn(filter1: any, filter2: any): boolean {
    if (filter1.id && filter2.id) {
      return filter1.id === filter2.id;
    } else if (!filter1.id && filter2.id) {
      return filter1 === filter2.id;
    } else {
      return filter1 === filter2;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s && s.unsubscribe());
  }
}
