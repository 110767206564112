import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-detail-assets-card',
  templateUrl: './detail-assets-card.component.html',
  styleUrls: ['./detail-assets-card.component.scss']
})
export class DetailAssetsCardComponent {
  @Input() total = 0;
  @Input() assets: Asset[] = [];

  @Output() selectItem: EventEmitter<Asset> = new EventEmitter();

  onClick(asset: Asset) {
    this.selectItem.emit(asset);
  }
}
