<mat-nav-list [disableRipple]="true" class="two-line-list">
    <mat-list-item
            (click)="onSelectBusinessProfileButtonClick(profile)"
            *ngFor="let profile of profiles"
            [lines]="2"
            [ngClass]="{ selected: selectedBusinessProfile && profile ? selectedBusinessProfile.id === profile.id : false }"
            class="hoverable"
    >
        <!--<iot-platform-ui-icon matListItemAvatar style="font-size: 24px" [name]="'business_profile'" [color]="'#394c5a'"></iot-platform-ui-icon>-->
        <mat-icon [svgIcon]="'business_profile'" matListItemAvatar style="font-size: 24px"></mat-icon>
        <h3 matListItemTitle>{{ profile.name }}</h3>
        <button (click)="$event.stopPropagation()" *ngIf="canDeleteBusinessProfile || canUpdateBusinessProfile"
                [matMenuTriggerFor]="actionMenu"
                class="bp-call-to-action" mat-icon-button
                matListItemMeta>
            <mat-icon>more_vert</mat-icon>
        </button>
        <p class="complementary-line" matListItemLine>
            <span>{{ profile.entityName }}</span>
        </p>

        <mat-menu #actionMenu="matMenu">
            <button (click)="selectBusinessProfile.emit(profile)" *ngIf="canUpdateBusinessProfile" mat-menu-item>
                <span> {{ 'IOT_DICTIONARY.EDIT' | translate | uppercase }}</span>
            </button>
            <div *ngIf="canDeleteBusinessProfile" [matTooltipDisabled]="isDeletePossible(profile)"
                 [matTooltip]="getTooltip(profile)"
                 matTooltipClass="regular-tooltip">
                <button (click)="onDeleteBusinessProfileButtonClick(profile)" [disabled]="!isDeletePossible(profile)"
                        mat-menu-item>
                    <span> {{ 'IOT_DICTIONARY.DELETE' | translate | uppercase }}</span>
                </button>
            </div>
        </mat-menu>
    </mat-list-item>
</mat-nav-list>
