import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, WritableSignal, signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Asset, AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';
import { DateFormatModule, ValueUnitFormatPipeModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';

interface AssetWithVariables extends Asset {
  variables: DeviceVariable[] | AssetVariable[];
}

@Component({
  selector: 'iot-platform-dashboards-asset-variable-selector-list',
  templateUrl: './asset-variable-selector-list.component.html',
  styleUrls: ['./asset-variable-selector-list.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ValueUnitFormatPipeModule,
    CdkDropList,
    NgTemplateOutlet,
    CdkDrag,
    DateFormatModule,
    FlexLayoutModule,
    MatButtonModule,
    NgClass
  ]
})
export class AssetVariableSelectorListComponent {
  @Input() control: AbstractControl;
  @Input() withDragAndDrop = false;
  @Input() withCounter = false;
  @Input() highlightFirstItem = false;
  @Input() nbSelectedLabel: string;
  @Input() emptyMessage: string;
  @Input() canDeleteVariables = true;
  @Input() disabled = false;
  @Input() set loading(value: boolean) {
    this.variablesLoading.set(value);
  }

  @Output() removeAssetVariable = new EventEmitter();

  variablesLoading: WritableSignal<boolean> = signal(true);

  get value(): AssetVariable[] {
    return this.control.value;
  }

  set value(value: AssetVariable[]) {
    this.control.setValue(value);
  }

  drop(event: CdkDragDrop<AssetWithVariables[]>) {
    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
  }

  removeSelectedVariable(selectedVar): void {
    this.value = this.value.filter((v) => v.id !== selectedVar.id);
    this.removeAssetVariable.emit();
  }
}
