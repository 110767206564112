import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AssetVariable } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {
  constructor(
    @Inject('environment') private environment,
    public httpClient: HttpClient
  ) {}

  getAssetVariableById(assetVariableId: string): Observable<AssetVariable> {
    return this.httpClient.get<AssetVariable>(`${this.environment.api.url}${this.environment.api.endpoints.assetVariables}/${assetVariableId}`);
  }
}
