import { Sort } from '@angular/material/sort';
import { FavoriteView, Filter, gridSortModel, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { NavigationActions } from '../../../../containers/+state/actions';
import { AssetsDbActions, AssetsUiActions } from '../actions';

export const assetsDbFeatureKey = 'assetsDb';

export interface State extends EntityState<Asset> {
  selectedAssetId: string | null;
  asset: Asset | null;
  allVariables?: AssetVariable[];
  variable?: AssetVariable;
  site?: Site;
  tags?: TagCategory[];
  settings: any;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  currentFilters?: Filter[];
  initialSort: gridSortModel[] | Sort;
  currentFavoriteView?: FavoriteView;
  error?: any;
}

export const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedAssetId: null,
  asset: null,
  allAssets: [],
  allVariables: [],
  tags: [],
  tagsLoaded: false,
  settings: null,
  initialSort: [],
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

const assetsDbReducer = createReducer(
  initialState,
  on(AssetsUiActions.loadAssetById, (state: State, { assetId }) => ({ ...state, selectedAssetId: assetId, asset: null })),
  on(AssetsUiActions.loadAssets, (state: State, { request }) => ({ ...state, asset: null, pagination: { ...state.pagination, currentPage: request.page } })),
  on(AssetsDbActions.loadMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(AssetsDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(NavigationActions.loadData, (state: State) => ({ ...state, asset: null })),

  on(AssetsDbActions.loadAssetByIdSuccess, (state: State, { asset }) => ({ ...state, selectedAssetId: asset.id, asset })),
  on(AssetsDbActions.loadAssetsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: { currentPage: response.currentPage, hasMore: response.hasMore, limit: response.limit, total: response.total, maxPage: response.maxPage }
    })
  ),
  on(AssetsUiActions.loadVariablesByAssetId, (state: State) => ({ ...state, allVariables: [] })),
  on(AssetsDbActions.loadVariablesByAssetIdSuccess, (state: State, { allVariables }) => ({ ...state, allVariables })),
  on(AssetsUiActions.updateAssetVariable, (state: State, { assetVariableToUpdate }) => ({ ...state, variable: assetVariableToUpdate })),
  on(AssetsDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, site })),
  on(AssetsDbActions.loadTagsByAssetIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  on(AssetsDbActions.addAssetSuccess, (state: State, { addedAsset }) => adapter.addOne(addedAsset, { ...state, selectedAssetId: addedAsset.id })),
  on(AssetsDbActions.selectAssetSuccess, (state: State, { selectedAsset }) => ({ ...state, selectedAssetId: selectedAsset.id })),
  on(AssetsDbActions.updateAssetSuccess, (state: State, { updatedAsset }) => adapter.updateOne({ changes: updatedAsset, id: updatedAsset.id }, state)),
  on(AssetsDbActions.updateTagsByAssetIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  on(AssetsDbActions.addAssetVariableSuccess, (state: State, { assetVariableAdded }) => ({
    ...state,
    allVariables: [...state.allVariables, assetVariableAdded]
  })),
  on(AssetsDbActions.updateAssetVariableSuccess, (state: State, { assetVariableUpdated }) => {
    const clonedVariables = Object.assign([], state.allVariables);
    let i = 0;
    for (const aV of clonedVariables) {
      if (aV.id === assetVariableUpdated.id) {
        clonedVariables[i] = assetVariableUpdated;
      } else {
        i++;
      }
    }

    state = {
      ...state,
      variable: assetVariableUpdated,
      allVariables: clonedVariables
    };

    return state;
  }),
  on(AssetsUiActions.saveCurrentFavoriteView, (state: State, { currentFavoriteView }) => ({
    ...state,
    currentFavoriteView,
    currentFilters: currentFavoriteView.filters
  })),
  on(AssetsUiActions.saveCurrentFilters, (state: State, { currentFilters }) => ({ ...state, currentFilters })),
  //
  on(AssetsDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(AssetsUiActions.saveMVSettings, (state: State) => adapter.removeAll({ ...state })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),
  //
  on(AssetsUiActions.saveInitialSort, (state: State, { initialSort }) => ({ ...state, initialSort }))
);

export function reducer(state: State | undefined, action: Action) {
  return assetsDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedAssetId;
export const getSite = (state: State) => state.site;
export const getAsset = (state: State) => state.asset;
