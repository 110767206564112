import { inject } from '@angular/core';
import { PlatformRequest, PlatformResponse, Product } from '@iot-platform/models/common';
import { Asset, AssetEvent, Device, DeviceEvent, Site } from '@iot-platform/models/i4b';
import { AssetEventsService } from 'libs/shared/src/lib/services/asset-events.service';
import { AssetsService } from 'libs/shared/src/lib/services/assets.service';
import { DeviceEventsService } from 'libs/shared/src/lib/services/device-events.service';
import { DevicesService } from 'libs/shared/src/lib/services/devices.service';
import { SitesService } from 'libs/shared/src/lib/services/sites.service';
import { Observable } from 'rxjs';
import { AbstractProductExportsService } from './abstract-product-exports.service';

export interface ExportsFormatter {
  formatBody(element: any): string;

  formatHeader(element: any): string;

  formatRequest(params: PlatformRequest): Observable<PlatformResponse>;
}

export class SitesFormatter implements ExportsFormatter {
  constructor(private service: SitesService) {}

  formatRequest(params: PlatformRequest): Observable<PlatformResponse> {
    return this.service.getAllSites(params);
  }

  formatHeader(element: any): string {
    return (
      [
        element.EXPORTS.SITE_NAME,
        element.INFO_FORM.BUSINESS_ID,
        element.EXPORTS.SITE_TYPE,
        element.EXPORTS.ENTITY_NAME,
        element.INFO_FORM.ADDRESS_1,
        element.INFO_FORM.ADDRESS_2,
        element.INFO_FORM.CITY,
        element.INFO_FORM.ZIP_CODE,
        element.INFO_FORM.COUNTRY,
        element.INFO_FORM.DESCRIPTION
      ].join(';') + '\n'
    );
  }

  formatBody(element: Site): string {
    return (
      [
        element.name ?? '',
        element.businessId ?? '',
        element.type ?? '',
        element.entity.name ?? '',
        element.address?.address1 ?? '',
        element.address?.address2 ?? '',
        element.address?.city ?? '',
        element.address?.zipCode ?? '',
        element.address?.country ?? '',
        element.description || ''
      ].join(';') + '\n'
    ).replace(/\s;/g, ';');
  }
}

export class AssetsFormatter implements ExportsFormatter {
  constructor(private service: AssetsService) {}

  formatRequest(params: PlatformRequest): Observable<PlatformResponse> {
    return this.service.getAll(params);
  }

  formatHeader(element: any): string {
    return (
      [
        element.EXPORTS.ASSET_NAME,
        element.TABLE_CONTENT.SHIP_TO,
        element.TABLE_CONTENT.STATUS,
        element.EXPORTS.BUSINESS_ID,
        element.EXPORTS.DESCRIPTION,
        element.EXPORTS.ASSET_STATUS_DATETIME,
        element.EXPORTS.PRODUCT_ID,
        element.EXPORTS.PRODUCT_NAME,
        element.EXPORTS.PRODUCT_CATALOG_NAME,
        element.EXPORTS.SITE_NAME,
        element.EXPORTS.SITE_TYPE,
        element.EXPORTS.ENTITY_NAME,
        element.EXPORTS.FOLLOWED_VARIABLE_NAME_1,
        element.EXPORTS.FOLLOWED_VARIABLE_VALUE_1,
        element.EXPORTS.FOLLOWED_VARIABLE_UNIT_1,
        element.EXPORTS.FOLLOWED_VARIABLE_DATETIME_1,
        element.EXPORTS.FOLLOWED_VARIABLE_NAME_2,
        element.EXPORTS.FOLLOWED_VARIABLE_VALUE_2,
        element.EXPORTS.FOLLOWED_VARIABLE_UNIT_2,
        element.EXPORTS.FOLLOWED_VARIABLE_DATETIME_2,
        element.EXPORTS.FOLLOWED_VARIABLE_NAME_3,
        element.EXPORTS.FOLLOWED_VARIABLE_VALUE_3,
        element.EXPORTS.FOLLOWED_VARIABLE_UNIT_3,
        element.EXPORTS.FOLLOWED_VARIABLE_DATETIME_3
      ].join(';') + '\n'
    );
  }

  formatBody(element: Asset): string {
    return (
      [
        element.name ?? '',
        element.erpReference.shipTo ?? '',
        element.status.name ?? '',
        element.businessId ?? '',
        element.description ?? '',
        element.status.datetime ?? '',
        element.product?.identifier ?? '',
        element.product?.name ?? '',
        element.product?.catalog?.name ?? '',
        element.site.name ?? '',
        element.site?.type ?? '',
        element.entity?.name ?? '',
        element.followedVariables?.['1']?.name ?? '',
        element.followedVariables?.['1']?.lastValue?.value ?? '',
        element.followedVariables?.['1']?.unit ?? '',
        element.followedVariables?.['1']?.lastValue?.datetime ?? '',
        element.followedVariables?.['2']?.name ?? '',
        element.followedVariables?.['2']?.lastValue?.value ?? '',
        element.followedVariables?.['2']?.unit ?? '',
        element.followedVariables?.['2']?.lastValue?.datetime ?? '',
        element.followedVariables?.['3']?.name ?? '',
        element.followedVariables?.['3']?.lastValue?.value ?? '',
        element.followedVariables?.['3']?.unit ?? '',
        element.followedVariables?.['3']?.lastValue?.datetime ?? ''
      ].join(';') + '\n'
    ).replace(/\s;/g, ';');
  }
}

export class DevicesFormatter implements ExportsFormatter {
  constructor(private service: DevicesService) {}

  formatRequest(params: PlatformRequest): Observable<PlatformResponse> {
    return this.service.getAll(params);
  }

  formatHeader(element: any): string {
    return (
      [
        element.TABLE_CONTENT.DEVICES,
        element.EXPORTS.DEVICE_IDENTIFIER,
        element.EXPORTS.CONNECTOR_ID,
        element.INFO_FORM.ENDPOINT,
        element.EXPORTS.DEVICE_FAMILY,
        element.EXPORTS.DEVICE_MODEL,
        element.EXPORTS.DEVICE_FIRMWARE,
        element.EXPORTS.SITE_NAME,
        element.EXPORTS.ENTITY_NAME,
        element.TABLE_CONTENT.STATUS,
        element.EXPORTS.LAST_CALL_DATE
      ].join(';') + '\n'
    );
  }

  formatBody(element: Device): string {
    return (
      [
        element.name ?? '',
        element.identifier ?? '',
        element.connector ?? '',
        element.communication.endpoint ?? '',
        element.type.family ?? '',
        element.type.model ?? '',
        element.type.firmware ?? '',
        element.site.name ?? '',
        element.entity.name ?? '',
        element.status.name ?? '',
        element.lastCallStatus.datetime ?? ''
      ].join(';') + '\n'
    ).replace(/\s;/g, ';');
  }
}

export class AssetEventsFormatter implements ExportsFormatter {
  constructor(private service: AssetEventsService) {}

  formatRequest(params: PlatformRequest): Observable<PlatformResponse> {
    return this.service.getAssetEvents(params);
  }

  formatHeader(element: any): string {
    return (
      [
        element.TIMELINE.OCCURRENCE_TIME,
        element.TIMELINE.RECEPTION_TIME,
        element.EXPORTS.ACKNOWLEDGE_TIME,
        element.EXPORTS.CLOSE_TIME,
        element.EXPORTS.SITE_NAME,
        element.EXPORTS.ENTITY_NAME,
        element.EXPORTS.ASSET_NAME,
        element.EXPORTS.ASSET_VARIABLE_NAME,
        element.TABLE.EVENT_VALUES,
        element.EXPORTS.UNIT,
        element.TABLE.TYPES,
        element.TABLE.SEVERITIES,
        element.TABLE.CLASSES,
        element.EXPORTS.EVENT_STATUS
      ].join(';') + '\n'
    );
  }

  formatBody(element: AssetEvent): string {
    return (
      [
        element.occurrenceTime ?? '',
        element.receptionTime ?? '',
        element.acknowledge?.datetime ?? '',
        element.close?.datetime ?? '',
        element.context.site.name ?? '',
        element.context.entity.name ?? '',
        element.context.asset.name ?? '',
        element.context.assetVariable.name ?? '',
        element.context.assetVariable.value ?? '',
        element.context.assetVariable.unit ?? '',
        element.type ? element.type : '',
        element.severity ? element.severity : '',
        element.class ? element.class : '',
        element.status ? element.status : ''
      ].join(';') + '\n'
    ).replace(/\s;/g, ';');
  }
}

export class DeviceEventsFormatter implements ExportsFormatter {
  constructor(private service: DeviceEventsService) {}

  formatRequest(params: PlatformRequest): Observable<PlatformResponse> {
    return this.service.getDeviceEvents(params);
  }

  formatHeader(element: any): string {
    return (
      [
        element.TIMELINE.OCCURRENCE_TIME,
        element.TIMELINE.RECEPTION_TIME,
        element.EXPORTS.ACKNOWLEDGE_TIME,
        element.EXPORTS.CLOSE_TIME,
        element.EXPORTS.SITE_NAME,
        element.EXPORTS.ENTITY_NAME,
        element.EXPORTS.DEVICE_NAME,
        element.EXPORTS.DEVICE_IDENTIFIER,
        element.EXPORTS.DEVICE_VARIABLE_NAME,
        element.TABLE.EVENT_VALUES,
        element.EXPORTS.UNIT,
        element.TABLE.TYPES,
        element.TABLE.SEVERITIES,
        element.TABLE.CLASSES,
        element.EXPORTS.EVENT_STATUS
      ].join(';') + '\n'
    );
  }

  formatBody(element: DeviceEvent): string {
    return (
      [
        element.occurrenceTime ?? '',
        element.receptionTime ?? '',
        element.acknowledge?.datetime ?? '',
        element.close?.datetime ?? '',
        element.context.site.name ?? '',
        element.context.entity.name ?? '',
        element.context.device.name ?? '',
        element.context.device.identifier ?? '',
        element.context.deviceVariable.name ?? '',
        element.context.deviceVariable.value ?? '',
        element.context.deviceVariable.unit ?? '',
        element.type ?? '',
        element.severity ?? '',
        element.class ?? '',
        element.status ?? ''
      ].join(';') + '\n'
    ).replace(/\s;/g, ';');
  }
}

export class ProductsFormatter implements ExportsFormatter {
  products$: Observable<PlatformResponse>;
  productExportsService: AbstractProductExportsService;

  constructor() {
    this.productExportsService = inject(AbstractProductExportsService);
    this.products$ = this.productExportsService.getProducts$();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatRequest(params: PlatformRequest): Observable<PlatformResponse> {
    return this.products$;
  }

  formatHeader(element: any): string {
    return [element.PRODUCT_ID, element.FORM.PRODUCT_NAME, element.CATALOG_NAME, element.FORM.ATTACHED_ENTITIES].join(';') + '\n';
  }

  formatBody(element: Product): string {
    return (
      [
        element.identifier ?? '',
        element.name ?? '',
        element.catalog.name ?? '',
        element.catalog.entities ? element.catalog.entities.map((e) => e.name).join(', ') : ''
      ].join(';') + '\n'
    ).replace(/\s;/g, ';');
  }
}
