<section *ngIf="asset" fxLayout="column" fxLayoutGap="1px">
  <iot-platform-ui-detail-popup-section-header
    headerTitle="{{ 'IOT_DICTIONARY.FOLLOWED_VARIABLE' | translate }}"
    style="padding-bottom: 10px"
  ></iot-platform-ui-detail-popup-section-header>

  <iot-platform-ui-detail-followed-variable
    *ngFor="let followedNumber of arrayToIterateForFollowedNumbers"
    [followedNumber]="followedNumber"
    [followedVariable]="asset?.followedVariables[followedNumber]"
  ></iot-platform-ui-detail-followed-variable>
</section>
