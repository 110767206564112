import { AsyncPipe, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReleaseNotesService } from './services/release-notes.service';

@Component({
  standalone: true,
  imports: [
    MatCardModule,
    MatToolbarModule,
    MatProgressBarModule,
    UpperCasePipe,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
    NgIf,
    AsyncPipe,
    TranslateModule
  ],
  selector: 'iot-platform-ui-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesComponent {
  releaseNote$: Observable<SafeHtml> = this.releaseNotesService.getReleaseNotes().pipe(map((unsafeHtml) => this.sanitizer.bypassSecurityTrustHtml(unsafeHtml)));
  currentVersion$: Observable<string> = this.releaseNotesService.getCurrentVersion();

  constructor(
    private releaseNotesService: ReleaseNotesService,
    private dialogRef: MatDialogRef<ReleaseNotesComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { appName: string }
  ) {}

  close() {
    this.dialogRef.close();
  }
}
