<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">
                {{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE' | translate: { elementName: data?.elementName } }}
            </h1>
            <button (click)="close()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content history-logs-popup--card-content" fxLayout="row">

        @if (historyLogsLoading()) {
            <div class="history-logs-popup--loader-container" fxFlex>
                <iot-platform-ui-card-loader></iot-platform-ui-card-loader>
            </div>
        }

        @if (!historyLogsLoading() && !historyLogs().length) {
            <div fxFlex fxLayoutAlign="center center">
                <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.NO_LOGS_FOUND' | translate }}</span>
            </div>
        }

        @if (!historyLogsLoading() && !!historyLogs().length) {
            <section
                    [ngClass]="{ invisible: historyLogsLoading(), visible: !historyLogsLoading() }"
                    class="history-logs-popup--main-section"
                    fxFlex="50%"
                    fxLayout="column"
            >

                @for (historyLog of historyLogs(); track historyLog.id) {
                    <div
                            (click)="selectHistoryLog(historyLog)"
                            [ngClass]="{ 'history-logs-popup--selected-log': historyLog.id === selectedHistoryLog?.id }"
                            class="history-logs-popup--log-summary"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                    >
                        <div fxFlex="75%" fxLayout="row" fxLayoutAlign="start center">
          <span [ngClass]="{ 'history-logs-popup--log-summary-system-modification': !historyLog.context?.userId }">{{
              !!historyLog.context?.userId ? (historyLog.context.userId | userName) : ('AUDIT_TRAIL.HISTORY_LOG_POPUP.SYSTEM' | translate)
              }}</span>
                            &nbsp;
                            <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.CONCEPT_EVENTS_LIST.' + historyLog.conceptEvent?.toUpperCase() | translate }}</span>
                            &nbsp;
                            <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE_CONCEPTS.' + historyLog.concept?.toUpperCase() | translate }}</span>
                            &nbsp;

                            @if (!!historyLog.updatedAttributes.length) {
                                <span
                                        [matTooltip]="historyLog.updatedAttributes.join(', ')"
                                        class="history-logs-popup--log-summary-attributes"
                                        fxFlex
                                        matTooltipClass="regular-tooltip"
                                >"{{ historyLog.updatedAttributes.join('", "') }}"</span
                                >
                            }
                        </div>
                        <div>{{ historyLog.datetime | dateFormat }}</div>
                    </div>
                }
            </section>
        }

        @if (!historyLogsLoading() && !!historyLogs().length) {
            <section
                    [ngClass]="{ invisible: historyLogsLoading(), visible: !historyLogsLoading() && !!historyLogs().length }"
                    class="history-logs-popup--main-section history-logs-popup--detail-section"
                    fxFlex="50%"
            >

                @if (!selectedHistoryLog && !selectedLodLoading()) {
                    <div fxFlex fxLayoutAlign="center center">
                        <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.SELECTED_LOG_PLACEHOLDER' | translate }}</span>
                    </div>
                }

                @if (selectedLodLoading()) {
                    <div class="history-logs-popup--loader-container" fxFlex>
                        <iot-platform-ui-card-loader></iot-platform-ui-card-loader>
                    </div>
                }

                @if (selectedHistoryLog && !selectedLodLoading()) {
                    <div class="full-width" fxLayout="column"
                         fxLayoutGap="10px">

                        @for (updatedAttribute of selectedHistoryLog.updatedAttributes; track updatedAttribute) {
                            <div fxLayout="row">
                                <div class="history-logs-popup--detail-section-old-image" fxFlex="50">
                                    <!-- @formatter:off -->
                                    <pre>{{ updatedAttribute }}: {{ (getParsedAttribute(selectedHistoryLog.oldImage, updatedAttribute) | json) ?? 'null' }}</pre>
                                </div>
                                <div class="history-logs-popup--detail-section-new-image" fxFlex="50">
                                    <!-- @formatter:off -->
                                    <pre>{{ updatedAttribute }}: {{ (getParsedAttribute(selectedHistoryLog.newImage, updatedAttribute) | json) ?? 'null' }}</pre>
                                </div>
                            </div>
                        }

                        @if (!selectedHistoryLog.oldImage && selectedHistoryLog.newImage) {
                            <div>
                                <div class="history-logs-popup--detail-section-new-image" fxFlex="50">
                                    <pre> {{ selectedHistoryLog.newImage | json }} </pre>
                                </div>
                            </div>
                        }

                        @if (!selectedHistoryLog.newImage && selectedHistoryLog.oldImage) {
                            <div>
                                <div class="history-logs-popup--detail-section-old-image" fxFlex="50">
                                    <pre> {{ selectedHistoryLog.oldImage | json }} </pre>
                                </div>
                            </div>
                        }

                    </div>
                }
            </section>
        }
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close()" class="button-regular" color="accent"
                mat-raised-button>{{ 'IOT_DICTIONARY.CLOSE' | translate }}
        </button>
    </mat-card-actions>
</mat-card>