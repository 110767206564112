import { createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { DevicesDbActions } from '../actions';

export const devicesUiFeatureKey = 'devicesUi';

export interface State {
  deviceLoaded: boolean;
  deviceLoading: boolean;
  deviceVarLoaded?: boolean;
  deviceVarLoading?: boolean;
  siteLoaded?: boolean;
  siteLoading?: boolean;
  callLogLoaded: boolean;
  callLogLoading: boolean;
  tagsLoaded?: boolean;
  error?: any;
}

export const initialState: State = {
  deviceLoaded: false,
  deviceLoading: false,
  deviceVarLoaded: false,
  deviceVarLoading: false,
  siteLoaded: false,
  siteLoading: false,
  callLogLoaded: false,
  callLogLoading: false,
  tagsLoaded: false,
  error: false
};

export const reducer = createReducer(
  initialState,
  on(DevicesDbActions.loadCallLogsByDeviceId, (state: State) => ({ ...state, callLogLoaded: false, callLogLoading: true })),
  on(DevicesDbActions.loadCallLogsByDeviceIdSuccess, (state: State) => ({ ...state, callLogLoaded: true, callLogLoading: false })),
  on(DevicesDbActions.loadCallLogsByDeviceIdFailure, (state: State, { error }) => ({ ...state, callLogLoaded: false, callLogLoading: false, error })),
  //
  on(DevicesDbActions.loadDevices, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.loadDevicesSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.loadDevicesFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: false, error })),
  //
  on(DevicesDbActions.loadDeviceById, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.loadDeviceByIdSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.loadDeviceByIdFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: true, error })),
  //
  on(DevicesDbActions.loadVariablesByDeviceId, (state: State) => ({ ...state, deviceVarLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.loadVariablesByDeviceIdSuccess, (state: State) => ({ ...state, deviceVarLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.loadVariablesByDeviceIdFailure, (state: State, { error }) => ({ ...state, deviceVarLoaded: false, deviceLoading: false, error })),
  //
  on(DevicesDbActions.loadSiteById, (state: State) => ({ ...state, siteLoaded: false, siteLoading: true, deviceLoading: true, deviceLoaded: false })),
  on(DevicesDbActions.loadSiteByIdSuccess, (state: State) => ({ ...state, siteLoaded: true, siteLoading: false, deviceLoading: false, deviceLoaded: true })),
  on(DevicesDbActions.loadSiteByIdFailure, (state: State, { error }) => ({ ...state, siteLoaded: false, siteLoading: false, error })),
  //

  on(DevicesDbActions.loadTagsByDeviceId, (state: State) => ({ ...state, tagsLoaded: false, tagsLoading: true, deviceLoading: true, deviceLoaded: false })),
  on(DevicesDbActions.loadTagsByDeviceIdSuccess, (state: State) => ({
    ...state,
    tagsLoaded: true,
    tagsLoading: false,
    deviceLoading: false,
    deviceLoaded: true
  })),
  //
  on(DevicesDbActions.addDevice, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.addDeviceSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.addDeviceFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: false, error })),
  //
  on(DevicesDbActions.updateDevice, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.updateDeviceSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.updateDeviceFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: false, error })),
  //
  on(DevicesDbActions.activateDevice, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.activateDeviceSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.activateDeviceFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: false, error })),
  //
  on(DevicesDbActions.resetDevice, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.resetDeviceSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.resetDeviceFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: false, error })),
  //
  on(DevicesDbActions.updateDeviceVariable, (state: State) => ({ ...state, deviceVarLoaded: false, deviceVarLoading: true })),
  on(DevicesDbActions.updateDeviceVariableSuccess, (state: State) => ({ ...state, deviceVarLoaded: true, deviceVarLoading: false })),
  on(DevicesDbActions.updateDeviceVariableFailure, (state: State, { error }) => ({ ...state, deviceVarLoaded: false, deviceVarLoading: false, error })),
  //
  on(DevicesDbActions.loadMVSettings, (state: State) => ({ ...state, deviceLoading: true, deviceLoaded: false })),
  on(DevicesDbActions.loadMVSettingsSuccess, (state: State) => ({ ...state, deviceLoading: false, deviceLoaded: true })),
  on(DevicesDbActions.loadMVSettingsFailure, (state: State, { error }) => ({ ...state, deviceLoading: false, deviceLoaded: true, error })),
  // ****
  on(DevicesDbActions.saveMVSettings, (state: State) => ({ ...state, deviceLoading: true, deviceLoaded: false })),
  on(DevicesDbActions.saveMVSettingsSuccess, (state: State) => ({ ...state, deviceLoading: false, deviceLoaded: true })),
  on(DevicesDbActions.saveMVSettingsFailure, (state: State, { error }) => ({ ...state, deviceLoading: false, deviceLoaded: true, error })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),
  //
  on(DevicesDbActions.resetDeviceVariablesLastValues, (state: State) => ({ ...state, deviceLoaded: false, deviceLoading: true })),
  on(DevicesDbActions.resetDeviceVariablesLastValuesSuccess, (state: State) => ({ ...state, deviceLoaded: true, deviceLoading: false })),
  on(DevicesDbActions.resetDeviceVariablesLastValuesFailure, (state: State, { error }) => ({ ...state, deviceLoaded: false, deviceLoading: false, error }))
);

export const getTagsLoaded = (state: State) => state.tagsLoaded;
export const getCallLogsLoading = (state: State) => state.callLogLoading;
export const getCallLogsLoaded = (state: State) => state.callLogLoaded;
export const getDeviceLoaded = (state: State) => state.deviceLoaded;
export const getDeviceLoading = (state: State) => state.deviceLoading;
export const getDeviceVarLoaded = (state: State) => state.deviceVarLoaded;
export const getSiteLoaded = (state: State) => state.siteLoaded;
