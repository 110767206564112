import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-card-loader',
  templateUrl: './card-loader.component.html',
  styleUrls: ['./card-loader.component.scss']
})
export class CardLoaderComponent {
  @Input() backgroundClass: string;
}
