<mat-card class="dialog-card timeseries-widget">
    <mat-card-title *ngIf="options?.header?.enabled" class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title timeseries-widget_title">{{ options?.header?.title }}</h1>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content" fxLayout="row">
        <iot4bos-ui-variable-chart
                [data]="options?.variables"
                [options]="options"
                class="timeseries-widget_chart timeseries-widget_chart--{{ options?.size?.toLowerCase() }}"
                variableType="assetVariable"
        ></iot4bos-ui-variable-chart>
    </mat-card-content>

    <mat-card-actions *ngIf="options?.footer?.enabled" class="dialog-card-actions" fxLayout="row"
                      fxLayoutAlign="center center">
        <div class="timeseries-widget_legend">{{ options?.footer?.legend }}</div>
    </mat-card-actions>
</mat-card>
