<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.TITLE' | translate: param }}</h1>
            <button (click)="exit()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
        <section class="" fxFlex fxLayout="column">
            <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <p>{{ unselectedRoles.length }}{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.AVAILABLE_ROLES' | translate | uppercase }}</p>
                <mat-progress-spinner *ngIf="unselectedRolesPending" [diameter]="20" color="accent"
                                      mode="indeterminate"></mat-progress-spinner>
            </mat-toolbar>

            <mat-list class="height-300 one-line-list" fxFlex>
                <mat-list-item *ngFor="let role of unselectedRoles" [lines]="1"
                               class="hoverable">
                    <mat-icon matListItemAvatar>work_outline</mat-icon>
                    <h3 matListItemTitle>{{ role.name }}</h3>
                    <button (click)="addRoleToProfile(role)" class="regular-round-button" mat-icon-button
                            matListItemMeta>
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </section>

        <section class="" fxFlex fxLayout="column">
            <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
                <p>{{ currentRoles.length }}{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.SELECTED_ROLES' | translate | uppercase }}</p>
            </mat-toolbar>

            <mat-list class="height-300 one-line-list" fxFlex>
                <mat-list-item *ngFor="let role of currentRoles" [lines]="1" class="hoverable">
                    <mat-icon matListItemIcon>work</mat-icon>
                    <h3 matListItemTitle>{{ role.name }}</h3>
                    <button (click)="removeRoleFromProfile(role)" mat-icon-button matListItemMeta>
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </section>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="exit()" class="button-regular" color="accent"
                mat-raised-button>{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.DONE' | translate }}</button>
    </mat-card-actions>
</mat-card>
