<mat-card class="dialog-card">
    <mat-card-title class="dialog-card-header">
        <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="dialog-card-title">{{ title$ | async }}</h1>
            <button (click)="closeOnCancel()" class="regular-round-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-title>

    <mat-card-content class="dialog-card-content">
        <form [formGroup]="poEventCreateForm" class="p-20" fxFlex fxLayout="column" fxLayoutAlign="space-between">
            <mat-form-field color="accent">
                <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.NAME' | translate }}</mat-label>
                <input
                  #eventLabelInput
                  [placeholder]=""
                  formControlName="eventLabel"
                  matInput
                  maxlength="50"
                  pattern="\S.*"
                  required
                />
                <mat-error
                        *ngIf="eventLabel.invalid && (eventLabel.errors.maxlength || eventLabel.errors.pattern)">{{ errorMessage$ | async }}</mat-error>
                <mat-hint align="end">{{ eventLabelInput.value?.length || 0 }}/50</mat-hint>
                <mat-hint *ngIf="displayDuplicateMessage$ | async">
                    <span class="dialog-card-input-error">{{ 'FAVORITE_VIEW.FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</span>
                </mat-hint>
            </mat-form-field>

            <iot-platform-ui-async-autocomplete
                    (reset)="resetEntity()"
                    (search)="resetEntity()"
                    (selectionChanged)="onEntitySelection($event)"
                    [data]="entities"
                    [disabled]="!!data"
                    [displayKey]="'label'"
                    [displaySearchIcon]="false"
                    [filterKey]="'label'"
                    [initialItem]="initialEntity"
                    [minLength]="1"
                    [placeholder]="'IOT_DICTIONARY.ENTITY'"
                    [required]="true"
                    [showSpinner]="dataLoading"
                    [standaloneMode]="true"
            >
            </iot-platform-ui-async-autocomplete>

            <mat-form-field color="accent">
                <mat-label>{{ 'IOT_DICTIONARY.RULE_TYPE' | translate }}</mat-label>
                <mat-select [placeholder]="" formControlName="eventType" required>
                    <mat-option *ngFor="let algo of algos" [value]="algo">{{ algo.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <p class="dialog-form-tips">{{ 'ASSETS.VARIABLE_FORM.MANDATORY_FIELDS' | translate }}</p>
        </form>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="closeOnCancel()" class="button-regular"
                mat-button>{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
        <button (click)="save('ADD')" [disabled]="!poEventCreateForm.valid || (isDisabled$ | async)"
                class="button-regular" color="accent"
                mat-raised-button>
            {{ actionButtonLabel | translate }}
        </button>
        <button (click)="save('CONFIGURE')" *ngIf="!data" [disabled]="!poEventCreateForm.valid || (isDisabled$ | async)"
                class="button-regular" color="accent"
                mat-raised-button>
            {{ 'ASSETS.VARIABLE_FORM.CREATE_CONFIGURE' | translate }}
        </button>
    </mat-card-actions>
</mat-card>
