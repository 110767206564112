<section fxLayout="column" fxLayoutGap="10px">
  <iot-platform-ui-detail-popup-section-header
    headerTitle="{{ (title ? title : tags?.length <= 1 ? 'IOT_DICTIONARY.TAG' : 'IOT_DICTIONARY.TAGS') | translate }} ({{ tags?.length }})"
  ></iot-platform-ui-detail-popup-section-header>
  @if(displayLoader && tags.length === 0) {
  <div class="detail-no-tags-card">
    <div class="detail-no-tags-card" fxFlex fxLayout="row" fxLayoutAlign="center center">{{ 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG' | translate }}</div>
  </div>
  }
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    @if(!displayLoader) {
    <iot-platform-ui-card-loader [backgroundClass]="'tag-loader mv-detail'"></iot-platform-ui-card-loader>
    } @if(displayLoader && tags && tags.length > 0) {
    <mat-chip-set>
      @for(tag of tags; track tag) {
      <iot-platform-ui-chip [chipButton]="{ display: false }" [chip]="tag" [isTag]="true"></iot-platform-ui-chip>
      }
    </mat-chip-set>
    }
  </div>
</section>
