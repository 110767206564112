import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HashUtils } from '@iot-platform/iot-platform-utils';
import { SidenavMenuBlock, SidenavMenuItem } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-sidenav-block',
  templateUrl: './sidenav-block.component.html',
  styleUrls: ['./sidenav-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavBlockComponent implements OnChanges {
  @Input() navBlock!: SidenavMenuBlock;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navBlock?.currentValue) {
      this.navBlock.items = changes.navBlock?.currentValue?.items.map((item: SidenavMenuItem) => ({
        ...item,
        id: HashUtils.simpleHashFromObj(item),
        hasChildren: !!item.submenu?.length
      }));
    }
  }

  trackById(index: number, item: SidenavMenuItem) {
    return item.id;
  }
}
