<form [formGroup]="parametersForm" style="margin-top: 10px" fxLayout="column" fxLayoutGap="4px">
  <div *ngFor="let parameter of formulaParameters">
    <shared-iot4bos-asset-ui-variable-parameter
      fxFlex
      [assetVariable]="parametersForm.controls[parameter.name].value"
      [parameter]="parameter"
      [isUnitInvalid]="parametersForm.controls[parameter.name].errors?.invalidUnit"
      [isTouched]="parametersForm.touched"
      [isFirstEmptyParameter]="firstEmptyParameter?.name === parameter.name"
      [invalidUnitTooltip]="invalidUnitTooltip | translate: { unit: parameter.unit }"
      [placeholder]="placeholder + parameter?.name | translate"
      (clearFormControl)="clearFormControl(parameter.name)"
    >
    </shared-iot4bos-asset-ui-variable-parameter>
  </div>
</form>
