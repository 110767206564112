<mat-card class="dialog-card last-value-widget" data-cy="iot-platform-ui-last-value-widget">
  @if (options?.header?.enabled){
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title last-value-widget_title">{{ options?.header?.title }}</h1>
    </mat-toolbar>
  </mat-card-title>
  }

  <mat-card-content class="dialog-card-content last-value-widget_content" fxLayout="column" fxLayoutGap="4px">
    @if (variable){
    <div fxFlex="20" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-icon>{{ variable.linked ? 'link' : 'link_off' }}</mat-icon>
        @if (eventIconParameters.displayIcon){
        <mat-icon
          [svgIcon]="eventIconParameters.name"
          [matTooltip]="eventIconParameters.tooltip | translate"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="above"
        ></mat-icon>
        }
      </div>
      <div fxLayout="column" fxFlex="80" >
        <div class="fw-600 last-value-widget_name-ellipsis">{{ variable.name }}</div>
        <div class="fs-12 last-value-widget_name-ellipsis">{{ variable.asset.name }}</div>
      </div>
    </div>
    <span fxFlex class="fw-600 last-value-widget_value" [ngStyle]="{ 'background-color': activatedThreshold?.cellColor }">
      {{ variable | valueUnitFormat }}</span
    >
    <span
      class="fs-12 last-value-widget_datetime"
      [ngClass]="{ recentDay: isRecentDay, recentHour: isRecentHour, futureDay: isFutureDay, nextDay: isNextDay }"
      >{{ variable.lastValue?.datetime | dateFormat }}</span
    >

    <div class="" fxFlex="50" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="4px">
      @for (threshold of variable.thresholds.values; track threshold.position){
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="fs-12 last-value-widget_thresholds-area">
        <span class="" fxFlex fxLayoutAlign="end center">{{ threshold.name }}</span>
        <span fxFlex="5" fxLayout="row" fxLayoutAlign="center center">{{ variable.thresholds.operator }}</span>
        <span
          fxLayout="row"
          fxLayoutAlign="end center"
          class="last-value-widget_threshold-value fw-600"
          [ngStyle]="{ 'background-color': threshold.position === activatedThreshold?.position ? activatedThreshold?.cellColor : null }"
          fxFlex="30"
          >{{ { lastValue: { value: threshold.value }, unit: variable.unit } | valueUnitFormat }}</span
        >
        <span class="last-value-widget_threshold-color" [ngStyle]="{ 'background-color': threshold.lineColor }">&nbsp;</span>
      </div>
      } @empty {
      <div class="fs-12 last-value-widget_spinner">
        {{ 'WIDGETS.LAST_VALUE.NO_THRESHOLD_MESSAGE' | translate }}
      </div>
      }
    </div>
    }@else {
    <mat-progress-spinner mode="indeterminate" [diameter]="40" color="accent" class="last-value-widget_spinner"></mat-progress-spinner>

    }
  </mat-card-content>
  @if (options?.footer?.enabled){
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="center center">
    <div class="last-value-widget_legend">{{ options?.footer?.legend }}</div>
  </mat-card-actions>
  }
</mat-card>
