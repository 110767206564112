<div fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
  <h2 [ngClass]="{ 'login-select-authentication--title': isI4BLogin }" class="layout-login-regular-title">{{ 'LOGIN.TITLE.LOGIN' | translate }}</h2>

  <form [formGroup]="form" [ngClass]="{ 'login-form-i4b': isI4BLogin }" fxLayout="column">
    <mat-form-field color="accent" fxFlex="100%">
      <mat-icon matIconPrefix>email</mat-icon>
      <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
      <input (input)="onChangeEmail($event.target.value)" formControlName="email" matInput type="email" />
      @if (email.invalid) {
        <mat-error>{{ (email?.errors?.email ? 'FORM.ERROR_MESSAGE.INVALID_EMAIL' : 'LOGIN.ERROR_MESSAGE.EMAIL') | translate }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field color="accent" fxFlex="100%">
      <mat-icon matIconPrefix>lock</mat-icon>
      <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
      <input [type]="hide ? 'password' : 'text'" formControlName="password" matInput />
      <mat-icon (click)="hide = !hide" class="layout-pointer-cursor" matIconSuffix>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      @if (password.invalid) {
        <mat-error>{{ 'LOGIN.ERROR_MESSAGE.PASSWORD' | translate }}</mat-error>
      }
    </mat-form-field>

    @if (!isI4BLogin) {
      <div class="login-reset-pwd-button" fxLayout="row" fxLayoutAlign="end start">
        <button (click)="onResetPassword()" mat-button type="button">{{ 'LOGIN.RESET_PASSWORD' | translate }}</button>
      </div>
    }

    <div [class.error]="hasError" class="layout-login-error" fxLayout="column">
      <em>{{ 'LOGIN.ERROR_MESSAGE.LOGIN_FAILED' | translate }}</em>
      <span>{{ 'LOGIN.ERROR_MESSAGE.FIX_EMAIL_PASSWORD' | translate }}</span>
    </div>

    <div [ngClass]="{ 'layout-form-actions-i4b': isI4BLogin }" class="layout-form-actions" fxLayoutGap="20px">
      @if (isI4BLogin) {
        <button (click)="cancel.emit()" class="layout-login-button" mat-button type="button">{{ 'LOGIN.BUTTON.CANCEL' | translate }}</button>
      }

      <iot-platform-ui-progress-bar-button
        (clickEvent)="onSignIn()"
        [disabled]="form.invalid"
        [enableGauge]="enableGauge && !hasError"
        [gaugeColor]="'accent'"
        [title]="'LOGIN.BUTTON.SIGN_IN' | translate"
        type="submit"
      ></iot-platform-ui-progress-bar-button>
    </div>
  </form>

  @if (isI4BLogin) {
    <span (click)="onResetPassword()" class="login-reset-pwd-button-i4b">{{ 'LOGIN.RESET_PASSWORD' | translate }}</span>
  }
</div>
