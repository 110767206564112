import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ApiInterceptor } from './api.interceptor';
import { ApiInterceptorOptions } from './api.interceptor-options';

export const API_INTERCEPTOR = new InjectionToken<unknown>('API_INTERCEPTOR_TOKEN');

@NgModule({
  imports: [HttpClientModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }]
})
export class ApiInterceptorModule {
  static forRoot(options: Partial<ApiInterceptorOptions>): ModuleWithProviders<ApiInterceptorModule> {
    return {
      ngModule: ApiInterceptorModule,
      providers: [
        {
          provide: API_INTERCEPTOR,
          useValue: options
        }
      ]
    };
  }
}
