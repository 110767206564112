import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Graph } from '@iot-platform/models/common';
import { WidgetEvent, WidgetInstance } from '@iot-platform/models/widgets';

@Component({
  selector: 'iot-platform-ui-widgets-container',
  templateUrl: './widgets-container.component.html',
  styleUrls: ['./widgets-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetsContainerComponent implements OnChanges {
  @Input() public widgets: WidgetInstance[] = [];
  @Input() public enableDragAndDrop = false;

  @Output() public dispatchEvent: EventEmitter<WidgetEvent> = new EventEmitter<WidgetEvent>();
  @Output() public widgetsOrderChange: EventEmitter<WidgetInstance[]> = new EventEmitter<WidgetInstance[]>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets.currentValue) {
      this.widgets = this.widgets.sort((widgetA, widgetB) =>
        widgetA.data.displayOrder > widgetB.data.displayOrder ? 1 : widgetA.data.displayOrder < widgetB.data.displayOrder ? -1 : 0
      );
    }
  }

  drop(event: CdkDragDrop<WidgetInstance[]>) {
    moveItemInArray(this.widgets, event.previousIndex, event.currentIndex);
    this.widgetsOrderChange.emit(this.getReorderedWidgets());
  }

  getReorderedWidgets(): WidgetInstance[] {
    return this.widgets.reduce((acc: WidgetInstance[], widget: WidgetInstance, currentIndex) => {
      if ((widget.data as Graph).displayOrder !== currentIndex) {
        (widget.data as Graph).displayOrder = currentIndex;
        acc.push(widget);
      }
      return acc;
    }, []);
  }
}
