import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@iot-platform/notification';
import { TranslateService } from '@ngx-translate/core';
import { PopupComponent } from '../../../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { DeviceCallLogService } from '../device-call-log.service';

@Component({
  selector: 'iot4bos-ui-device-call-log-message-popup',
  templateUrl: './device-call-log-message-popup.component.html',
  styleUrls: ['./device-call-log-message-popup.component.scss']
})
export class DeviceCallLogMessagePopupComponent implements OnInit {
  message;
  loading = true;

  constructor(
    public matDialogRef: MatDialogRef<DeviceCallLogMessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { direction: string; deviceIdentifier: string; timestamp: number },
    private readonly deviceCallLogService: DeviceCallLogService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.deviceCallLogService.getCallLogMessage(this.data.direction, this.data.deviceIdentifier, this.data.timestamp).subscribe(
      (message) => {
        this.message = message;
        this.loading = false;
      },
      () => {
        this.matDialogRef.close();
        this.dialog.open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: {
            type: 'error',
            value: this.translateService.instant('DEVICES.CALL_LOG.MESSAGE_LOADING_ERROR')
          }
        });
      }
    );
  }

  close() {
    this.matDialogRef.close();
  }

  copyMessageToClipboard() {
    navigator.clipboard
      .writeText(JSON.stringify(this.message, null, 2))
      .then(() => this.notificationService.displaySuccess(this.translateService.instant('DEVICES.CALL_LOG.MESSAGE_COPIED')));
  }
}
