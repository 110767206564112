import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Asset } from '@iot-platform/models/i4b';
import { MapFacade } from '../../state/facades/map.facade';

interface Hour {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'iot-platform-maps-map-panel-info-toolbox',
  templateUrl: './map-panel-info-toolbox.component.html',
  styleUrls: ['./map-panel-info-toolbox.component.scss']
})
export class MapPanelInfoToolboxComponent implements OnInit {
  @Input() asset: Asset;
  @Output() loadRoutes: EventEmitter<{ asset: Asset; daysToDisplay: string; minutesBetweenSegment: number; limit: number }> = new EventEmitter<{
    asset: Asset;
    daysToDisplay: string;
    minutesBetweenSegment: number;
    limit: number;
  }>();

  SELECT_HOUR_LABEL = 'Select duration';
  routesLoading = false;
  limit = 20000;

  hours: Hour[] = [
    { value: this.SELECT_HOUR_LABEL, viewValue: this.SELECT_HOUR_LABEL },
    { value: '60', viewValue: '1 hour' },
    { value: '1440', viewValue: '1 day' },
    { value: '2880', viewValue: '2 days' },
    { value: '4320', viewValue: '3 days' },
    { value: '7200', viewValue: '5 days' },
    { value: '14400', viewValue: '10 days' },
    { value: '43200', viewValue: '30 days' }
  ];

  selectedHours = this.SELECT_HOUR_LABEL;
  minuteBetweenSegment = 5;

  // TEST
  constructor(
    private readonly zone: NgZone,
    private mapFacade: MapFacade,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.zone.run(() => {
      this.mapFacade.routesLoading$?.subscribe((loading) => {
        this.routesLoading = loading;
        this.cdr.detectChanges();
      });
    });
  }

  onLoadRoutes() {
    if (this.selectedHours !== this.SELECT_HOUR_LABEL && this.asset) {
      this.loadRoutes.emit({ asset: this.asset, daysToDisplay: this.selectedHours, minutesBetweenSegment: this.minuteBetweenSegment, limit: this.limit });
    }
  }
}
