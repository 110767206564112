import { inject, Signal } from '@angular/core';
import { AuthFacade, AuthorizationService } from '@iot-platform/auth';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { ApiFeatureSelector, ApiSelector, BaseFacade, CommonGenericModel, FavoriteView } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export abstract class I4BBaseFacade<T extends CommonGenericModel, P, F> extends BaseFacade<T, P, F> {
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly authorizationService: AuthorizationService = inject(AuthorizationService);
  abstract concept: string;
  //
  selectedItemInGrid$: Observable<CommonGenericModel | undefined> = this.store.select(fromGrids.getSelectedItemInSelectedGrid);
  selectedItemInGrid: Signal<CommonGenericModel | undefined> = this.store.selectSignal(fromGrids.getSelectedItemInSelectedGrid);
  abstract grids$: Observable<I4BGrid<I4BGridOptions, I4BGridData>[]>;
  abstract grids: Signal<I4BGrid<I4BGridOptions, I4BGridData>[]>;
  abstract grid$: Observable<I4BGrid<I4BGridOptions, I4BGridData> | undefined>;
  abstract grid: Signal<I4BGrid<I4BGridOptions, I4BGridData> | undefined>;

  abstract gridConfiguration: Signal<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null>;

  abstract gridConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null>;
  //
  abstract favoriteViews$: Observable<FavoriteView[]>;
  abstract favoriteViews: Signal<FavoriteView[]>;
  abstract currentFavoriteView$: Observable<FavoriteView>;
  abstract currentFavoriteView: Signal<FavoriteView>;
  loadingFavoriteViews$ = this.store.select(fromFavoriteViews.getLoading);
  abstract favoriteViewConfiguration$: Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null>;
  abstract favoriteViewConfiguration: Signal<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null>;

  abstract canCreate: Signal<boolean>;
  abstract canRead: Signal<boolean>;
  abstract canUpdate: Signal<boolean>;
  abstract canDelete: Signal<boolean>;

  protected constructor(
    protected store: Store,
    protected selector: ApiSelector<any, T, P, F> | ApiFeatureSelector<any, T, P, F>
  ) {
    super(store, selector);
  }

  // GRIDS
  getDefaultGrid(masterView: string) {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: masterView }));
  }

  loadGrids(): void {
    this.store.dispatch(GridsDbActions.loadGrids({ concept: this.getConcept() }));
  }

  // FAVORITE VIEWS
  addFavoriteView(data: { favoriteView: FavoriteView; grid?: I4BGrid<I4BGridOptions, I4BGridData> }): void {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }): void {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }
  deleteFavoriteView(favoriteView: FavoriteView): void {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setFavoriteView(favoriteView: FavoriteView, masterView: string): void {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView, favoriteView }));
  }
  //

  getConcept(): string {
    return this.concept;
  }
}
