<div *ngIf="!!selectedRule" class="po-event-info--container" fxFlex fxLayout="column">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <iot-platform-ui-detail-popup-section-header [headerTitle]="'PO_EVENTS.INFO_PAGE.POE' | translate | uppercase"
                                                     class="po-event-info--header"
                                                     fxFlex
        ></iot-platform-ui-detail-popup-section-header>
        <button
                (click)="editRule.emit()"
                *ngIf="canUpdateRule && selectedRule?.isEditable"
                [matTooltip]="'IOT_DICTIONARY.EDIT' | translate"
                class="light-button edit-rule-btn"
                mat-mini-fab
                matTooltipClass="regular-tooltip"
        >
            <mat-icon>edit</mat-icon>
        </button>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="33" fxLayout="column" fxLayoutGap="10px">
            <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.NAME' | translate"
                    [value]="selectedRule.name"
            ></iot-platform-ui-detail-popup-display-property>
            <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.ENTITY' | translate"
                    [value]="selectedRule.entity.name"
            ></iot-platform-ui-detail-popup-display-property>
        </div>

        <div fxFlex="33" fxLayout="column" fxLayoutGap="10px">
            <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.CREATED_AT' | translate"
                    [value]="selectedRule.createdAt | dateFormat"
            ></iot-platform-ui-detail-popup-display-property>
            <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.CREATED_BY' | translate"
                    [value]="selectedRule.createdBy | userName"
            ></iot-platform-ui-detail-popup-display-property>
        </div>

        <div fxFlex="33" fxLayout="column" fxLayoutGap="10px">
            <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.UPDATED_AT' | translate"
                    [value]="selectedRule.updatedAt ? (selectedRule.updatedAt | dateFormat) : '-'"
            ></iot-platform-ui-detail-popup-display-property>
            <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.UPDATED_BY' | translate"
                    [value]="selectedRule.updatedBy | userName"
            ></iot-platform-ui-detail-popup-display-property>
        </div>
    </div>
</div>

<div *ngIf="!!selectedRule" class="po-event-info--container" fxFlex fxLayout="column">
    <div class="po-event-info--header" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <span class="fw-600 po-event-info--section-title">{{ 'IOT_DICTIONARY.RULE_TYPE' | translate | uppercase }}</span>
        <span class="fw-600">{{ selectedRule.algo | uppercase }}</span>
        <span
                [ngClass]="{
        'capitalize-first-letter': true,
        'po-event-info--is-active': selectedRule?.isActive,
        'po-event-info--is-inactive': !selectedRule?.isActive
      }"
        >{{ 'IOT_DICTIONARY.' + (selectedRule?.isActive ? 'active' : 'inactive') | translate }}</span
        >
        <hr class="regular-1px-line" fxFlex/>
        <button
                (click)="configureRule.emit()"
                *ngIf="canUpdateRule && selectedRule?.isEditable"
                [matTooltipClass]="'regular-tooltip capitalize-first-letter'"
                [matTooltip]="'IOT_DICTIONARY.CONFIGURE' | translate"
                class="light-button configure-rule-btn"
                mat-mini-fab
        >
            <mat-icon>settings</mat-icon>
        </button>
    </div>

    <div class="po-event-info--configuration-container" fxLayout="row wrap" fxLayoutAlign="start center">
        <iot-platform-ui-detail-popup-display-property
                [property]="'PO_EVENTS.CREATION_CONFIGURATION_POPUP.BACK_TO_NORMAL' | translate"
                [value]="'IOT_DICTIONARY.' + selectedRule.backToNormal | translate"
                fxFlex="33%"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
                [property]="'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CLASS' | translate"
                [value]="selectedRule.class"
                fxFlex="33%"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
                [property]="'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONCEPT' | translate"
                [value]="selectedRule.concept ? ('IOT_DICTIONARY.' + selectedRule.concept | translate) : '-'"
                fxFlex="33%"
        ></iot-platform-ui-detail-popup-display-property>
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.SEVERITY' | translate }}</span>
            <iot-platform-ui-event-severity-display
                    [severity]="selectedRule.severity.toLowerCase()"></iot-platform-ui-event-severity-display>
        </div>
        <iot-platform-ui-detail-popup-display-property
                *ngFor="let param of selectedRule.params"
                [property]="'PO_EVENTS.CREATION_CONFIGURATION_POPUP.INPUT_LIST.' + param.name | translate"
                [value]="getTypeof(param.value) === 'number' ? (param.value | numberFormat) : param.value"
                fxFlex="33%"
        ></iot-platform-ui-detail-popup-display-property>
    </div>
</div>

<div *ngIf="!!selectedRule" class="po-event-info--container" fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <iot-platform-ui-detail-popup-section-header
                    [headerTitle]="'IOT_DICTIONARY.FILTERS' | translate | uppercase"
                    [separatorLinePosition]="'NONE'"
                    fxFlex
            ></iot-platform-ui-detail-popup-section-header>
        </div>
    </div>
    <mat-chip-set *ngIf="selectedRule.filters" fxLayout="row wrap">
        <iot-platform-ui-chip *ngFor="let filter of selectedRule.filters" [chipButton]="{ display: false }"
                              [chip]="filter"></iot-platform-ui-chip>
    </mat-chip-set>
    <span *ngIf="!selectedRule.filters"
          class="po-event-info--no-filter">{{ 'TOPICS.SHELL.NO_FILTER' | translate }}</span>
</div>
