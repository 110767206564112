import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DetailPopupCardsModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { Asset } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, DetailPopupCardsModule, DetailPopupModule, TranslateModule, NgFor, NgIf],
  selector: 'shared-iot4bos-asset-ui-followed-variables-details',
  templateUrl: './followed-variables-details.component.html',
  styleUrls: ['./followed-variables-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowedVariablesDetailsComponent {
  @Input() asset: Asset | null = null;

  maxFollowedNumber = 3;
  arrayToIterateForFollowedNumbers = new Array(this.maxFollowedNumber).fill(null).map((x, i) => i + 1);
}
