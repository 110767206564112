<mat-sidenav-container (backdropClick)="onCloseComments()" data-cy="iot4bos-ui-devices-shell" fxFlex hasBackdrop="true">
  <mat-sidenav-content fxLayout="column">
    <iot-platform-ui-toolbar-v2
      (dispatchToolbarEvent)="onToolbarEvent($event)"
      [autoRefresh]="{
        counter: 100 - ((timerValue$ | async) / (deviceGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay) * 100,
        timeLeft: (deviceGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay - (timerValue$ | async),
        displaySpinner: (deviceGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.enabled
      }"
      [buttonList]="masterViewDevicesButtonList"
      [dashboardsConfiguration]="dashboardsConfiguration()"
      [favoriteViewConfiguration]="deviceFavoriteViewsConfiguration$ | async"
      [gridsConfiguration]="deviceGridsConfiguration$ | async"
      [isDataLoaded]="devicesLoaded$ | async"
      [name]="'DEVICES.NAME_TOOLBAR' | translate"
      [pageTypeOptions]="{
        visible: true,
        pageType: pageType$ | async,
        options: [DEFAULT_PAGE_TYPE_OPTION_GRID, DEFAULT_PAGE_TYPE_OPTION_MAP, DEFAULT_PAGE_TYPE_OPTION_DASHBOARD]
      }"
      [size]="toolbarSize"
      [total]="total$ | async"
    >
    </iot-platform-ui-toolbar-v2>

    <iot-platform-ui-filter-engine
      (applyFilters)="onApplyFilters($event)"
      [currentFavoriteView]="currentFavoriteView$ | async"
      [currentFilters]="currentFilters$ | async"
      [displayActionButtons]="true"
      [expanded]="filterEngineOpened"
      [masterView]="'devices'"
      [withFavoriteFilters]="true"
    >
    </iot-platform-ui-filter-engine>

    <div class="layout-master-view-engine-container">
      @switch (pageType$ | async) {
        @case (ToolbarPageType.GRID) {
          <grid-engine-grid-manager
            data-cy="grid-engine-grid-manager"
            (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
            (timerValueChanged)="_timerValue$.next($event)"
            [currentFilters]="currentFilters$ | async"
            [gridSort]="gridSort$ | async"
            [grid]="grid$ | async"
            [userPermissions]="userPermissions"
            [visibleNodeId]="selectedRowId"
            class="layout-ag-grid-shell"
            fxFlex
          ></grid-engine-grid-manager>
        }
        @case (ToolbarPageType.MAP) {
          @if (currentBusinessProfile) {
            <iot-platform-maps-map
              data-cy="iot-platform-maps-map"
              (dispatchEvent)="onMapEvent($event)"
              (dispatchMapNavigationEvent)="onMapNavigationEvent($event)"
              *ngIf="currentBusinessProfile"
              [concept]="concept"
              [displayMode]="displayMode$ | async"
              [filters]="currentFilters$ | async"
              [zoom]="3"
              fxFlex
            ></iot-platform-maps-map>
          }
        }
        @case (ToolbarPageType.DASHBOARD) {
          <iot-platform-dashboards-dashboards-shell
            [currentDashboard]="currentDashboard()"
            [currentFilters]="currentFilters$ | async"
          ></iot-platform-dashboards-dashboards-shell>
        }
        @default {
          <grid-engine-grid-manager
            data-cy="grid-engine-grid-manager"
            (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
            (timerValueChanged)="_timerValue$.next($event)"
            [currentFilters]="currentFilters$ | async"
            [gridSort]="gridSort$ | async"
            [grid]="grid$ | async"
            [userPermissions]="userPermissions"
            [visibleNodeId]="selectedRowId"
            class="layout-ag-grid-shell"
            fxFlex
          ></grid-engine-grid-manager>
        }
      }
    </div>
  </mat-sidenav-content>

  <mat-sidenav #sidenav disableClose position="end">
    <shared-iot4bos-ui-comments-panel
      (commentsEvent)="onCommentsEvent($event)"
      [canDeleteComment]="canReadDevice"
      [canEditComment]="canReadDevice"
      [comments]="deviceComments()"
      [defaultConcept]="CONCEPT.DEVICE"
      [filters]="commentsContexts"
      [loading]="deviceCommentsLoading()"
      [withFilters]="true"
      [withFooter]="canReadDevice"
      data-cy="iot4bos-ui-devices-comments"
    >
    </shared-iot4bos-ui-comments-panel>
  </mat-sidenav>
</mat-sidenav-container>
