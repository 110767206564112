import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BusinessProfile } from '@iot-platform/models/common';

export const signInSuccess = createAction('[Auth/API] Sign In Success', props<{ cognitoUser: CognitoUser }>());
export const signInFailure = createAction('[Auth/API] Sign In Failure', props<{ error: any }>());

export const signInWithSSOSuccess = createAction(
  '[Auth/API] Sign In SSO Success',
  props<{ idToken: string; accessToken: string; refreshToken: string; expiresIn: number }>()
);
export const signInWithSSOFailure = createAction('[Auth/API] Sign In SSO Failure', props<{ error: HttpErrorResponse }>());

export const loadAccountSuccess = createAction('[Auth/API] Load Account Success', props<{ account: any; withRedirect?: boolean }>());
export const loadAccountFailure = createAction('[Auth/API] Load Account Failure', props<{ error: any }>());

export const loadPrivilegesSuccess = createAction('[Auth/API] Load Privileges Success', props<{ privileges: any }>());
export const loadPrivilegesFailure = createAction('[Auth/API] Load Privileges Failure', props<{ error: any }>());

export const signOutSuccess = createAction('[Auth/API] Sign Out Success');
export const signOutFailure = createAction('[Auth/API] Sign Out Failure', props<{ error: any }>());

export const changePasswordSuccess = createAction('[Auth/API] Change Password Success', props<{ result: any }>());
export const changePasswordFailure = createAction('[Auth/API] Change Password Failure', props<{ error: any }>());

export const forgotPasswordSuccess = createAction('[Auth/API] Forgot Password Success', props<{ result: any }>());
export const forgotPasswordFailure = createAction('[Auth/API] Forgot Password Failure', props<{ error: any }>());

export const updatePasswordWithCodeSuccess = createAction('[Auth/Page] Update Password with code Success', props<{ result: any }>());
export const updatePasswordWithCodeFailure = createAction('[Auth/Page] Update Password with code Failure', props<{ error: any }>());

export const retrieveSsoSessionSuccess = createAction(
  '[Auth/Page] Retrieve SSO Session Success',
  props<{ idToken: string; accessToken: string; refreshToken: string }>()
);
export const retrieveSsoSessionFailure = createAction('[Auth/Page] Retrieve SSO Session Failure', props<{ error: any }>());

export const refreshSsoTokensSuccess = createAction(
  '[Auth/Page] Refresh SSO Tokens Success',
  props<{ idToken: string; accessToken: string; tokenType: string; expiresIn: number }>()
);
export const refreshSsoTokensFailure = createAction('[Auth/Page] Refresh SSO Tokens Failure', props<{ error: any }>());

export const retrieveSession = createAction('[Auth/Page] Retrieve Session');
export const retrieveSessionSuccess = createAction('[Auth/Page] Retrieve Session Success', props<{ cognitoUser: CognitoUser }>());
export const retrieveSessionFailure = createAction('[Auth/Page] Retrieve Session Failure', props<{ error: any }>());

export const storeSession = createAction('[Auth/Page] Store Session', props<{ session: any }>());
export const storeSessionSuccess = createAction('[Auth/Page] Store Session Success');
export const storeSessionFailure = createAction('[Auth/Page] Store Session Failure');

export const refreshToken = createAction('[Auth/Page] Refresh Token');
export const refreshTokenSuccess = createAction('[Auth/Page] Refresh Token Success', props<{ refreshed: CognitoUser }>());
export const refreshTokenFailure = createAction('[Auth/Page] Refresh Token Failure', props<{ error: any }>());

export const checkIfUserIsAdmin = createAction('[Auth/Page] Check If Is Admin', props<{ entityId: string; userId: string }>());
export const checkIfUserIsAdminSuccess = createAction('[Auth/Page] Check If Is Admin Success', props<{ isAdminUser: boolean }>());
export const checkIfUserIsAdminFailure = createAction('[Auth/Page] Check If Is Admin Failure', props<{ error: any }>());

export const setInitialStateFromStore = createAction('[Auth/Page] Set initial state from store', props<{ initialState: any }>());
export const setBusinessProfiles = createAction('[Auth/API] Set Business Profiles', props<{ businessProfiles: BusinessProfile[] }>());
