import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterCriteriaConfiguration } from '../models';
import { FilterEngineSettingsService } from './filter-engine-settings.service';

@Injectable()
export class FilterEngineService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly filterEngineSettingsService: FilterEngineSettingsService = inject(FilterEngineSettingsService);

  getFilterCriteriaByConcept(masterView: string): Observable<FilterCriteriaConfiguration[]> {
    return this.httpClient.get<FilterCriteriaConfiguration[]>(`assets/engines/filters/filter-criteria-${masterView}.json`).pipe(
      map((data) => {
        let settings = [...data];
        if (this.filterEngineSettingsService.options) {
          settings = this.mergeAdditionalOptions(this.filterEngineSettingsService.options[masterView], data);
        }
        return this.sortFilterCriteria(settings);
      })
    );
  }

  sortFilterCriteria(filterCriteria: FilterCriteriaConfiguration[]): FilterCriteriaConfiguration[] {
    filterCriteria.sort(SortUtil.sortByOrder);
    filterCriteria.forEach((criteria: FilterCriteriaConfiguration) =>
      Array.isArray(criteria.options) ? criteria.options.sort(SortUtil.sortByOrder) : criteria
    );
    return filterCriteria;
  }

  private mergeAdditionalOptions(masterViewSettings: any, configurations: FilterCriteriaConfiguration[]): FilterCriteriaConfiguration[] {
    if (masterViewSettings) {
      return configurations.reduce((acc: FilterCriteriaConfiguration[], item: FilterCriteriaConfiguration) => {
        const category = masterViewSettings[item.key];
        if (category) {
          item.options = item.options.map((option: any) => {
            const filter = category[option.key];
            if (filter) {
              option.options = { ...option.options, ...filter };
            }
            return option;
          });
        }
        return [...acc, item];
      }, []);
    }
    return configurations;
  }
}
