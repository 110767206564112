import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-subscription-button',
  templateUrl: './subscription-button.component.html',
  styleUrls: ['./subscription-button.component.scss']
})
export class SubscriptionButtonComponent {
  @Input() isCurrentUserSubscribed!: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click: EventEmitter<any> = new EventEmitter();
}
