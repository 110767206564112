<div class="chat-message-container" fxLayoutGap="4px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon [svgIcon]="'smart_toy'" color="accent" class="message-icon"></mat-icon>
    <span class="message-author">{{ 'AI Bot' }}</span>
    <span class="message-date">{{ source?.datetime | dateFormat }}</span>
  </div>
  <div class="message-value">
    <a [href]="source.url" target="_blank">{{ source.comment }}</a>
  </div>
</div>
