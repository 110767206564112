import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

import { BusinessProfile, FavoriteView, Filter } from '@iot-platform/models/common';

import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

/* FIXME
    Till the moment the following import causes an exception that broke the app execution :
    import { FavoriteViewsService } from '@iot-platform/shared/components';
    Uncaught TypeError: Cannot read properties of undefined (reading 'AuthBusinessProfilesPageActions')
        at Module.AuthBusinessProfilesPageActions (audit-trail.service.ts:10:21)
        at 64186 (favorite-views-api.reducer.ts:33:6)
*/
import { FavoriteViewsService } from 'libs/shared/src/lib/components/favorite-views/services/favorite-views.service';
import { BehaviorSubject, forkJoin, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { FilterEngineModule } from '../filter-engine';

enum GridTypes {
  DEFAULT_GRID = 'defaultGrid',
  USER_GRID = 'userGrid',
  SHARED_GRIDS = 'sharedGrid'
}

@Component({
  selector: 'iot-platform-ui-favorite-view-form',
  templateUrl: './favorite-view-form.component.html',
  styleUrls: ['./favorite-view-form.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    ColorPickerModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    DetailPopupModule,
    MatTabsModule,
    MatSelectModule,
    FilterEngineModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    AsyncPipe,
    UpperCasePipe,
    MatInputModule
  ]
})
export class FavoriteViewFormComponent implements OnInit, OnDestroy {
  favoriteViewForm: UntypedFormGroup;
  favoriteViewNames: string[] = [];
  bPName = { businessProfileName: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).name };
  mode: { creation: boolean; edition: boolean };
  businessProfileList: BusinessProfile[] = [];
  currentFilters: Filter[] = [];
  currentFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  currentFavoriteView$ = of(null);
  clearAppliedFilters$ = of(false);
  displayDuplicateMessage$: Subject<boolean> = new Subject<boolean>();
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  checkingName$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  GridTypes = GridTypes;
  allGrids: I4BGrid<I4BGridOptions, I4BGridData>[] = [];
  userGrids: I4BGrid<I4BGridOptions, I4BGridData>[] = [];
  sharedGrids: I4BGrid<I4BGridOptions, I4BGridData>[] = [];
  selectedGrid?: I4BGrid<I4BGridOptions, I4BGridData>;
  isSelectedGridIdValid = false;
  sharedBoxChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  destroyed$: Subject<boolean> = new Subject<boolean>();
  enabledGridTab: string[] = ['sites', 'assets', 'devices', 'device-events', 'asset-events'];

  constructor(
    private readonly translateService: TranslateService,
    private readonly favoriteViewsService: FavoriteViewsService,
    public dialogRef: MatDialogRef<FavoriteViewFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      favoriteView: FavoriteView;
      canUpdateBusinessProfile: boolean;
      readOnly?: boolean;
      grids?: I4BGrid<I4BGridOptions, I4BGridData>[];
      adminMode?: boolean;
      duplicateMode?: boolean;
      forcedShare?: boolean;
      userId?: string;
    },
    private readonly storage: LocalStorageService
  ) {
    const keyboard$ = fromEvent(document, 'keyup');
    keyboard$
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this.checkingName$.next(true);
          this.isDisabled$.next(true);
        }),
        debounceTime(500),
        switchMap(() => {
          this.checkingName$.next(false);
          if (this.name.value && this.businessProfileId.value) {
            return this.checkBusinessProfileNameUnicity();
          } else {
            return of(null);
          }
        })
      )
      .subscribe();
  }

  get title$(): Observable<string> {
    if (this.data.favoriteView.id) {
      return this.data.duplicateMode
        ? this.translateService.get('FAVORITE_VIEW.FORM.DUPLICATE_FAVORITE_VIEW', { favoriteViewName: this.data.favoriteView.name })
        : this.translateService.get('FAVORITE_VIEW.FORM.EDIT_FAVORITE_VIEW', { favoriteViewName: this.data.favoriteView.name });
    }
    return this.translateService.get('FAVORITE_VIEW.FORM.CREATE_FAVORITE_VIEW');
  }

  get errorMessage$(): Observable<string> {
    return this.translateService.get('FAVORITE_VIEW.FORM.ERROR_MESSAGE.REQUIRED');
  }

  get name(): AbstractControl {
    return this.favoriteViewForm.get('name');
  }

  get color(): AbstractControl {
    return this.favoriteViewForm.get('color');
  }

  get shared(): AbstractControl {
    return this.favoriteViewForm.get('shared');
  }

  get pinned(): AbstractControl {
    return this.favoriteViewForm.get('pinned');
  }

  get description(): AbstractControl {
    return this.favoriteViewForm.get('description');
  }

  get businessProfileId(): AbstractControl {
    return this.favoriteViewForm?.get('businessProfileId');
  }

  get filters(): AbstractControl {
    return this.favoriteViewForm?.get('filters');
  }

  get selectedGridType(): AbstractControl {
    return this.favoriteViewForm?.get('selectedGridType');
  }

  get selectedGridId(): AbstractControl {
    return this.favoriteViewForm?.get('selectedGridId');
  }

  ngOnInit() {
    this.allGrids = this.data.grids ?? [];
    this.initForm();
    this.setUserAndSharedGrids();
    this.setSelectedGridType();

    if (this.data.canUpdateBusinessProfile) {
      this.favoriteViewsService
        .getBusinessProfiles()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((bps) => {
          this.businessProfileList = bps;
          this.businessProfileId.setValue(
            this.businessProfileList.find((bp) => {
              if (this.data.favoriteView.businessProfileId) {
                return bp.id === this.data.favoriteView.businessProfileId;
              } else {
                return bp.id === JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).id;
              }
            })?.id
          );
          if (!this.data.adminMode && !this.data.duplicateMode) {
            this.businessProfileId.disable();
          }
        });
    } else {
      this.businessProfileId.setValue(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).id);
      if (!this.data.adminMode && !this.data.duplicateMode) {
        this.businessProfileId.disable();
      }
    }

    if (!this.data.grids && this.data.favoriteView.masterView) {
      this.businessProfileId.valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          take(this.data.duplicateMode ? 1 : 500),
          switchMap((businessProfileId) => {
            if (businessProfileId) {
              return forkJoin([
                this.favoriteViewsService.getGridsByMasterViewAndBusinessProfileId(this.data.favoriteView.masterView, businessProfileId),
                this.favoriteViewsService.getPersonalGridByUserId(this.data.favoriteView.masterView, this.data.userId)
              ]);
            } else {
              return of([]);
            }
          })
        )
        .subscribe(([sharedGrids, userGrids]) => {
          this.allGrids = sharedGrids.concat(userGrids);
          this.setUserAndSharedGrids();
          this.setSelectedGridType();
        });
    }

    this.favoriteViewForm.statusChanges.pipe(takeUntil(this.destroyed$), take(1)).subscribe((status) => {
      this.mode = { creation: status === 'INVALID', edition: status === 'PENDING' };
    });

    if (this.data.adminMode || this.data.duplicateMode) {
      this.businessProfileId.enable();
    }

    if (this.data.duplicateMode) {
      this.pinned.disable();
      this.selectedGridType.disable();
      this.selectedGridId.disable();
      this.filters.disable();
    }

    this.currentFilters$.next(this.filters.value);
  }

  initForm(): void {
    this.favoriteViewForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.getNameForFavoriteViewForm(), [Validators.required, Validators.maxLength(50), Validators.pattern('\\S.*')]),
      color: new UntypedFormControl(this.data.favoriteView.color ?? '', [Validators.required]),
      shared: new UntypedFormControl({
        value: (this.data.favoriteView.shared || this.data.forcedShare) ?? false,
        disabled: (this.data.favoriteView.shared || this.data.forcedShare) ?? false
      }),
      pinned: new UntypedFormControl(this.data.favoriteView.pinned ?? false),
      businessProfileId: new UntypedFormControl(this.data.favoriteView.businessProfileId, [Validators.required]),
      description: new UntypedFormControl(this.data.favoriteView.description ?? '', [Validators.maxLength(200)]),
      filters: new UntypedFormControl(this.data.favoriteView.filters ?? [], [Validators.required, Validators.minLength(1)]),
      selectedGridType: new UntypedFormControl(GridTypes.DEFAULT_GRID),
      selectedGridId: new UntypedFormControl(null)
    });

    if (this.data.readOnly) {
      this.favoriteViewForm.controls.name.disable();
      this.favoriteViewForm.controls.color.disable();
      this.favoriteViewForm.controls.shared.disable();
      this.favoriteViewForm.controls.pinned.disable();
    }
  }

  getNameForFavoriteViewForm(): string {
    if (this.data.favoriteView.name) {
      return this.data.duplicateMode
        ? this.translateService.instant('FAVORITE_VIEW.FORM.DUPLICATE_NAME', { favoriteViewName: this.data.favoriteView.name.trim() })
        : this.data.favoriteView.name.trim();
    }
    return '';
  }

  onSelectColor(selectedColor: string): void {
    this.color.setValue(selectedColor);
  }

  checkBusinessProfileNameUnicity() {
    this.isDisabled$.next(true);

    return this.sharedBoxChanged$.pipe(takeUntil(this.destroyed$)).pipe(
      switchMap((shared) =>
        this.favoriteViewsService.getFavoriteViewsByBusinessProfile(this.businessProfileId.value, this.data?.favoriteView?.masterView, shared).pipe(
          tap((favoriteViews) => {
            const favoriteViewNames = favoriteViews
              .filter((fv) => (this.data.favoriteView.id ? fv.id !== this.data.favoriteView.id : fv))
              .map((favoriteView: FavoriteView) => favoriteView.name.toLowerCase());
            const isFVNameTaken: boolean = favoriteViewNames.includes(this.name.value.toLowerCase());
            this.isDisabled$.next(isFVNameTaken && this.name.touched);
            this.displayDuplicateMessage$.next(isFVNameTaken);
          })
        )
      )
    );
  }

  onBusinessProfileChange() {
    if (this.name.value && this.businessProfileId.value) {
      this.checkBusinessProfileNameUnicity().pipe(takeUntil(this.destroyed$)).subscribe();
    }
  }

  onApplyFilters(filters: Filter[]): void {
    this.filters.patchValue(filters);
    this.currentFilters$.next(this.filters.value);
  }

  onGridTypeChange() {
    this.selectedGridId.patchValue(null);
    this.selectedGrid = undefined;
    this.setIsSelectedGridIdValid();
  }

  onSelectedGridChange(event: MatSelectChange) {
    this.selectedGridId.patchValue(event.value.id);
    this.setIsSelectedGridIdValid();
  }

  setUserAndSharedGrids(): void {
    this.userGrids = this.allGrids?.filter((g) => !g.businessProfileId && !!g.userId && !g.isDefault) ?? [];
    this.sharedGrids = this.allGrids?.filter((g) => !!g.businessProfileId) ?? [];
  }

  setIsSelectedGridIdValid(): void {
    this.isSelectedGridIdValid =
      this.selectedGridType.value === GridTypes.DEFAULT_GRID && !this.selectedGridId.value
        ? true
        : this.selectedGridType.value !== GridTypes.DEFAULT_GRID && !!this.selectedGridId.value;
  }

  setSelectedGridType(): void {
    if (!this.data.favoriteView.gridId) {
      this.selectedGridType.patchValue(GridTypes.DEFAULT_GRID);
      this.selectedGridId.patchValue(null);
    } else {
      const selectedGridShared = this.sharedGrids.find((g) => g.id === this.data.favoriteView?.gridId);
      const selectedGridUser = this.userGrids.find((g) => g.id === this.data.favoriteView?.gridId);

      if (selectedGridShared) {
        this.selectedGridType.patchValue(GridTypes.SHARED_GRIDS);
        this.selectedGridId.patchValue(this.data.favoriteView.gridId);
        this.selectedGrid = selectedGridShared;
      }
      if (selectedGridUser) {
        this.selectedGridType.patchValue(GridTypes.USER_GRID);
        this.selectedGridId.patchValue(this.data.favoriteView.gridId);
        this.selectedGrid = selectedGridUser;
      }
      if (!selectedGridUser && !selectedGridShared) {
        this.selectedGridType.patchValue(GridTypes.DEFAULT_GRID);
        this.selectedGridId.patchValue(null);
        this.selectedGrid = undefined;
      }
    }
    this.setIsSelectedGridIdValid();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.favoriteViewForm.valid) {
      if (this.data.duplicateMode) {
        this.saveForDuplicationMode();
      } else if (this.data.adminMode && !this.data.duplicateMode) {
        this.saveForAdminEditionMode();
      } else {
        this.saveForCreationMode();
      }
    }
  }

  saveForCreationMode(): void {
    const favoriteView: FavoriteView = {
      ...this.data.favoriteView,
      name: this.name.value.trim(),
      color: this.color.value,
      shared: this.shared.value,
      pinned: this.pinned.value,
      description: this.description.value,
      businessProfileId: this.businessProfileId.value,
      filters: this.filters.value,
      gridId: this.selectedGridId.value
    };
    this.dialogRef.close({
      grid: this.selectedGridType.value === GridTypes.USER_GRID && favoriteView.shared ? this.selectedGrid : null,
      favoriteView
    });
  }

  saveForDuplicationMode(): void {
    const favoriteView: FavoriteView = {
      name: this.name.value.trim(),
      color: this.color.value,
      shared: this.shared.value,
      pinned: this.pinned.value,
      description: this.description.value,
      businessProfileId: this.businessProfileId.value,
      filters: this.filters.value,
      gridId: this.selectedGridId.value,
      concept: this.data.favoriteView.concept,
      masterView: this.data.favoriteView.masterView
    };
    this.dialogRef.close({ grid: this.selectedGrid, favoriteView });
  }

  saveForAdminEditionMode(): void {
    const favoriteView: FavoriteView = {
      name: this.name.value.trim(),
      color: this.color.value,
      shared: this.shared.value,
      pinned: this.pinned.value,
      description: this.description.value,
      businessProfileId: this.businessProfileId.value,
      filters: this.filters.value,
      gridId: this.selectedGridId.value,
      concept: this.data.favoriteView.concept,
      masterView: this.data.favoriteView.masterView
    };
    this.dialogRef.close({
      grid: this.selectedGridType.value === GridTypes.USER_GRID && favoriteView.shared ? this.selectedGrid : null,
      favoriteView
    });
  }

  displayGridTab() {
    if (this.data.favoriteView.masterView) {
      return this.enabledGridTab.includes(this.data.favoriteView.masterView.toLowerCase());
    } else {
      return false;
    }
  }

  filtersNumber(): number {
    return this.filters.value ? this.filters.value.filter((filter: Filter) => filter.criteriaKey !== 'includeSubEntities').length : 0;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
